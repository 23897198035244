import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogBody,
} from '@/components/dialog'
import { Dispatch, SetStateAction } from 'react'

export function TripTnCModal({
  modalControls,
}: {
  modalControls: {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
  }
}) {
  return (
    <Dialog
      open={modalControls.isOpen}
      onOpenChange={() => modalControls.setIsOpen(!modalControls.isOpen)}
      //   size="fullscreen"
      modal
      scrollBehavior="outside"
      variant={'modal'}
    >
      <DialogContent minH="full" aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>Terms and Conditions</DialogTitle>
        </DialogHeader>
        <DialogBody>
          T & C content goes here. Is rich text coming from Contentful and will
          display accordingly. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Voluptates quam distinctio beatae. Beatae nobis
          dolores nihil tempora, debitis saepe sunt quo maiores. Adipisci labore
          delectus esse dolorum in deserunt aspernatur. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Voluptates quam distinctio beatae.
          Beatae nobis dolores nihil tempora, debitis saepe sunt quo maiores.
          <br /> Adipisci labore delectus esse dolorum in deserunt aspernatur.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          quam distinctio beatae. Beatae nobis dolores nihil tempora, debitis
          saepe sunt quo maiores. Adipisci labore delectus esse dolorum in
          deserunt aspernatur. <br /> <br />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          quam distinctio beatae. Beatae nobis dolores nihil tempora, debitis
          saepe sunt quo maiores. Adipisci labore delectus esse dolorum in
          deserunt aspernatur.Lorem ipsum dolor sit amet consectetur adipisicing
          elit. Voluptates quam distinctio beatae. Beatae nobis dolores nihil
          tempora, debitis saepe sunt quo maiores. Adipisci labore delectus esse
          dolorum in deserunt aspernatur. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Voluptates quam distinctio beatae. Beatae nobis
          dolores nihil tempora, debitis saepe sunt quo maiores. Adipisci labore
          delectus esse dolorum in deserunt aspernatur.Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Voluptates quam distinctio beatae.
          Beatae nobis dolores nihil tempora, debitis saepe sunt quo maiores.
          Adipisci labore delectus esse dolorum in deserunt aspernatur. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Voluptates quam
          distinctio beatae. Beatae nobis dolores nihil tempora, debitis saepe
          sunt quo maiores. Adipisci labore delectus esse dolorum in deserunt
          aspernatur.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptates quam distinctio beatae. Beatae nobis dolores nihil tempora,
          debitis saepe sunt quo maiores. Adipisci labore delectus esse dolorum
          in deserunt aspernatur. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Voluptates quam distinctio beatae. Beatae nobis
          dolores nihil tempora, debitis saepe sunt quo maiores. Adipisci labore
          delectus esse dolorum in deserunt aspernatur.Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Voluptates quam distinctio beatae.
          Beatae nobis dolores nihil tempora, debitis saepe sunt quo maiores.
          Adipisci labore delectus esse dolorum in deserunt aspernatur. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Voluptates quam
          distinctio beatae. Beatae nobis dolores nihil tempora, debitis saepe
          sunt quo maiores. Adipisci labore delectus esse dolorum in deserunt
          aspernatur.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptates quam distinctio beatae. Beatae nobis dolores nihil tempora,
          debitis saepe sunt quo maiores. Adipisci labore delectus esse dolorum
          in deserunt aspernatur. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Voluptates quam distinctio beatae. Beatae nobis
          dolores nihil tempora, debitis saepe sunt quo maiores. Adipisci labore
          delectus esse dolorum in deserunt aspernatur.Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Voluptates quam distinctio beatae.
          Beatae nobis dolores nihil tempora, debitis saepe sunt quo maiores.
          Adipisci labore delectus esse dolorum in deserunt aspernatur. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Voluptates quam
          distinctio beatae. Beatae nobis dolores nihil tempora, debitis saepe
          sunt quo maiores. Adipisci labore delectus esse dolorum in deserunt
          aspernatur.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptates quam distinctio beatae. Beatae nobis dolores nihil tempora,
          debitis saepe sunt quo maiores. Adipisci labore delectus esse dolorum
          in deserunt aspernatur. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Voluptates quam distinctio beatae. Beatae nobis
          dolores nihil tempora, debitis saepe sunt quo maiores. Adipisci labore
          delectus esse dolorum in deserunt aspernatur.Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Voluptates quam distinctio beatae.
          Beatae nobis dolores nihil tempora, debitis saepe sunt quo maiores.
          Adipisci labore delectus esse dolorum in deserunt aspernatur. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Voluptates quam
          distinctio beatae. Beatae nobis dolores nihil tempora, debitis saepe
          sunt quo maiores. Adipisci labore delectus esse dolorum in deserunt
          aspernatur.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptates quam distinctio beatae. Beatae nobis dolores nihil tempora,
          debitis saepe sunt quo maiores. Adipisci labore delectus esse dolorum
          in deserunt aspernatur. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Voluptates quam distinctio beatae. Beatae nobis
          dolores nihil tempora, debitis saepe sunt quo maiores. Adipisci labore
          delectus esse dolorum in deserunt aspernatur.Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Voluptates quam distinctio beatae.
          Beatae nobis dolores nihil tempora, debitis saepe sunt quo maiores.
          Adipisci labore delectus esse dolorum in deserunt aspernatur. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Voluptates quam
          distinctio beatae. Beatae nobis dolores nihil tempora, debitis saepe
          sunt quo maiores. Adipisci labore delectus esse dolorum in deserunt
          aspernatur.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptates quam distinctio beatae. Beatae nobis dolores nihil tempora,
          debitis saepe sunt quo maiores. Adipisci labore delectus esse dolorum
          in deserunt aspernatur. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Voluptates quam distinctio beatae. Beatae nobis
          dolores nihil tempora, debitis saepe sunt quo maiores. Adipisci labore
          delectus esse dolorum in deserunt aspernatur.Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Voluptates quam distinctio beatae.
          Beatae nobis dolores nihil tempora, debitis saepe sunt quo maiores.
          Adipisci labore delectus esse dolorum in deserunt aspernatur. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Voluptates quam
          distinctio beatae. Beatae nobis dolores nihil tempora, debitis saepe
          sunt quo maiores. Adipisci labore delectus esse dolorum in deserunt
          aspernatur.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptates quam distinctio beatae. Beatae nobis dolores nihil tempora,
          debitis saepe sunt quo maiores. Adipisci labore delectus esse dolorum
          in deserunt aspernatur. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Voluptates quam distinctio beatae. Beatae nobis
          dolores nihil tempora, debitis saepe sunt quo maiores. Adipisci labore
          delectus esse dolorum in deserunt aspernatur.Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Voluptates quam distinctio beatae.
          Beatae nobis dolores nihil tempora, debitis saepe sunt quo maiores.
          Adipisci labore delectus esse dolorum in deserunt aspernatur. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Voluptates quam
          distinctio beatae. Beatae nobis dolores nihil tempora, debitis saepe
          sunt quo maiores. Adipisci labore delectus esse dolorum in deserunt
          aspernatur.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptates quam distinctio beatae. Beatae nobis dolores nihil tempora,
          debitis saepe sunt quo maiores. Adipisci labore delectus esse dolorum
          in deserunt aspernatur. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Voluptates -quam distinctio beatae. Beatae nobis
          dolores nihil tempora, debitis saepe sunt quo maiores. Adipisci labore
          delectus esse dolorum in deserunt aspernatur.
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}
