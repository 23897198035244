import {
  resolveCta,
  resolveMedia,
  genericDataResolver,
  commonContentResolver,
} from '@/utility/content-resolvers'
import { debug } from '@/utility/logging'

import type { HeroProps } from '@/types/components/hero-section'
import type { MediaProps } from '@/types/interface'
import type { PageSlug } from '@/types/resolved-types'

export const dataResolver = (
  brand: string,
  data: Contentful.Models.Hero,
  isLead?: boolean,
  allSlugs?: PageSlug[],
) =>
  genericDataResolver<Contentful.Models.Hero, never, HeroProps>(
    data,
    brand,
    {
      id: '',
      brand: '',
      titleSize: 'heading1',
      title: '',
      text: '',
      eyebrow: '',
      seasonYear: '',
      buttons: [],
      gradient: 'medium',
      variant: 'tall',
      showBadge: false,
      badgeText: '',
      badgeType: undefined,
      tag: 'h1',
    },
    {}, // No item collections to resolve for Hero
    async (resolvedData, context): Promise<HeroProps> => {
      const resolvedButtons = data.cta
        ? await resolveCta(data.cta, context.locale, context.brand, allSlugs)
        : []

      const resolvedMedia = data.media
        ? resolveMedia(data.media as MediaProps)
        : {}

      debug('Hero data resolver', { data })

      return {
        ...commonContentResolver(data as never, isLead),
        brand: context.brand,
        eyebrow: data.eyebrow || '',
        buttons: resolvedButtons,
        gradient: (data.gradient || 'medium') as HeroProps['gradient'],
        variant: (data.variant || 'tall') as HeroProps['variant'],
        ...resolvedMedia,
      }
    },
  )
