import React from 'react'
import { Button } from '@/components/button'
import { MdArrowForwardIos } from 'react-icons/md'
import type { MenuItem as MenuItemType } from '../desktopNav/dropdownItem'
import type { MenuStackItem } from './mobileNav'
import { css } from '@/styled-system/css'

export const MenuItem = ({
  item,
  onOpenSubmenu,
  hasChildren,
}: {
  item: MenuItemType
  onOpenSubmenu: (item: MenuStackItem) => void
  hasChildren: boolean
}) => {
  return (
    <li>
      <Button
        variant="ghost"
        className={css({
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: 'font-size-3',
          fontFamily: 'font-family-logotype',
          ...(hasChildren && {
            textTransform: 'uppercase',
            fontWeight: 500,
            letterSpacing: '-0.5px',
          }),
          color: 'semantic-bg-reverse',
          minH: 40,
        })}
        onClick={() => onOpenSubmenu(item as MenuStackItem)}
      >
        {item.title}
        {item.items && <MdArrowForwardIos className="h-4 w-4" />}
      </Button>
    </li>
  )
}

MenuItem.displayName = 'MobileMenuItem'
