import { css } from '@/styled-system/css'

import { ReactNode } from 'react'

import { BLOCKS, Inline, Block } from '@contentful/rich-text-types'

import { Table, TableBody, TableCell, TableHead } from '@/components/table'
import { Title } from '@/components/title'

export const renderRichTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <p
          className={css({
            mb: 'semantic-spacing-24',

            '& > a': {
              color: 'semantic-color-typography-body-solid',
              textDecoration: 'underline',

              _hover: {
                color: 'semantic-primary-highlight',
              },
            },
          })}
        >
          {children}
        </p>
      )
    },

    [BLOCKS.OL_LIST]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <ol
          className={css({
            listStyleType: 'numeric',
            mb: 'semantic-spacing-16',
            ml: 'semantic-spacing-16',
            pl: 'semantic-spacing-2',
          })}
        >
          {children}
        </ol>
      )
    },

    [BLOCKS.UL_LIST]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <ul
          className={css({
            listStyleType: 'initial',
            mb: 'semantic-spacing-16',
            ml: 'semantic-spacing-16',
            pl: 'semantic-spacing-2',
          })}
        >
          {children}
        </ul>
      )
    },

    [BLOCKS.LIST_ITEM]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <li
          className={css({
            textStyle: 'semantic-text-md-font-regular',
            pb: 'semantic-spacing-8',
            '& > p': {
              display: 'inline',
            },
          })}
        >
          {children}
        </li>
      )
    },

    [BLOCKS.HEADING_1]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <Title
          className={css({ mb: 'semantic-spacing-24' })}
          format="standard"
          size="heading1"
          tag="h1"
        >
          {children}
        </Title>
      )
    },

    [BLOCKS.HEADING_2]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <Title
          className={css({ mb: 'semantic-spacing-24' })}
          format="standard"
          size="heading2"
          tag="h2"
        >
          {children}
        </Title>
      )
    },

    [BLOCKS.HEADING_3]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <Title
          className={css({ mb: 'semantic-spacing-24' })}
          format="standard"
          size="heading3"
          tag="h3"
        >
          {children}
        </Title>
      )
    },

    [BLOCKS.HEADING_4]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <Title
          className={css({ mb: 'semantic-spacing-24' })}
          format="standard"
          size="heading4"
          tag="h4"
        >
          {children}
        </Title>
      )
    },

    [BLOCKS.HEADING_5]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <Title
          className={css({ mb: 'semantic-spacing-24' })}
          format="standard"
          size="heading5"
          tag="h5"
        >
          {children}
        </Title>
      )
    },

    [BLOCKS.HEADING_6]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <Title
          className={css({ mb: 'semantic-spacing-24' })}
          format="standard"
          size="heading6"
          tag="h6"
        >
          {children}
        </Title>
      )
    },

    [BLOCKS.HR]: (_: Block | Inline): ReactNode => {
      return <hr />
    },

    [BLOCKS.QUOTE]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return <blockquote>{children}</blockquote>
    },

    [BLOCKS.TABLE]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return (
        <Table>
          <TableBody>{children}</TableBody>
        </Table>
      )
    },

    [BLOCKS.TABLE_ROW]: (_: Block | Inline, children: ReactNode): ReactNode => {
      return <tr>{children}</tr>
    },

    [BLOCKS.TABLE_CELL]: (
      _: Block | Inline,
      children: ReactNode,
    ): ReactNode => {
      return <TableCell>{children}</TableCell>
    },

    [BLOCKS.TABLE_HEADER_CELL]: (
      _: Block | Inline,
      children: ReactNode,
    ): ReactNode => {
      return <TableHead>{children}</TableHead>
    },
  },
}
