import { DatePicker, DatePickerPrimitive } from '@/components/date-picker'
import { LabelStyled } from './label-styled'
import { css } from '@/styled-system/css'

export function DateSelect({
  startDate,
  selectedDate,
  setSelectedDate,
  ...rest
}: Readonly<
  {
    setSelectedDate: (date: Date) => void
    startDate?: Date
    selectedDate: Date
  } & React.ComponentProps<typeof DatePickerPrimitive>
>) {
  return (
    <div
      className={css({
        my: 'semantic-spacing-32',
      })}
    >
      <LabelStyled>Date</LabelStyled>
      <DatePicker
        {...rest}
        startDate={startDate}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </div>
  )
}
