import Link from 'next/link'

import type { TitleProps } from '@/components/title'
import { RichtextRenderer } from '@/components/richtextEditor'

import type { Document as RichTextDocument } from '@contentful/rich-text-types'

import type { TripCard } from '@/global/components/trip-carousel'

import type { TestimonialCardProps } from '@/types/components'
import type { MediaProps, ButtonProps } from '@/types/interface'
import type {
  ResolvedCtaProps,
  ResolvedMediaProps,
} from '@/types/resolved-types'
import type { PageSlug } from '@/types/resolved-types'

type ResolverContext = {
  locale: string
  brand: string
}

type ContentfulContentTypes =
  | Contentful.Models.Accordion
  | Contentful.Models.AccordionItem
  | Contentful.Models.Article
  | Contentful.Models.ArticleCardGridCarousel
  | Contentful.Models.BrochureCardGridCarousel
  | Contentful.Models.BrochureCardItem
  | Contentful.Models.ContentSlider
  | Contentful.Models.ContentSliderItem
  | Contentful.Models.FeaturedItem
  | Contentful.Models.FeaturedItemListSection
  | Contentful.Models.FullBleedSection
  | Contentful.Models.GalleryItem
  | Contentful.Models.GridCarousel
  | Contentful.Models.Hero
  | Contentful.Models.MosaicCarousel
  | Contentful.Models.SplitFeatures
  | Contentful.Models.SplitTestimonials
  | Contentful.Models.SplitTestimonialItem
  | Contentful.Models.TabbedCarousel
  | Contentful.Models.TabbedCarouselItem
  | Contentful.Models.Trip
  | Contentful.Models.TripCarousel

type CommonContentfulProps = {
  sys: { id: string }
  title?: string
  description?: string
  image?: MediaProps
  cta?: ButtonProps
  lightboxCta?: ButtonProps
}

type CommonSectionProps = {
  sys: { id: string; locale?: string }
  showInNav?: boolean
  navTitle?: string
  title: string
  text?: {
    json: RichTextDocument
  }
  titleSize?: TitleProps['size'] | string
  headingTag?: TitleProps['tag'] | string
}

type CollectionResolvers<T extends ContentfulContentTypes, R> = {
  [key: string]: {
    collectionKey: string
    resolver: (
      item: T & CommonContentfulProps,
      context: ResolverContext,
    ) => Promise<R>
  }
}

/* 
    This utility function sanitizes the query string
    and returns a sanitized string
*/
export const sanitizeQueryString = (queryString: string): string => {
  if (typeof queryString !== 'string') return ''

  return queryString
    .replace(/^\?/, '')
    .split('&')
    .map((pair) => {
      const [key, value] = pair.split('=').map((part) => part.trim())
      if (!key) return ''
      return `${encodeURIComponent(decodeURIComponent(key))}=${encodeURIComponent(decodeURIComponent(value || ''))}`
    })
    .filter(Boolean)
    .join('&')
}
/* 
    This utility function resolves the media from the contentful data
    and returns an object with the resolved media props
*/
export const resolveMedia = (media: MediaProps): ResolvedMediaProps => {
  const { type, bynder, youtube } = media || {}
  const { original, alternativeText, description, internalId } =
    bynder?.[0] || {}

  return {
    type: type,
    ...(type === 'youtube' && {
      videoSource: youtube,
      posterImage: original?.url,
      videoType: type,
    }),
    ...(type === 'bynder' && {
      imageSource: original?.url,
      src: original?.url,
    }),
    as: type === 'youtube' ? 'video' : 'image',
    alt: alternativeText || description || '',
    description: description || '',
    mediaId: internalId || '',
  }
}
/* 
    This utility function resolves the CTA buttons from the contentful data
    and returns an array of objects with the resolved button props
*/

interface PageData {
  sysId: string
  __typename: string
  slug: string
  parentPage: string
  pageTitle: string
}

const safeJsonParse = (jsonString: string): PageData => {
  const defaultValue: PageData = {
    sysId: '',
    __typename: '',
    slug: '',
    parentPage: '',
    pageTitle: '',
  }

  try {
    // Attempt to parse the JSON
    const parsedData = (
      typeof jsonString === 'string' ? JSON.parse(jsonString) : jsonString
    ) as PageData

    // Return the parsed data, falling back to default values if any property is missing
    return {
      sysId: parsedData.sysId ?? defaultValue.sysId,
      __typename: parsedData.__typename ?? defaultValue.__typename,
      slug: parsedData.slug ?? defaultValue.slug,
      parentPage: parsedData.parentPage ?? defaultValue.parentPage,
      pageTitle: parsedData.pageTitle ?? defaultValue.pageTitle,
    }
  } catch (error) {
    console.error('Error parsing JSON:', error)
    return defaultValue
  }
}

export const resolveCta = async (
  cta: { buttons?: ButtonProps[] },
  _locale: string,
  _brand: string,
  slugs?: PageSlug[],
): Promise<ResolvedCtaProps[]> => {
  if (!cta?.buttons) return []

  return Promise.all(
    cta.buttons.map(async (button) => {
      const {
        label,
        variant,
        type,
        email,
        phone,
        query,
        target,
        pageProps,
        href,
      } = button

      const sanitizedQuery = query ? sanitizeQueryString(query) : ''
      let buttonProps: Record<string, string> = {}

      if (type === 'internal_link') {
        const parsedPageProps = (pageProps &&
          safeJsonParse(pageProps)) as PageData

        const resolvedPageSlugData = slugs?.find((slug) => {
          if (!parsedPageProps?.sysId || !slug.pageId) return null
          return String(slug.pageId) === String(parsedPageProps.sysId)
        })

        buttonProps = resolvedPageSlugData?.slug
          ? {
              as: Link as never,
              href: resolvedPageSlugData?.slug as string,
            }
          : {
              as: 'button',
            }
      } else if (type === 'external_link') {
        buttonProps = {
          as: 'a',
          href: href
            ? sanitizedQuery
              ? `${href}?${sanitizedQuery}`
              : href
            : '#',
          target: target || '_blank',
        }
      } else if (type === 'email') {
        buttonProps.href = `mailto:${email}`
      } else if (type === 'phone') {
        buttonProps.href = `tel:${phone}`
      }

      return {
        ...buttonProps,
        label: label,
        variant: variant as ResolvedCtaProps['variant'],
        href: buttonProps.href,
        title: label,
      }
    }),
  )
}
// Utility functions
export const resolveRichText = (json: RichTextDocument | undefined) => {
  if (!json) return null
  return <RichtextRenderer json={json} />
}
/*
    This utility function resolves the contentful data
    and returns an object with the resolved data
*/
export const genericDataResolver = async <
  T extends ContentfulContentTypes,
  R,
  P,
>(
  data: any,
  brand: string,
  defaultProps: P,
  collectionResolvers: CollectionResolvers<T, R>,
  dataTransformer?: (
    resolvedData: Partial<P>,
    context: ResolverContext,
  ) => Promise<P>,
): Promise<P> => {
  if (!data || !data.sys?.id) return defaultProps

  const context: ResolverContext = {
    locale: data.sys?.locale || 'en',
    brand,
  }
  const resolvedCollections: { [key: string]: any[] } = {}

  for (const [key, { collectionKey, resolver }] of Object.entries(
    collectionResolvers,
  )) {
    const entries = data[collectionKey]?.items || []

    const resolvedItems = await Promise.all(
      entries.map(async (item: T & CommonContentfulProps) => {
        return resolver({ ...item }, context)
      }),
    )

    resolvedCollections[key] = resolvedItems.filter(Boolean) ?? []
  }

  const resolvedData: Partial<P> = {
    ...defaultProps,
    ...resolvedCollections,
  }

  if (dataTransformer) {
    return await dataTransformer(resolvedData, context)
  }

  return resolvedData as P
}

export const commonContentResolver = (
  data: CommonSectionProps,
  isLead?: boolean,
) => {
  return {
    id: data?.sys?.id || '',
    locale: data?.sys?.locale || 'en',
    showInNav: data?.showInNav || false,
    navTitle: data?.navTitle || '',
    title: data?.title || '',
    titleSize: (data?.titleSize || 'heading3') as TitleProps['size'],
    text: data?.text?.json ? resolveRichText(data.text.json) : '',
    titleTag: (isLead ? 'h1' : 'h2') as TitleProps['tag'],
  }
}

export const resolveGalleryItem = async (
  item: Contentful.Models.GalleryItem,
  context?: { locale: string; brand: string },
  slugs?: PageSlug[],
) => {
  if (!item?.sys?.id)
    return {
      id: '',
      title: '',
      text: '',
      image: null,
      cta: null,
      lightboxCta: null,
    }

  const resolvedCta = item.cta
    ? await resolveCta(
        item.cta,
        context?.locale ?? 'en',
        context?.brand ?? '',
        slugs,
      )
    : []
  const resolvedLightboxCtaCta = item.lightboxCta
    ? await resolveCta(
        item.lightboxCta,
        context?.locale ?? 'en',
        context?.brand ?? '',
        slugs,
      )
    : []

  return {
    id: item.sys.id,
    title: item.title || '',
    text: item.text?.json ? resolveRichText(item.text.json) : null,
    longCaption: item.longCaption?.json
      ? resolveRichText(item.longCaption.json)
      : null,
    shortCaption: item.shortCaption || '',
    image: resolveMedia(item.image as MediaProps),
    cta: resolvedCta?.[0] as ResolvedCtaProps,
    lightboxCta: resolvedLightboxCtaCta?.[0] as ResolvedCtaProps,
  }
}

export const resolveIconText = async (
  item: Contentful.Models.FeaturedItem,
  context?: { locale: string; brand: string },
  slugs?: PageSlug[],
) => {
  const resolvedCta = item.cta
    ? await resolveCta(
        item.cta,
        context?.locale ?? 'en',
        context?.brand ?? '',
        slugs,
      )
    : []

  return {
    id: item.sys.id,
    title: item.title || '',
    text: item.text?.json ? resolveRichText(item.text.json) : '',
    icon: resolveMedia(item.icon as MediaProps)?.src || '',
    iconRaw: item.iconSvg || '',
    cta: resolvedCta?.[0] as ResolvedCtaProps,
  }
}

export const resolveContentCard = async (
  item: Contentful.Models.ContentSliderItem,
  context?: { locale: string; brand: string },
  slugs?: PageSlug[],
) => {
  if (!item?.sys?.id)
    return {
      id: '',
      title: '',
      text: '',
      image: null,
      cta: null,
    }

  const resolvedCta = item.cta
    ? await resolveCta(
        item.cta,
        context?.locale ?? 'en',
        context?.brand ?? '',
        slugs,
      )
    : []
  return {
    id: item.sys.id,
    title: item.title || '',
    text: item.text?.json ? resolveRichText(item.text.json) : '',
    image: resolveMedia(item.image as MediaProps),
    altImage: resolveMedia(item.altImage as MediaProps),
    cta: resolvedCta?.[0] as ResolvedCtaProps,
  }
}

export const resolveBrochureCard = async (
  item: Contentful.Models.BrochureCardItem,
  context?: { locale: string; brand: string },
  slugs?: PageSlug[],
) => {
  if (!item?.sys?.id) {
    return {
      id: '',
      title: '',
      text: '',
      image: null,
      cta: null,
    }
  }

  const resolvedCta = item.cta
    ? await resolveCta(
        item.cta,
        context?.locale ?? 'en',
        context?.brand ?? '',
        slugs,
      )
    : []

  return {
    id: item.sys.id,
    title: item.title || '',
    text: item.text?.json ? resolveRichText(item.text.json) : '',
    image: item.image ? resolveMedia(item.image as MediaProps) : null,
    cta: resolvedCta?.[0] as ResolvedCtaProps,
  }
}

export const resolveArticleCard = async (
  item: Contentful.Models.Article,
  _context?: { locale: string; brand: string },
) => {
  if (!item?.sys?.id)
    return {
      id: '',
      title: '',
      image: null,
      text: '',
      tags: [],
      cta: null,
    }

  return {
    id: item.sys.id,
    title: item.cardTitle || '',
    text: item.cardText?.json ? resolveRichText(item.cardText.json) : '',
    image: resolveMedia(item.overviewCardImage as MediaProps),
    tags: (item.meta?.tags || []).filter(
      (tag): tag is { id: string; name: string } =>
        tag !== null && typeof tag === 'object' && 'id' in tag && 'name' in tag,
    ),
    cta: {
      href: item.slug || '', // @TODO resolve page slug
      title: item.cardTitle || '',
      label: 'Read more',
      target: '_self',
    } as ResolvedCtaProps,
  }
}

export const resolveTestimonial = async (
  item: Contentful.Models.SplitTestimonialItem,
  context?: { locale: string; brand: string },
  slugs?: PageSlug[],
): Promise<TestimonialCardProps | null> => {
  if (!item?.sys?.id)
    return {
      id: '',
      text: '',
    }
  const resolvedCta = item.cta
    ? await resolveCta(
        item.cta,
        context?.locale ?? 'en',
        context?.brand ?? '',
        slugs,
      )
    : []
  return {
    id: item.sys.id,
    text: item.text?.json ? resolveRichText(item.text.json) : '',
    author: item.author || '',
    destination: item.destination || '',
    link: item.referenceLink ?? null, // TODO: resolve link
    buttonProps: resolvedCta ? resolvedCta[0] : undefined,
  }
}

export const resolveAccordionItem = async (
  item: Contentful.Models.AccordionItem,
  context?: { locale: string; brand: string },
  slugs?: PageSlug[],
) => {
  if (!item?.sys?.id)
    return {
      id: '',
      title: '',
      text: '',
      cta: null,
    }

  const resolvedCta = item.cta
    ? await resolveCta(
        item.cta,
        context?.locale ?? 'en',
        context?.brand ?? '',
        slugs,
      )
    : []
  return {
    id: item.sys.id,
    title: item.title || '',
    text: item.text?.json ? resolveRichText(item.text.json) : '',
    cta: resolvedCta as ResolvedCtaProps[] | null,
  }
}

export const resolveTripCard = async (
  item: TripCard,
  _context?: { locale: string; brand: string },
) => {
  if (!item?.sys?.id)
    return {
      id: '',
      title: '',
      text: '',
      image: null,
      url: '',
    }

  //@TODO resolve trip url
  return {
    id: item.sys.id,
    url: `/trip/${item.tripCode}`, // hardcoded url to be fetched from algolia
    image: resolveMedia(item.cardImage as MediaProps),
    title: item.name,
    startDestination: item.startDestination?.name,
    endDestination: item.endDestination?.name,
    duration: item.duration,
    mainTravelStyle: item.mainTravelStyle?.name,
    travelStylesCollection:
      item.travelStylesCollection?.items.map((item) => item?.name) || [],
    brandId: item.brandId,
    tripRegion: item.tripRegion?.name,
    tag: item.mainTravelStyle?.name,
    promo: item.promo || '',
    tripCode: item.tripCode,
  }
}
