import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md'

import { Button } from '@/components/button'

export const LightboxNav = ({
  paginate,
}: Readonly<{
  paginate: (newDirection: number) => void
}>) => {
  return (
    <>
      <Button
        css={{
          pos: 'absolute',
          borderRadius: 'semantic-border-radius-round',
          maxW: 'semantic-sizing-48',
          top: 'calc(50% - var(--spacing-semantic-spacing-48))',
          left: 0,
          m: 'semantic-spacing-24',
          zIndex: 1,
        }}
        variant="overlay"
        size="lg"
        aria-label="Previous image"
        onClick={() => paginate(-1)}
      >
        <MdOutlineArrowBackIos />
      </Button>

      <Button
        css={{
          pos: 'absolute',
          borderRadius: 'semantic-border-radius-round',
          maxW: 'semantic-sizing-48',
          top: 'calc(50% - var(--spacing-semantic-spacing-48))',
          right: 0,
          m: 'semantic-spacing-24',
          zIndex: 1,
        }}
        size="lg"
        variant="overlay"
        aria-label="Next image"
        onClick={() => paginate(1)}
      >
        <MdOutlineArrowForwardIos />
      </Button>
    </>
  )
}
