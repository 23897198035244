'use client'

import { css } from '@/styled-system/css'
import { Image } from '@/components/image'
import { motion, AnimatePresence, type PanInfo, wrap } from 'framer-motion'
import type {
  ResolvedCtaProps,
  ResolvedMediaProps,
} from '@/types/resolved-types'

import { DialogBody } from '@/components/dialog'
import {
  LightboxFooter,
  LightboxHeader,
  LightboxNav,
  lightboxConfig,
} from '@/components/lightbox'

type Slide = {
  longCaption?: string | React.ReactNode
  shortCaption?: string
  image: ResolvedMediaProps | undefined | null
  lightboxCta?: ResolvedCtaProps | undefined | null
}

type LightboxSlideProps = {
  direction: number
  handlePaginate: (direction: number) => void
  page: number
  slides: Slide[]
}

export const LightboxSlide = ({
  direction,
  handlePaginate,
  page,
  slides,
}: Readonly<LightboxSlideProps>) => {
  const slidesLength = slides.length ?? 0
  const currentIndex = wrap(0, slidesLength, page)
  const currentSlide = slides[currentIndex]

  const handleDragEnd = (_: MouseEvent, { offset, velocity }: PanInfo) => {
    const swipe = lightboxConfig.swipePower(offset.x, velocity.x)

    if (swipe < -lightboxConfig.swipeConfidenceThreshold) {
      handlePaginate(1)
    } else if (swipe > lightboxConfig.swipeConfidenceThreshold) {
      handlePaginate(-1)
    }
  }

  return (
    <>
      <LightboxHeader
        counter={`${currentIndex + 1} / ${slidesLength}`}
        desc={currentSlide?.longCaption}
        title={currentSlide?.shortCaption}
      />

      <DialogBody display="flex" p={0}>
        <motion.div
          className={css({
            alignItems: 'center',
            display: 'flex',
            flex: '1 1 0%',
            justifyContent: 'center',
            pos: 'relative',
          })}
          animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.8 }}
          transition={lightboxConfig.transition.enter}
        >
          <AnimatePresence initial={false} custom={direction}>
            <motion.span
              className={css({
                maxH: { base: 'full', xl: 706 },
                maxW: { base: 'full', xl: 1280 },
                pos: 'relative',
                w: { base: 'full', lg: 'full', xl: 'full' },
                h: { base: 'fit-content', lg: 'full', xl: 'full' },
                aspectRatio: {
                  base: '768/436',
                  lg: 'auto',
                },
                zIndex: 2,
              })}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={handleDragEnd}
              animate="center"
              custom={direction}
              exit="exit"
              initial="enter"
              key={`slide-${page}`}
              transition={{
                x: lightboxConfig.transition.swipeX,
                opacity: { duration: 0.2 },
              }}
              variants={lightboxConfig.animation}
            >
              {currentSlide?.image?.src && (
                <Image
                  fill
                  sizes="100vw"
                  src={currentSlide?.image?.src}
                  alt={currentSlide?.image?.alt}
                />
              )}
            </motion.span>
          </AnimatePresence>
        </motion.div>

        <LightboxNav paginate={handlePaginate} />
      </DialogBody>

      <LightboxFooter
        shortCaption={currentSlide?.shortCaption}
        caption={currentSlide?.longCaption}
        cta={currentSlide?.lightboxCta ?? undefined}
      />
    </>
  )
}
