import { ContentCarousel } from '@/components/card-carousel'
import { ContentCard } from '@/components/content-card'

import type { ContentCardCarouselProps } from '@/types/components/content-card-carousel'

export const ContentCardCarouselSection = ({
  props,
}: {
  props: ContentCardCarouselProps
}) => {
  if (!props || !props?.items?.length) {
    return null
  }

  const { items, ...rest } = props

  return (
    <ContentCarousel {...rest}>
      {items.map((item, index) => (
        <ContentCard {...item} key={`content-card-${index}${item.id}`} />
      ))}
    </ContentCarousel>
  )
}
