import { css } from '@/styled-system/css'

import { Button } from '@/components/button'
import { MdArrowForward, MdOutlineRoomService } from 'react-icons/md'

// TODO: ALU-852: Implement the RequestQuote component
export function RequestQuote({}) {
  return (
    <Button
      className={css({
        w: 'full',
        '& svg': {
          fill: 'button-color-primary-default-icon',
          w: 'semantic-sizing-24',
        },
      })}
      size={{ base: 'md', md: 'lg' }}
      variant="primary"
      rightIcon={<MdArrowForward />}
    >
      Request a quote
    </Button>
  )
}

export function SpeakToAnExpert() {
  return (
    <Button
      className={css({
        mt: 'semantic-spacing-16',
        w: 'full',
        '& svg': {
          fill: 'button-color-tertiary-default-icon',
          w: 'semantic-sizing-24',
        },
      })}
      size={{ base: 'md', md: 'lg' }}
      variant="tertiary"
      rightIcon={<MdOutlineRoomService />}
    >
      Speak to an expert
    </Button>
  )
}
export function CheckAvailability() {
  return (
    <Button
      className={css({
        w: 'full',
        '& svg': {
          fill: 'button-color-primary-default-icon',
          w: 'semantic-sizing-24',
        },
      })}
      size={{ base: 'md', md: 'lg' }}
      variant="primary"
      rightIcon={<MdArrowForward />}
    >
      Check availability
    </Button>
  )
}
