import { Button } from '@/components/button'

import { ContentfulLivePreview } from '@contentful/live-preview'

import Link from '@/global/components/link'

import { css, cx } from '@/styled-system/css'
import { Box } from '@/styled-system/jsx'

import type { TestimonialCardProps } from '@/types/components'

const blockquoteStyles = css({
  textStyle: 'semantic-text-xl-font-regular',
  color: 'semantic-fg-muted',
  '& > p': {
    lineHeight: '35px',
    mb: 'semantic-spacing-4',
    '&::before': { content: 'open-quote', display: 'inline' },
    '&::after': { content: 'close-quote', display: 'inline' },
    '& a': { textStyle: 'semantic-text-xl-font-regular-underline' },
  },
})

const metaStyles = css({
  display: 'inline-flex',
  justifyContent: 'start',
  alignItems: 'center',
  textStyle: 'semantic-text-md-font-regular',
  marginTop: 'semantic-spacing-16',
})

const buttonStyles = css({
  marginTop: 'semantic-spacing-32',
})

const MetaComponent = ({
  link,
  text,
}: {
  link?: string | null
  text?: string
}) =>
  link ? (
    <Link href={link} title={text}>
      {text}
    </Link>
  ) : (
    <span>{text}</span>
  )

export const TestimonialCard = ({
  id,
  text,
  author,
  destination,
  link,
  buttonProps,
  className,
}: TestimonialCardProps) => {
  if (!text) return null
  return (
    <Box
      className={cx(className)}
      {...ContentfulLivePreview.getProps({ entryId: id, fieldId: 'title' })}
    >
      <blockquote className={blockquoteStyles}>
        {text}
        <span className={metaStyles}>
          {author && <span>{author} -&nbsp;</span>}
          <MetaComponent link={link} text={destination} />
        </span>
      </blockquote>
      {buttonProps?.href && (
        <Button
          size={{ base: 'sm', md: 'lg' }}
          {...buttonProps}
          className={buttonStyles}
          css={{ mt: 'semantic-spacing-8' }}
        >
          {buttonProps.label}
        </Button>
      )}
    </Box>
  )
}
