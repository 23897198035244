import { BrochureCard } from '@/components/brochure-card'
import { ContentCarousel } from '@/components/card-carousel'

import type { BrochureCardCarouselProps } from '@/types/components/brochure-card-carousel'

export const BrochureCardCarouselSection = ({
  props,
}: {
  props: BrochureCardCarouselProps
}) => {
  if (!props || !props?.items?.length) {
    return null
  }

  const { items, ...rest } = props

  return (
    <ContentCarousel {...rest}>
      {items.map((item, index) => (
        <BrochureCard {...item} key={`brochure-card-${index}${item.id}`} />
      ))}
    </ContentCarousel>
  )
}
