import { css } from '@/styled-system/css'
import { Flex, Box } from '@/styled-system/jsx'

import styles from './loader.module.css'

const boxClassName = css({
  borderColor: 'semantic-fg-disabled',
  border: '1px solid',
  borderRadius: 'semantic-border-radius-medium',
  bg: 'semantic-bg-default',
  boxShadow: 'semantic-shadow-xl',
  p: 'semantic-spacing-16',
  width: 'full',
  md: {
    p: 'semantic-spacing-32',
    minWidth: '340px',
  },
  lg: { w: '443px' },
})

export function BoxLoader({
  children,
  isLoading,
}: {
  isLoading: boolean
  children?: React.ReactNode
}) {
  return (
    <Box className={boxClassName}>
      {isLoading ? (
        <Flex h="full">
          <Box className={styles.loader} m="auto"></Box>
        </Flex>
      ) : (
        children
      )}
    </Box>
  )
}
