import { ArticleCard } from '@/components/article-card'
import { ContentCarousel } from '@/components/card-carousel'

import type { ArticleCardCarouselProps } from '@/types/components/article-card-carousel'

export const ArticleCardCarouselSection = ({
  props,
}: {
  props: ArticleCardCarouselProps
}) => {
  if (!props || !props?.items?.length) {
    return null
  }

  const { items, ...rest } = props

  return (
    <ContentCarousel {...rest}>
      {items.map((item, index) => (
        <ArticleCard {...item} key={`content-card-${index}${item.id}`} />
      ))}
    </ContentCarousel>
  )
}
