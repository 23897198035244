import { Box, BoxProps, Flex } from '@/styled-system/jsx'
import { css, cx } from '@/styled-system/css'
import type { ResolvedMediaProps } from '@/types/resolved-types'
import { Image } from '@/components/image'

import { backgroundOverlay, LightboxExpandControl } from '@/components/lightbox'

export const LightboxThumbnail = ({
  slide,
  smSize = false,
  onImageClick,
  ...props
}: Readonly<
  BoxProps & {
    slide: {
      image: ResolvedMediaProps | undefined | null
      shortCaption?: string
    }
    smSize?: boolean
    onImageClick: () => void
  }
>) => {
  if (!slide?.image?.src) return null
  return (
    <Box
      className={cx(backgroundOverlay)}
      cursor="pointer"
      height="full"
      pos="relative"
      w="full"
      onClick={onImageClick}
      {...props}
    >
      <LightboxExpandControl />
      <Image
        className={css({ zIndex: -1, minHeight: '100%', minWidth: '100%' })}
        alt={slide?.image.alt}
        src={slide?.image.src}
      />
      <Flex
        background="linear-gradient(189deg, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 85.91%)"
        bottom={0}
        h={188}
        left={0}
        pos="absolute"
        w="full"
      >
        <p
          className={css({
            color: 'semantic-color-primary-reverse-default-text',
            mt: 'auto',
            px: 'semantic-spacing-24',
            pb: {
              base: 'semantic-spacing-16',
              md: smSize ? 'semantic-spacing-16' : 'semantic-spacing-24',
            },
            textStyle: 'semantic-text-xs-font-bold',
          })}
        >
          {slide?.shortCaption}
        </p>
      </Flex>
    </Box>
  )
}
