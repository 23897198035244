'use client'

import { useState } from 'react'

export const useLightboxSlide = () => {
  const [[page, direction], setPage] = useState<[number, number]>([0, 0])

  const handlePaginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection])
  }

  return {
    direction,
    handlePaginate,
    page,
    setPage,
  }
}

export const lightboxConfig = {
  animation: {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      }
    },
  },
  /**
   * Experimenting with distilling swipe offset and velocity into a single variable, so the
   * less distance a user has swiped, the more velocity they need to register as a swipe.
   * Should accomodate longer swipes and short flicks without having binary checks on
   * just distance thresholds and velocity > 0.
   */
  swipeConfidenceThreshold: 10000,
  swipePower: (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity
  },
  transition: {
    enter: {
      type: 'spring',
      damping: 25,
      stiffness: 120,
    },
    swipeX: { type: 'spring', stiffness: 300, damping: 30 },
  },
}

export const swipeConfidenceThreshold = 10000
