export function Location({ colour }: Readonly<{ colour?: string }>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="12"
        fill={
          colour
            ? `var(--colors-semantic-${colour})`
            : 'var(--colors-button-color-secondary-default-background)'
        }
      />
      <path
        d="M12.0002 5.3335C9.42016 5.3335 7.3335 7.42016 7.3335 10.0002C7.3335 13.5002 12.0002 18.6668 12.0002 18.6668C12.0002 18.6668 16.6668 13.5002 16.6668 10.0002C16.6668 7.42016 14.5802 5.3335 12.0002 5.3335ZM12.0002 11.6668C11.5581 11.6668 11.1342 11.4912 10.8217 11.1787C10.5091 10.8661 10.3335 10.4422 10.3335 10.0002C10.3335 9.55814 10.5091 9.13421 10.8217 8.82165C11.1342 8.50909 11.5581 8.3335 12.0002 8.3335C12.4422 8.3335 12.8661 8.50909 13.1787 8.82165C13.4912 9.13421 13.6668 9.55814 13.6668 10.0002C13.6668 10.4422 13.4912 10.8661 13.1787 11.1787C12.8661 11.4912 12.4422 11.6668 12.0002 11.6668Z"
        fill={
          colour ? 'white' : 'var(--colors-button-color-secondary-default-icon)'
        }
      />
    </svg>
  )
}
