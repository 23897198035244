'use client'

import {
  useContentfulInspectorMode,
  useContentfulLiveUpdates,
} from '@contentful/live-preview/react'

import type { DocumentNode } from 'graphql'

type InspectorModeReturn = ReturnType<typeof useContentfulInspectorMode>

type InspectorProps = Pick<
  Parameters<InspectorModeReturn>[0],
  'entryId' | 'fieldId'
>

export function useLiveUpdate<TEntry extends Contentful.Models.Entry>(
  entry: TEntry,
  query?: DocumentNode,
): {
  /**
   * The Contentful entry that will be updated in live preview (draft mode) mode.
   */
  entry: TEntry

  /**
   * Get the Contentful inspector props for the provided `props.fieldId`.
   */
  getInspectorProps(props: InspectorProps): Record<string, unknown>
} {
  const inspectorMode = useContentfulInspectorMode({
    entryId: entry.sys.id,
  })

  return {
    entry: useContentfulLiveUpdates(entry, { query }),

    getInspectorProps(props: InspectorProps) {
      return inspectorMode(props) ?? {}
    },
  }
}
