import * as React from 'react'
import { range } from 'lodash'
import { getYear } from 'date-fns'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import { css } from '@/styled-system/css'

import './styles.css'
import { ForwardedRef, forwardRef } from 'react'

/**
 * DatePicker Custom headers to render just years in the date picker's header
 * ie TripWidget
 */
export function YearsCustomHeader({
  date,
  changeYear,
  startDate = new Date(),
}: { startDate?: Date } & ReactDatePickerCustomHeaderProps) {
  const years = range(getYear(startDate), getYear(new Date(startDate)) + 4, 1)
  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'space-between',
        p: 'semantic-spacing-16',
        '& button': {
          textStyle: 'semantic-text-sm-font-regular',
          px: '11px',
          py: 'semantic-spacing-4',
          border: 'none',
          background: 'transparent',
          cursor: 'pointer',
          '&:hover': {
            color: 'blue',
          },
          color: 'semantic-color-primary-default-text-accent',
          _selected: {
            textStyle: 'semantic-text-sm-font-bold',
            color: 'semantic-color-primary-active-text',
            bg: 'semantic-color-primary-active-background',
            borderRadius: 'semantic-border-radius-round',
          },
        },
      })}
    >
      {years.map((year) => {
        const selected = getYear(date) === year

        return (
          <button
            key={year}
            data-selected={selected ? true : null}
            type="button"
            value={year}
            onClick={() => changeYear(year)}
          >
            {year}
          </button>
        )
      })}
    </div>
  )
}
export const CustomDivAsInput = forwardRef(
  (
    {
      value,
      onClick,
      className,
    }: Readonly<{
      value?: string
      onClick?: React.MouseEventHandler<HTMLDivElement>
      className?: string
    }>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <div className={className} onClick={onClick} ref={ref}>
      {value}
    </div>
  ),
)

CustomDivAsInput.displayName = 'CustomDivAsInput'
