import { css } from '@/styled-system/css'
import { Box, Flex, HStack } from '@/styled-system/jsx'
import { token } from '@/styled-system/tokens'

import { FlightPath, Line, Rail } from '@/components/icons'

import ReactPinchPanZoom from '@/global/components/trip-itinerary/image-pan/react-pinch-pan-zoom'

import type { TripItineraryConfig } from '@/types/components/itinerary'
import { ContentfulLivePreview } from '@contentful/live-preview'

export function TripItineraryMap({
  config,
}: Readonly<{
  config: TripItineraryConfig
}>) {
  return (
    <Box
      h={{ base: 480, md: 526, lg: 'full' }}
      overflow="hidden"
      pos="relative"
      w="full"
      {...ContentfulLivePreview.getProps({
        entryId: config.id,
        fieldId: 'map',
      })}
    >
      <Box pos="absolute" right={0} top={0} zIndex={500}>
        <HStack
          bg="rgb(255 255 255 / .5)"
          columnGap={{
            base: token('spacing.semantic-spacing-16'),
            lg: token('spacing.semantic-spacing-24'),
          }}
          display="flex"
          flexWrap="wrap"
          m="semantic-spacing-16"
          px="semantic-spacing-24"
          py="semantic-spacing-8"
          rowGap={{
            base: token('spacing.semantic-spacing-16'),
            lg: 0,
          }}
          textStyle="semantic-text-md-font-regular"
        >
          {/* Map legends config is missing in contentful model */}
          <Flex alignItems="center">
            <Line colour="#939598" />
            <span className={css({ ml: 'semantic-spacing-12' })}>Coach</span>
          </Flex>
          <Flex alignItems="center">
            <Line colour="#1272B9" />
            <span className={css({ ml: 'semantic-spacing-12' })}>Cruise</span>
          </Flex>
          <Flex alignItems="center">
            <FlightPath />
            <span className={css({ ml: 'semantic-spacing-12' })}>
              Included Flight
            </span>
          </Flex>
          <Flex alignItems="center">
            <Rail />
            <span className={css({ ml: 'semantic-spacing-12' })}>Rail</span>
          </Flex>
        </HStack>
      </Box>
      <ReactPinchPanZoom config={config} />
    </Box>
  )
}
