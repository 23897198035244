'use client'

import { Box } from '@/styled-system/jsx'
import { css } from '@/styled-system/css'
import { token } from '@/styled-system/tokens'

import { useState } from 'react'
import { MdAddCircleOutline } from 'react-icons/md'

import { Button } from '@/components/button'
import { useDisclosure } from '@/components/hooks/use-disclosure'

import { TripItineraryDetailModal } from '@/global/components/trip-itinerary/trip-itinerary-detail-modal'
import { TripItineraryTimelineScroller } from '@/global/components/trip-itinerary/trip-itinerary-timeline-scroller'

import type { TripItineraryConfig } from '@/types/components/itinerary'

export function TripItineraryTimeline({
  config,
}: Readonly<{
  config: TripItineraryConfig
}>) {
  const [activeDay, setActiveDay] = useState(1)

  const disclosure = useDisclosure()

  const tripDuration = config.i18n['trip-duration']
    .replace('{days}', config.duration.toString())
    .replace('{nights}', (config.duration - 1).toString())
    .replace('{type}', config.travelStyle || '')

  const onDaySelect = (day: number) => {
    setActiveDay(day)
    disclosure.onOpen()
  }

  if (!disclosure.isOpen && activeDay > 1) {
    setActiveDay(1)
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        h={{
          base: 560,
          md: 640,
          lg: `calc(100% - (${token('spacing.semantic-spacing-32')}  * 1.5))`,
        }}
        pos="relative"
        color="semantic-color-typography-body-solid"
      >
        <TripItineraryTimelineScroller
          activeDay={activeDay}
          config={config}
          onDaySelect={onDaySelect}
        >
          <h3
            className={css({
              color: 'semantic-color-typography-headings-solid',
              mb: 'semantic-spacing-8',
              pr: 'semantic-spacing-8',
              textStyle: {
                base: 'semantic-text-2xl-font-regular',
                md: 'semantic-text-3xl-font-regular',
              },
            })}
          >
            {config.journey ??
              `${config.startDestination} to ${config.endDestination}`}
          </h3>
          <p
            className={css({
              mb: 'semantic-spacing-24',
              pr: 'semantic-spacing-8',
              textStyle: {
                base: 'semantic-text-lg-font-regular',
                md: 'semantic-text-xl-font-regular',
              },
            })}
          >
            {tripDuration}
          </p>
        </TripItineraryTimelineScroller>
      </Box>

      <Button
        size="lg"
        css={{ w: 'full' }}
        variant="secondary"
        onClick={disclosure.onOpen}
        rightIcon={<MdAddCircleOutline />}
      >
        {config.i18n['explore-itinerary']}
      </Button>

      {disclosure.isOpen ? (
        <TripItineraryDetailModal
          activeDay={activeDay}
          config={config}
          disclosure={disclosure}
          onDaySelect={onDaySelect}
        />
      ) : null}
    </>
  )
}
