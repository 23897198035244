import {
  commonContentResolver,
  genericDataResolver,
  resolveContentCard,
  resolveCta,
} from '@/utility/content-resolvers'

import type {
  FullBleedSectionProps,
  FullBleedCardProps,
} from '@/types/components/bleed-carousel'
import { PageSlug } from '@/types/resolved-types'
export const dataResolver = (
  brand: string,
  data: Contentful.Models.FullBleedSection,
  isLead?: boolean,
  allSlugs?: PageSlug[],
) =>
  genericDataResolver<
    Contentful.Models.ContentSliderItem,
    FullBleedCardProps | null,
    FullBleedSectionProps
  >(
    data,
    brand,
    {
      id: '',
      showInNav: false,
      name: '',
      title: '',
      titleSize: 'heading3',
      text: '',
      items: [],
      cta: null,
      backgroundVariant: 'default',
    },
    {
      items: {
        collectionKey: 'items',
        resolver: async (item, context) => {
          const resolvedItem = await resolveContentCard(item, context, allSlugs)

          if (resolvedItem) {
            return resolvedItem
          }

          return null
        },
      },
    },
    async (resolvedData, context): Promise<FullBleedSectionProps> => ({
      ...commonContentResolver(data as never, isLead),
      backgroundVariant: (data.background ||
        'default') as FullBleedSectionProps['backgroundVariant'],
      cta: data.cta
        ? (
            await resolveCta(data.cta, context.locale, context.brand, allSlugs)
          )[0] || null
        : null,
      items: resolvedData.items ?? [],
    }),
  )
