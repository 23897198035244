'use client'

import { useEffect, useState } from 'react'

import { useLiveUpdate } from '@/components/hooks/use-live-update'
import { useSite } from '@/providers/site'
import { Hero } from '@/global/components/hero/hero-banner'
import { dataResolver } from '@/global/components/hero/util/data-resolver'

import { HeroDocument } from '@/graphql/client'

import { error } from '@/utility/logging'

import type { HeroData, HeroProps } from '@/types/components/hero-section'

export function HeroLivePreview({
  brand,
  hero: originalHero,
  isLead,
}: Readonly<{
  brand: string
  hero: HeroData
  isLead?: boolean
}>) {
  const [heroData, setHeroData] = useState<HeroProps>({} as never)
  const { slugs } = useSite()

  const { entry: hero, getInspectorProps } = useLiveUpdate(
    originalHero,
    HeroDocument,
  )

  useEffect(() => {
    if (hero) {
      const fetchData = async () => {
        try {
          const result = await dataResolver(brand, hero, isLead, slugs)
          setHeroData(result)
        } catch (err) {
          error('Error fetching hero data', {
            brand,
            err: (err as Error).message,
          })
        }
      }

      fetchData()
    }
  }, [hero, isLead, slugs])

  return heroData ? (
    <Hero
      {...heroData}
      livePreviewProps={{
        title: getInspectorProps({ fieldId: 'title' }),
        eyebrow: getInspectorProps({ fieldId: 'eyebrow' }),
        media: getInspectorProps({ fieldId: 'media' }),
        cta: getInspectorProps({ fieldId: 'cta' }),
        text: getInspectorProps({ fieldId: 'text' }),
      }}
      seasonYear={originalHero?.seasonYear}
    />
  ) : null
}
