'use client'

import { TripItineraryHeader } from '@/global/components/trip-itinerary/trip-itinerary-header'
import { TripItineraryMap } from '@/global/components/trip-itinerary/trip-itinerary-map'
import { TripItineraryTimeline } from '@/global/components/trip-itinerary/trip-itinerary-timeline'

import { Box, Divider, Section } from '@/styled-system/jsx'

import type { TripItineraryConfig } from '@/types/components/itinerary'

import './index.css'

export function Itinerary({
  config,
  trip,
}: Readonly<{
  config: TripItineraryConfig
  trip: Contentful.Models.Trip
}>) {
  return (
    <>
      <Divider borderColor="semantic-border-default" borderWidth={1} />

      <TripItineraryHeader config={config} trip={trip} />
      <Section noPaddingX maxW={1440}>
        <Box
          bg="#fcfcfc"
          display="flex"
          flexDirection={{ base: 'column', lg: 'row' }}
          h={{ lg: '768px' }}
        >
          <Box
            flexShrink={0}
            h={{ base: 'auto', lg: '100%' }}
            maxW={{ lg: 456 }}
            order={{ base: 2, lg: -1 }}
            px={{ base: 'semantic-spacing-20', md: 'semantic-spacing-56' }}
            pb={{ base: 'semantic-spacing-24', md: 'semantic-spacing-32' }}
            pt={{ base: 'semantic-spacing-24', md: 'semantic-spacing-48' }}
          >
            <TripItineraryTimeline config={config} />
          </Box>

          <TripItineraryMap config={config} />
        </Box>
      </Section>

      <Divider borderColor="semantic-border-default" borderWidth={1} />
    </>
  )
}
