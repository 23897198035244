'use client'

import { useEffect, useState } from 'react'

import { error } from '@/utility/logging'
import { getTripPricesByDateRange } from '@/utility/client/algolia'

export function useTripsPricingInfo({
  brand,
  locale,
  tripsCode,
  dateRange,
}: {
  brand: string
  locale: string
  tripsCode?: string[]
  dateRange?: { from?: string; to?: string }
}) {
  const [tripsPricing, setTripsPricing] =
    useState<AlgoliaSearchResult.TripPricesByDateRange | null>(null)

  useEffect(() => {
    if (!tripsCode?.length) {
      return
    }

    const fetchData = async () => {
      try {
        const tripsPricing = await getTripPricesByDateRange({
          brand: brand,
          locale: locale,
          tripCode: [...new Set(tripsCode)],
          dateRange,
        })

        if (tripsPricing) {
          setTripsPricing(tripsPricing)
        }
      } catch (err) {
        error('Error fetching trip pricing:', err)
      }
    }

    fetchData()
  }, [brand, locale])

  return {
    tripsPricing,
  }
}
