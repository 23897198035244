import { Box, Flex, type BoxProps } from '@/styled-system/jsx'
import { css, cx } from '@/styled-system/css'
import { Title } from '@/components/title'
import type { TitleProps } from '@/components/title'
import type { ResolvedCtaProps } from '@/types/resolved-types'
import { Button } from '@/components/button'
import omit from 'lodash/omit'
import type { ContentfulLivePreview } from '@contentful/live-preview'
type SectionHeaderProps = React.PropsWithChildren<
  BoxProps & {
    cta?: ResolvedCtaProps | null
    description?: string | React.ReactNode
    heading: string
    overwriteHeadingStyles?: string
    isReversed?: boolean
    titleProps?: Omit<TitleProps, 'children'>
    descriptionProps?: ContentfulLivePreview & {
      className?: string
    }
    ctaProps?: ContentfulLivePreview & {
      className?: string
    }
  }
>

export const SectionHeader = ({
  isReversed = false,
  children,
  description,
  heading,
  overwriteHeadingStyles,
  cta,
  titleProps = {
    size: 'heading3',
    tag: 'h2',
    format: 'standard',
    className: '',
  },
  descriptionProps = {},
  ctaProps = {},
  ...rest
}: Readonly<SectionHeaderProps>) => {
  return (
    <Box
      className={cx(
        css({
          mb: {
            base: 'semantic-spacing-40',
            md: 'semantic-spacing-64',
          },
        }),
        overwriteHeadingStyles,
      )}
      {...rest}
    >
      <Flex
        columnGap="semantic-spacing-24"
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent="space-between"
        mb="semantic-spacing-24"
        rowGap="semantic-spacing-24"
      >
        <Title {...titleProps} theme={isReversed ? 'light' : 'dark'}>
          {heading}
        </Title>
        {cta?.href ? (
          <Button
            size={{ base: 'sm', md: 'md', lg: 'lg' }}
            className={cx(
              css({
                width: 'fit-content',
              }),
              cta?.className,
              ctaProps?.className,
            )}
            {...omit(cta, 'className', 'label')}
            {...omit(ctaProps, 'className', 'label')}
          >
            {cta.label}
          </Button>
        ) : null}
      </Flex>

      <Box
        className={cx(
          css({
            color: isReversed
              ? 'semantic-color-typography-body-reversed'
              : 'semantic-color-typography-body-solid',
            // TODO: Remove hardcoded line height when we have a proper text style token for md
            lineHeight: 1.75,
            mb: children ? 'semantic-spacing-24' : 'semantic-spacing-0',
            textStyle: {
              base: 'semantic-text-md-font-regular',
              md: 'semantic-text-lg-font-regular-spaced',
              lg: 'semantic-text-xl-font-regular-spaced',
            },
          }),
          descriptionProps?.className,
        )}
        {...omit(descriptionProps, 'className')}
      >
        {typeof description === 'string' ? <p>{description}</p> : description}
      </Box>
      {children}
    </Box>
  )
}
