import nextId from 'react-id-generator'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/select'
import { LabelStyled } from './label-styled'
import { css } from '@/styled-system/css'

export function Dropdown({
  defaultValue,
  id,
  items,
  label,
  onChange,
}: Readonly<{
  defaultValue: string
  id?: string
  items: { value: string; text: string | React.ReactNode }[]
  label: string
  onChange?: (v: string) => void
}>) {
  return (
    <div
      className={css({
        mb: 'semantic-spacing-32',
      })}
    >
      <LabelStyled htmlFor={id}>{label}</LabelStyled>
      <Select defaultValue={defaultValue} onValueChange={onChange}>
        <SelectTrigger id={id} w="100%" pb="semantic-spacing-6" tabIndex={0}>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {items.map((item) => (
            <SelectItem key={nextId('select-item-')} value={item.value}>
              {item.text}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
