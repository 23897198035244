'use client'

import { useEffect, useState } from 'react'

import { useLiveUpdate } from '@/components/hooks/use-live-update'
import { useSite } from '@/providers/site'

import { TwoColumnGalleryCarouselSection } from '@/global/components/two-column-gallery-carousel/gallery-carousel-section'
import { dataResolver } from '@/global/components/two-column-gallery-carousel/util/data-resolver'

import { ContentSliderDocument } from '@/graphql/client'

import { error } from '@/utility/logging'

import type { TwoColumnGalleryCarouselConfig } from '@/types/components/two-column-gallery-carousel'

export function GalleryCarouselLivePreview({
  brand,
  data: originalData,
  isLead,
}: Readonly<{
  brand: string
  data: Contentful.Models.ContentSlider
  isLead?: boolean
}>) {
  const [data, setData] = useState<TwoColumnGalleryCarouselConfig>({} as never)
  const { slugs } = useSite()

  const { entry: section, getInspectorProps } = useLiveUpdate(
    originalData,
    ContentSliderDocument,
  )

  useEffect(() => {
    if (section) {
      const fetchData = async () => {
        try {
          const result = await dataResolver(brand, section, isLead, slugs)
          setData(result)
        } catch (err) {
          error('Error fetching two column gallery carousel data', {
            brand,
            err: (err as Error).message,
          })
        }
      }
      fetchData()
    }
  }, [section, isLead, slugs])

  return data ? (
    <TwoColumnGalleryCarouselSection
      props={{
        ...data,
        livePreviewProps: {
          title: getInspectorProps({ fieldId: 'title' }),
          text: getInspectorProps({ fieldId: 'text' }),
          cta: getInspectorProps({ fieldId: 'cta' }),
        },
      }}
    />
  ) : null
}
