import useEmblaCarousel from 'embla-carousel-react'
import { memo, useMemo, useCallback } from 'react'

import { usePrevNextButtons } from '@/components/carousel/btn-prev-next'
import { Carousel } from '@/components/carousel'
import { Control } from '@/components/carousel/nav-control'
import { useDisclosure } from '@/components/hooks/use-disclosure'
import {
  Lightbox,
  LightboxSlide,
  useLightboxSlide,
} from '@/components/lightbox'

import { CarouselSlide } from '@/global/components/media-gallery-carousel-tabs/carousel-slide'
import {
  colorVariants,
  ColorVariantType,
} from '@/global/components/media-gallery-carousel-tabs/color-variants'

import { css } from '@/styled-system/css'

import type { CarouselItem } from '@/types/components/media-gallery-carousel-tabs'

interface GalleryCarouselProps {
  carouselItem: CarouselItem[]
  colorVariantType: ColorVariantType
}

export const GalleryCarousel: React.FC<GalleryCarouselProps> = memo(
  ({ carouselItem, colorVariantType }) => {
    const colorVariant = useMemo(
      () => colorVariants[colorVariantType],
      [colorVariantType],
    )
    const disclosure = useDisclosure()
    const { direction, handlePaginate, page, setPage } = useLightboxSlide()

    const [emblaRef, emblaApi] = useEmblaCarousel({
      active: carouselItem.length > 1,
    })

    const { currentActiveSlide } = usePrevNextButtons(emblaApi)

    const openLightbox = useCallback(
      (index: number) => {
        setPage([index, 0])
        disclosure.onOpen()
      },
      [setPage, disclosure],
    )

    const wrapperStyles = useMemo(
      () =>
        css({
          overflow: 'hidden',
          color: colorVariant?.bodyText,
        }),
      [colorVariant?.bodyText],
    )

    return (
      <>
        <Carousel emblaRef={emblaRef} emblaWrapperStyle={wrapperStyles}>
          {carouselItem.map((slide, index) => (
            <CarouselSlide
              key={`slide-${slide.id || index}`}
              slide={slide}
              emblaApi={emblaApi}
              openLightbox={() => openLightbox(index)}
              slideInView={currentActiveSlide === index}
            />
          ))}
        </Carousel>
        <Control emblaApi={emblaApi} variant={colorVariantType} />

        <Lightbox disclosure={disclosure}>
          <LightboxSlide
            direction={direction}
            handlePaginate={handlePaginate}
            page={page}
            slides={carouselItem}
          />
        </Lightbox>
      </>
    )
  },
)

GalleryCarousel.displayName = 'GalleryCarousel'
