'use client'

import { openEntryInEditorUtility } from '@contentful/live-preview'

import { Button } from '@/components/button'
import { Title } from '@/components/title'

import { useSite } from '@/providers/site'

import { css } from '@/styled-system/css'
import { Box, Flex, Section } from '@/styled-system/jsx'

type ConfigError = {
  buttonLabel?: string
  entryId: string
  fieldId?: string
  text: string
}

export function ConfigError({
  buttonLabel = 'Open Entry',
  entryId,
  fieldId,
  text,
}: Readonly<ConfigError>) {
  const { contentfulEnv, contentfulSpace, isContentfulFrame, getLocale } =
    useSite()

  const focusedField = fieldId ?? 'internalName'

  const openEntry = () => {
    openEntryInEditorUtility(
      {
        entryId,
        fieldId: focusedField,
        locale: getLocale(),
      },
      ['https://app.contentful.com', window.location.origin],
    )
  }

  return (
    <Section>
      <Flex
        alignItems="center"
        borderColor="semantic-error-default"
        borderRadius="semantic-border-radius-large"
        borderStyle="dashed"
        borderWidth="border-width-2"
        gap="semantic-spacing-32"
        px={{
          base: 'semantic-spacing-16',
          md: 'semantic-spacing-24',
        }}
        py={{
          base: 'semantic-spacing-16',
          md: 'semantic-spacing-24',
        }}
      >
        <Box>
          <Title format="standard" size="heading5" tag="h1">
            Configuration Error
          </Title>
          <p
            className={css({
              textStyle: 'semantic-text-lg-font-regular-spaced',
            })}
          >
            {text}
          </p>
        </Box>

        <Box ml="auto">
          {isContentfulFrame ? (
            <Button size="lg" variant="secondary" onClick={openEntry}>
              {buttonLabel}
            </Button>
          ) : (
            <Button
              as="a"
              size="lg"
              variant="secondary"
              href={`https://app.contentful.com/spaces/${contentfulSpace}/environments/${contentfulEnv}/entries/${entryId}?focusedField=${focusedField}`}
              target="_blank"
            >
              {buttonLabel}
            </Button>
          )}
        </Box>
      </Flex>
    </Section>
  )
}
