export function FreedomOfChoice() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="12"
        fill="var(--colors-semantic-primary-default)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 9.04428L17.1476 11.524V9.88561H15.3985V17.0812H17.1476L14.69 20.9336L12.2103 17.0812H13.9815V15.3985H6.83026V17.1697L3 14.69L6.8524 12.2103V13.9373H8.35793V6.8524H6.60886L9.06642 3L11.5461 6.8524H9.77491V8.42435H17.1476V6.56457L21 9.04428ZM9.77491 13.9373V9.86347H13.9373V13.9373H9.77491Z"
        fill="white"
      />
    </svg>
  )
}
