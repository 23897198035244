import { MdArrowForward } from 'react-icons/md'

import {
  BackgroundMedia,
  type BackgroundMediaProps,
} from '@/components/backgroundMedia'
import { Badge } from '@/components/badge'
import { ButtonGroup } from '@/components/buttonGroup'
import { ResolvedCtaProps } from '@/types/resolved-types'
import { SectionHeader } from '@/components/section-header'

import { ContentfulLivePreview } from '@contentful/live-preview'

import { css } from '@/styled-system/css'

import type { TitleProps } from '@/components/title'

import type { TitleVariantProps } from '@/styled-system/recipes'

import type { LivePreviewProps } from '@/types/components/live-preview'

export interface HeroProps extends BackgroundMediaProps {
  id: string
  /* Brand property for further segmentation of the component */
  brand?: string
  /* Size of the title not related to the heading tag */
  titleSize?: TitleVariantProps['size']
  /* Title of the hero component */
  title: string | undefined
  text?: string | React.ReactNode
  /* Eyebrow of the hero component */
  eyebrow?: string
  /* Season year of the hero component */
  seasonYear?: string
  /* Buttons of the hero component */
  buttons?: ResolvedCtaProps[] | null | undefined
  /* Gradient variant for the backdrop */
  gradient?: 'light' | 'medium' | 'strong'
  /* Hero height variation */
  variant?: 'tall' | 'short'
  /* Show Badge above the title */
  showBadge?: boolean
  /* Support for custom badge text */
  badgeText?: string
  /* Preset badge Types */
  badgeType?: 'travelMarvel' | 'aptLuxury'
  /* Heading Tag element h1, h2 */
  titleTag?: TitleProps['tag']
  /* Live preview props for the component */
  livePreviewProps?: LivePreviewProps
  /* Locale for the badge */
  locale?: string
}

type Badge = {
  text: string
  badgeType: string
}

type BadgeConfig = {
  [key: string]: Badge
}

const badgeConfig: BadgeConfig = {
  travelmarvel: {
    text: 'Travel Marvel',
    badgeType: 'travelMarvel',
  },
  apt: {
    text: 'APT Luxury',
    badgeType: 'aptLuxury',
  },
  // Add more brands as needed
}
export const Hero = ({
  id,
  alt = 'Background Image',
  as = 'image',
  autoplay,
  brand,
  locale,
  buttons,
  eyebrow,
  gradient,
  imageSource,
  posterImage,
  priority = false,
  quality = 95,
  seasonYear,
  showPlayButton = true,
  title = '',
  titleTag,
  text,
  useIntersectionObserver = true,
  variant = 'tall',
  videoSource,
}: HeroProps) => {
  return (
    <BackgroundMedia
      classes={{
        root: css({
          position: 'relative',
          width: '100%',
          minHeight: variant === 'tall' ? '736px' : 'min-content',
          height: variant === 'tall' ? '100dvh' : 'min-content',
          maxHeight: 'min-content',
          pt: {
            base: '100px',
            lg: '142px',
          },
        }),
        content: css({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: 'semantic-spacing-24',
          px: 'semantic-spacing-24',
          py: {
            base: 'semantic-spacing-48',
            md: 'semantic-spacing-64',
          },
        }),
      }}
      as={as}
      alt={alt}
      aspectRatio={variant === 'tall' ? '16/9' : '21/9'}
      posterImage={posterImage}
      priority={priority}
      quality={quality}
      showPlayButton={showPlayButton}
      useIntersectionObserver={useIntersectionObserver}
      autoplay={autoplay}
      gradient={gradient}
      imageSource={imageSource}
      videoSource={videoSource}
      {...ContentfulLivePreview.getProps({
        entryId: id,
        fieldId: 'media',
      })}
    >
      {locale === 'en-GB' && brand && (
        <Badge
          variant={badgeConfig[brand]?.badgeType as HeroProps['badgeType']}
        >
          {badgeConfig[brand]?.text}
        </Badge>
      )}

      {eyebrow && (
        <span
          className={css({
            textStyle: {
              base: 'semantic-heading-logotype-sm',
              sm: 'semantic-heading-logotype-md',
              md: 'semantic-heading-logotype-lg',
            },
            textTransform: 'uppercase',
            shadow: 'semantic-text-shadow-lg',
            color: 'semantic-fg-on-accent',
            position: 'relative',
            paddingBottom: 'semantic-spacing-16',
            _after: {
              content: '""',
              display: 'block',
              width: 32,
              height: 2,
              bg: 'semantic-fg-on-accent',
              position: 'absolute',
              inset: 'center',
              left: '50%',
              bottom: 0,
              transform: ' translateX( -50%)',
            },
          })}
          {...ContentfulLivePreview.getProps({
            entryId: id,
            fieldId: 'eyebrow',
          })}
        >
          {eyebrow}
        </span>
      )}
      <SectionHeader
        heading={title}
        isReversed
        overwriteHeadingStyles={css({
          mb: {
            base: 'semantic-spacing-0',
            md: 'semantic-spacing-0',
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxW: 1280,
        })}
        titleProps={{
          size: 'heading1',
          tag: titleTag || 'h1',
          format: 'logotype',
          className: css({
            textTransform: 'uppercase',
            textShadow: 'semantic-shadow-md',
          }),
          ...ContentfulLivePreview.getProps({
            entryId: id,
            fieldId: 'title',
          }),
        }}
        description={text}
        descriptionProps={{
          ...ContentfulLivePreview.getProps({
            entryId: id,
            fieldId: 'text',
          }),
          className: css({
            maxWidth: 860,
            '& p:last-child': {
              marginBottom: 0,
            },
            '& p > a': {
              color: 'semantic-color-typography-body-reversed',
              '&:hover': {
                color: 'semantic-color-typography-links-reversed',
              },
            },
          }),
        }}
      >
        {seasonYear && (
          <span
            className={css({
              textStyle: 'semantic-heading-logotype-lg',
              textTransform: 'uppercase',
              shadow: 'semantic-text-shadow-lg',
              color: 'semantic-fg-on-accent',
            })}
          >
            {seasonYear}
          </span>
        )}
        {buttons ? (
          <ButtonGroup
            className={css({
              marginTop: 'semantic-spacing-16',
            })}
            buttons={buttons.map((button, index: number) =>
              button?.label !== ''
                ? {
                    ...button,
                    key: index,
                    children: button.label,
                    rightIcon:
                      button?.variant === 'primary' ? <MdArrowForward /> : '',
                    size: { base: 'sm', md: 'lg' },
                    ...ContentfulLivePreview.getProps({
                      entryId: id,
                      fieldId: 'cta',
                    }),
                  }
                : button,
            )}
            layout="inline"
          />
        ) : null}
      </SectionHeader>
    </BackgroundMedia>
  )
}
