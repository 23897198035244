import {
  documentToReactComponents,
  type Options,
} from '@contentful/rich-text-react-renderer'

import {
  BLOCKS,
  Document,
  Hyperlink,
  INLINES,
  Inline,
  Block,
} from '@contentful/rich-text-types'

import { renderRichTextOptions } from '@/components/richtextEditor/render-rich-text-option'

import Link from '@/global/components/link'

import { css } from '@/styled-system/css'

export type Links = {
  entries?: {
    hyperlink: ReadonlyArray<{ sys: Contentful.Models.Sys } | null>
  }
  assets?: {
    hyperlink: {
      description?: string
      sys: Contentful.Models.Sys
      title: string
      url: string
    }[]
  }
}

function renderOptions(links: Links): Options {
  // create an entry map
  const entryMap = new Map()

  // loop through the inline linked entries and add them to the map
  if (links?.assets?.hyperlink) {
    for (const entry of links?.assets?.hyperlink) {
      entryMap.set(entry.sys.id, entry)
    }
  }

  // loop through the inline linked entries and add them to the map
  if (links?.entries?.hyperlink) {
    for (const entry of links?.entries?.hyperlink) {
      if (entry?.sys.id) {
        entryMap.set(entry.sys.id, entry)
      }
    }
  }

  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const isEmptyChildren = children?.toString().trim() === ''
        if (isEmptyChildren) return null
        return <p>{children}</p>
      },
      [INLINES.HYPERLINK]: (
        node: Block | Inline | Hyperlink,
        children: string | React.ReactNode,
      ) => {
        // find the entry in the entryMap by ID
        return (
          <a
            href={node.data.uri}
            title={typeof children === 'string' ? children : ''}
            className={css({
              color: 'button-color-ghost-default-text',
              _hover: {
                color: 'button-color-ghost-hover-text',
              },
            })}
          >
            {children}
          </a>
        )
      },
      // other options...
      [INLINES.ENTRY_HYPERLINK]: (
        node: Block | Inline,
        children: string | React.ReactNode,
      ) => {
        const asset = entryMap.get(node.data.target.sys.id)
        if (!asset?.slug) return null
        // find the entry in the entryMap by ID
        return (
          <Link
            href={asset.slug}
            title={typeof children === 'string' ? children : ''}
            target="_blank"
            className={css({
              color: 'button-color-ghost-default-text',
              _hover: {
                color: 'button-color-ghost-hover-text',
              },
            })}
          >
            {children}
          </Link>
        )
      },
    },
  }
}

export const RichtextRenderer = ({
  json,
  links,
}: Readonly<{
  json: Document
  links?: Links
}>) => {
  if (!json) return null

  const baseOptions = links ? renderOptions(links) : renderRichTextOptions

  return documentToReactComponents(json, baseOptions)
}
