import React from 'react'
import { css, cx, Argument } from '@/styled-system/css'
import { SVGRenderer } from './svgRenderer'
import Image from 'next/image'
import { MdCircle } from 'react-icons/md'
import { ContentfulLivePreview } from '@contentful/live-preview'

interface IconProps {
  width?: number
  height?: number
}

interface ClassNames {
  root?: string | Argument
  icon?: string | Argument
  content?: string | Argument
  title?: string | Argument
}

export interface InlineIconTextProps {
  id: string
  iconRaw?: string
  icon?: string
  title?: string
  text: React.ReactNode
  classNames?: ClassNames
  iconProps?: IconProps
}

export const InlineIconText: React.FC<InlineIconTextProps> = ({
  id,
  iconRaw,
  icon,
  title,
  text,
  classNames,
  iconProps = {
    width: 48,
    height: 48,
  },
}) => {
  const { width, height } = iconProps

  const renderIcon = () => {
    if (icon) {
      return (
        <Image
          src={icon}
          width={width}
          height={height}
          alt={title || 'Icon Text'}
        />
      )
    }
    if (iconRaw) {
      return (
        <SVGRenderer
          svgString={iconRaw}
          className={css({
            width: '100%',
            height: '100%',
            '& > svg': {
              width: '100%',
              height: '100%',
            },
          })}
        />
      )
    }
    return (
      <MdCircle
        className={css({
          mdDown: {
            display: 'none',
          },
          w: 'semantic-sizing-8',
          h: 'semantic-sizing-8',
          mt: 'semantic-spacing-8',
          mx: 20,
        })}
      />
    )
  }

  return (
    <div
      className={cx(
        css({
          display: 'inline-flex',
          width: '100%',
          alignItems: 'center',
          gap: 'semantic-spacing-16',
        }),
        classNames?.root,
      )}
      {...ContentfulLivePreview.getProps({ entryId: id, fieldId: 'title' })}
    >
      <span
        className={cx(
          css({
            position: 'relative',
            display: 'block',
            width,
            height,
            alignSelf: !icon && !iconRaw ? 'baseline' : '',
          }),
          classNames?.icon,
        )}
      >
        {renderIcon()}
      </span>

      <div
        className={cx(
          css({
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 'semantic-spacing-4',
            textAlign: {
              base: 'center',
              md: 'left',
            },
          }),
          classNames?.content,
        )}
      >
        {title && <span className={cx(classNames?.title)}>{title}</span>}
        {text}
      </div>
    </div>
  )
}
