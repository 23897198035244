import { currencyFormatter } from '@/utility/currency'
import { LabelStyled } from '@/global/components/trip-widget/components/label-styled'

import { useSite } from '@/providers/site'

import { css } from '@/styled-system/css'

export function PriceTripCode({
  price,
  base,
  tripCode,
}: Readonly<{
  price: number
  base: number
  tripCode: string
}>) {
  const { locale } = useSite()

  return (
    <div
      className={css({
        '& span': { textStyle: 'semantic-text-sm-font-regular-strikethrough' },
        '& h3': {
          textStyle: 'semantic-text-3xl-font-regular',
          color: 'semantic-fg-default',
        },
        mt: 'semantic-spacing-32',
        md: { mt: 'semantic-spacing-56' },
      })}
    >
      <LabelStyled variant="small">
        From <span>{currencyFormatter(base, locale)}</span>
      </LabelStyled>
      <h3>{currencyFormatter(price, locale)} pp</h3>
      <LabelStyled variant="small">Trip code {tripCode}</LabelStyled>
    </div>
  )
}
