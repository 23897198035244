import { Label } from '@/components/label'
import { css } from '@/styled-system/css'

export function LabelStyled({
  children,
  variant = 'normal',
  htmlFor,
}: Readonly<
  { variant?: 'normal' | 'small'; htmlFor?: string } & React.PropsWithChildren
>) {
  const variantStyles =
    variant === 'normal'
      ? {
          textStyle: 'semantic-text-md-font-regular',
          mb: 'semantic-spacing-8',
        }
      : {
          textStyle: 'semantic-text-sm-font-regular',
          mb: 0,
        }

  return (
    <Label
      htmlFor={htmlFor || ''}
      className={css({
        color: 'semantic-fg-subtle',
        display: 'block',
        ...variantStyles,
      })}
    >
      {children}
    </Label>
  )
}
