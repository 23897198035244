'use client'

import React, { useCallback, useState } from 'react'
import { css, cx } from '@/styled-system/css'
import { Box, Section, Flex } from '@/styled-system/jsx'
import useEmblaCarousel from 'embla-carousel-react'
import type { TitleProps } from '@/components/title'
import { Button } from '@/components/button'
import { Carousel } from '@/components/carousel'
import {
  Lightbox,
  LightboxSlide,
  useLightboxSlide,
} from '@/components/lightbox'
import { Control } from '@/components/carousel/nav-control'
import { SectionHeader } from '@/components/section-header'
import { useDisclosure } from '@/components/hooks/use-disclosure'
import { InlineIconText } from '@/components/inline-icon-text'
import { CarouselSlide } from '@/global/components/media-gallery-carousel/carousel-slide'
import type { MediaGalleryCarouselConfig } from '@/types/components/media-gallery-carousel'

const viewportStyle = css({
  mb: 'semantic-spacing-24',
})

export function GalleryCarousel({
  title = '',
  titleSize = 'heading3',
  text,
  titleTag,
  mediaItems = [],
  featureItems = [],
  backgroundVariant = 'default',
  cta,
  livePreviewProps,
}: Readonly<MediaGalleryCarouselConfig>) {
  const disclosure = useDisclosure()
  const hasFeatures = featureItems.length > 0
  const [emblaRef, emblaApi] = useEmblaCarousel({
    active: mediaItems.length > 1,
  })

  const isReversed = backgroundVariant === 'reverse'

  const { direction, handlePaginate, page, setPage } = useLightboxSlide()

  const [_currentSlide, setCurrentSlide] = useState(0)

  const openLightbox = useCallback(
    (index: number) => {
      setPage([index, 0])
      disclosure.onOpen()
    },
    [setPage, disclosure],
  )

  const onSlideChange = useCallback(() => {
    if (emblaApi) {
      setCurrentSlide(emblaApi.selectedScrollSnap())
    }
  }, [emblaApi])

  React.useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSlideChange)
      onSlideChange()
    }
    return () => {
      if (emblaApi) emblaApi.off('select', onSlideChange)
    }
  }, [emblaApi, onSlideChange])

  if (!mediaItems.length) return null

  return (
    <Box
      overflow="hidden"
      w="full"
      bg={
        backgroundVariant === 'default'
          ? 'semantic-bg-default'
          : 'semantic-bg-reverse'
      }
    >
      <Section
        py={{
          base: 'semantic-spacing-32',
          md: 'semantic-spacing-64',
        }}
      >
        <div
          className={cx(
            css({
              display: 'grid',
              gridTemplateColumns: '1fr',
              rowGap: 'semantic-spacing-40',
              mb: 'semantic-spacing-40',
              md: {
                mb: 'semantic-spacing-64',
                ...(hasFeatures
                  ? {
                      gridTemplateColumns: 'repeat(12, 1fr)',
                      gap: 0,
                    }
                  : {}),
              },
            }),
          )}
        >
          <SectionHeader
            isReversed={isReversed}
            heading={title}
            titleProps={{
              size: titleSize as TitleProps['size'],
              tag: titleTag || 'h2',
              format: 'standard',
              ...(livePreviewProps?.title ?? {}),
            }}
            descriptionProps={{
              ...(livePreviewProps?.text ?? {}),
              ...(isReversed && {
                '& p > a': {
                  color: 'semantic-color-typography-body-reversed',
                  '&:hover': {
                    color: 'semantic-color-typography-links-reversed',
                  },
                },
              }),
            }}
            description={text}
            className={
              hasFeatures
                ? cx(
                    css({
                      gridColumn: 'span 7',
                      pr: {
                        md: 'semantic-spacing-32',
                      },
                    }),
                  )
                : undefined
            }
          >
            {cta?.href && (
              <Button size={{ base: 'sm', md: 'lg' }} {...cta}>
                {cta.label}
              </Button>
            )}
          </SectionHeader>
          {hasFeatures && (
            <Flex
              flexDirection={{ base: 'column' }}
              justifyContent={{
                base: 'start',
                md: 'center',
              }}
              alignItems={{
                base: 'start',
                md: 'center',
              }}
              rowGap={{
                base: 'semantic-spacing-16',
                md: 'semantic-spacing-32',
              }}
              className={cx(
                css({
                  gridColumn: 'span 5',
                  bg: 'semantic-bg-accent',
                  borderRadius: 'border-radius-4',
                  py: 'semantic-spacing-32',
                  px: {
                    base: 'semantic-spacing-16',
                    md: 'semantic-spacing-48',
                  },
                }),
              )}
            >
              {featureItems.map((item) => (
                <InlineIconText
                  key={item.id}
                  {...item}
                  classNames={item?.classNames}
                />
              ))}
            </Flex>
          )}
        </div>

        <Carousel emblaRef={emblaRef} emblaViewportStyle={viewportStyle}>
          <CarouselSlide slides={mediaItems} openLightbox={openLightbox} />
        </Carousel>

        <Control emblaApi={emblaApi} variant={backgroundVariant as never} />

        <Lightbox disclosure={disclosure}>
          <LightboxSlide
            direction={direction}
            handlePaginate={handlePaginate}
            page={page}
            slides={mediaItems}
          />
        </Lightbox>
      </Section>
    </Box>
  )
}
