import {
  commonContentResolver,
  genericDataResolver,
  resolveBrochureCard,
  resolveCta,
} from '@/utility/content-resolvers'
import type { PageSlug } from '@/types/resolved-types'

import type {
  BrochureCardCarouselProps,
  BrochureCard as ResolvedBrochureCard,
} from '@/types/components/brochure-card-carousel'

export const dataResolver = (
  brand: string,
  data: Contentful.Models.BrochureCardGridCarousel,
  isLead?: boolean,
  slugs?: PageSlug[],
) =>
  genericDataResolver<
    Contentful.Models.BrochureCardGridCarousel,
    ResolvedBrochureCard | null,
    BrochureCardCarouselProps
  >(
    data,
    brand,
    {
      id: '',
      showInNav: false,
      name: '',
      title: '',
      titleSize: 'heading3',
      text: '',
      items: [],
      cta: null,
      backgroundVariant: 'default',
    },
    {
      items: {
        collectionKey: 'items',
        resolver: async (item, context) => {
          const resolvedItem = await resolveBrochureCard(
            item as never,
            context,
            slugs,
          )

          if (resolvedItem) {
            return resolvedItem
          }

          return null
        },
      },
    },
    async (resolvedData, context): Promise<BrochureCardCarouselProps> => ({
      ...commonContentResolver(data as never, isLead),
      backgroundVariant: (data.background ||
        'default') as BrochureCardCarouselProps['backgroundVariant'],
      cta: data.cta
        ? (
            await resolveCta(data.cta, context.locale, context.brand, slugs)
          )[0] || null
        : null,
      items: resolvedData.items || [],
    }),
  )
