'use client'
import * as React from 'react'

import { css } from '@/styled-system/css'
import { AccordionItem as AccordionItemProps } from '@/types/components/accordion-section'
import { ButtonGroup } from '@/components/buttonGroup'
import {
  AccordionPrimitiveContent,
  AccordionPrimitiveItem,
  AccordionPrimitiveTrigger,
} from './accordion-primitive'
import { ContentfulLivePreview } from '@contentful/live-preview'

export const AccordionItem = ({
  item,
  onContentClick,
}: {
  item: AccordionItemProps
  onContentClick: (id: string) => void
}) => {
  return (
    <AccordionPrimitiveItem key={item.id} value={item.id}>
      <AccordionPrimitiveTrigger
        onClick={() => {
          onContentClick(item.id)
        }}
        {...ContentfulLivePreview.getProps({
          entryId: item.id,
          fieldId: 'title',
        })}
      >
        {item.title}
      </AccordionPrimitiveTrigger>
      <AccordionPrimitiveContent
        {...ContentfulLivePreview.getProps({
          entryId: item.id,
          fieldId: 'text',
        })}
      >
        {item.text}
        {item.cta ? (
          <ButtonGroup
            className={css({
              marginTop: 'semantic-spacing-16',
            })}
            buttons={item.cta?.map((button, index: number) =>
              index === 0 && button?.variant === 'primary'
                ? {
                    ...button,
                    children: button.label,
                    size: { base: 'sm', md: 'lg' },
                  }
                : button,
            )}
            layout="inline"
          />
        ) : null}
      </AccordionPrimitiveContent>
    </AccordionPrimitiveItem>
  )
}
