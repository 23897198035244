import {
  commonContentResolver,
  genericDataResolver,
  resolveCta,
  resolveTripCard,
} from '@/utility/content-resolvers'

import type { TripCardProps } from '@/types/components/trip-card'
import type { TripCarouselProps } from '@/types/components/trip-carousel'
import type { PageSlug } from '@/types/resolved-types'

export const dataResolver = (
  brand: string,
  data: Contentful.Models.TripCarousel,
  isLead?: boolean,
  slugs?: PageSlug[],
): Promise<TripCarouselProps> =>
  genericDataResolver<
    Contentful.Models.TripCarousel,
    TripCardProps,
    TripCarouselProps
  >(
    data,
    brand,
    {
      showInNav: false,
      navTitle: '',
      title: '',
      titleSize: 'heading3',
      text: '',
      items: [],
      backgroundVariant: 'default',
    },
    {
      items: {
        collectionKey: 'items',
        resolver: async (item, context) => ({
          ...(await resolveTripCard(item as never, context)),
        }),
      },
    },
    async (resolvedData, context): Promise<TripCarouselProps> => ({
      ...commonContentResolver(data as never, isLead),
      backgroundVariant:
        data.background as TripCarouselProps['backgroundVariant'],
      cardVariant: (data.cardVariant ??
        'overlay') as TripCarouselProps['cardVariant'],
      cta: data.cta
        ? (
            await resolveCta(data.cta, context.locale, context.brand, slugs)
          )[0] || null
        : null,
      items: resolvedData.items || [],
      preferredDateRange: {
        from: data.preferredDateFrom,
        to: data.preferredDateTo,
      },
    }),
  )
