'use client'

import { Image } from '@/components/image'

import { Box, Section } from '@/styled-system/jsx'
import { css } from '@/styled-system/css'

const themeAPT = {
  textColor: 'color-class-luxury-500',
  bg: 'color-class-luxury-50',
  alertBody:
    'There is no finer way to discover the charming landscapes of Europe than aboard one of our new Luxury River Ships.',
  alertTitle: 'APT Luxury River Cruising',
}

const themeTravelMarvel = {
  textColor: 'color-class-travelmarvel-600',
  bg: 'color-class-travelmarvel-50',
  alertBody:
    'Explore Europe on a Travelmarvel River Cruise and you will enjoy the perfect balance of inclusions and leisure time.',
  alertTitle: 'Travelmarvel River Cruising',
}

export default function InfoCallout({
  brand,
  logo,
}: Readonly<{ brand: string; logo?: string }>) {
  const theme = brand === 'apt' ? themeAPT : themeTravelMarvel

  return (
    <Section my={{ base: 'semantic-spacing-20', md: 'semantic-spacing-24' }}>
      <Box
        p={{ base: 'semantic-spacing-20', md: 'semantic-spacing-32' }}
        bg={theme.bg}
        borderRadius="border-radius-4"
        display="flex"
      >
        {logo ? (
          <Box
            h={{ base: 'semantic-sizing-32', md: 'semantic-sizing-56' }}
            mr="semantic-spacing-16"
            position="relative"
          >
            <Image
              fill={false}
              width={32}
              height={32}
              src={logo}
              alt={`${brand} logo`}
              className={css({
                objectFit: 'contain',
                maxHeight: '100%',
                width: 'fit-content',
              })}
            />
          </Box>
        ) : null}

        <Box>
          <Box
            className={css({
              textStyle: {
                base: 'semantic-heading-standard-sm',
                md: 'semantic-heading-standard-md',
              },
              color: theme.textColor,
              mb: 'semantic-spacing-8',
            })}
          >
            {theme.alertTitle}
          </Box>
          <Box textStyle="semantic-text-md-font-regular">{theme.alertBody}</Box>
        </Box>
      </Box>
    </Section>
  )
}
