import { MdArrowBack, MdArrowForward } from 'react-icons/md'

import { Button } from '@/components/button'
import { DialogFooter } from '@/components/dialog'

import type { TripItineraryConfig } from '@/types/components/itinerary'

export function Footer({
  activeDay,
  config,
  onDaySelect,
}: {
  activeDay: number
  config: TripItineraryConfig
  onDaySelect: (day: number) => void
}) {
  return (
    <DialogFooter
      boxShadow="0px -10px 10px -5px rgb(0 0 0 / 4%)"
      px={{ base: 'semantic-spacing-20', md: 'semantic-spacing-24' }}
      py={{ base: 'semantic-spacing-20', md: 'semantic-spacing-20' }}
    >
      {activeDay > 1 ? (
        <Button
          leftIcon={<MdArrowBack />}
          size={{ base: 'sm', md: 'lg' }}
          variant="secondary"
          css={{ mr: 'auto' }}
          onClick={() => onDaySelect(activeDay - 1)}
        >
          Day {activeDay - 1}
        </Button>
      ) : null}

      {activeDay < config.days.length ? (
        <Button
          rightIcon={<MdArrowForward />}
          size={{ base: 'sm', md: 'lg' }}
          variant="secondary"
          onClick={() => onDaySelect(activeDay + 1)}
        >
          Day {activeDay + 1}
        </Button>
      ) : null}
    </DialogFooter>
  )
}
