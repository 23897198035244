import {
  commonContentResolver,
  genericDataResolver,
  resolveContentCard,
  resolveCta,
} from '@/utility/content-resolvers'
import type { PageSlug } from '@/types/resolved-types'

import type {
  SlideItem,
  TwoColumnGalleryCarouselConfig,
} from '@/types/components/two-column-gallery-carousel'

export const dataResolver = (
  brand: string,
  data: Contentful.Models.ContentSlider,
  isLead?: boolean,
  slugs?: PageSlug[],
): Promise<TwoColumnGalleryCarouselConfig> =>
  genericDataResolver<
    Contentful.Models.ContentSlider,
    SlideItem | null,
    TwoColumnGalleryCarouselConfig
  >(
    data,
    brand,
    {
      showInNav: false,
      navTitle: '',
      title: '',
      titleSize: 'heading3',
      text: '',
      items: [],
      cta: null,
    },
    {
      items: {
        collectionKey: 'items',
        resolver: async (item, context) => {
          const resolvedItem = await resolveContentCard(
            item as never,
            context,
            slugs,
          )
          if (resolvedItem) {
            return resolvedItem
          }
          return null
        },
      },
    },
    async (resolvedData, context): Promise<TwoColumnGalleryCarouselConfig> => ({
      ...commonContentResolver(data as never, isLead),
      items: resolvedData.items || [],
      cta: data.cta
        ? (
            await resolveCta(data.cta, context.locale, context.brand, slugs)
          )[0] || null
        : null,
    }),
  )
