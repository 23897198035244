import { ConditionalValue } from '@pandacss/types'

import { cx } from '@/styled-system/css'
import { Flex } from '@/styled-system/jsx'
import { ButtonVariant } from '@/styled-system/recipes'

import {
  NavigationControl,
  usePrevNextButtons,
  type NavigationControlProps,
} from '@/components/carousel/btn-prev-next'
import { DotButton, useDotButton } from '@/components/carousel/nav-dot'

import type { EmblaCarouselType } from 'embla-carousel'

type NavControlProps = Omit<
  NavigationControlProps,
  'disabled' | 'isPrevBtn' | 'onClick'
>

export function Control({
  className,
  disabledNavControl = false,
  emblaApi,
  navSize,
  nextNavProps,
  prevNavProps,
  variant = 'default',
  loopToEdge = false,
}: Readonly<{
  className?: string
  disabledNavControl?: boolean
  emblaApi: EmblaCarouselType | undefined
  navSize?: ConditionalValue<ButtonVariant['size']>
  nextNavProps?: NavControlProps
  prevNavProps?: NavControlProps
  variant?: 'default' | 'reverse'
  loopToEdge?: boolean
}>) {
  const {
    isActive,
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi, loopToEdge)

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  if (!isActive) return null

  return isActive ? (
    <div className={cx('embla__controls', className)}>
      <Flex className="embla__buttons" justifyContent="space-between">
        <NavigationControl
          isPrevBtn
          disabled={prevBtnDisabled}
          onClick={onPrevButtonClick}
          tabIndex={disabledNavControl ? -1 : 0}
          size={navSize}
          {...prevNavProps}
        />

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              className={cx(
                'embla__dot'.concat(
                  index === selectedIndex ? ' embla__dot--selected' : '',
                ),
                variant,
              )}
              key={index}
              onClick={() => onDotButtonClick(index)}
              aria-label={`Go to slide ${index + 1}`}
              tabIndex={disabledNavControl ? -1 : 0}
            />
          ))}
        </div>

        <NavigationControl
          disabled={nextBtnDisabled}
          onClick={onNextButtonClick}
          tabIndex={disabledNavControl ? -1 : 0}
          size={navSize}
          {...nextNavProps}
        />
      </Flex>
    </div>
  ) : null
}
