'use client'

import { useEffect, useState } from 'react'

import { useLiveUpdate } from '@/components/hooks/use-live-update'
import { useSite } from '@/providers/site'

import { SplitFeatureSection as SplitFeatureSectionComponent } from '@/global/components/split-feature-section/split-feature-section'
import { dataResolver } from '@/global/components/split-feature-section/util/data-resolver'

import { SplitFeaturesDocument } from '@/graphql/client'

import { error } from '@/utility/logging'

import type { SplitFeaturesSectionProps } from '@/types/components/split-features-section'

export function SplitFeaturesLivePreview({
  brand,
  data: originalData,
  isLead,
}: Readonly<{
  brand: string
  data: Contentful.Models.SplitFeatures
  isLead?: boolean
}>) {
  const [data, setData] = useState<SplitFeaturesSectionProps>({} as never)
  const { slugs } = useSite()

  const { entry: section, getInspectorProps } = useLiveUpdate(
    originalData,
    SplitFeaturesDocument,
  )

  useEffect(() => {
    if (section) {
      const fetchData = async () => {
        try {
          const result = await dataResolver(brand, section, isLead, slugs)
          setData(result)
        } catch (err) {
          error('Error fetching split feature section data', {
            brand,
            err: (err as Error).message,
          })
        }
      }

      fetchData()
    }
  }, [section, isLead, slugs])

  return data ? (
    <SplitFeatureSectionComponent
      {...data}
      livePreviewProps={{
        title: getInspectorProps({ fieldId: 'title' }),
        text: getInspectorProps({ fieldId: 'text' }),
        cta: getInspectorProps({ fieldId: 'cta' }),
      }}
    />
  ) : null
}
