import { css } from '@/styled-system/css'
import { Box } from '@/styled-system/jsx'

import { DialogHeader, DialogTitle } from '@/components/dialog'

import { TripItineraryTimelineScroller } from '@/global/components/trip-itinerary/trip-itinerary-timeline-scroller'

import type { TripItineraryConfig } from '@/types/components/itinerary'

export const Header = ({
  activeDay,
  config,
  onDaySelect,
}: {
  activeDay: number
  config: TripItineraryConfig
  onDaySelect: (day: number) => void
}) => {
  return (
    <DialogHeader
      borderBottomColor="semantic-border-default"
      borderBottomStyle="solid"
      borderBottomWidth={1}
    >
      <DialogTitle
        className={css({
          px: {
            base: 'semantic-spacing-20',
            md: 'semantic-spacing-24',
            lg: 'semantic-spacing-32',
          },
          py: {
            base: 'semantic-spacing-12',
            md: 'semantic-spacing-16',
            lg: 'semantic-spacing-24',
          },
          textStyle: {
            base: 'semantic-text-md-font-regular',
            md: 'semantic-text-2xl-font-regular',
            lg: 'semantic-text-3xl-font-regular',
          },
        })}
      >
        {config.journey ??
          `${config.startDestination} to ${config.endDestination}`}
      </DialogTitle>

      <Box
        display="flex"
        flexDirection="row"
        pt={{ base: 'semantic-spacing-8', lg: 'semantic-spacing-16' }}
        px="semantic-spacing-24"
        pos="relative"
        w="full"
      >
        <TripItineraryTimelineScroller
          activeDay={activeDay}
          config={config}
          direction="horizontal"
          onDaySelect={onDaySelect}
        />
      </Box>
    </DialogHeader>
  )
}
