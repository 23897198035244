'use client'

import { useState } from 'react'

import { useTripsData } from '@/global/components/trip-widget/utils'

import { Dropdown } from './components/dropdown'
import { DateSelect } from './components/date-select'
import { Info } from './components/trip-info'

import { useSite } from '@/providers/site'
import { BookingOptions } from './components/booking-options'

import { BoxLoader } from './components/loader'
import { RequestQuoteOption } from './components/request-quote'

type RoomType = 'single' | 'double'

export function TripWidget({
  seasons,
  trips,
}: Readonly<{
  seasons: Contentful.Models.TripSeason[]
  trips: Contentful.Models.Trip[]
}>) {
  const { brand, locale } = useSite()

  const [selectedRoomType, setSelectedRoomType] = useState<RoomType>('double')

  const {
    availableTrips,

    dateList,
    selectedDate,
    routeList,
    selectedRoute,
    setSelectedRoute,

    setSelectedDate,

    selectedDatePrice,

    isLoading,
  } = useTripsData(seasons, trips, brand.code!, locale)

  const hasAvailableTrips = availableTrips && availableTrips?.length

  const price = selectedDatePrice?.[selectedRoomType]?.price
  const basePrice = selectedDatePrice?.[selectedRoomType]?.basePrice

  return (
    <BoxLoader isLoading={isLoading}>
      <Dropdown
        id={'dropdown-route'}
        label="Route"
        items={routeList}
        defaultValue={selectedRoute.sys?.id || ''}
        onChange={(v) => {
          setSelectedRoute(v)
        }}
      />

      {hasAvailableTrips ? (
        <>
          <DateSelect
            startDate={dateList[0]}
            includeDates={availableTrips}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />

          <Dropdown
            id={'dropdown-guests'}
            label="Guests and Room"
            items={[
              {
                text: '2 Guests, 1 Room',
                value: 'double',
              },
              {
                text: '1 Guest, 1 Room',
                value: 'single',
              },
            ]}
            defaultValue={'double'}
            onChange={(v) => setSelectedRoomType(v as RoomType)}
          />
        </>
      ) : null}

      <Info duration={selectedRoute.duration || 0} />

      {hasAvailableTrips && price && basePrice ? (
        <BookingOptions
          price={price}
          basePrice={basePrice}
          tripCode={selectedRoute.tripCode || ''}
        />
      ) : (
        <RequestQuoteOption />
      )}
    </BoxLoader>
  )
}
