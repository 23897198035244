const tokens = {
  "durations.fastest": {
    "value": "50ms",
    "variable": "var(--durations-fastest)"
  },
  "durations.faster": {
    "value": "100ms",
    "variable": "var(--durations-faster)"
  },
  "durations.fast": {
    "value": "150ms",
    "variable": "var(--durations-fast)"
  },
  "durations.normal": {
    "value": "200ms",
    "variable": "var(--durations-normal)"
  },
  "durations.slow": {
    "value": "300ms",
    "variable": "var(--durations-slow)"
  },
  "durations.slower": {
    "value": "400ms",
    "variable": "var(--durations-slower)"
  },
  "durations.slowest": {
    "value": "500ms",
    "variable": "var(--durations-slowest)"
  },
  "sizes.full": {
    "value": "100%",
    "variable": "var(--sizes-full)"
  },
  "sizes.breakpoint-base": {
    "value": "0px",
    "variable": "var(--sizes-breakpoint-base)"
  },
  "sizes.breakpoint-xs": {
    "value": "375px",
    "variable": "var(--sizes-breakpoint-xs)"
  },
  "sizes.breakpoint-sm": {
    "value": "600px",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "1024px",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-xl": {
    "value": "1280px",
    "variable": "var(--sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-2xl": {
    "value": "1440px",
    "variable": "var(--sizes-breakpoint-2xl)"
  },
  "breakpoints.base": {
    "value": "0px",
    "variable": "var(--breakpoints-base)"
  },
  "breakpoints.xs": {
    "value": "375px",
    "variable": "var(--breakpoints-xs)"
  },
  "breakpoints.sm": {
    "value": "600px",
    "variable": "var(--breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "768px",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "1024px",
    "variable": "var(--breakpoints-lg)"
  },
  "breakpoints.xl": {
    "value": "1280px",
    "variable": "var(--breakpoints-xl)"
  },
  "breakpoints.2xl": {
    "value": "1440px",
    "variable": "var(--breakpoints-2xl)"
  },
  "spacing.-space-0": {
    "value": "var(--spacing-space-0)",
    "variable": "var(--spacing-space-0)"
  },
  "spacing.-space-25": {
    "value": "var(--spacing-space-25)",
    "variable": "var(--spacing-space-25)"
  },
  "spacing.-space-50": {
    "value": "var(--spacing-space-50)",
    "variable": "var(--spacing-space-50)"
  },
  "spacing.-space-75": {
    "value": "var(--spacing-space-75)",
    "variable": "var(--spacing-space-75)"
  },
  "spacing.-space-100": {
    "value": "var(--spacing-space-100)",
    "variable": "var(--spacing-space-100)"
  },
  "spacing.-space-125": {
    "value": "var(--spacing-space-125)",
    "variable": "var(--spacing-space-125)"
  },
  "spacing.-space-150": {
    "value": "var(--spacing-space-150)",
    "variable": "var(--spacing-space-150)"
  },
  "spacing.-space-175": {
    "value": "var(--spacing-space-175)",
    "variable": "var(--spacing-space-175)"
  },
  "spacing.-space-200": {
    "value": "var(--spacing-space-200)",
    "variable": "var(--spacing-space-200)"
  },
  "spacing.-space-225": {
    "value": "var(--spacing-space-225)",
    "variable": "var(--spacing-space-225)"
  },
  "spacing.-space-250": {
    "value": "var(--spacing-space-250)",
    "variable": "var(--spacing-space-250)"
  },
  "spacing.-space-275": {
    "value": "var(--spacing-space-275)",
    "variable": "var(--spacing-space-275)"
  },
  "spacing.-space-300": {
    "value": "var(--spacing-space-300)",
    "variable": "var(--spacing-space-300)"
  },
  "spacing.-space-350": {
    "value": "var(--spacing-space-350)",
    "variable": "var(--spacing-space-350)"
  },
  "spacing.-space-400": {
    "value": "var(--spacing-space-400)",
    "variable": "var(--spacing-space-400)"
  },
  "spacing.-space-500": {
    "value": "var(--spacing-space-500)",
    "variable": "var(--spacing-space-500)"
  },
  "spacing.-space-600": {
    "value": "var(--spacing-space-600)",
    "variable": "var(--spacing-space-600)"
  },
  "spacing.-space-700": {
    "value": "var(--spacing-space-700)",
    "variable": "var(--spacing-space-700)"
  },
  "spacing.-space-800": {
    "value": "var(--spacing-space-800)",
    "variable": "var(--spacing-space-800)"
  },
  "spacing.-space-900": {
    "value": "var(--spacing-space-900)",
    "variable": "var(--spacing-space-900)"
  },
  "spacing.-space-1000": {
    "value": "var(--spacing-space-1000)",
    "variable": "var(--spacing-space-1000)"
  },
  "spacing.-space-1200": {
    "value": "var(--spacing-space-1200)",
    "variable": "var(--spacing-space-1200)"
  },
  "spacing.-space-1400": {
    "value": "var(--spacing-space-1400)",
    "variable": "var(--spacing-space-1400)"
  },
  "spacing.-space-1600": {
    "value": "var(--spacing-space-1600)",
    "variable": "var(--spacing-space-1600)"
  },
  "spacing.-space-1800": {
    "value": "var(--spacing-space-1800)",
    "variable": "var(--spacing-space-1800)"
  },
  "spacing.-space-2000": {
    "value": "var(--spacing-space-2000)",
    "variable": "var(--spacing-space-2000)"
  },
  "spacing.-space-2200": {
    "value": "var(--spacing-space-2200)",
    "variable": "var(--spacing-space-2200)"
  },
  "spacing.-space-2400": {
    "value": "var(--spacing-space-2400)",
    "variable": "var(--spacing-space-2400)"
  },
  "spacing.-space-2600": {
    "value": "var(--spacing-space-2600)",
    "variable": "var(--spacing-space-2600)"
  },
  "spacing.-space-2800": {
    "value": "var(--spacing-space-2800)",
    "variable": "var(--spacing-space-2800)"
  },
  "spacing.-space-3000": {
    "value": "var(--spacing-space-3000)",
    "variable": "var(--spacing-space-3000)"
  },
  "spacing.-space-3200": {
    "value": "var(--spacing-space-3200)",
    "variable": "var(--spacing-space-3200)"
  },
  "spacing.-space-3400": {
    "value": "var(--spacing-space-3400)",
    "variable": "var(--spacing-space-3400)"
  },
  "spacing.-space-3600": {
    "value": "var(--spacing-space-3600)",
    "variable": "var(--spacing-space-3600)"
  },
  "spacing.-space-3800": {
    "value": "var(--spacing-space-3800)",
    "variable": "var(--spacing-space-3800)"
  },
  "spacing.-space-1": {
    "value": "var(--spacing-space-1)",
    "variable": "var(--spacing-space-1)"
  },
  "spacing.-semantic-spacing-0": {
    "value": "var(--spacing-semantic-spacing-0)",
    "variable": "var(--spacing-semantic-spacing-0)"
  },
  "spacing.-semantic-spacing-2": {
    "value": "var(--spacing-semantic-spacing-2)",
    "variable": "var(--spacing-semantic-spacing-2)"
  },
  "spacing.-semantic-spacing-4": {
    "value": "var(--spacing-semantic-spacing-4)",
    "variable": "var(--spacing-semantic-spacing-4)"
  },
  "spacing.-semantic-spacing-6": {
    "value": "var(--spacing-semantic-spacing-6)",
    "variable": "var(--spacing-semantic-spacing-6)"
  },
  "spacing.-semantic-spacing-8": {
    "value": "var(--spacing-semantic-spacing-8)",
    "variable": "var(--spacing-semantic-spacing-8)"
  },
  "spacing.-semantic-spacing-10": {
    "value": "var(--spacing-semantic-spacing-10)",
    "variable": "var(--spacing-semantic-spacing-10)"
  },
  "spacing.-semantic-spacing-12": {
    "value": "var(--spacing-semantic-spacing-12)",
    "variable": "var(--spacing-semantic-spacing-12)"
  },
  "spacing.-semantic-spacing-16": {
    "value": "var(--spacing-semantic-spacing-16)",
    "variable": "var(--spacing-semantic-spacing-16)"
  },
  "spacing.-semantic-spacing-20": {
    "value": "var(--spacing-semantic-spacing-20)",
    "variable": "var(--spacing-semantic-spacing-20)"
  },
  "spacing.-semantic-spacing-24": {
    "value": "var(--spacing-semantic-spacing-24)",
    "variable": "var(--spacing-semantic-spacing-24)"
  },
  "spacing.-semantic-spacing-32": {
    "value": "var(--spacing-semantic-spacing-32)",
    "variable": "var(--spacing-semantic-spacing-32)"
  },
  "spacing.-semantic-spacing-40": {
    "value": "var(--spacing-semantic-spacing-40)",
    "variable": "var(--spacing-semantic-spacing-40)"
  },
  "spacing.-semantic-spacing-48": {
    "value": "var(--spacing-semantic-spacing-48)",
    "variable": "var(--spacing-semantic-spacing-48)"
  },
  "spacing.-semantic-spacing-56": {
    "value": "var(--spacing-semantic-spacing-56)",
    "variable": "var(--spacing-semantic-spacing-56)"
  },
  "spacing.-semantic-spacing-64": {
    "value": "var(--spacing-semantic-spacing-64)",
    "variable": "var(--spacing-semantic-spacing-64)"
  },
  "spacing.-semantic-spacing-1": {
    "value": "var(--spacing-semantic-spacing-1)",
    "variable": "var(--spacing-semantic-spacing-1)"
  },
  "radii.border-radius-0": {
    "value": "var(--radii-border-radius-0)",
    "variable": "var(--radii-border-radius-0)"
  },
  "radii.border-radius-1": {
    "value": "var(--radii-border-radius-1)",
    "variable": "var(--radii-border-radius-1)"
  },
  "radii.border-radius-2": {
    "value": "var(--radii-border-radius-2)",
    "variable": "var(--radii-border-radius-2)"
  },
  "radii.border-radius-3": {
    "value": "var(--radii-border-radius-3)",
    "variable": "var(--radii-border-radius-3)"
  },
  "radii.border-radius-4": {
    "value": "var(--radii-border-radius-4)",
    "variable": "var(--radii-border-radius-4)"
  },
  "radii.border-radius-5": {
    "value": "var(--radii-border-radius-5)",
    "variable": "var(--radii-border-radius-5)"
  },
  "radii.border-radius-6": {
    "value": "var(--radii-border-radius-6)",
    "variable": "var(--radii-border-radius-6)"
  },
  "radii.border-radius-7": {
    "value": "var(--radii-border-radius-7)",
    "variable": "var(--radii-border-radius-7)"
  },
  "radii.border-radius-8": {
    "value": "var(--radii-border-radius-8)",
    "variable": "var(--radii-border-radius-8)"
  },
  "radii.border-radius-9": {
    "value": "var(--radii-border-radius-9)",
    "variable": "var(--radii-border-radius-9)"
  },
  "radii.semantic-border-radius-x-small": {
    "value": "var(--radii-semantic-border-radius-x-small)",
    "variable": "var(--radii-semantic-border-radius-x-small)"
  },
  "radii.semantic-border-radius-small": {
    "value": "var(--radii-semantic-border-radius-small)",
    "variable": "var(--radii-semantic-border-radius-small)"
  },
  "radii.semantic-border-radius-medium": {
    "value": "var(--radii-semantic-border-radius-medium)",
    "variable": "var(--radii-semantic-border-radius-medium)"
  },
  "radii.semantic-border-radius-large": {
    "value": "var(--radii-semantic-border-radius-large)",
    "variable": "var(--radii-semantic-border-radius-large)"
  },
  "radii.semantic-border-radius-x-large": {
    "value": "var(--radii-semantic-border-radius-x-large)",
    "variable": "var(--radii-semantic-border-radius-x-large)"
  },
  "radii.semantic-border-radius-round": {
    "value": "var(--radii-semantic-border-radius-round)",
    "variable": "var(--radii-semantic-border-radius-round)"
  },
  "radii.button-layout-lg-border-radius": {
    "value": "var(--radii-button-layout-lg-border-radius)",
    "variable": "var(--radii-button-layout-lg-border-radius)"
  },
  "radii.button-layout-md-border-radius": {
    "value": "var(--radii-button-layout-md-border-radius)",
    "variable": "var(--radii-button-layout-md-border-radius)"
  },
  "radii.button-layout-sm-border-radius": {
    "value": "var(--radii-button-layout-sm-border-radius)",
    "variable": "var(--radii-button-layout-sm-border-radius)"
  },
  "radii.button-layout-xs-border-radius": {
    "value": "var(--radii-button-layout-xs-border-radius)",
    "variable": "var(--radii-button-layout-xs-border-radius)"
  },
  "radii.button-ghost-layout-lg-border-radius": {
    "value": "var(--radii-button-ghost-layout-lg-border-radius)",
    "variable": "var(--radii-button-ghost-layout-lg-border-radius)"
  },
  "radii.button-ghost-layout-md-border-radius": {
    "value": "var(--radii-button-ghost-layout-md-border-radius)",
    "variable": "var(--radii-button-ghost-layout-md-border-radius)"
  },
  "radii.button-ghost-layout-sm-border-radius": {
    "value": "var(--radii-button-ghost-layout-sm-border-radius)",
    "variable": "var(--radii-button-ghost-layout-sm-border-radius)"
  },
  "radii.button-ghost-layout-xs-border-radius": {
    "value": "var(--radii-button-ghost-layout-xs-border-radius)",
    "variable": "var(--radii-button-ghost-layout-xs-border-radius)"
  },
  "borderWidths.border-width-0": {
    "value": "var(--border-widths-border-width-0)",
    "variable": "var(--border-widths-border-width-0)"
  },
  "borderWidths.border-width-1": {
    "value": "var(--border-widths-border-width-1)",
    "variable": "var(--border-widths-border-width-1)"
  },
  "borderWidths.border-width-2": {
    "value": "var(--border-widths-border-width-2)",
    "variable": "var(--border-widths-border-width-2)"
  },
  "borderWidths.border-width-3": {
    "value": "var(--border-widths-border-width-3)",
    "variable": "var(--border-widths-border-width-3)"
  },
  "borderWidths.border-width-4": {
    "value": "var(--border-widths-border-width-4)",
    "variable": "var(--border-widths-border-width-4)"
  },
  "borderWidths.border-width-5": {
    "value": "var(--border-widths-border-width-5)",
    "variable": "var(--border-widths-border-width-5)"
  },
  "borderWidths.border-width-6": {
    "value": "var(--border-widths-border-width-6)",
    "variable": "var(--border-widths-border-width-6)"
  },
  "borderWidths.semantic-border-width-x-small": {
    "value": "var(--border-widths-semantic-border-width-x-small)",
    "variable": "var(--border-widths-semantic-border-width-x-small)"
  },
  "borderWidths.semantic-border-width-small": {
    "value": "var(--border-widths-semantic-border-width-small)",
    "variable": "var(--border-widths-semantic-border-width-small)"
  },
  "borderWidths.semantic-border-width-medium": {
    "value": "var(--border-widths-semantic-border-width-medium)",
    "variable": "var(--border-widths-semantic-border-width-medium)"
  },
  "borderWidths.semantic-border-width-large": {
    "value": "var(--border-widths-semantic-border-width-large)",
    "variable": "var(--border-widths-semantic-border-width-large)"
  },
  "borderWidths.semantic-border-width-x-large": {
    "value": "var(--border-widths-semantic-border-width-x-large)",
    "variable": "var(--border-widths-semantic-border-width-x-large)"
  },
  "borderWidths.semantic-border-width-xx-large": {
    "value": "var(--border-widths-semantic-border-width-xx-large)",
    "variable": "var(--border-widths-semantic-border-width-xx-large)"
  },
  "sizes.size-0": {
    "value": "var(--sizes-size-0)",
    "variable": "var(--sizes-size-0)"
  },
  "sizes.size-12": {
    "value": "var(--sizes-size-12)",
    "variable": "var(--sizes-size-12)"
  },
  "sizes.size-25": {
    "value": "var(--sizes-size-25)",
    "variable": "var(--sizes-size-25)"
  },
  "sizes.size-50": {
    "value": "var(--sizes-size-50)",
    "variable": "var(--sizes-size-50)"
  },
  "sizes.size-75": {
    "value": "var(--sizes-size-75)",
    "variable": "var(--sizes-size-75)"
  },
  "sizes.size-100": {
    "value": "var(--sizes-size-100)",
    "variable": "var(--sizes-size-100)"
  },
  "sizes.size-125": {
    "value": "var(--sizes-size-125)",
    "variable": "var(--sizes-size-125)"
  },
  "sizes.size-150": {
    "value": "var(--sizes-size-150)",
    "variable": "var(--sizes-size-150)"
  },
  "sizes.size-175": {
    "value": "var(--sizes-size-175)",
    "variable": "var(--sizes-size-175)"
  },
  "sizes.size-200": {
    "value": "var(--sizes-size-200)",
    "variable": "var(--sizes-size-200)"
  },
  "sizes.size-225": {
    "value": "var(--sizes-size-225)",
    "variable": "var(--sizes-size-225)"
  },
  "sizes.size-250": {
    "value": "var(--sizes-size-250)",
    "variable": "var(--sizes-size-250)"
  },
  "sizes.size-275": {
    "value": "var(--sizes-size-275)",
    "variable": "var(--sizes-size-275)"
  },
  "sizes.size-300": {
    "value": "var(--sizes-size-300)",
    "variable": "var(--sizes-size-300)"
  },
  "sizes.size-320": {
    "value": "var(--sizes-size-320)",
    "variable": "var(--sizes-size-320)"
  },
  "sizes.size-340": {
    "value": "var(--sizes-size-340)",
    "variable": "var(--sizes-size-340)"
  },
  "sizes.size-360": {
    "value": "var(--sizes-size-360)",
    "variable": "var(--sizes-size-360)"
  },
  "sizes.size-400": {
    "value": "var(--sizes-size-400)",
    "variable": "var(--sizes-size-400)"
  },
  "sizes.size-425": {
    "value": "var(--sizes-size-425)",
    "variable": "var(--sizes-size-425)"
  },
  "sizes.size-450": {
    "value": "var(--sizes-size-450)",
    "variable": "var(--sizes-size-450)"
  },
  "sizes.size-500": {
    "value": "var(--sizes-size-500)",
    "variable": "var(--sizes-size-500)"
  },
  "sizes.size-550": {
    "value": "var(--sizes-size-550)",
    "variable": "var(--sizes-size-550)"
  },
  "sizes.size-600": {
    "value": "var(--sizes-size-600)",
    "variable": "var(--sizes-size-600)"
  },
  "sizes.size-660": {
    "value": "var(--sizes-size-660)",
    "variable": "var(--sizes-size-660)"
  },
  "sizes.size-700": {
    "value": "var(--sizes-size-700)",
    "variable": "var(--sizes-size-700)"
  },
  "sizes.size-800": {
    "value": "var(--sizes-size-800)",
    "variable": "var(--sizes-size-800)"
  },
  "sizes.size-900": {
    "value": "var(--sizes-size-900)",
    "variable": "var(--sizes-size-900)"
  },
  "sizes.size-1000": {
    "value": "var(--sizes-size-1000)",
    "variable": "var(--sizes-size-1000)"
  },
  "sizes.size-1200": {
    "value": "var(--sizes-size-1200)",
    "variable": "var(--sizes-size-1200)"
  },
  "sizes.size-1400": {
    "value": "var(--sizes-size-1400)",
    "variable": "var(--sizes-size-1400)"
  },
  "sizes.size-1600": {
    "value": "var(--sizes-size-1600)",
    "variable": "var(--sizes-size-1600)"
  },
  "sizes.size-1800": {
    "value": "var(--sizes-size-1800)",
    "variable": "var(--sizes-size-1800)"
  },
  "sizes.size-2000": {
    "value": "var(--sizes-size-2000)",
    "variable": "var(--sizes-size-2000)"
  },
  "sizes.size-2200": {
    "value": "var(--sizes-size-2200)",
    "variable": "var(--sizes-size-2200)"
  },
  "sizes.size-2400": {
    "value": "var(--sizes-size-2400)",
    "variable": "var(--sizes-size-2400)"
  },
  "sizes.size-2600": {
    "value": "var(--sizes-size-2600)",
    "variable": "var(--sizes-size-2600)"
  },
  "sizes.size-2800": {
    "value": "var(--sizes-size-2800)",
    "variable": "var(--sizes-size-2800)"
  },
  "sizes.size-3000": {
    "value": "var(--sizes-size-3000)",
    "variable": "var(--sizes-size-3000)"
  },
  "sizes.size-3200": {
    "value": "var(--sizes-size-3200)",
    "variable": "var(--sizes-size-3200)"
  },
  "sizes.size-3400": {
    "value": "var(--sizes-size-3400)",
    "variable": "var(--sizes-size-3400)"
  },
  "sizes.size-3600": {
    "value": "var(--sizes-size-3600)",
    "variable": "var(--sizes-size-3600)"
  },
  "sizes.size-3800": {
    "value": "var(--sizes-size-3800)",
    "variable": "var(--sizes-size-3800)"
  },
  "sizes.size-3900": {
    "value": "var(--sizes-size-3900)",
    "variable": "var(--sizes-size-3900)"
  },
  "sizes.size-4000": {
    "value": "var(--sizes-size-4000)",
    "variable": "var(--sizes-size-4000)"
  },
  "sizes.size-4200": {
    "value": "var(--sizes-size-4200)",
    "variable": "var(--sizes-size-4200)"
  },
  "sizes.size-6000": {
    "value": "var(--sizes-size-6000)",
    "variable": "var(--sizes-size-6000)"
  },
  "sizes.semantic-sizing-1": {
    "value": "var(--sizes-semantic-sizing-1)",
    "variable": "var(--sizes-semantic-sizing-1)"
  },
  "sizes.semantic-sizing-4": {
    "value": "var(--sizes-semantic-sizing-4)",
    "variable": "var(--sizes-semantic-sizing-4)"
  },
  "sizes.semantic-sizing-8": {
    "value": "var(--sizes-semantic-sizing-8)",
    "variable": "var(--sizes-semantic-sizing-8)"
  },
  "sizes.semantic-sizing-12": {
    "value": "var(--sizes-semantic-sizing-12)",
    "variable": "var(--sizes-semantic-sizing-12)"
  },
  "sizes.semantic-sizing-16": {
    "value": "var(--sizes-semantic-sizing-16)",
    "variable": "var(--sizes-semantic-sizing-16)"
  },
  "sizes.semantic-sizing-20": {
    "value": "var(--sizes-semantic-sizing-20)",
    "variable": "var(--sizes-semantic-sizing-20)"
  },
  "sizes.semantic-sizing-24": {
    "value": "var(--sizes-semantic-sizing-24)",
    "variable": "var(--sizes-semantic-sizing-24)"
  },
  "sizes.semantic-sizing-26": {
    "value": "var(--sizes-semantic-sizing-26)",
    "variable": "var(--sizes-semantic-sizing-26)"
  },
  "sizes.semantic-sizing-28": {
    "value": "var(--sizes-semantic-sizing-28)",
    "variable": "var(--sizes-semantic-sizing-28)"
  },
  "sizes.semantic-sizing-30": {
    "value": "var(--sizes-semantic-sizing-30)",
    "variable": "var(--sizes-semantic-sizing-30)"
  },
  "sizes.semantic-sizing-32": {
    "value": "var(--sizes-semantic-sizing-32)",
    "variable": "var(--sizes-semantic-sizing-32)"
  },
  "sizes.semantic-sizing-34": {
    "value": "var(--sizes-semantic-sizing-34)",
    "variable": "var(--sizes-semantic-sizing-34)"
  },
  "sizes.semantic-sizing-40": {
    "value": "var(--sizes-semantic-sizing-40)",
    "variable": "var(--sizes-semantic-sizing-40)"
  },
  "sizes.semantic-sizing-44": {
    "value": "var(--sizes-semantic-sizing-44)",
    "variable": "var(--sizes-semantic-sizing-44)"
  },
  "sizes.semantic-sizing-48": {
    "value": "var(--sizes-semantic-sizing-48)",
    "variable": "var(--sizes-semantic-sizing-48)"
  },
  "sizes.semantic-sizing-50": {
    "value": "var(--sizes-semantic-sizing-50)",
    "variable": "var(--sizes-semantic-sizing-50)"
  },
  "sizes.semantic-sizing-56": {
    "value": "var(--sizes-semantic-sizing-56)",
    "variable": "var(--sizes-semantic-sizing-56)"
  },
  "sizes.semantic-sizing-64": {
    "value": "var(--sizes-semantic-sizing-64)",
    "variable": "var(--sizes-semantic-sizing-64)"
  },
  "sizes.semantic-sizing-72": {
    "value": "var(--sizes-semantic-sizing-72)",
    "variable": "var(--sizes-semantic-sizing-72)"
  },
  "sizes.semantic-sizing-80": {
    "value": "var(--sizes-semantic-sizing-80)",
    "variable": "var(--sizes-semantic-sizing-80)"
  },
  "sizes.semantic-sizing-224": {
    "value": "var(--sizes-semantic-sizing-224)",
    "variable": "var(--sizes-semantic-sizing-224)"
  },
  "sizes.semantic-sizing-320": {
    "value": "var(--sizes-semantic-sizing-320)",
    "variable": "var(--sizes-semantic-sizing-320)"
  },
  "sizes.semantic-sizing-354": {
    "value": "var(--sizes-semantic-sizing-354)",
    "variable": "var(--sizes-semantic-sizing-354)"
  },
  "sizes.semantic-sizing-378": {
    "value": "var(--sizes-semantic-sizing-378)",
    "variable": "var(--sizes-semantic-sizing-378)"
  },
  "sizes.semantic-sizing-400": {
    "value": "var(--sizes-semantic-sizing-400)",
    "variable": "var(--sizes-semantic-sizing-400)"
  },
  "sizes.semantic-sizing-448": {
    "value": "var(--sizes-semantic-sizing-448)",
    "variable": "var(--sizes-semantic-sizing-448)"
  },
  "sizes.semantic-sizing-600": {
    "value": "var(--sizes-semantic-sizing-600)",
    "variable": "var(--sizes-semantic-sizing-600)"
  },
  "sizes.semantic-sizing-icon-size-xx-small": {
    "value": "var(--sizes-semantic-sizing-icon-size-xx-small)",
    "variable": "var(--sizes-semantic-sizing-icon-size-xx-small)"
  },
  "sizes.semantic-sizing-icon-size-x-small": {
    "value": "var(--sizes-semantic-sizing-icon-size-x-small)",
    "variable": "var(--sizes-semantic-sizing-icon-size-x-small)"
  },
  "sizes.semantic-sizing-icon-size-small": {
    "value": "var(--sizes-semantic-sizing-icon-size-small)",
    "variable": "var(--sizes-semantic-sizing-icon-size-small)"
  },
  "sizes.semantic-sizing-icon-size-medium": {
    "value": "var(--sizes-semantic-sizing-icon-size-medium)",
    "variable": "var(--sizes-semantic-sizing-icon-size-medium)"
  },
  "sizes.semantic-sizing-icon-size-large": {
    "value": "var(--sizes-semantic-sizing-icon-size-large)",
    "variable": "var(--sizes-semantic-sizing-icon-size-large)"
  },
  "sizes.semantic-sizing-icon-size-x-large": {
    "value": "var(--sizes-semantic-sizing-icon-size-x-large)",
    "variable": "var(--sizes-semantic-sizing-icon-size-x-large)"
  },
  "sizes.semantic-sizing-icon-size-xx-large": {
    "value": "var(--sizes-semantic-sizing-icon-size-xx-large)",
    "variable": "var(--sizes-semantic-sizing-icon-size-xx-large)"
  },
  "sizes.semantic-sizing-icon-size-xxx-large": {
    "value": "var(--sizes-semantic-sizing-icon-size-xxx-large)",
    "variable": "var(--sizes-semantic-sizing-icon-size-xxx-large)"
  },
  "sizes.semantic-sizing-icon-size-xxxx-large": {
    "value": "var(--sizes-semantic-sizing-icon-size-xxxx-large)",
    "variable": "var(--sizes-semantic-sizing-icon-size-xxxx-large)"
  },
  "spacing.space-0": {
    "value": "var(--spacing-space-0)",
    "variable": "var(--spacing-space-0)"
  },
  "spacing.space-25": {
    "value": "var(--spacing-space-25)",
    "variable": "var(--spacing-space-25)"
  },
  "spacing.space-50": {
    "value": "var(--spacing-space-50)",
    "variable": "var(--spacing-space-50)"
  },
  "spacing.space-75": {
    "value": "var(--spacing-space-75)",
    "variable": "var(--spacing-space-75)"
  },
  "spacing.space-100": {
    "value": "var(--spacing-space-100)",
    "variable": "var(--spacing-space-100)"
  },
  "spacing.space-125": {
    "value": "var(--spacing-space-125)",
    "variable": "var(--spacing-space-125)"
  },
  "spacing.space-150": {
    "value": "var(--spacing-space-150)",
    "variable": "var(--spacing-space-150)"
  },
  "spacing.space-175": {
    "value": "var(--spacing-space-175)",
    "variable": "var(--spacing-space-175)"
  },
  "spacing.space-200": {
    "value": "var(--spacing-space-200)",
    "variable": "var(--spacing-space-200)"
  },
  "spacing.space-225": {
    "value": "var(--spacing-space-225)",
    "variable": "var(--spacing-space-225)"
  },
  "spacing.space-250": {
    "value": "var(--spacing-space-250)",
    "variable": "var(--spacing-space-250)"
  },
  "spacing.space-275": {
    "value": "var(--spacing-space-275)",
    "variable": "var(--spacing-space-275)"
  },
  "spacing.space-300": {
    "value": "var(--spacing-space-300)",
    "variable": "var(--spacing-space-300)"
  },
  "spacing.space-350": {
    "value": "var(--spacing-space-350)",
    "variable": "var(--spacing-space-350)"
  },
  "spacing.space-400": {
    "value": "var(--spacing-space-400)",
    "variable": "var(--spacing-space-400)"
  },
  "spacing.space-500": {
    "value": "var(--spacing-space-500)",
    "variable": "var(--spacing-space-500)"
  },
  "spacing.space-600": {
    "value": "var(--spacing-space-600)",
    "variable": "var(--spacing-space-600)"
  },
  "spacing.space-700": {
    "value": "var(--spacing-space-700)",
    "variable": "var(--spacing-space-700)"
  },
  "spacing.space-800": {
    "value": "var(--spacing-space-800)",
    "variable": "var(--spacing-space-800)"
  },
  "spacing.space-900": {
    "value": "var(--spacing-space-900)",
    "variable": "var(--spacing-space-900)"
  },
  "spacing.space-1000": {
    "value": "var(--spacing-space-1000)",
    "variable": "var(--spacing-space-1000)"
  },
  "spacing.space-1200": {
    "value": "var(--spacing-space-1200)",
    "variable": "var(--spacing-space-1200)"
  },
  "spacing.space-1400": {
    "value": "var(--spacing-space-1400)",
    "variable": "var(--spacing-space-1400)"
  },
  "spacing.space-1600": {
    "value": "var(--spacing-space-1600)",
    "variable": "var(--spacing-space-1600)"
  },
  "spacing.space-1800": {
    "value": "var(--spacing-space-1800)",
    "variable": "var(--spacing-space-1800)"
  },
  "spacing.space-2000": {
    "value": "var(--spacing-space-2000)",
    "variable": "var(--spacing-space-2000)"
  },
  "spacing.space-2200": {
    "value": "var(--spacing-space-2200)",
    "variable": "var(--spacing-space-2200)"
  },
  "spacing.space-2400": {
    "value": "var(--spacing-space-2400)",
    "variable": "var(--spacing-space-2400)"
  },
  "spacing.space-2600": {
    "value": "var(--spacing-space-2600)",
    "variable": "var(--spacing-space-2600)"
  },
  "spacing.space-2800": {
    "value": "var(--spacing-space-2800)",
    "variable": "var(--spacing-space-2800)"
  },
  "spacing.space-3000": {
    "value": "var(--spacing-space-3000)",
    "variable": "var(--spacing-space-3000)"
  },
  "spacing.space-3200": {
    "value": "var(--spacing-space-3200)",
    "variable": "var(--spacing-space-3200)"
  },
  "spacing.space-3400": {
    "value": "var(--spacing-space-3400)",
    "variable": "var(--spacing-space-3400)"
  },
  "spacing.space-3600": {
    "value": "var(--spacing-space-3600)",
    "variable": "var(--spacing-space-3600)"
  },
  "spacing.space-3800": {
    "value": "var(--spacing-space-3800)",
    "variable": "var(--spacing-space-3800)"
  },
  "spacing.space-1": {
    "value": "var(--spacing-space-1)",
    "variable": "var(--spacing-space-1)"
  },
  "spacing.semantic-spacing-0": {
    "value": "var(--spacing-semantic-spacing-0)",
    "variable": "var(--spacing-semantic-spacing-0)"
  },
  "spacing.semantic-spacing-2": {
    "value": "var(--spacing-semantic-spacing-2)",
    "variable": "var(--spacing-semantic-spacing-2)"
  },
  "spacing.semantic-spacing-4": {
    "value": "var(--spacing-semantic-spacing-4)",
    "variable": "var(--spacing-semantic-spacing-4)"
  },
  "spacing.semantic-spacing-6": {
    "value": "var(--spacing-semantic-spacing-6)",
    "variable": "var(--spacing-semantic-spacing-6)"
  },
  "spacing.semantic-spacing-8": {
    "value": "var(--spacing-semantic-spacing-8)",
    "variable": "var(--spacing-semantic-spacing-8)"
  },
  "spacing.semantic-spacing-10": {
    "value": "var(--spacing-semantic-spacing-10)",
    "variable": "var(--spacing-semantic-spacing-10)"
  },
  "spacing.semantic-spacing-12": {
    "value": "var(--spacing-semantic-spacing-12)",
    "variable": "var(--spacing-semantic-spacing-12)"
  },
  "spacing.semantic-spacing-16": {
    "value": "var(--spacing-semantic-spacing-16)",
    "variable": "var(--spacing-semantic-spacing-16)"
  },
  "spacing.semantic-spacing-20": {
    "value": "var(--spacing-semantic-spacing-20)",
    "variable": "var(--spacing-semantic-spacing-20)"
  },
  "spacing.semantic-spacing-24": {
    "value": "var(--spacing-semantic-spacing-24)",
    "variable": "var(--spacing-semantic-spacing-24)"
  },
  "spacing.semantic-spacing-32": {
    "value": "var(--spacing-semantic-spacing-32)",
    "variable": "var(--spacing-semantic-spacing-32)"
  },
  "spacing.semantic-spacing-40": {
    "value": "var(--spacing-semantic-spacing-40)",
    "variable": "var(--spacing-semantic-spacing-40)"
  },
  "spacing.semantic-spacing-48": {
    "value": "var(--spacing-semantic-spacing-48)",
    "variable": "var(--spacing-semantic-spacing-48)"
  },
  "spacing.semantic-spacing-56": {
    "value": "var(--spacing-semantic-spacing-56)",
    "variable": "var(--spacing-semantic-spacing-56)"
  },
  "spacing.semantic-spacing-64": {
    "value": "var(--spacing-semantic-spacing-64)",
    "variable": "var(--spacing-semantic-spacing-64)"
  },
  "spacing.semantic-spacing-1": {
    "value": "var(--spacing-semantic-spacing-1)",
    "variable": "var(--spacing-semantic-spacing-1)"
  },
  "fonts.font-family-serif": {
    "value": "var(--fonts-font-family-serif)",
    "variable": "var(--fonts-font-family-serif)"
  },
  "fonts.font-family-sans-serif": {
    "value": "var(--fonts-font-family-sans-serif)",
    "variable": "var(--fonts-font-family-sans-serif)"
  },
  "fonts.font-family-logotype": {
    "value": "var(--fonts-font-family-logotype)",
    "variable": "var(--fonts-font-family-logotype)"
  },
  "fontSizes.font-size-0": {
    "value": "var(--font-sizes-font-size-0)",
    "variable": "var(--font-sizes-font-size-0)"
  },
  "fontSizes.font-size-1": {
    "value": "var(--font-sizes-font-size-1)",
    "variable": "var(--font-sizes-font-size-1)"
  },
  "fontSizes.font-size-2": {
    "value": "var(--font-sizes-font-size-2)",
    "variable": "var(--font-sizes-font-size-2)"
  },
  "fontSizes.font-size-3": {
    "value": "var(--font-sizes-font-size-3)",
    "variable": "var(--font-sizes-font-size-3)"
  },
  "fontSizes.font-size-4": {
    "value": "var(--font-sizes-font-size-4)",
    "variable": "var(--font-sizes-font-size-4)"
  },
  "fontSizes.font-size-5": {
    "value": "var(--font-sizes-font-size-5)",
    "variable": "var(--font-sizes-font-size-5)"
  },
  "fontSizes.font-size-6": {
    "value": "var(--font-sizes-font-size-6)",
    "variable": "var(--font-sizes-font-size-6)"
  },
  "fontSizes.font-size-7": {
    "value": "var(--font-sizes-font-size-7)",
    "variable": "var(--font-sizes-font-size-7)"
  },
  "fontSizes.font-size-8": {
    "value": "var(--font-sizes-font-size-8)",
    "variable": "var(--font-sizes-font-size-8)"
  },
  "fontSizes.font-size-9": {
    "value": "var(--font-sizes-font-size-9)",
    "variable": "var(--font-sizes-font-size-9)"
  },
  "fontSizes.font-size-10": {
    "value": "var(--font-sizes-font-size-10)",
    "variable": "var(--font-sizes-font-size-10)"
  },
  "fontSizes.font-size-11": {
    "value": "var(--font-sizes-font-size-11)",
    "variable": "var(--font-sizes-font-size-11)"
  },
  "fontSizes.font-size-12": {
    "value": "var(--font-sizes-font-size-12)",
    "variable": "var(--font-sizes-font-size-12)"
  },
  "fontWeights.font-weight-semi-bold": {
    "value": "var(--font-weights-font-weight-semi-bold)",
    "variable": "var(--font-weights-font-weight-semi-bold)"
  },
  "fontWeights.font-weight-book": {
    "value": "var(--font-weights-font-weight-book)",
    "variable": "var(--font-weights-font-weight-book)"
  },
  "fontWeights.font-weight-light": {
    "value": "var(--font-weights-font-weight-light)",
    "variable": "var(--font-weights-font-weight-light)"
  },
  "fontWeights.font-weight-bold": {
    "value": "var(--font-weights-font-weight-bold)",
    "variable": "var(--font-weights-font-weight-bold)"
  },
  "fontWeights.font-weight-regular": {
    "value": "var(--font-weights-font-weight-regular)",
    "variable": "var(--font-weights-font-weight-regular)"
  },
  "fontWeights.font-weight-medium": {
    "value": "var(--font-weights-font-weight-medium)",
    "variable": "var(--font-weights-font-weight-medium)"
  },
  "lineHeights.line-height-0": {
    "value": "var(--line-heights-line-height-0)",
    "variable": "var(--line-heights-line-height-0)"
  },
  "lineHeights.line-height-1": {
    "value": "var(--line-heights-line-height-1)",
    "variable": "var(--line-heights-line-height-1)"
  },
  "lineHeights.line-height-2": {
    "value": "var(--line-heights-line-height-2)",
    "variable": "var(--line-heights-line-height-2)"
  },
  "lineHeights.line-height-3": {
    "value": "var(--line-heights-line-height-3)",
    "variable": "var(--line-heights-line-height-3)"
  },
  "lineHeights.line-height-4": {
    "value": "var(--line-heights-line-height-4)",
    "variable": "var(--line-heights-line-height-4)"
  },
  "lineHeights.line-height-5": {
    "value": "var(--line-heights-line-height-5)",
    "variable": "var(--line-heights-line-height-5)"
  },
  "lineHeights.line-height-6": {
    "value": "var(--line-heights-line-height-6)",
    "variable": "var(--line-heights-line-height-6)"
  },
  "lineHeights.line-height-7": {
    "value": "var(--line-heights-line-height-7)",
    "variable": "var(--line-heights-line-height-7)"
  },
  "lineHeights.line-height-8": {
    "value": "var(--line-heights-line-height-8)",
    "variable": "var(--line-heights-line-height-8)"
  },
  "lineHeights.line-height-80": {
    "value": "var(--line-heights-line-height-80)",
    "variable": "var(--line-heights-line-height-80)"
  },
  "lineHeights.line-height-100": {
    "value": "var(--line-heights-line-height-100)",
    "variable": "var(--line-heights-line-height-100)"
  },
  "lineHeights.line-height-120": {
    "value": "var(--line-heights-line-height-120)",
    "variable": "var(--line-heights-line-height-120)"
  },
  "lineHeights.line-height-125": {
    "value": "var(--line-heights-line-height-125)",
    "variable": "var(--line-heights-line-height-125)"
  },
  "lineHeights.line-height-150": {
    "value": "var(--line-heights-line-height-150)",
    "variable": "var(--line-heights-line-height-150)"
  },
  "lineHeights.line-height-175": {
    "value": "var(--line-heights-line-height-175)",
    "variable": "var(--line-heights-line-height-175)"
  },
  "lineHeights.line-height-200": {
    "value": "var(--line-heights-line-height-200)",
    "variable": "var(--line-heights-line-height-200)"
  },
  "colors.semantic-fg-default": {
    "value": "var(--colors-semantic-fg-default)",
    "variable": "var(--colors-semantic-fg-default)"
  },
  "colors.semantic-fg-muted": {
    "value": "var(--colors-semantic-fg-muted)",
    "variable": "var(--colors-semantic-fg-muted)"
  },
  "colors.semantic-fg-subtle": {
    "value": "var(--colors-semantic-fg-subtle)",
    "variable": "var(--colors-semantic-fg-subtle)"
  },
  "colors.semantic-fg-on-accent": {
    "value": "var(--colors-semantic-fg-on-accent)",
    "variable": "var(--colors-semantic-fg-on-accent)"
  },
  "colors.semantic-fg-on-accent-alt": {
    "value": "var(--colors-semantic-fg-on-accent-alt)",
    "variable": "var(--colors-semantic-fg-on-accent-alt)"
  },
  "colors.semantic-fg-on-disabled": {
    "value": "var(--colors-semantic-fg-on-disabled)",
    "variable": "var(--colors-semantic-fg-on-disabled)"
  },
  "colors.semantic-fg-disabled": {
    "value": "var(--colors-semantic-fg-disabled)",
    "variable": "var(--colors-semantic-fg-disabled)"
  },
  "colors.semantic-fg-accent": {
    "value": "var(--colors-semantic-fg-accent)",
    "variable": "var(--colors-semantic-fg-accent)"
  },
  "colors.semantic-fg-accent-alt": {
    "value": "var(--colors-semantic-fg-accent-alt)",
    "variable": "var(--colors-semantic-fg-accent-alt)"
  },
  "colors.semantic-fg-accent-dark": {
    "value": "var(--colors-semantic-fg-accent-dark)",
    "variable": "var(--colors-semantic-fg-accent-dark)"
  },
  "colors.semantic-bg-default": {
    "value": "var(--colors-semantic-bg-default)",
    "variable": "var(--colors-semantic-bg-default)"
  },
  "colors.semantic-bg-muted": {
    "value": "var(--colors-semantic-bg-muted)",
    "variable": "var(--colors-semantic-bg-muted)"
  },
  "colors.semantic-bg-subtle": {
    "value": "var(--colors-semantic-bg-subtle)",
    "variable": "var(--colors-semantic-bg-subtle)"
  },
  "colors.semantic-bg-accent": {
    "value": "var(--colors-semantic-bg-accent)",
    "variable": "var(--colors-semantic-bg-accent)"
  },
  "colors.semantic-bg-accent-alt": {
    "value": "var(--colors-semantic-bg-accent-alt)",
    "variable": "var(--colors-semantic-bg-accent-alt)"
  },
  "colors.semantic-bg-canvas": {
    "value": "var(--colors-semantic-bg-canvas)",
    "variable": "var(--colors-semantic-bg-canvas)"
  },
  "colors.semantic-bg-overlay": {
    "value": "var(--colors-semantic-bg-overlay)",
    "variable": "var(--colors-semantic-bg-overlay)"
  },
  "colors.semantic-bg-reverse": {
    "value": "var(--colors-semantic-bg-reverse)",
    "variable": "var(--colors-semantic-bg-reverse)"
  },
  "colors.semantic-bg-reverse-alt": {
    "value": "var(--colors-semantic-bg-reverse-alt)",
    "variable": "var(--colors-semantic-bg-reverse-alt)"
  },
  "colors.semantic-primary-default": {
    "value": "var(--colors-semantic-primary-default)",
    "variable": "var(--colors-semantic-primary-default)"
  },
  "colors.semantic-primary-emphasised": {
    "value": "var(--colors-semantic-primary-emphasised)",
    "variable": "var(--colors-semantic-primary-emphasised)"
  },
  "colors.semantic-primary-muted": {
    "value": "var(--colors-semantic-primary-muted)",
    "variable": "var(--colors-semantic-primary-muted)"
  },
  "colors.semantic-primary-highlight": {
    "value": "var(--colors-semantic-primary-highlight)",
    "variable": "var(--colors-semantic-primary-highlight)"
  },
  "colors.semantic-primary-subtle": {
    "value": "var(--colors-semantic-primary-subtle)",
    "variable": "var(--colors-semantic-primary-subtle)"
  },
  "colors.semantic-primary-disabled": {
    "value": "var(--colors-semantic-primary-disabled)",
    "variable": "var(--colors-semantic-primary-disabled)"
  },
  "colors.semantic-primary-enhanced-default": {
    "value": "var(--colors-semantic-primary-enhanced-default)",
    "variable": "var(--colors-semantic-primary-enhanced-default)"
  },
  "colors.semantic-primary-enhanced-emphasised": {
    "value": "var(--colors-semantic-primary-enhanced-emphasised)",
    "variable": "var(--colors-semantic-primary-enhanced-emphasised)"
  },
  "colors.semantic-primary-enhanced-muted": {
    "value": "var(--colors-semantic-primary-enhanced-muted)",
    "variable": "var(--colors-semantic-primary-enhanced-muted)"
  },
  "colors.semantic-primary-enhanced-highlight": {
    "value": "var(--colors-semantic-primary-enhanced-highlight)",
    "variable": "var(--colors-semantic-primary-enhanced-highlight)"
  },
  "colors.semantic-primary-enhanced-subtle": {
    "value": "var(--colors-semantic-primary-enhanced-subtle)",
    "variable": "var(--colors-semantic-primary-enhanced-subtle)"
  },
  "colors.semantic-primary-enhanced-disabled": {
    "value": "var(--colors-semantic-primary-enhanced-disabled)",
    "variable": "var(--colors-semantic-primary-enhanced-disabled)"
  },
  "colors.semantic-secondary-default": {
    "value": "var(--colors-semantic-secondary-default)",
    "variable": "var(--colors-semantic-secondary-default)"
  },
  "colors.semantic-secondary-emphasised": {
    "value": "var(--colors-semantic-secondary-emphasised)",
    "variable": "var(--colors-semantic-secondary-emphasised)"
  },
  "colors.semantic-secondary-muted": {
    "value": "var(--colors-semantic-secondary-muted)",
    "variable": "var(--colors-semantic-secondary-muted)"
  },
  "colors.semantic-secondary-highlight": {
    "value": "var(--colors-semantic-secondary-highlight)",
    "variable": "var(--colors-semantic-secondary-highlight)"
  },
  "colors.semantic-secondary-subtle": {
    "value": "var(--colors-semantic-secondary-subtle)",
    "variable": "var(--colors-semantic-secondary-subtle)"
  },
  "colors.semantic-secondary-disabled": {
    "value": "var(--colors-semantic-secondary-disabled)",
    "variable": "var(--colors-semantic-secondary-disabled)"
  },
  "colors.semantic-warning-default": {
    "value": "var(--colors-semantic-warning-default)",
    "variable": "var(--colors-semantic-warning-default)"
  },
  "colors.semantic-warning-emphasised": {
    "value": "var(--colors-semantic-warning-emphasised)",
    "variable": "var(--colors-semantic-warning-emphasised)"
  },
  "colors.semantic-warning-muted": {
    "value": "var(--colors-semantic-warning-muted)",
    "variable": "var(--colors-semantic-warning-muted)"
  },
  "colors.semantic-warning-subtle": {
    "value": "var(--colors-semantic-warning-subtle)",
    "variable": "var(--colors-semantic-warning-subtle)"
  },
  "colors.semantic-warning-on-warning": {
    "value": "var(--colors-semantic-warning-on-warning)",
    "variable": "var(--colors-semantic-warning-on-warning)"
  },
  "colors.semantic-error-default": {
    "value": "var(--colors-semantic-error-default)",
    "variable": "var(--colors-semantic-error-default)"
  },
  "colors.semantic-error-emphasised": {
    "value": "var(--colors-semantic-error-emphasised)",
    "variable": "var(--colors-semantic-error-emphasised)"
  },
  "colors.semantic-error-muted": {
    "value": "var(--colors-semantic-error-muted)",
    "variable": "var(--colors-semantic-error-muted)"
  },
  "colors.semantic-error-subtle": {
    "value": "var(--colors-semantic-error-subtle)",
    "variable": "var(--colors-semantic-error-subtle)"
  },
  "colors.semantic-error-on-error": {
    "value": "var(--colors-semantic-error-on-error)",
    "variable": "var(--colors-semantic-error-on-error)"
  },
  "colors.semantic-info-default": {
    "value": "var(--colors-semantic-info-default)",
    "variable": "var(--colors-semantic-info-default)"
  },
  "colors.semantic-info-emphasised": {
    "value": "var(--colors-semantic-info-emphasised)",
    "variable": "var(--colors-semantic-info-emphasised)"
  },
  "colors.semantic-info-muted": {
    "value": "var(--colors-semantic-info-muted)",
    "variable": "var(--colors-semantic-info-muted)"
  },
  "colors.semantic-info-subtle": {
    "value": "var(--colors-semantic-info-subtle)",
    "variable": "var(--colors-semantic-info-subtle)"
  },
  "colors.semantic-info-on-info": {
    "value": "var(--colors-semantic-info-on-info)",
    "variable": "var(--colors-semantic-info-on-info)"
  },
  "colors.semantic-success-default": {
    "value": "var(--colors-semantic-success-default)",
    "variable": "var(--colors-semantic-success-default)"
  },
  "colors.semantic-success-emphasised": {
    "value": "var(--colors-semantic-success-emphasised)",
    "variable": "var(--colors-semantic-success-emphasised)"
  },
  "colors.semantic-success-muted": {
    "value": "var(--colors-semantic-success-muted)",
    "variable": "var(--colors-semantic-success-muted)"
  },
  "colors.semantic-success-subtle": {
    "value": "var(--colors-semantic-success-subtle)",
    "variable": "var(--colors-semantic-success-subtle)"
  },
  "colors.semantic-success-on-success": {
    "value": "var(--colors-semantic-success-on-success)",
    "variable": "var(--colors-semantic-success-on-success)"
  },
  "colors.semantic-help-default": {
    "value": "var(--colors-semantic-help-default)",
    "variable": "var(--colors-semantic-help-default)"
  },
  "colors.semantic-help-emphasised": {
    "value": "var(--colors-semantic-help-emphasised)",
    "variable": "var(--colors-semantic-help-emphasised)"
  },
  "colors.semantic-help-muted": {
    "value": "var(--colors-semantic-help-muted)",
    "variable": "var(--colors-semantic-help-muted)"
  },
  "colors.semantic-help-subtle": {
    "value": "var(--colors-semantic-help-subtle)",
    "variable": "var(--colors-semantic-help-subtle)"
  },
  "colors.semantic-help-on-help": {
    "value": "var(--colors-semantic-help-on-help)",
    "variable": "var(--colors-semantic-help-on-help)"
  },
  "colors.semantic-neutral-default": {
    "value": "var(--colors-semantic-neutral-default)",
    "variable": "var(--colors-semantic-neutral-default)"
  },
  "colors.semantic-neutral-emphasised": {
    "value": "var(--colors-semantic-neutral-emphasised)",
    "variable": "var(--colors-semantic-neutral-emphasised)"
  },
  "colors.semantic-neutral-muted": {
    "value": "var(--colors-semantic-neutral-muted)",
    "variable": "var(--colors-semantic-neutral-muted)"
  },
  "colors.semantic-neutral-subtle": {
    "value": "var(--colors-semantic-neutral-subtle)",
    "variable": "var(--colors-semantic-neutral-subtle)"
  },
  "colors.semantic-neutral-on-success": {
    "value": "var(--colors-semantic-neutral-on-success)",
    "variable": "var(--colors-semantic-neutral-on-success)"
  },
  "colors.semantic-border-default": {
    "value": "var(--colors-semantic-border-default)",
    "variable": "var(--colors-semantic-border-default)"
  },
  "colors.semantic-border-focus": {
    "value": "var(--colors-semantic-border-focus)",
    "variable": "var(--colors-semantic-border-focus)"
  },
  "colors.semantic-border-selected": {
    "value": "var(--colors-semantic-border-selected)",
    "variable": "var(--colors-semantic-border-selected)"
  },
  "colors.semantic-overlay-light": {
    "value": "var(--colors-semantic-overlay-light)",
    "variable": "var(--colors-semantic-overlay-light)"
  },
  "colors.semantic-overlay-medium": {
    "value": "var(--colors-semantic-overlay-medium)",
    "variable": "var(--colors-semantic-overlay-medium)"
  },
  "colors.semantic-overlay-strong": {
    "value": "var(--colors-semantic-overlay-strong)",
    "variable": "var(--colors-semantic-overlay-strong)"
  },
  "colors.semantic-overlay-gradient": {
    "value": "var(--colors-semantic-overlay-gradient)",
    "variable": "var(--colors-semantic-overlay-gradient)"
  },
  "colors.semantic-color-primary-default-border": {
    "value": "var(--colors-semantic-color-primary-default-border)",
    "variable": "var(--colors-semantic-color-primary-default-border)"
  },
  "colors.semantic-color-primary-default-text": {
    "value": "var(--colors-semantic-color-primary-default-text)",
    "variable": "var(--colors-semantic-color-primary-default-text)"
  },
  "colors.semantic-color-primary-default-background": {
    "value": "var(--colors-semantic-color-primary-default-background)",
    "variable": "var(--colors-semantic-color-primary-default-background)"
  },
  "colors.semantic-color-primary-default-icon": {
    "value": "var(--colors-semantic-color-primary-default-icon)",
    "variable": "var(--colors-semantic-color-primary-default-icon)"
  },
  "colors.semantic-color-primary-default-text-accent": {
    "value": "var(--colors-semantic-color-primary-default-text-accent)",
    "variable": "var(--colors-semantic-color-primary-default-text-accent)"
  },
  "colors.semantic-color-primary-hover-border": {
    "value": "var(--colors-semantic-color-primary-hover-border)",
    "variable": "var(--colors-semantic-color-primary-hover-border)"
  },
  "colors.semantic-color-primary-hover-text": {
    "value": "var(--colors-semantic-color-primary-hover-text)",
    "variable": "var(--colors-semantic-color-primary-hover-text)"
  },
  "colors.semantic-color-primary-hover-background": {
    "value": "var(--colors-semantic-color-primary-hover-background)",
    "variable": "var(--colors-semantic-color-primary-hover-background)"
  },
  "colors.semantic-color-primary-hover-icon": {
    "value": "var(--colors-semantic-color-primary-hover-icon)",
    "variable": "var(--colors-semantic-color-primary-hover-icon)"
  },
  "colors.semantic-color-primary-hover-text-accent": {
    "value": "var(--colors-semantic-color-primary-hover-text-accent)",
    "variable": "var(--colors-semantic-color-primary-hover-text-accent)"
  },
  "colors.semantic-color-primary-active-border": {
    "value": "var(--colors-semantic-color-primary-active-border)",
    "variable": "var(--colors-semantic-color-primary-active-border)"
  },
  "colors.semantic-color-primary-active-text": {
    "value": "var(--colors-semantic-color-primary-active-text)",
    "variable": "var(--colors-semantic-color-primary-active-text)"
  },
  "colors.semantic-color-primary-active-background": {
    "value": "var(--colors-semantic-color-primary-active-background)",
    "variable": "var(--colors-semantic-color-primary-active-background)"
  },
  "colors.semantic-color-primary-active-icon": {
    "value": "var(--colors-semantic-color-primary-active-icon)",
    "variable": "var(--colors-semantic-color-primary-active-icon)"
  },
  "colors.semantic-color-primary-active-text-accent": {
    "value": "var(--colors-semantic-color-primary-active-text-accent)",
    "variable": "var(--colors-semantic-color-primary-active-text-accent)"
  },
  "colors.semantic-color-primary-focus-border": {
    "value": "var(--colors-semantic-color-primary-focus-border)",
    "variable": "var(--colors-semantic-color-primary-focus-border)"
  },
  "colors.semantic-color-primary-focus-text": {
    "value": "var(--colors-semantic-color-primary-focus-text)",
    "variable": "var(--colors-semantic-color-primary-focus-text)"
  },
  "colors.semantic-color-primary-focus-background": {
    "value": "var(--colors-semantic-color-primary-focus-background)",
    "variable": "var(--colors-semantic-color-primary-focus-background)"
  },
  "colors.semantic-color-primary-focus-icon": {
    "value": "var(--colors-semantic-color-primary-focus-icon)",
    "variable": "var(--colors-semantic-color-primary-focus-icon)"
  },
  "colors.semantic-color-primary-focus-text-accent": {
    "value": "var(--colors-semantic-color-primary-focus-text-accent)",
    "variable": "var(--colors-semantic-color-primary-focus-text-accent)"
  },
  "colors.semantic-color-primary-disabled-border": {
    "value": "var(--colors-semantic-color-primary-disabled-border)",
    "variable": "var(--colors-semantic-color-primary-disabled-border)"
  },
  "colors.semantic-color-primary-disabled-text": {
    "value": "var(--colors-semantic-color-primary-disabled-text)",
    "variable": "var(--colors-semantic-color-primary-disabled-text)"
  },
  "colors.semantic-color-primary-disabled-background": {
    "value": "var(--colors-semantic-color-primary-disabled-background)",
    "variable": "var(--colors-semantic-color-primary-disabled-background)"
  },
  "colors.semantic-color-primary-disabled-icon": {
    "value": "var(--colors-semantic-color-primary-disabled-icon)",
    "variable": "var(--colors-semantic-color-primary-disabled-icon)"
  },
  "colors.semantic-color-primary-disabled-text-accent": {
    "value": "var(--colors-semantic-color-primary-disabled-text-accent)",
    "variable": "var(--colors-semantic-color-primary-disabled-text-accent)"
  },
  "colors.semantic-color-primary-selected-border": {
    "value": "var(--colors-semantic-color-primary-selected-border)",
    "variable": "var(--colors-semantic-color-primary-selected-border)"
  },
  "colors.semantic-color-primary-selected-text": {
    "value": "var(--colors-semantic-color-primary-selected-text)",
    "variable": "var(--colors-semantic-color-primary-selected-text)"
  },
  "colors.semantic-color-primary-selected-background": {
    "value": "var(--colors-semantic-color-primary-selected-background)",
    "variable": "var(--colors-semantic-color-primary-selected-background)"
  },
  "colors.semantic-color-primary-selected-icon": {
    "value": "var(--colors-semantic-color-primary-selected-icon)",
    "variable": "var(--colors-semantic-color-primary-selected-icon)"
  },
  "colors.semantic-color-primary-selected-text-accent": {
    "value": "var(--colors-semantic-color-primary-selected-text-accent)",
    "variable": "var(--colors-semantic-color-primary-selected-text-accent)"
  },
  "colors.semantic-color-secondary-default-border": {
    "value": "var(--colors-semantic-color-secondary-default-border)",
    "variable": "var(--colors-semantic-color-secondary-default-border)"
  },
  "colors.semantic-color-secondary-default-text": {
    "value": "var(--colors-semantic-color-secondary-default-text)",
    "variable": "var(--colors-semantic-color-secondary-default-text)"
  },
  "colors.semantic-color-secondary-default-background": {
    "value": "var(--colors-semantic-color-secondary-default-background)",
    "variable": "var(--colors-semantic-color-secondary-default-background)"
  },
  "colors.semantic-color-secondary-default-icon": {
    "value": "var(--colors-semantic-color-secondary-default-icon)",
    "variable": "var(--colors-semantic-color-secondary-default-icon)"
  },
  "colors.semantic-color-secondary-default-text-accent": {
    "value": "var(--colors-semantic-color-secondary-default-text-accent)",
    "variable": "var(--colors-semantic-color-secondary-default-text-accent)"
  },
  "colors.semantic-color-secondary-hover-border": {
    "value": "var(--colors-semantic-color-secondary-hover-border)",
    "variable": "var(--colors-semantic-color-secondary-hover-border)"
  },
  "colors.semantic-color-secondary-hover-text": {
    "value": "var(--colors-semantic-color-secondary-hover-text)",
    "variable": "var(--colors-semantic-color-secondary-hover-text)"
  },
  "colors.semantic-color-secondary-hover-background": {
    "value": "var(--colors-semantic-color-secondary-hover-background)",
    "variable": "var(--colors-semantic-color-secondary-hover-background)"
  },
  "colors.semantic-color-secondary-hover-icon": {
    "value": "var(--colors-semantic-color-secondary-hover-icon)",
    "variable": "var(--colors-semantic-color-secondary-hover-icon)"
  },
  "colors.semantic-color-secondary-hover-text-accent": {
    "value": "var(--colors-semantic-color-secondary-hover-text-accent)",
    "variable": "var(--colors-semantic-color-secondary-hover-text-accent)"
  },
  "colors.semantic-color-secondary-active-border": {
    "value": "var(--colors-semantic-color-secondary-active-border)",
    "variable": "var(--colors-semantic-color-secondary-active-border)"
  },
  "colors.semantic-color-secondary-active-text": {
    "value": "var(--colors-semantic-color-secondary-active-text)",
    "variable": "var(--colors-semantic-color-secondary-active-text)"
  },
  "colors.semantic-color-secondary-active-background": {
    "value": "var(--colors-semantic-color-secondary-active-background)",
    "variable": "var(--colors-semantic-color-secondary-active-background)"
  },
  "colors.semantic-color-secondary-active-icon": {
    "value": "var(--colors-semantic-color-secondary-active-icon)",
    "variable": "var(--colors-semantic-color-secondary-active-icon)"
  },
  "colors.semantic-color-secondary-active-text-accent": {
    "value": "var(--colors-semantic-color-secondary-active-text-accent)",
    "variable": "var(--colors-semantic-color-secondary-active-text-accent)"
  },
  "colors.semantic-color-secondary-focus-border": {
    "value": "var(--colors-semantic-color-secondary-focus-border)",
    "variable": "var(--colors-semantic-color-secondary-focus-border)"
  },
  "colors.semantic-color-secondary-focus-text": {
    "value": "var(--colors-semantic-color-secondary-focus-text)",
    "variable": "var(--colors-semantic-color-secondary-focus-text)"
  },
  "colors.semantic-color-secondary-focus-background": {
    "value": "var(--colors-semantic-color-secondary-focus-background)",
    "variable": "var(--colors-semantic-color-secondary-focus-background)"
  },
  "colors.semantic-color-secondary-focus-icon": {
    "value": "var(--colors-semantic-color-secondary-focus-icon)",
    "variable": "var(--colors-semantic-color-secondary-focus-icon)"
  },
  "colors.semantic-color-secondary-focus-text-accent": {
    "value": "var(--colors-semantic-color-secondary-focus-text-accent)",
    "variable": "var(--colors-semantic-color-secondary-focus-text-accent)"
  },
  "colors.semantic-color-secondary-disabled-background": {
    "value": "var(--colors-semantic-color-secondary-disabled-background)",
    "variable": "var(--colors-semantic-color-secondary-disabled-background)"
  },
  "colors.semantic-color-secondary-disabled-text": {
    "value": "var(--colors-semantic-color-secondary-disabled-text)",
    "variable": "var(--colors-semantic-color-secondary-disabled-text)"
  },
  "colors.semantic-color-secondary-disabled-border": {
    "value": "var(--colors-semantic-color-secondary-disabled-border)",
    "variable": "var(--colors-semantic-color-secondary-disabled-border)"
  },
  "colors.semantic-color-secondary-disabled-icon": {
    "value": "var(--colors-semantic-color-secondary-disabled-icon)",
    "variable": "var(--colors-semantic-color-secondary-disabled-icon)"
  },
  "colors.semantic-color-secondary-disabled-text-accent": {
    "value": "var(--colors-semantic-color-secondary-disabled-text-accent)",
    "variable": "var(--colors-semantic-color-secondary-disabled-text-accent)"
  },
  "colors.semantic-color-tertiary-default-border": {
    "value": "var(--colors-semantic-color-tertiary-default-border)",
    "variable": "var(--colors-semantic-color-tertiary-default-border)"
  },
  "colors.semantic-color-tertiary-default-text": {
    "value": "var(--colors-semantic-color-tertiary-default-text)",
    "variable": "var(--colors-semantic-color-tertiary-default-text)"
  },
  "colors.semantic-color-tertiary-default-background": {
    "value": "var(--colors-semantic-color-tertiary-default-background)",
    "variable": "var(--colors-semantic-color-tertiary-default-background)"
  },
  "colors.semantic-color-tertiary-default-icon": {
    "value": "var(--colors-semantic-color-tertiary-default-icon)",
    "variable": "var(--colors-semantic-color-tertiary-default-icon)"
  },
  "colors.semantic-color-tertiary-default-text-accent": {
    "value": "var(--colors-semantic-color-tertiary-default-text-accent)",
    "variable": "var(--colors-semantic-color-tertiary-default-text-accent)"
  },
  "colors.semantic-color-tertiary-hover-border": {
    "value": "var(--colors-semantic-color-tertiary-hover-border)",
    "variable": "var(--colors-semantic-color-tertiary-hover-border)"
  },
  "colors.semantic-color-tertiary-hover-text": {
    "value": "var(--colors-semantic-color-tertiary-hover-text)",
    "variable": "var(--colors-semantic-color-tertiary-hover-text)"
  },
  "colors.semantic-color-tertiary-hover-background": {
    "value": "var(--colors-semantic-color-tertiary-hover-background)",
    "variable": "var(--colors-semantic-color-tertiary-hover-background)"
  },
  "colors.semantic-color-tertiary-hover-icon": {
    "value": "var(--colors-semantic-color-tertiary-hover-icon)",
    "variable": "var(--colors-semantic-color-tertiary-hover-icon)"
  },
  "colors.semantic-color-tertiary-hover-text-accent": {
    "value": "var(--colors-semantic-color-tertiary-hover-text-accent)",
    "variable": "var(--colors-semantic-color-tertiary-hover-text-accent)"
  },
  "colors.semantic-color-tertiary-active-border": {
    "value": "var(--colors-semantic-color-tertiary-active-border)",
    "variable": "var(--colors-semantic-color-tertiary-active-border)"
  },
  "colors.semantic-color-tertiary-active-text": {
    "value": "var(--colors-semantic-color-tertiary-active-text)",
    "variable": "var(--colors-semantic-color-tertiary-active-text)"
  },
  "colors.semantic-color-tertiary-active-background": {
    "value": "var(--colors-semantic-color-tertiary-active-background)",
    "variable": "var(--colors-semantic-color-tertiary-active-background)"
  },
  "colors.semantic-color-tertiary-active-icon": {
    "value": "var(--colors-semantic-color-tertiary-active-icon)",
    "variable": "var(--colors-semantic-color-tertiary-active-icon)"
  },
  "colors.semantic-color-tertiary-active-text-accent": {
    "value": "var(--colors-semantic-color-tertiary-active-text-accent)",
    "variable": "var(--colors-semantic-color-tertiary-active-text-accent)"
  },
  "colors.semantic-color-tertiary-focus-border": {
    "value": "var(--colors-semantic-color-tertiary-focus-border)",
    "variable": "var(--colors-semantic-color-tertiary-focus-border)"
  },
  "colors.semantic-color-tertiary-focus-text": {
    "value": "var(--colors-semantic-color-tertiary-focus-text)",
    "variable": "var(--colors-semantic-color-tertiary-focus-text)"
  },
  "colors.semantic-color-tertiary-focus-background": {
    "value": "var(--colors-semantic-color-tertiary-focus-background)",
    "variable": "var(--colors-semantic-color-tertiary-focus-background)"
  },
  "colors.semantic-color-tertiary-focus-icon": {
    "value": "var(--colors-semantic-color-tertiary-focus-icon)",
    "variable": "var(--colors-semantic-color-tertiary-focus-icon)"
  },
  "colors.semantic-color-tertiary-focus-text-accent": {
    "value": "var(--colors-semantic-color-tertiary-focus-text-accent)",
    "variable": "var(--colors-semantic-color-tertiary-focus-text-accent)"
  },
  "colors.semantic-color-tertiary-disabled-background": {
    "value": "var(--colors-semantic-color-tertiary-disabled-background)",
    "variable": "var(--colors-semantic-color-tertiary-disabled-background)"
  },
  "colors.semantic-color-tertiary-disabled-text": {
    "value": "var(--colors-semantic-color-tertiary-disabled-text)",
    "variable": "var(--colors-semantic-color-tertiary-disabled-text)"
  },
  "colors.semantic-color-tertiary-disabled-border": {
    "value": "var(--colors-semantic-color-tertiary-disabled-border)",
    "variable": "var(--colors-semantic-color-tertiary-disabled-border)"
  },
  "colors.semantic-color-tertiary-disabled-icon": {
    "value": "var(--colors-semantic-color-tertiary-disabled-icon)",
    "variable": "var(--colors-semantic-color-tertiary-disabled-icon)"
  },
  "colors.semantic-color-tertiary-disabled-text-accent": {
    "value": "var(--colors-semantic-color-tertiary-disabled-text-accent)",
    "variable": "var(--colors-semantic-color-tertiary-disabled-text-accent)"
  },
  "colors.semantic-color-primary-reverse-default-border": {
    "value": "var(--colors-semantic-color-primary-reverse-default-border)",
    "variable": "var(--colors-semantic-color-primary-reverse-default-border)"
  },
  "colors.semantic-color-primary-reverse-default-text": {
    "value": "var(--colors-semantic-color-primary-reverse-default-text)",
    "variable": "var(--colors-semantic-color-primary-reverse-default-text)"
  },
  "colors.semantic-color-primary-reverse-default-background": {
    "value": "var(--colors-semantic-color-primary-reverse-default-background)",
    "variable": "var(--colors-semantic-color-primary-reverse-default-background)"
  },
  "colors.semantic-color-primary-reverse-default-icon": {
    "value": "var(--colors-semantic-color-primary-reverse-default-icon)",
    "variable": "var(--colors-semantic-color-primary-reverse-default-icon)"
  },
  "colors.semantic-color-primary-reverse-default-text-accent": {
    "value": "var(--colors-semantic-color-primary-reverse-default-text-accent)",
    "variable": "var(--colors-semantic-color-primary-reverse-default-text-accent)"
  },
  "colors.semantic-color-primary-reverse-hover-border": {
    "value": "var(--colors-semantic-color-primary-reverse-hover-border)",
    "variable": "var(--colors-semantic-color-primary-reverse-hover-border)"
  },
  "colors.semantic-color-primary-reverse-hover-text": {
    "value": "var(--colors-semantic-color-primary-reverse-hover-text)",
    "variable": "var(--colors-semantic-color-primary-reverse-hover-text)"
  },
  "colors.semantic-color-primary-reverse-hover-background": {
    "value": "var(--colors-semantic-color-primary-reverse-hover-background)",
    "variable": "var(--colors-semantic-color-primary-reverse-hover-background)"
  },
  "colors.semantic-color-primary-reverse-hover-icon": {
    "value": "var(--colors-semantic-color-primary-reverse-hover-icon)",
    "variable": "var(--colors-semantic-color-primary-reverse-hover-icon)"
  },
  "colors.semantic-color-primary-reverse-hover-text-accent": {
    "value": "var(--colors-semantic-color-primary-reverse-hover-text-accent)",
    "variable": "var(--colors-semantic-color-primary-reverse-hover-text-accent)"
  },
  "colors.semantic-color-primary-reverse-active-border": {
    "value": "var(--colors-semantic-color-primary-reverse-active-border)",
    "variable": "var(--colors-semantic-color-primary-reverse-active-border)"
  },
  "colors.semantic-color-primary-reverse-active-text": {
    "value": "var(--colors-semantic-color-primary-reverse-active-text)",
    "variable": "var(--colors-semantic-color-primary-reverse-active-text)"
  },
  "colors.semantic-color-primary-reverse-active-background": {
    "value": "var(--colors-semantic-color-primary-reverse-active-background)",
    "variable": "var(--colors-semantic-color-primary-reverse-active-background)"
  },
  "colors.semantic-color-primary-reverse-active-icon": {
    "value": "var(--colors-semantic-color-primary-reverse-active-icon)",
    "variable": "var(--colors-semantic-color-primary-reverse-active-icon)"
  },
  "colors.semantic-color-primary-reverse-active-text-accent": {
    "value": "var(--colors-semantic-color-primary-reverse-active-text-accent)",
    "variable": "var(--colors-semantic-color-primary-reverse-active-text-accent)"
  },
  "colors.semantic-color-primary-reverse-focus-border": {
    "value": "var(--colors-semantic-color-primary-reverse-focus-border)",
    "variable": "var(--colors-semantic-color-primary-reverse-focus-border)"
  },
  "colors.semantic-color-primary-reverse-focus-text": {
    "value": "var(--colors-semantic-color-primary-reverse-focus-text)",
    "variable": "var(--colors-semantic-color-primary-reverse-focus-text)"
  },
  "colors.semantic-color-primary-reverse-focus-background": {
    "value": "var(--colors-semantic-color-primary-reverse-focus-background)",
    "variable": "var(--colors-semantic-color-primary-reverse-focus-background)"
  },
  "colors.semantic-color-primary-reverse-focus-icon": {
    "value": "var(--colors-semantic-color-primary-reverse-focus-icon)",
    "variable": "var(--colors-semantic-color-primary-reverse-focus-icon)"
  },
  "colors.semantic-color-primary-reverse-focus-text-accent": {
    "value": "var(--colors-semantic-color-primary-reverse-focus-text-accent)",
    "variable": "var(--colors-semantic-color-primary-reverse-focus-text-accent)"
  },
  "colors.semantic-color-primary-reverse-disabled-border": {
    "value": "var(--colors-semantic-color-primary-reverse-disabled-border)",
    "variable": "var(--colors-semantic-color-primary-reverse-disabled-border)"
  },
  "colors.semantic-color-primary-reverse-disabled-text": {
    "value": "var(--colors-semantic-color-primary-reverse-disabled-text)",
    "variable": "var(--colors-semantic-color-primary-reverse-disabled-text)"
  },
  "colors.semantic-color-primary-reverse-disabled-background": {
    "value": "var(--colors-semantic-color-primary-reverse-disabled-background)",
    "variable": "var(--colors-semantic-color-primary-reverse-disabled-background)"
  },
  "colors.semantic-color-primary-reverse-disabled-icon": {
    "value": "var(--colors-semantic-color-primary-reverse-disabled-icon)",
    "variable": "var(--colors-semantic-color-primary-reverse-disabled-icon)"
  },
  "colors.semantic-color-primary-reverse-disabled-text-accent": {
    "value": "var(--colors-semantic-color-primary-reverse-disabled-text-accent)",
    "variable": "var(--colors-semantic-color-primary-reverse-disabled-text-accent)"
  },
  "colors.semantic-color-primary-reverse-selected-border": {
    "value": "var(--colors-semantic-color-primary-reverse-selected-border)",
    "variable": "var(--colors-semantic-color-primary-reverse-selected-border)"
  },
  "colors.semantic-color-primary-reverse-selected-text": {
    "value": "var(--colors-semantic-color-primary-reverse-selected-text)",
    "variable": "var(--colors-semantic-color-primary-reverse-selected-text)"
  },
  "colors.semantic-color-primary-reverse-selected-background": {
    "value": "var(--colors-semantic-color-primary-reverse-selected-background)",
    "variable": "var(--colors-semantic-color-primary-reverse-selected-background)"
  },
  "colors.semantic-color-primary-reverse-selected-icon": {
    "value": "var(--colors-semantic-color-primary-reverse-selected-icon)",
    "variable": "var(--colors-semantic-color-primary-reverse-selected-icon)"
  },
  "colors.semantic-color-primary-reverse-selected-text-accent": {
    "value": "var(--colors-semantic-color-primary-reverse-selected-text-accent)",
    "variable": "var(--colors-semantic-color-primary-reverse-selected-text-accent)"
  },
  "colors.semantic-color-secondary-reverse-default-border": {
    "value": "var(--colors-semantic-color-secondary-reverse-default-border)",
    "variable": "var(--colors-semantic-color-secondary-reverse-default-border)"
  },
  "colors.semantic-color-secondary-reverse-default-text": {
    "value": "var(--colors-semantic-color-secondary-reverse-default-text)",
    "variable": "var(--colors-semantic-color-secondary-reverse-default-text)"
  },
  "colors.semantic-color-secondary-reverse-default-background": {
    "value": "var(--colors-semantic-color-secondary-reverse-default-background)",
    "variable": "var(--colors-semantic-color-secondary-reverse-default-background)"
  },
  "colors.semantic-color-secondary-reverse-default-icon": {
    "value": "var(--colors-semantic-color-secondary-reverse-default-icon)",
    "variable": "var(--colors-semantic-color-secondary-reverse-default-icon)"
  },
  "colors.semantic-color-secondary-reverse-default-text-accent": {
    "value": "var(--colors-semantic-color-secondary-reverse-default-text-accent)",
    "variable": "var(--colors-semantic-color-secondary-reverse-default-text-accent)"
  },
  "colors.semantic-color-secondary-reverse-hover-border": {
    "value": "var(--colors-semantic-color-secondary-reverse-hover-border)",
    "variable": "var(--colors-semantic-color-secondary-reverse-hover-border)"
  },
  "colors.semantic-color-secondary-reverse-hover-text": {
    "value": "var(--colors-semantic-color-secondary-reverse-hover-text)",
    "variable": "var(--colors-semantic-color-secondary-reverse-hover-text)"
  },
  "colors.semantic-color-secondary-reverse-hover-background": {
    "value": "var(--colors-semantic-color-secondary-reverse-hover-background)",
    "variable": "var(--colors-semantic-color-secondary-reverse-hover-background)"
  },
  "colors.semantic-color-secondary-reverse-hover-icon": {
    "value": "var(--colors-semantic-color-secondary-reverse-hover-icon)",
    "variable": "var(--colors-semantic-color-secondary-reverse-hover-icon)"
  },
  "colors.semantic-color-secondary-reverse-hover-text-accent": {
    "value": "var(--colors-semantic-color-secondary-reverse-hover-text-accent)",
    "variable": "var(--colors-semantic-color-secondary-reverse-hover-text-accent)"
  },
  "colors.semantic-color-secondary-reverse-active-border": {
    "value": "var(--colors-semantic-color-secondary-reverse-active-border)",
    "variable": "var(--colors-semantic-color-secondary-reverse-active-border)"
  },
  "colors.semantic-color-secondary-reverse-active-text": {
    "value": "var(--colors-semantic-color-secondary-reverse-active-text)",
    "variable": "var(--colors-semantic-color-secondary-reverse-active-text)"
  },
  "colors.semantic-color-secondary-reverse-active-background": {
    "value": "var(--colors-semantic-color-secondary-reverse-active-background)",
    "variable": "var(--colors-semantic-color-secondary-reverse-active-background)"
  },
  "colors.semantic-color-secondary-reverse-active-icon": {
    "value": "var(--colors-semantic-color-secondary-reverse-active-icon)",
    "variable": "var(--colors-semantic-color-secondary-reverse-active-icon)"
  },
  "colors.semantic-color-secondary-reverse-active-text-accent": {
    "value": "var(--colors-semantic-color-secondary-reverse-active-text-accent)",
    "variable": "var(--colors-semantic-color-secondary-reverse-active-text-accent)"
  },
  "colors.semantic-color-secondary-reverse-focus-border": {
    "value": "var(--colors-semantic-color-secondary-reverse-focus-border)",
    "variable": "var(--colors-semantic-color-secondary-reverse-focus-border)"
  },
  "colors.semantic-color-secondary-reverse-focus-text": {
    "value": "var(--colors-semantic-color-secondary-reverse-focus-text)",
    "variable": "var(--colors-semantic-color-secondary-reverse-focus-text)"
  },
  "colors.semantic-color-secondary-reverse-focus-background": {
    "value": "var(--colors-semantic-color-secondary-reverse-focus-background)",
    "variable": "var(--colors-semantic-color-secondary-reverse-focus-background)"
  },
  "colors.semantic-color-secondary-reverse-focus-icon": {
    "value": "var(--colors-semantic-color-secondary-reverse-focus-icon)",
    "variable": "var(--colors-semantic-color-secondary-reverse-focus-icon)"
  },
  "colors.semantic-color-secondary-reverse-focus-text-accent": {
    "value": "var(--colors-semantic-color-secondary-reverse-focus-text-accent)",
    "variable": "var(--colors-semantic-color-secondary-reverse-focus-text-accent)"
  },
  "colors.semantic-color-secondary-reverse-disabled-background": {
    "value": "var(--colors-semantic-color-secondary-reverse-disabled-background)",
    "variable": "var(--colors-semantic-color-secondary-reverse-disabled-background)"
  },
  "colors.semantic-color-secondary-reverse-disabled-text": {
    "value": "var(--colors-semantic-color-secondary-reverse-disabled-text)",
    "variable": "var(--colors-semantic-color-secondary-reverse-disabled-text)"
  },
  "colors.semantic-color-secondary-reverse-disabled-border": {
    "value": "var(--colors-semantic-color-secondary-reverse-disabled-border)",
    "variable": "var(--colors-semantic-color-secondary-reverse-disabled-border)"
  },
  "colors.semantic-color-secondary-reverse-disabled-icon": {
    "value": "var(--colors-semantic-color-secondary-reverse-disabled-icon)",
    "variable": "var(--colors-semantic-color-secondary-reverse-disabled-icon)"
  },
  "colors.semantic-color-secondary-reverse-disabled-text-accent": {
    "value": "var(--colors-semantic-color-secondary-reverse-disabled-text-accent)",
    "variable": "var(--colors-semantic-color-secondary-reverse-disabled-text-accent)"
  },
  "colors.semantic-color-tertiary-reverse-default-border": {
    "value": "var(--colors-semantic-color-tertiary-reverse-default-border)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-default-border)"
  },
  "colors.semantic-color-tertiary-reverse-default-text": {
    "value": "var(--colors-semantic-color-tertiary-reverse-default-text)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-default-text)"
  },
  "colors.semantic-color-tertiary-reverse-default-background": {
    "value": "var(--colors-semantic-color-tertiary-reverse-default-background)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-default-background)"
  },
  "colors.semantic-color-tertiary-reverse-default-icon": {
    "value": "var(--colors-semantic-color-tertiary-reverse-default-icon)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-default-icon)"
  },
  "colors.semantic-color-tertiary-reverse-default-text-accent": {
    "value": "var(--colors-semantic-color-tertiary-reverse-default-text-accent)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-default-text-accent)"
  },
  "colors.semantic-color-tertiary-reverse-hover-border": {
    "value": "var(--colors-semantic-color-tertiary-reverse-hover-border)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-hover-border)"
  },
  "colors.semantic-color-tertiary-reverse-hover-text": {
    "value": "var(--colors-semantic-color-tertiary-reverse-hover-text)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-hover-text)"
  },
  "colors.semantic-color-tertiary-reverse-hover-background": {
    "value": "var(--colors-semantic-color-tertiary-reverse-hover-background)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-hover-background)"
  },
  "colors.semantic-color-tertiary-reverse-hover-icon": {
    "value": "var(--colors-semantic-color-tertiary-reverse-hover-icon)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-hover-icon)"
  },
  "colors.semantic-color-tertiary-reverse-hover-text-accent": {
    "value": "var(--colors-semantic-color-tertiary-reverse-hover-text-accent)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-hover-text-accent)"
  },
  "colors.semantic-color-tertiary-reverse-active-border": {
    "value": "var(--colors-semantic-color-tertiary-reverse-active-border)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-active-border)"
  },
  "colors.semantic-color-tertiary-reverse-active-text": {
    "value": "var(--colors-semantic-color-tertiary-reverse-active-text)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-active-text)"
  },
  "colors.semantic-color-tertiary-reverse-active-background": {
    "value": "var(--colors-semantic-color-tertiary-reverse-active-background)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-active-background)"
  },
  "colors.semantic-color-tertiary-reverse-active-icon": {
    "value": "var(--colors-semantic-color-tertiary-reverse-active-icon)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-active-icon)"
  },
  "colors.semantic-color-tertiary-reverse-active-text-accent": {
    "value": "var(--colors-semantic-color-tertiary-reverse-active-text-accent)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-active-text-accent)"
  },
  "colors.semantic-color-tertiary-reverse-focus-border": {
    "value": "var(--colors-semantic-color-tertiary-reverse-focus-border)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-focus-border)"
  },
  "colors.semantic-color-tertiary-reverse-focus-text": {
    "value": "var(--colors-semantic-color-tertiary-reverse-focus-text)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-focus-text)"
  },
  "colors.semantic-color-tertiary-reverse-focus-background": {
    "value": "var(--colors-semantic-color-tertiary-reverse-focus-background)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-focus-background)"
  },
  "colors.semantic-color-tertiary-reverse-focus-icon": {
    "value": "var(--colors-semantic-color-tertiary-reverse-focus-icon)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-focus-icon)"
  },
  "colors.semantic-color-tertiary-reverse-focus-text-accent": {
    "value": "var(--colors-semantic-color-tertiary-reverse-focus-text-accent)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-focus-text-accent)"
  },
  "colors.semantic-color-tertiary-reverse-disabled-background": {
    "value": "var(--colors-semantic-color-tertiary-reverse-disabled-background)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-disabled-background)"
  },
  "colors.semantic-color-tertiary-reverse-disabled-text": {
    "value": "var(--colors-semantic-color-tertiary-reverse-disabled-text)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-disabled-text)"
  },
  "colors.semantic-color-tertiary-reverse-disabled-border": {
    "value": "var(--colors-semantic-color-tertiary-reverse-disabled-border)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-disabled-border)"
  },
  "colors.semantic-color-tertiary-reverse-disabled-icon": {
    "value": "var(--colors-semantic-color-tertiary-reverse-disabled-icon)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-disabled-icon)"
  },
  "colors.semantic-color-tertiary-reverse-disabled-text-accent": {
    "value": "var(--colors-semantic-color-tertiary-reverse-disabled-text-accent)",
    "variable": "var(--colors-semantic-color-tertiary-reverse-disabled-text-accent)"
  },
  "colors.semantic-color-focus-border": {
    "value": "var(--colors-semantic-color-focus-border)",
    "variable": "var(--colors-semantic-color-focus-border)"
  },
  "colors.semantic-color-feedback-help-background": {
    "value": "var(--colors-semantic-color-feedback-help-background)",
    "variable": "var(--colors-semantic-color-feedback-help-background)"
  },
  "colors.semantic-color-feedback-help-background-subtle": {
    "value": "var(--colors-semantic-color-feedback-help-background-subtle)",
    "variable": "var(--colors-semantic-color-feedback-help-background-subtle)"
  },
  "colors.semantic-color-feedback-help-border": {
    "value": "var(--colors-semantic-color-feedback-help-border)",
    "variable": "var(--colors-semantic-color-feedback-help-border)"
  },
  "colors.semantic-color-feedback-help-icon-accent": {
    "value": "var(--colors-semantic-color-feedback-help-icon-accent)",
    "variable": "var(--colors-semantic-color-feedback-help-icon-accent)"
  },
  "colors.semantic-color-feedback-help-text-accent": {
    "value": "var(--colors-semantic-color-feedback-help-text-accent)",
    "variable": "var(--colors-semantic-color-feedback-help-text-accent)"
  },
  "colors.semantic-color-feedback-error-background": {
    "value": "var(--colors-semantic-color-feedback-error-background)",
    "variable": "var(--colors-semantic-color-feedback-error-background)"
  },
  "colors.semantic-color-feedback-error-background-subtle": {
    "value": "var(--colors-semantic-color-feedback-error-background-subtle)",
    "variable": "var(--colors-semantic-color-feedback-error-background-subtle)"
  },
  "colors.semantic-color-feedback-error-border": {
    "value": "var(--colors-semantic-color-feedback-error-border)",
    "variable": "var(--colors-semantic-color-feedback-error-border)"
  },
  "colors.semantic-color-feedback-error-icon-accent": {
    "value": "var(--colors-semantic-color-feedback-error-icon-accent)",
    "variable": "var(--colors-semantic-color-feedback-error-icon-accent)"
  },
  "colors.semantic-color-feedback-error-text-accent": {
    "value": "var(--colors-semantic-color-feedback-error-text-accent)",
    "variable": "var(--colors-semantic-color-feedback-error-text-accent)"
  },
  "colors.semantic-color-feedback-warning-background": {
    "value": "var(--colors-semantic-color-feedback-warning-background)",
    "variable": "var(--colors-semantic-color-feedback-warning-background)"
  },
  "colors.semantic-color-feedback-warning-background-subtle": {
    "value": "var(--colors-semantic-color-feedback-warning-background-subtle)",
    "variable": "var(--colors-semantic-color-feedback-warning-background-subtle)"
  },
  "colors.semantic-color-feedback-warning-border": {
    "value": "var(--colors-semantic-color-feedback-warning-border)",
    "variable": "var(--colors-semantic-color-feedback-warning-border)"
  },
  "colors.semantic-color-feedback-warning-icon-accent": {
    "value": "var(--colors-semantic-color-feedback-warning-icon-accent)",
    "variable": "var(--colors-semantic-color-feedback-warning-icon-accent)"
  },
  "colors.semantic-color-feedback-warning-text-accent": {
    "value": "var(--colors-semantic-color-feedback-warning-text-accent)",
    "variable": "var(--colors-semantic-color-feedback-warning-text-accent)"
  },
  "colors.semantic-color-feedback-success-background": {
    "value": "var(--colors-semantic-color-feedback-success-background)",
    "variable": "var(--colors-semantic-color-feedback-success-background)"
  },
  "colors.semantic-color-feedback-success-background-subtle": {
    "value": "var(--colors-semantic-color-feedback-success-background-subtle)",
    "variable": "var(--colors-semantic-color-feedback-success-background-subtle)"
  },
  "colors.semantic-color-feedback-success-border": {
    "value": "var(--colors-semantic-color-feedback-success-border)",
    "variable": "var(--colors-semantic-color-feedback-success-border)"
  },
  "colors.semantic-color-feedback-success-icon-accent": {
    "value": "var(--colors-semantic-color-feedback-success-icon-accent)",
    "variable": "var(--colors-semantic-color-feedback-success-icon-accent)"
  },
  "colors.semantic-color-feedback-success-text-accent": {
    "value": "var(--colors-semantic-color-feedback-success-text-accent)",
    "variable": "var(--colors-semantic-color-feedback-success-text-accent)"
  },
  "colors.semantic-color-feedback-neutral-background": {
    "value": "var(--colors-semantic-color-feedback-neutral-background)",
    "variable": "var(--colors-semantic-color-feedback-neutral-background)"
  },
  "colors.semantic-color-feedback-neutral-background-subtle": {
    "value": "var(--colors-semantic-color-feedback-neutral-background-subtle)",
    "variable": "var(--colors-semantic-color-feedback-neutral-background-subtle)"
  },
  "colors.semantic-color-feedback-neutral-border": {
    "value": "var(--colors-semantic-color-feedback-neutral-border)",
    "variable": "var(--colors-semantic-color-feedback-neutral-border)"
  },
  "colors.semantic-color-feedback-neutral-icon-accent": {
    "value": "var(--colors-semantic-color-feedback-neutral-icon-accent)",
    "variable": "var(--colors-semantic-color-feedback-neutral-icon-accent)"
  },
  "colors.semantic-color-feedback-neutral-text-accent": {
    "value": "var(--colors-semantic-color-feedback-neutral-text-accent)",
    "variable": "var(--colors-semantic-color-feedback-neutral-text-accent)"
  },
  "colors.semantic-color-feedback-info-background": {
    "value": "var(--colors-semantic-color-feedback-info-background)",
    "variable": "var(--colors-semantic-color-feedback-info-background)"
  },
  "colors.semantic-color-feedback-info-background-subtle": {
    "value": "var(--colors-semantic-color-feedback-info-background-subtle)",
    "variable": "var(--colors-semantic-color-feedback-info-background-subtle)"
  },
  "colors.semantic-color-feedback-info-border": {
    "value": "var(--colors-semantic-color-feedback-info-border)",
    "variable": "var(--colors-semantic-color-feedback-info-border)"
  },
  "colors.semantic-color-feedback-info-icon-accent": {
    "value": "var(--colors-semantic-color-feedback-info-icon-accent)",
    "variable": "var(--colors-semantic-color-feedback-info-icon-accent)"
  },
  "colors.semantic-color-feedback-info-text-accent": {
    "value": "var(--colors-semantic-color-feedback-info-text-accent)",
    "variable": "var(--colors-semantic-color-feedback-info-text-accent)"
  },
  "colors.semantic-color-feedback-icons-solid": {
    "value": "var(--colors-semantic-color-feedback-icons-solid)",
    "variable": "var(--colors-semantic-color-feedback-icons-solid)"
  },
  "colors.semantic-color-feedback-icons-reversed": {
    "value": "var(--colors-semantic-color-feedback-icons-reversed)",
    "variable": "var(--colors-semantic-color-feedback-icons-reversed)"
  },
  "colors.semantic-color-feedback-typography-solid": {
    "value": "var(--colors-semantic-color-feedback-typography-solid)",
    "variable": "var(--colors-semantic-color-feedback-typography-solid)"
  },
  "colors.semantic-color-feedback-typography-reversed": {
    "value": "var(--colors-semantic-color-feedback-typography-reversed)",
    "variable": "var(--colors-semantic-color-feedback-typography-reversed)"
  },
  "colors.semantic-color-typography-headings-solid": {
    "value": "var(--colors-semantic-color-typography-headings-solid)",
    "variable": "var(--colors-semantic-color-typography-headings-solid)"
  },
  "colors.semantic-color-typography-headings-reversed": {
    "value": "var(--colors-semantic-color-typography-headings-reversed)",
    "variable": "var(--colors-semantic-color-typography-headings-reversed)"
  },
  "colors.semantic-color-typography-body-solid": {
    "value": "var(--colors-semantic-color-typography-body-solid)",
    "variable": "var(--colors-semantic-color-typography-body-solid)"
  },
  "colors.semantic-color-typography-body-reversed": {
    "value": "var(--colors-semantic-color-typography-body-reversed)",
    "variable": "var(--colors-semantic-color-typography-body-reversed)"
  },
  "colors.semantic-color-typography-links-solid": {
    "value": "var(--colors-semantic-color-typography-links-solid)",
    "variable": "var(--colors-semantic-color-typography-links-solid)"
  },
  "colors.semantic-color-typography-links-reversed": {
    "value": "var(--colors-semantic-color-typography-links-reversed)",
    "variable": "var(--colors-semantic-color-typography-links-reversed)"
  },
  "colors.semantic-color-typography-pricepoints-solid": {
    "value": "var(--colors-semantic-color-typography-pricepoints-solid)",
    "variable": "var(--colors-semantic-color-typography-pricepoints-solid)"
  },
  "colors.semantic-color-typography-pricepoints-reversed": {
    "value": "var(--colors-semantic-color-typography-pricepoints-reversed)",
    "variable": "var(--colors-semantic-color-typography-pricepoints-reversed)"
  },
  "colors.semantic-color-inputs-default-border": {
    "value": "var(--colors-semantic-color-inputs-default-border)",
    "variable": "var(--colors-semantic-color-inputs-default-border)"
  },
  "colors.semantic-color-inputs-default-text": {
    "value": "var(--colors-semantic-color-inputs-default-text)",
    "variable": "var(--colors-semantic-color-inputs-default-text)"
  },
  "colors.semantic-color-inputs-default-background": {
    "value": "var(--colors-semantic-color-inputs-default-background)",
    "variable": "var(--colors-semantic-color-inputs-default-background)"
  },
  "colors.semantic-color-inputs-default-icon": {
    "value": "var(--colors-semantic-color-inputs-default-icon)",
    "variable": "var(--colors-semantic-color-inputs-default-icon)"
  },
  "colors.semantic-color-inputs-error-border": {
    "value": "var(--colors-semantic-color-inputs-error-border)",
    "variable": "var(--colors-semantic-color-inputs-error-border)"
  },
  "colors.semantic-color-inputs-error-text": {
    "value": "var(--colors-semantic-color-inputs-error-text)",
    "variable": "var(--colors-semantic-color-inputs-error-text)"
  },
  "colors.semantic-color-inputs-error-background": {
    "value": "var(--colors-semantic-color-inputs-error-background)",
    "variable": "var(--colors-semantic-color-inputs-error-background)"
  },
  "colors.semantic-color-inputs-error-icon": {
    "value": "var(--colors-semantic-color-inputs-error-icon)",
    "variable": "var(--colors-semantic-color-inputs-error-icon)"
  },
  "colors.semantic-color-inputs-success-border": {
    "value": "var(--colors-semantic-color-inputs-success-border)",
    "variable": "var(--colors-semantic-color-inputs-success-border)"
  },
  "colors.semantic-color-inputs-success-text": {
    "value": "var(--colors-semantic-color-inputs-success-text)",
    "variable": "var(--colors-semantic-color-inputs-success-text)"
  },
  "colors.semantic-color-inputs-success-background": {
    "value": "var(--colors-semantic-color-inputs-success-background)",
    "variable": "var(--colors-semantic-color-inputs-success-background)"
  },
  "colors.semantic-color-inputs-success-icon": {
    "value": "var(--colors-semantic-color-inputs-success-icon)",
    "variable": "var(--colors-semantic-color-inputs-success-icon)"
  },
  "colors.semantic-color-inputs-disabled-border": {
    "value": "var(--colors-semantic-color-inputs-disabled-border)",
    "variable": "var(--colors-semantic-color-inputs-disabled-border)"
  },
  "colors.semantic-color-inputs-disabled-text": {
    "value": "var(--colors-semantic-color-inputs-disabled-text)",
    "variable": "var(--colors-semantic-color-inputs-disabled-text)"
  },
  "colors.semantic-color-inputs-disabled-background": {
    "value": "var(--colors-semantic-color-inputs-disabled-background)",
    "variable": "var(--colors-semantic-color-inputs-disabled-background)"
  },
  "colors.semantic-color-inputs-disabled-icon": {
    "value": "var(--colors-semantic-color-inputs-disabled-icon)",
    "variable": "var(--colors-semantic-color-inputs-disabled-icon)"
  },
  "colors.semantic-color-inputs-filled-border": {
    "value": "var(--colors-semantic-color-inputs-filled-border)",
    "variable": "var(--colors-semantic-color-inputs-filled-border)"
  },
  "colors.semantic-color-inputs-filled-text": {
    "value": "var(--colors-semantic-color-inputs-filled-text)",
    "variable": "var(--colors-semantic-color-inputs-filled-text)"
  },
  "colors.semantic-color-inputs-filled-background": {
    "value": "var(--colors-semantic-color-inputs-filled-background)",
    "variable": "var(--colors-semantic-color-inputs-filled-background)"
  },
  "colors.semantic-color-inputs-filled-icon": {
    "value": "var(--colors-semantic-color-inputs-filled-icon)",
    "variable": "var(--colors-semantic-color-inputs-filled-icon)"
  },
  "colors.semantic-color-tags-extentions-background": {
    "value": "var(--colors-semantic-color-tags-extentions-background)",
    "variable": "var(--colors-semantic-color-tags-extentions-background)"
  },
  "colors.semantic-color-tags-extentions-background-subtle": {
    "value": "var(--colors-semantic-color-tags-extentions-background-subtle)",
    "variable": "var(--colors-semantic-color-tags-extentions-background-subtle)"
  },
  "colors.semantic-color-tags-extentions-text": {
    "value": "var(--colors-semantic-color-tags-extentions-text)",
    "variable": "var(--colors-semantic-color-tags-extentions-text)"
  },
  "colors.semantic-color-tags-extentions-icon": {
    "value": "var(--colors-semantic-color-tags-extentions-icon)",
    "variable": "var(--colors-semantic-color-tags-extentions-icon)"
  },
  "colors.semantic-color-tags-triptype-background": {
    "value": "var(--colors-semantic-color-tags-triptype-background)",
    "variable": "var(--colors-semantic-color-tags-triptype-background)"
  },
  "colors.semantic-color-tags-triptype-background-subtle": {
    "value": "var(--colors-semantic-color-tags-triptype-background-subtle)",
    "variable": "var(--colors-semantic-color-tags-triptype-background-subtle)"
  },
  "colors.semantic-color-tags-triptype-text": {
    "value": "var(--colors-semantic-color-tags-triptype-text)",
    "variable": "var(--colors-semantic-color-tags-triptype-text)"
  },
  "colors.semantic-color-tags-triptype-icon": {
    "value": "var(--colors-semantic-color-tags-triptype-icon)",
    "variable": "var(--colors-semantic-color-tags-triptype-icon)"
  },
  "colors.semantic-color-tags-promo-a-background": {
    "value": "var(--colors-semantic-color-tags-promo-a-background)",
    "variable": "var(--colors-semantic-color-tags-promo-a-background)"
  },
  "colors.semantic-color-tags-promo-a-background-subtle": {
    "value": "var(--colors-semantic-color-tags-promo-a-background-subtle)",
    "variable": "var(--colors-semantic-color-tags-promo-a-background-subtle)"
  },
  "colors.semantic-color-tags-promo-a-text": {
    "value": "var(--colors-semantic-color-tags-promo-a-text)",
    "variable": "var(--colors-semantic-color-tags-promo-a-text)"
  },
  "colors.semantic-color-tags-promo-a-icon": {
    "value": "var(--colors-semantic-color-tags-promo-a-icon)",
    "variable": "var(--colors-semantic-color-tags-promo-a-icon)"
  },
  "colors.semantic-color-tags-promo-b-background": {
    "value": "var(--colors-semantic-color-tags-promo-b-background)",
    "variable": "var(--colors-semantic-color-tags-promo-b-background)"
  },
  "colors.semantic-color-tags-promo-b-background-subtle": {
    "value": "var(--colors-semantic-color-tags-promo-b-background-subtle)",
    "variable": "var(--colors-semantic-color-tags-promo-b-background-subtle)"
  },
  "colors.semantic-color-tags-promo-b-text": {
    "value": "var(--colors-semantic-color-tags-promo-b-text)",
    "variable": "var(--colors-semantic-color-tags-promo-b-text)"
  },
  "colors.semantic-color-tags-promo-b-icon": {
    "value": "var(--colors-semantic-color-tags-promo-b-icon)",
    "variable": "var(--colors-semantic-color-tags-promo-b-icon)"
  },
  "colors.color-primary-50": {
    "value": "var(--colors-color-primary-50)",
    "variable": "var(--colors-color-primary-50)"
  },
  "colors.color-primary-100": {
    "value": "var(--colors-color-primary-100)",
    "variable": "var(--colors-color-primary-100)"
  },
  "colors.color-primary-200": {
    "value": "var(--colors-color-primary-200)",
    "variable": "var(--colors-color-primary-200)"
  },
  "colors.color-primary-300": {
    "value": "var(--colors-color-primary-300)",
    "variable": "var(--colors-color-primary-300)"
  },
  "colors.color-primary-400": {
    "value": "var(--colors-color-primary-400)",
    "variable": "var(--colors-color-primary-400)"
  },
  "colors.color-primary-500": {
    "value": "var(--colors-color-primary-500)",
    "variable": "var(--colors-color-primary-500)"
  },
  "colors.color-primary-600": {
    "value": "var(--colors-color-primary-600)",
    "variable": "var(--colors-color-primary-600)"
  },
  "colors.color-primary-700": {
    "value": "var(--colors-color-primary-700)",
    "variable": "var(--colors-color-primary-700)"
  },
  "colors.color-primary-800": {
    "value": "var(--colors-color-primary-800)",
    "variable": "var(--colors-color-primary-800)"
  },
  "colors.color-primary-900": {
    "value": "var(--colors-color-primary-900)",
    "variable": "var(--colors-color-primary-900)"
  },
  "colors.color-secondary-50": {
    "value": "var(--colors-color-secondary-50)",
    "variable": "var(--colors-color-secondary-50)"
  },
  "colors.color-secondary-100": {
    "value": "var(--colors-color-secondary-100)",
    "variable": "var(--colors-color-secondary-100)"
  },
  "colors.color-secondary-200": {
    "value": "var(--colors-color-secondary-200)",
    "variable": "var(--colors-color-secondary-200)"
  },
  "colors.color-secondary-300": {
    "value": "var(--colors-color-secondary-300)",
    "variable": "var(--colors-color-secondary-300)"
  },
  "colors.color-secondary-400": {
    "value": "var(--colors-color-secondary-400)",
    "variable": "var(--colors-color-secondary-400)"
  },
  "colors.color-secondary-500": {
    "value": "var(--colors-color-secondary-500)",
    "variable": "var(--colors-color-secondary-500)"
  },
  "colors.color-secondary-600": {
    "value": "var(--colors-color-secondary-600)",
    "variable": "var(--colors-color-secondary-600)"
  },
  "colors.color-secondary-700": {
    "value": "var(--colors-color-secondary-700)",
    "variable": "var(--colors-color-secondary-700)"
  },
  "colors.color-secondary-800": {
    "value": "var(--colors-color-secondary-800)",
    "variable": "var(--colors-color-secondary-800)"
  },
  "colors.color-secondary-900": {
    "value": "var(--colors-color-secondary-900)",
    "variable": "var(--colors-color-secondary-900)"
  },
  "colors.color-tertiary-a-50": {
    "value": "var(--colors-color-tertiary-a-50)",
    "variable": "var(--colors-color-tertiary-a-50)"
  },
  "colors.color-tertiary-a-100": {
    "value": "var(--colors-color-tertiary-a-100)",
    "variable": "var(--colors-color-tertiary-a-100)"
  },
  "colors.color-tertiary-a-200": {
    "value": "var(--colors-color-tertiary-a-200)",
    "variable": "var(--colors-color-tertiary-a-200)"
  },
  "colors.color-tertiary-a-300": {
    "value": "var(--colors-color-tertiary-a-300)",
    "variable": "var(--colors-color-tertiary-a-300)"
  },
  "colors.color-tertiary-a-400": {
    "value": "var(--colors-color-tertiary-a-400)",
    "variable": "var(--colors-color-tertiary-a-400)"
  },
  "colors.color-tertiary-a-500": {
    "value": "var(--colors-color-tertiary-a-500)",
    "variable": "var(--colors-color-tertiary-a-500)"
  },
  "colors.color-tertiary-a-600": {
    "value": "var(--colors-color-tertiary-a-600)",
    "variable": "var(--colors-color-tertiary-a-600)"
  },
  "colors.color-tertiary-a-700": {
    "value": "var(--colors-color-tertiary-a-700)",
    "variable": "var(--colors-color-tertiary-a-700)"
  },
  "colors.color-tertiary-a-800": {
    "value": "var(--colors-color-tertiary-a-800)",
    "variable": "var(--colors-color-tertiary-a-800)"
  },
  "colors.color-tertiary-a-900": {
    "value": "var(--colors-color-tertiary-a-900)",
    "variable": "var(--colors-color-tertiary-a-900)"
  },
  "colors.color-tertiary-b-50": {
    "value": "var(--colors-color-tertiary-b-50)",
    "variable": "var(--colors-color-tertiary-b-50)"
  },
  "colors.color-tertiary-b-100": {
    "value": "var(--colors-color-tertiary-b-100)",
    "variable": "var(--colors-color-tertiary-b-100)"
  },
  "colors.color-tertiary-b-200": {
    "value": "var(--colors-color-tertiary-b-200)",
    "variable": "var(--colors-color-tertiary-b-200)"
  },
  "colors.color-tertiary-b-300": {
    "value": "var(--colors-color-tertiary-b-300)",
    "variable": "var(--colors-color-tertiary-b-300)"
  },
  "colors.color-tertiary-b-400": {
    "value": "var(--colors-color-tertiary-b-400)",
    "variable": "var(--colors-color-tertiary-b-400)"
  },
  "colors.color-tertiary-b-500": {
    "value": "var(--colors-color-tertiary-b-500)",
    "variable": "var(--colors-color-tertiary-b-500)"
  },
  "colors.color-tertiary-b-600": {
    "value": "var(--colors-color-tertiary-b-600)",
    "variable": "var(--colors-color-tertiary-b-600)"
  },
  "colors.color-tertiary-b-700": {
    "value": "var(--colors-color-tertiary-b-700)",
    "variable": "var(--colors-color-tertiary-b-700)"
  },
  "colors.color-tertiary-b-800": {
    "value": "var(--colors-color-tertiary-b-800)",
    "variable": "var(--colors-color-tertiary-b-800)"
  },
  "colors.color-tertiary-b-900": {
    "value": "var(--colors-color-tertiary-b-900)",
    "variable": "var(--colors-color-tertiary-b-900)"
  },
  "colors.color-neutral-0": {
    "value": "var(--colors-color-neutral-0)",
    "variable": "var(--colors-color-neutral-0)"
  },
  "colors.color-neutral-50": {
    "value": "var(--colors-color-neutral-50)",
    "variable": "var(--colors-color-neutral-50)"
  },
  "colors.color-neutral-100": {
    "value": "var(--colors-color-neutral-100)",
    "variable": "var(--colors-color-neutral-100)"
  },
  "colors.color-neutral-200": {
    "value": "var(--colors-color-neutral-200)",
    "variable": "var(--colors-color-neutral-200)"
  },
  "colors.color-neutral-300": {
    "value": "var(--colors-color-neutral-300)",
    "variable": "var(--colors-color-neutral-300)"
  },
  "colors.color-neutral-400": {
    "value": "var(--colors-color-neutral-400)",
    "variable": "var(--colors-color-neutral-400)"
  },
  "colors.color-neutral-500": {
    "value": "var(--colors-color-neutral-500)",
    "variable": "var(--colors-color-neutral-500)"
  },
  "colors.color-neutral-600": {
    "value": "var(--colors-color-neutral-600)",
    "variable": "var(--colors-color-neutral-600)"
  },
  "colors.color-neutral-700": {
    "value": "var(--colors-color-neutral-700)",
    "variable": "var(--colors-color-neutral-700)"
  },
  "colors.color-neutral-800": {
    "value": "var(--colors-color-neutral-800)",
    "variable": "var(--colors-color-neutral-800)"
  },
  "colors.color-neutral-900": {
    "value": "var(--colors-color-neutral-900)",
    "variable": "var(--colors-color-neutral-900)"
  },
  "colors.color-neutral-1000": {
    "value": "var(--colors-color-neutral-1000)",
    "variable": "var(--colors-color-neutral-1000)"
  },
  "colors.color-neutral-1100": {
    "value": "var(--colors-color-neutral-1100)",
    "variable": "var(--colors-color-neutral-1100)"
  },
  "colors.color-extended-accent-a-50": {
    "value": "var(--colors-color-extended-accent-a-50)",
    "variable": "var(--colors-color-extended-accent-a-50)"
  },
  "colors.color-extended-accent-a-100": {
    "value": "var(--colors-color-extended-accent-a-100)",
    "variable": "var(--colors-color-extended-accent-a-100)"
  },
  "colors.color-extended-accent-a-200": {
    "value": "var(--colors-color-extended-accent-a-200)",
    "variable": "var(--colors-color-extended-accent-a-200)"
  },
  "colors.color-extended-accent-a-300": {
    "value": "var(--colors-color-extended-accent-a-300)",
    "variable": "var(--colors-color-extended-accent-a-300)"
  },
  "colors.color-extended-accent-a-400": {
    "value": "var(--colors-color-extended-accent-a-400)",
    "variable": "var(--colors-color-extended-accent-a-400)"
  },
  "colors.color-extended-accent-a-500": {
    "value": "var(--colors-color-extended-accent-a-500)",
    "variable": "var(--colors-color-extended-accent-a-500)"
  },
  "colors.color-extended-accent-a-600": {
    "value": "var(--colors-color-extended-accent-a-600)",
    "variable": "var(--colors-color-extended-accent-a-600)"
  },
  "colors.color-extended-accent-a-700": {
    "value": "var(--colors-color-extended-accent-a-700)",
    "variable": "var(--colors-color-extended-accent-a-700)"
  },
  "colors.color-extended-accent-a-800": {
    "value": "var(--colors-color-extended-accent-a-800)",
    "variable": "var(--colors-color-extended-accent-a-800)"
  },
  "colors.color-extended-accent-a-900": {
    "value": "var(--colors-color-extended-accent-a-900)",
    "variable": "var(--colors-color-extended-accent-a-900)"
  },
  "colors.color-extended-accent-b-50": {
    "value": "var(--colors-color-extended-accent-b-50)",
    "variable": "var(--colors-color-extended-accent-b-50)"
  },
  "colors.color-extended-accent-b-100": {
    "value": "var(--colors-color-extended-accent-b-100)",
    "variable": "var(--colors-color-extended-accent-b-100)"
  },
  "colors.color-extended-accent-b-200": {
    "value": "var(--colors-color-extended-accent-b-200)",
    "variable": "var(--colors-color-extended-accent-b-200)"
  },
  "colors.color-extended-accent-b-300": {
    "value": "var(--colors-color-extended-accent-b-300)",
    "variable": "var(--colors-color-extended-accent-b-300)"
  },
  "colors.color-extended-accent-b-400": {
    "value": "var(--colors-color-extended-accent-b-400)",
    "variable": "var(--colors-color-extended-accent-b-400)"
  },
  "colors.color-extended-accent-b-500": {
    "value": "var(--colors-color-extended-accent-b-500)",
    "variable": "var(--colors-color-extended-accent-b-500)"
  },
  "colors.color-extended-accent-b-600": {
    "value": "var(--colors-color-extended-accent-b-600)",
    "variable": "var(--colors-color-extended-accent-b-600)"
  },
  "colors.color-extended-accent-b-700": {
    "value": "var(--colors-color-extended-accent-b-700)",
    "variable": "var(--colors-color-extended-accent-b-700)"
  },
  "colors.color-extended-accent-b-800": {
    "value": "var(--colors-color-extended-accent-b-800)",
    "variable": "var(--colors-color-extended-accent-b-800)"
  },
  "colors.color-extended-accent-b-900": {
    "value": "var(--colors-color-extended-accent-b-900)",
    "variable": "var(--colors-color-extended-accent-b-900)"
  },
  "colors.color-extended-accent-c-50": {
    "value": "var(--colors-color-extended-accent-c-50)",
    "variable": "var(--colors-color-extended-accent-c-50)"
  },
  "colors.color-extended-accent-c-100": {
    "value": "var(--colors-color-extended-accent-c-100)",
    "variable": "var(--colors-color-extended-accent-c-100)"
  },
  "colors.color-extended-accent-c-200": {
    "value": "var(--colors-color-extended-accent-c-200)",
    "variable": "var(--colors-color-extended-accent-c-200)"
  },
  "colors.color-extended-accent-c-300": {
    "value": "var(--colors-color-extended-accent-c-300)",
    "variable": "var(--colors-color-extended-accent-c-300)"
  },
  "colors.color-extended-accent-c-400": {
    "value": "var(--colors-color-extended-accent-c-400)",
    "variable": "var(--colors-color-extended-accent-c-400)"
  },
  "colors.color-extended-accent-c-500": {
    "value": "var(--colors-color-extended-accent-c-500)",
    "variable": "var(--colors-color-extended-accent-c-500)"
  },
  "colors.color-extended-accent-c-600": {
    "value": "var(--colors-color-extended-accent-c-600)",
    "variable": "var(--colors-color-extended-accent-c-600)"
  },
  "colors.color-extended-accent-c-700": {
    "value": "var(--colors-color-extended-accent-c-700)",
    "variable": "var(--colors-color-extended-accent-c-700)"
  },
  "colors.color-extended-accent-c-800": {
    "value": "var(--colors-color-extended-accent-c-800)",
    "variable": "var(--colors-color-extended-accent-c-800)"
  },
  "colors.color-extended-accent-c-900": {
    "value": "var(--colors-color-extended-accent-c-900)",
    "variable": "var(--colors-color-extended-accent-c-900)"
  },
  "colors.color-extended-accent-d-50": {
    "value": "var(--colors-color-extended-accent-d-50)",
    "variable": "var(--colors-color-extended-accent-d-50)"
  },
  "colors.color-extended-accent-d-100": {
    "value": "var(--colors-color-extended-accent-d-100)",
    "variable": "var(--colors-color-extended-accent-d-100)"
  },
  "colors.color-extended-accent-d-200": {
    "value": "var(--colors-color-extended-accent-d-200)",
    "variable": "var(--colors-color-extended-accent-d-200)"
  },
  "colors.color-extended-accent-d-300": {
    "value": "var(--colors-color-extended-accent-d-300)",
    "variable": "var(--colors-color-extended-accent-d-300)"
  },
  "colors.color-extended-accent-d-400": {
    "value": "var(--colors-color-extended-accent-d-400)",
    "variable": "var(--colors-color-extended-accent-d-400)"
  },
  "colors.color-extended-accent-d-500": {
    "value": "var(--colors-color-extended-accent-d-500)",
    "variable": "var(--colors-color-extended-accent-d-500)"
  },
  "colors.color-extended-accent-d-600": {
    "value": "var(--colors-color-extended-accent-d-600)",
    "variable": "var(--colors-color-extended-accent-d-600)"
  },
  "colors.color-extended-accent-d-700": {
    "value": "var(--colors-color-extended-accent-d-700)",
    "variable": "var(--colors-color-extended-accent-d-700)"
  },
  "colors.color-extended-accent-d-800": {
    "value": "var(--colors-color-extended-accent-d-800)",
    "variable": "var(--colors-color-extended-accent-d-800)"
  },
  "colors.color-extended-accent-d-900": {
    "value": "var(--colors-color-extended-accent-d-900)",
    "variable": "var(--colors-color-extended-accent-d-900)"
  },
  "colors.color-extended-accent-e-50": {
    "value": "var(--colors-color-extended-accent-e-50)",
    "variable": "var(--colors-color-extended-accent-e-50)"
  },
  "colors.color-extended-accent-e-100": {
    "value": "var(--colors-color-extended-accent-e-100)",
    "variable": "var(--colors-color-extended-accent-e-100)"
  },
  "colors.color-extended-accent-e-200": {
    "value": "var(--colors-color-extended-accent-e-200)",
    "variable": "var(--colors-color-extended-accent-e-200)"
  },
  "colors.color-extended-accent-e-300": {
    "value": "var(--colors-color-extended-accent-e-300)",
    "variable": "var(--colors-color-extended-accent-e-300)"
  },
  "colors.color-extended-accent-e-400": {
    "value": "var(--colors-color-extended-accent-e-400)",
    "variable": "var(--colors-color-extended-accent-e-400)"
  },
  "colors.color-extended-accent-e-500": {
    "value": "var(--colors-color-extended-accent-e-500)",
    "variable": "var(--colors-color-extended-accent-e-500)"
  },
  "colors.color-extended-accent-e-600": {
    "value": "var(--colors-color-extended-accent-e-600)",
    "variable": "var(--colors-color-extended-accent-e-600)"
  },
  "colors.color-extended-accent-e-700": {
    "value": "var(--colors-color-extended-accent-e-700)",
    "variable": "var(--colors-color-extended-accent-e-700)"
  },
  "colors.color-extended-accent-e-800": {
    "value": "var(--colors-color-extended-accent-e-800)",
    "variable": "var(--colors-color-extended-accent-e-800)"
  },
  "colors.color-extended-accent-e-900": {
    "value": "var(--colors-color-extended-accent-e-900)",
    "variable": "var(--colors-color-extended-accent-e-900)"
  },
  "colors.color-category-c": {
    "value": "var(--colors-color-category-c)",
    "variable": "var(--colors-color-category-c)"
  },
  "colors.color-category-d": {
    "value": "var(--colors-color-category-d)",
    "variable": "var(--colors-color-category-d)"
  },
  "colors.color-category-e": {
    "value": "var(--colors-color-category-e)",
    "variable": "var(--colors-color-category-e)"
  },
  "colors.color-category-b-plus": {
    "value": "var(--colors-color-category-b-plus)",
    "variable": "var(--colors-color-category-b-plus)"
  },
  "colors.color-category-et": {
    "value": "var(--colors-color-category-et)",
    "variable": "var(--colors-color-category-et)"
  },
  "colors.color-category-p": {
    "value": "var(--colors-color-category-p)",
    "variable": "var(--colors-color-category-p)"
  },
  "colors.color-category-a": {
    "value": "var(--colors-color-category-a)",
    "variable": "var(--colors-color-category-a)"
  },
  "colors.color-category-p-plus": {
    "value": "var(--colors-color-category-p-plus)",
    "variable": "var(--colors-color-category-p-plus)"
  },
  "colors.color-category-t": {
    "value": "var(--colors-color-category-t)",
    "variable": "var(--colors-color-category-t)"
  },
  "colors.color-category-t-plus": {
    "value": "var(--colors-color-category-t-plus)",
    "variable": "var(--colors-color-category-t-plus)"
  },
  "colors.color-category-p-2": {
    "value": "var(--colors-color-category-p-2)",
    "variable": "var(--colors-color-category-p-2)"
  },
  "colors.color-category-cb": {
    "value": "var(--colors-color-category-cb)",
    "variable": "var(--colors-color-category-cb)"
  },
  "colors.color-category-owners": {
    "value": "var(--colors-color-category-owners)",
    "variable": "var(--colors-color-category-owners)"
  },
  "colors.color-category-royal": {
    "value": "var(--colors-color-category-royal)",
    "variable": "var(--colors-color-category-royal)"
  },
  "colors.button-color-primary-default-background": {
    "value": "var(--colors-button-color-primary-default-background)",
    "variable": "var(--colors-button-color-primary-default-background)"
  },
  "colors.button-color-primary-default-text": {
    "value": "var(--colors-button-color-primary-default-text)",
    "variable": "var(--colors-button-color-primary-default-text)"
  },
  "colors.button-color-primary-default-icon": {
    "value": "var(--colors-button-color-primary-default-icon)",
    "variable": "var(--colors-button-color-primary-default-icon)"
  },
  "colors.button-color-primary-hover-background": {
    "value": "var(--colors-button-color-primary-hover-background)",
    "variable": "var(--colors-button-color-primary-hover-background)"
  },
  "colors.button-color-primary-hover-text": {
    "value": "var(--colors-button-color-primary-hover-text)",
    "variable": "var(--colors-button-color-primary-hover-text)"
  },
  "colors.button-color-primary-hover-icon": {
    "value": "var(--colors-button-color-primary-hover-icon)",
    "variable": "var(--colors-button-color-primary-hover-icon)"
  },
  "colors.button-color-primary-active-background": {
    "value": "var(--colors-button-color-primary-active-background)",
    "variable": "var(--colors-button-color-primary-active-background)"
  },
  "colors.button-color-primary-active-text": {
    "value": "var(--colors-button-color-primary-active-text)",
    "variable": "var(--colors-button-color-primary-active-text)"
  },
  "colors.button-color-primary-active-icon": {
    "value": "var(--colors-button-color-primary-active-icon)",
    "variable": "var(--colors-button-color-primary-active-icon)"
  },
  "colors.button-color-primary-focus-background": {
    "value": "var(--colors-button-color-primary-focus-background)",
    "variable": "var(--colors-button-color-primary-focus-background)"
  },
  "colors.button-color-primary-focus-text": {
    "value": "var(--colors-button-color-primary-focus-text)",
    "variable": "var(--colors-button-color-primary-focus-text)"
  },
  "colors.button-color-primary-focus-icon": {
    "value": "var(--colors-button-color-primary-focus-icon)",
    "variable": "var(--colors-button-color-primary-focus-icon)"
  },
  "colors.button-color-primary-selected-background": {
    "value": "var(--colors-button-color-primary-selected-background)",
    "variable": "var(--colors-button-color-primary-selected-background)"
  },
  "colors.button-color-primary-selected-text": {
    "value": "var(--colors-button-color-primary-selected-text)",
    "variable": "var(--colors-button-color-primary-selected-text)"
  },
  "colors.button-color-primary-selected-icon": {
    "value": "var(--colors-button-color-primary-selected-icon)",
    "variable": "var(--colors-button-color-primary-selected-icon)"
  },
  "colors.button-color-primary-disabled-background": {
    "value": "var(--colors-button-color-primary-disabled-background)",
    "variable": "var(--colors-button-color-primary-disabled-background)"
  },
  "colors.button-color-primary-disabled-text": {
    "value": "var(--colors-button-color-primary-disabled-text)",
    "variable": "var(--colors-button-color-primary-disabled-text)"
  },
  "colors.button-color-primary-disabled-icon": {
    "value": "var(--colors-button-color-primary-disabled-icon)",
    "variable": "var(--colors-button-color-primary-disabled-icon)"
  },
  "colors.button-color-enhanced-primary-default-background": {
    "value": "var(--colors-button-color-enhanced-primary-default-background)",
    "variable": "var(--colors-button-color-enhanced-primary-default-background)"
  },
  "colors.button-color-enhanced-primary-default-text": {
    "value": "var(--colors-button-color-enhanced-primary-default-text)",
    "variable": "var(--colors-button-color-enhanced-primary-default-text)"
  },
  "colors.button-color-enhanced-primary-default-icon": {
    "value": "var(--colors-button-color-enhanced-primary-default-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-default-icon)"
  },
  "colors.button-color-enhanced-primary-hover-background": {
    "value": "var(--colors-button-color-enhanced-primary-hover-background)",
    "variable": "var(--colors-button-color-enhanced-primary-hover-background)"
  },
  "colors.button-color-enhanced-primary-hover-text": {
    "value": "var(--colors-button-color-enhanced-primary-hover-text)",
    "variable": "var(--colors-button-color-enhanced-primary-hover-text)"
  },
  "colors.button-color-enhanced-primary-hover-icon": {
    "value": "var(--colors-button-color-enhanced-primary-hover-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-hover-icon)"
  },
  "colors.button-color-enhanced-primary-active-background": {
    "value": "var(--colors-button-color-enhanced-primary-active-background)",
    "variable": "var(--colors-button-color-enhanced-primary-active-background)"
  },
  "colors.button-color-enhanced-primary-active-text": {
    "value": "var(--colors-button-color-enhanced-primary-active-text)",
    "variable": "var(--colors-button-color-enhanced-primary-active-text)"
  },
  "colors.button-color-enhanced-primary-active-icon": {
    "value": "var(--colors-button-color-enhanced-primary-active-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-active-icon)"
  },
  "colors.button-color-enhanced-primary-focus-background": {
    "value": "var(--colors-button-color-enhanced-primary-focus-background)",
    "variable": "var(--colors-button-color-enhanced-primary-focus-background)"
  },
  "colors.button-color-enhanced-primary-focus-text": {
    "value": "var(--colors-button-color-enhanced-primary-focus-text)",
    "variable": "var(--colors-button-color-enhanced-primary-focus-text)"
  },
  "colors.button-color-enhanced-primary-focus-icon": {
    "value": "var(--colors-button-color-enhanced-primary-focus-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-focus-icon)"
  },
  "colors.button-color-enhanced-primary-selected-background": {
    "value": "var(--colors-button-color-enhanced-primary-selected-background)",
    "variable": "var(--colors-button-color-enhanced-primary-selected-background)"
  },
  "colors.button-color-enhanced-primary-selected-text": {
    "value": "var(--colors-button-color-enhanced-primary-selected-text)",
    "variable": "var(--colors-button-color-enhanced-primary-selected-text)"
  },
  "colors.button-color-enhanced-primary-selected-icon": {
    "value": "var(--colors-button-color-enhanced-primary-selected-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-selected-icon)"
  },
  "colors.button-color-enhanced-primary-disabled-background": {
    "value": "var(--colors-button-color-enhanced-primary-disabled-background)",
    "variable": "var(--colors-button-color-enhanced-primary-disabled-background)"
  },
  "colors.button-color-enhanced-primary-disabled-text": {
    "value": "var(--colors-button-color-enhanced-primary-disabled-text)",
    "variable": "var(--colors-button-color-enhanced-primary-disabled-text)"
  },
  "colors.button-color-enhanced-primary-disabled-icon": {
    "value": "var(--colors-button-color-enhanced-primary-disabled-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-disabled-icon)"
  },
  "colors.button-color-secondary-default-background": {
    "value": "var(--colors-button-color-secondary-default-background)",
    "variable": "var(--colors-button-color-secondary-default-background)"
  },
  "colors.button-color-secondary-default-text": {
    "value": "var(--colors-button-color-secondary-default-text)",
    "variable": "var(--colors-button-color-secondary-default-text)"
  },
  "colors.button-color-secondary-default-icon": {
    "value": "var(--colors-button-color-secondary-default-icon)",
    "variable": "var(--colors-button-color-secondary-default-icon)"
  },
  "colors.button-color-secondary-hover-background": {
    "value": "var(--colors-button-color-secondary-hover-background)",
    "variable": "var(--colors-button-color-secondary-hover-background)"
  },
  "colors.button-color-secondary-hover-text": {
    "value": "var(--colors-button-color-secondary-hover-text)",
    "variable": "var(--colors-button-color-secondary-hover-text)"
  },
  "colors.button-color-secondary-hover-icon": {
    "value": "var(--colors-button-color-secondary-hover-icon)",
    "variable": "var(--colors-button-color-secondary-hover-icon)"
  },
  "colors.button-color-secondary-active-background": {
    "value": "var(--colors-button-color-secondary-active-background)",
    "variable": "var(--colors-button-color-secondary-active-background)"
  },
  "colors.button-color-secondary-active-text": {
    "value": "var(--colors-button-color-secondary-active-text)",
    "variable": "var(--colors-button-color-secondary-active-text)"
  },
  "colors.button-color-secondary-active-icon": {
    "value": "var(--colors-button-color-secondary-active-icon)",
    "variable": "var(--colors-button-color-secondary-active-icon)"
  },
  "colors.button-color-secondary-focus-background": {
    "value": "var(--colors-button-color-secondary-focus-background)",
    "variable": "var(--colors-button-color-secondary-focus-background)"
  },
  "colors.button-color-secondary-focus-text": {
    "value": "var(--colors-button-color-secondary-focus-text)",
    "variable": "var(--colors-button-color-secondary-focus-text)"
  },
  "colors.button-color-secondary-focus-icon": {
    "value": "var(--colors-button-color-secondary-focus-icon)",
    "variable": "var(--colors-button-color-secondary-focus-icon)"
  },
  "colors.button-color-secondary-disabled-background": {
    "value": "var(--colors-button-color-secondary-disabled-background)",
    "variable": "var(--colors-button-color-secondary-disabled-background)"
  },
  "colors.button-color-secondary-disabled-text": {
    "value": "var(--colors-button-color-secondary-disabled-text)",
    "variable": "var(--colors-button-color-secondary-disabled-text)"
  },
  "colors.button-color-secondary-disabled-icon": {
    "value": "var(--colors-button-color-secondary-disabled-icon)",
    "variable": "var(--colors-button-color-secondary-disabled-icon)"
  },
  "colors.button-color-tertiary-default-background": {
    "value": "var(--colors-button-color-tertiary-default-background)",
    "variable": "var(--colors-button-color-tertiary-default-background)"
  },
  "colors.button-color-tertiary-default-text": {
    "value": "var(--colors-button-color-tertiary-default-text)",
    "variable": "var(--colors-button-color-tertiary-default-text)"
  },
  "colors.button-color-tertiary-default-border": {
    "value": "var(--colors-button-color-tertiary-default-border)",
    "variable": "var(--colors-button-color-tertiary-default-border)"
  },
  "colors.button-color-tertiary-default-icon": {
    "value": "var(--colors-button-color-tertiary-default-icon)",
    "variable": "var(--colors-button-color-tertiary-default-icon)"
  },
  "colors.button-color-tertiary-hover-background": {
    "value": "var(--colors-button-color-tertiary-hover-background)",
    "variable": "var(--colors-button-color-tertiary-hover-background)"
  },
  "colors.button-color-tertiary-hover-text": {
    "value": "var(--colors-button-color-tertiary-hover-text)",
    "variable": "var(--colors-button-color-tertiary-hover-text)"
  },
  "colors.button-color-tertiary-hover-icon": {
    "value": "var(--colors-button-color-tertiary-hover-icon)",
    "variable": "var(--colors-button-color-tertiary-hover-icon)"
  },
  "colors.button-color-tertiary-active-background": {
    "value": "var(--colors-button-color-tertiary-active-background)",
    "variable": "var(--colors-button-color-tertiary-active-background)"
  },
  "colors.button-color-tertiary-active-text": {
    "value": "var(--colors-button-color-tertiary-active-text)",
    "variable": "var(--colors-button-color-tertiary-active-text)"
  },
  "colors.button-color-tertiary-active-icon": {
    "value": "var(--colors-button-color-tertiary-active-icon)",
    "variable": "var(--colors-button-color-tertiary-active-icon)"
  },
  "colors.button-color-tertiary-focus-background": {
    "value": "var(--colors-button-color-tertiary-focus-background)",
    "variable": "var(--colors-button-color-tertiary-focus-background)"
  },
  "colors.button-color-tertiary-focus-text": {
    "value": "var(--colors-button-color-tertiary-focus-text)",
    "variable": "var(--colors-button-color-tertiary-focus-text)"
  },
  "colors.button-color-tertiary-focus-border": {
    "value": "var(--colors-button-color-tertiary-focus-border)",
    "variable": "var(--colors-button-color-tertiary-focus-border)"
  },
  "colors.button-color-tertiary-focus-icon": {
    "value": "var(--colors-button-color-tertiary-focus-icon)",
    "variable": "var(--colors-button-color-tertiary-focus-icon)"
  },
  "colors.button-color-tertiary-disabled-background": {
    "value": "var(--colors-button-color-tertiary-disabled-background)",
    "variable": "var(--colors-button-color-tertiary-disabled-background)"
  },
  "colors.button-color-tertiary-disabled-text": {
    "value": "var(--colors-button-color-tertiary-disabled-text)",
    "variable": "var(--colors-button-color-tertiary-disabled-text)"
  },
  "colors.button-color-tertiary-disabled-icon": {
    "value": "var(--colors-button-color-tertiary-disabled-icon)",
    "variable": "var(--colors-button-color-tertiary-disabled-icon)"
  },
  "colors.button-color-ghost-default-text": {
    "value": "var(--colors-button-color-ghost-default-text)",
    "variable": "var(--colors-button-color-ghost-default-text)"
  },
  "colors.button-color-ghost-default-border": {
    "value": "var(--colors-button-color-ghost-default-border)",
    "variable": "var(--colors-button-color-ghost-default-border)"
  },
  "colors.button-color-ghost-default-icon": {
    "value": "var(--colors-button-color-ghost-default-icon)",
    "variable": "var(--colors-button-color-ghost-default-icon)"
  },
  "colors.button-color-ghost-hover-background": {
    "value": "var(--colors-button-color-ghost-hover-background)",
    "variable": "var(--colors-button-color-ghost-hover-background)"
  },
  "colors.button-color-ghost-hover-text": {
    "value": "var(--colors-button-color-ghost-hover-text)",
    "variable": "var(--colors-button-color-ghost-hover-text)"
  },
  "colors.button-color-ghost-hover-icon": {
    "value": "var(--colors-button-color-ghost-hover-icon)",
    "variable": "var(--colors-button-color-ghost-hover-icon)"
  },
  "colors.button-color-ghost-active-background": {
    "value": "var(--colors-button-color-ghost-active-background)",
    "variable": "var(--colors-button-color-ghost-active-background)"
  },
  "colors.button-color-ghost-active-text": {
    "value": "var(--colors-button-color-ghost-active-text)",
    "variable": "var(--colors-button-color-ghost-active-text)"
  },
  "colors.button-color-ghost-active-icon": {
    "value": "var(--colors-button-color-ghost-active-icon)",
    "variable": "var(--colors-button-color-ghost-active-icon)"
  },
  "colors.button-color-ghost-focus-text": {
    "value": "var(--colors-button-color-ghost-focus-text)",
    "variable": "var(--colors-button-color-ghost-focus-text)"
  },
  "colors.button-color-ghost-focus-border": {
    "value": "var(--colors-button-color-ghost-focus-border)",
    "variable": "var(--colors-button-color-ghost-focus-border)"
  },
  "colors.button-color-ghost-focus-icon": {
    "value": "var(--colors-button-color-ghost-focus-icon)",
    "variable": "var(--colors-button-color-ghost-focus-icon)"
  },
  "colors.button-color-ghost-disabled-background": {
    "value": "var(--colors-button-color-ghost-disabled-background)",
    "variable": "var(--colors-button-color-ghost-disabled-background)"
  },
  "colors.button-color-ghost-disabled-text": {
    "value": "var(--colors-button-color-ghost-disabled-text)",
    "variable": "var(--colors-button-color-ghost-disabled-text)"
  },
  "colors.button-color-ghost-disabled-icon": {
    "value": "var(--colors-button-color-ghost-disabled-icon)",
    "variable": "var(--colors-button-color-ghost-disabled-icon)"
  },
  "colors.button-color-primary-reverse-default-background": {
    "value": "var(--colors-button-color-primary-reverse-default-background)",
    "variable": "var(--colors-button-color-primary-reverse-default-background)"
  },
  "colors.button-color-primary-reverse-default-text": {
    "value": "var(--colors-button-color-primary-reverse-default-text)",
    "variable": "var(--colors-button-color-primary-reverse-default-text)"
  },
  "colors.button-color-primary-reverse-default-icon": {
    "value": "var(--colors-button-color-primary-reverse-default-icon)",
    "variable": "var(--colors-button-color-primary-reverse-default-icon)"
  },
  "colors.button-color-primary-reverse-hover-background": {
    "value": "var(--colors-button-color-primary-reverse-hover-background)",
    "variable": "var(--colors-button-color-primary-reverse-hover-background)"
  },
  "colors.button-color-primary-reverse-hover-text": {
    "value": "var(--colors-button-color-primary-reverse-hover-text)",
    "variable": "var(--colors-button-color-primary-reverse-hover-text)"
  },
  "colors.button-color-primary-reverse-hover-icon": {
    "value": "var(--colors-button-color-primary-reverse-hover-icon)",
    "variable": "var(--colors-button-color-primary-reverse-hover-icon)"
  },
  "colors.button-color-primary-reverse-active-background": {
    "value": "var(--colors-button-color-primary-reverse-active-background)",
    "variable": "var(--colors-button-color-primary-reverse-active-background)"
  },
  "colors.button-color-primary-reverse-active-text": {
    "value": "var(--colors-button-color-primary-reverse-active-text)",
    "variable": "var(--colors-button-color-primary-reverse-active-text)"
  },
  "colors.button-color-primary-reverse-active-icon": {
    "value": "var(--colors-button-color-primary-reverse-active-icon)",
    "variable": "var(--colors-button-color-primary-reverse-active-icon)"
  },
  "colors.button-color-primary-reverse-focus-background": {
    "value": "var(--colors-button-color-primary-reverse-focus-background)",
    "variable": "var(--colors-button-color-primary-reverse-focus-background)"
  },
  "colors.button-color-primary-reverse-focus-text": {
    "value": "var(--colors-button-color-primary-reverse-focus-text)",
    "variable": "var(--colors-button-color-primary-reverse-focus-text)"
  },
  "colors.button-color-primary-reverse-focus-icon": {
    "value": "var(--colors-button-color-primary-reverse-focus-icon)",
    "variable": "var(--colors-button-color-primary-reverse-focus-icon)"
  },
  "colors.button-color-primary-reverse-selected-background": {
    "value": "var(--colors-button-color-primary-reverse-selected-background)",
    "variable": "var(--colors-button-color-primary-reverse-selected-background)"
  },
  "colors.button-color-primary-reverse-selected-text": {
    "value": "var(--colors-button-color-primary-reverse-selected-text)",
    "variable": "var(--colors-button-color-primary-reverse-selected-text)"
  },
  "colors.button-color-primary-reverse-selected-icon": {
    "value": "var(--colors-button-color-primary-reverse-selected-icon)",
    "variable": "var(--colors-button-color-primary-reverse-selected-icon)"
  },
  "colors.button-color-primary-reverse-disabled-background": {
    "value": "var(--colors-button-color-primary-reverse-disabled-background)",
    "variable": "var(--colors-button-color-primary-reverse-disabled-background)"
  },
  "colors.button-color-primary-reverse-disabled-text": {
    "value": "var(--colors-button-color-primary-reverse-disabled-text)",
    "variable": "var(--colors-button-color-primary-reverse-disabled-text)"
  },
  "colors.button-color-primary-reverse-disabled-icon": {
    "value": "var(--colors-button-color-primary-reverse-disabled-icon)",
    "variable": "var(--colors-button-color-primary-reverse-disabled-icon)"
  },
  "colors.button-color-enhanced-primary-reverse-default-background": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-default-background)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-default-background)"
  },
  "colors.button-color-enhanced-primary-reverse-default-text": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-default-text)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-default-text)"
  },
  "colors.button-color-enhanced-primary-reverse-default-icon": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-default-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-default-icon)"
  },
  "colors.button-color-enhanced-primary-reverse-hover-background": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-hover-background)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-hover-background)"
  },
  "colors.button-color-enhanced-primary-reverse-hover-text": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-hover-text)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-hover-text)"
  },
  "colors.button-color-enhanced-primary-reverse-hover-icon": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-hover-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-hover-icon)"
  },
  "colors.button-color-enhanced-primary-reverse-active-background": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-active-background)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-active-background)"
  },
  "colors.button-color-enhanced-primary-reverse-active-text": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-active-text)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-active-text)"
  },
  "colors.button-color-enhanced-primary-reverse-active-icon": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-active-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-active-icon)"
  },
  "colors.button-color-enhanced-primary-reverse-focus-background": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-focus-background)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-focus-background)"
  },
  "colors.button-color-enhanced-primary-reverse-focus-text": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-focus-text)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-focus-text)"
  },
  "colors.button-color-enhanced-primary-reverse-focus-icon": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-focus-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-focus-icon)"
  },
  "colors.button-color-enhanced-primary-reverse-selected-background": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-selected-background)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-selected-background)"
  },
  "colors.button-color-enhanced-primary-reverse-selected-text": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-selected-text)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-selected-text)"
  },
  "colors.button-color-enhanced-primary-reverse-selected-icon": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-selected-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-selected-icon)"
  },
  "colors.button-color-enhanced-primary-reverse-disabled-background": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-disabled-background)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-disabled-background)"
  },
  "colors.button-color-enhanced-primary-reverse-disabled-text": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-disabled-text)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-disabled-text)"
  },
  "colors.button-color-enhanced-primary-reverse-disabled-icon": {
    "value": "var(--colors-button-color-enhanced-primary-reverse-disabled-icon)",
    "variable": "var(--colors-button-color-enhanced-primary-reverse-disabled-icon)"
  },
  "colors.button-color-secondary-reverse-default-background": {
    "value": "var(--colors-button-color-secondary-reverse-default-background)",
    "variable": "var(--colors-button-color-secondary-reverse-default-background)"
  },
  "colors.button-color-secondary-reverse-default-text": {
    "value": "var(--colors-button-color-secondary-reverse-default-text)",
    "variable": "var(--colors-button-color-secondary-reverse-default-text)"
  },
  "colors.button-color-secondary-reverse-default-icon": {
    "value": "var(--colors-button-color-secondary-reverse-default-icon)",
    "variable": "var(--colors-button-color-secondary-reverse-default-icon)"
  },
  "colors.button-color-secondary-reverse-hover-background": {
    "value": "var(--colors-button-color-secondary-reverse-hover-background)",
    "variable": "var(--colors-button-color-secondary-reverse-hover-background)"
  },
  "colors.button-color-secondary-reverse-hover-text": {
    "value": "var(--colors-button-color-secondary-reverse-hover-text)",
    "variable": "var(--colors-button-color-secondary-reverse-hover-text)"
  },
  "colors.button-color-secondary-reverse-hover-icon": {
    "value": "var(--colors-button-color-secondary-reverse-hover-icon)",
    "variable": "var(--colors-button-color-secondary-reverse-hover-icon)"
  },
  "colors.button-color-secondary-reverse-active-background": {
    "value": "var(--colors-button-color-secondary-reverse-active-background)",
    "variable": "var(--colors-button-color-secondary-reverse-active-background)"
  },
  "colors.button-color-secondary-reverse-active-text": {
    "value": "var(--colors-button-color-secondary-reverse-active-text)",
    "variable": "var(--colors-button-color-secondary-reverse-active-text)"
  },
  "colors.button-color-secondary-reverse-active-icon": {
    "value": "var(--colors-button-color-secondary-reverse-active-icon)",
    "variable": "var(--colors-button-color-secondary-reverse-active-icon)"
  },
  "colors.button-color-secondary-reverse-focus-background": {
    "value": "var(--colors-button-color-secondary-reverse-focus-background)",
    "variable": "var(--colors-button-color-secondary-reverse-focus-background)"
  },
  "colors.button-color-secondary-reverse-focus-text": {
    "value": "var(--colors-button-color-secondary-reverse-focus-text)",
    "variable": "var(--colors-button-color-secondary-reverse-focus-text)"
  },
  "colors.button-color-secondary-reverse-focus-icon": {
    "value": "var(--colors-button-color-secondary-reverse-focus-icon)",
    "variable": "var(--colors-button-color-secondary-reverse-focus-icon)"
  },
  "colors.button-color-secondary-reverse-disabled-background": {
    "value": "var(--colors-button-color-secondary-reverse-disabled-background)",
    "variable": "var(--colors-button-color-secondary-reverse-disabled-background)"
  },
  "colors.button-color-secondary-reverse-disabled-text": {
    "value": "var(--colors-button-color-secondary-reverse-disabled-text)",
    "variable": "var(--colors-button-color-secondary-reverse-disabled-text)"
  },
  "colors.button-color-secondary-reverse-disabled-icon": {
    "value": "var(--colors-button-color-secondary-reverse-disabled-icon)",
    "variable": "var(--colors-button-color-secondary-reverse-disabled-icon)"
  },
  "colors.button-color-tertiary-reverse-default-background": {
    "value": "var(--colors-button-color-tertiary-reverse-default-background)",
    "variable": "var(--colors-button-color-tertiary-reverse-default-background)"
  },
  "colors.button-color-tertiary-reverse-default-text": {
    "value": "var(--colors-button-color-tertiary-reverse-default-text)",
    "variable": "var(--colors-button-color-tertiary-reverse-default-text)"
  },
  "colors.button-color-tertiary-reverse-default-border": {
    "value": "var(--colors-button-color-tertiary-reverse-default-border)",
    "variable": "var(--colors-button-color-tertiary-reverse-default-border)"
  },
  "colors.button-color-tertiary-reverse-default-icon": {
    "value": "var(--colors-button-color-tertiary-reverse-default-icon)",
    "variable": "var(--colors-button-color-tertiary-reverse-default-icon)"
  },
  "colors.button-color-tertiary-reverse-hover-background": {
    "value": "var(--colors-button-color-tertiary-reverse-hover-background)",
    "variable": "var(--colors-button-color-tertiary-reverse-hover-background)"
  },
  "colors.button-color-tertiary-reverse-hover-text": {
    "value": "var(--colors-button-color-tertiary-reverse-hover-text)",
    "variable": "var(--colors-button-color-tertiary-reverse-hover-text)"
  },
  "colors.button-color-tertiary-reverse-hover-icon": {
    "value": "var(--colors-button-color-tertiary-reverse-hover-icon)",
    "variable": "var(--colors-button-color-tertiary-reverse-hover-icon)"
  },
  "colors.button-color-tertiary-reverse-active-background": {
    "value": "var(--colors-button-color-tertiary-reverse-active-background)",
    "variable": "var(--colors-button-color-tertiary-reverse-active-background)"
  },
  "colors.button-color-tertiary-reverse-active-text": {
    "value": "var(--colors-button-color-tertiary-reverse-active-text)",
    "variable": "var(--colors-button-color-tertiary-reverse-active-text)"
  },
  "colors.button-color-tertiary-reverse-active-icon": {
    "value": "var(--colors-button-color-tertiary-reverse-active-icon)",
    "variable": "var(--colors-button-color-tertiary-reverse-active-icon)"
  },
  "colors.button-color-tertiary-reverse-focus-background": {
    "value": "var(--colors-button-color-tertiary-reverse-focus-background)",
    "variable": "var(--colors-button-color-tertiary-reverse-focus-background)"
  },
  "colors.button-color-tertiary-reverse-focus-text": {
    "value": "var(--colors-button-color-tertiary-reverse-focus-text)",
    "variable": "var(--colors-button-color-tertiary-reverse-focus-text)"
  },
  "colors.button-color-tertiary-reverse-focus-border": {
    "value": "var(--colors-button-color-tertiary-reverse-focus-border)",
    "variable": "var(--colors-button-color-tertiary-reverse-focus-border)"
  },
  "colors.button-color-tertiary-reverse-focus-icon": {
    "value": "var(--colors-button-color-tertiary-reverse-focus-icon)",
    "variable": "var(--colors-button-color-tertiary-reverse-focus-icon)"
  },
  "colors.button-color-tertiary-reverse-disabled-background": {
    "value": "var(--colors-button-color-tertiary-reverse-disabled-background)",
    "variable": "var(--colors-button-color-tertiary-reverse-disabled-background)"
  },
  "colors.button-color-tertiary-reverse-disabled-text": {
    "value": "var(--colors-button-color-tertiary-reverse-disabled-text)",
    "variable": "var(--colors-button-color-tertiary-reverse-disabled-text)"
  },
  "colors.button-color-tertiary-reverse-disabled-icon": {
    "value": "var(--colors-button-color-tertiary-reverse-disabled-icon)",
    "variable": "var(--colors-button-color-tertiary-reverse-disabled-icon)"
  },
  "colors.button-color-ghost-reverse-default-text": {
    "value": "var(--colors-button-color-ghost-reverse-default-text)",
    "variable": "var(--colors-button-color-ghost-reverse-default-text)"
  },
  "colors.button-color-ghost-reverse-default-border": {
    "value": "var(--colors-button-color-ghost-reverse-default-border)",
    "variable": "var(--colors-button-color-ghost-reverse-default-border)"
  },
  "colors.button-color-ghost-reverse-default-icon": {
    "value": "var(--colors-button-color-ghost-reverse-default-icon)",
    "variable": "var(--colors-button-color-ghost-reverse-default-icon)"
  },
  "colors.button-color-ghost-reverse-hover-background": {
    "value": "var(--colors-button-color-ghost-reverse-hover-background)",
    "variable": "var(--colors-button-color-ghost-reverse-hover-background)"
  },
  "colors.button-color-ghost-reverse-hover-text": {
    "value": "var(--colors-button-color-ghost-reverse-hover-text)",
    "variable": "var(--colors-button-color-ghost-reverse-hover-text)"
  },
  "colors.button-color-ghost-reverse-hover-icon": {
    "value": "var(--colors-button-color-ghost-reverse-hover-icon)",
    "variable": "var(--colors-button-color-ghost-reverse-hover-icon)"
  },
  "colors.button-color-ghost-reverse-active-background": {
    "value": "var(--colors-button-color-ghost-reverse-active-background)",
    "variable": "var(--colors-button-color-ghost-reverse-active-background)"
  },
  "colors.button-color-ghost-reverse-active-text": {
    "value": "var(--colors-button-color-ghost-reverse-active-text)",
    "variable": "var(--colors-button-color-ghost-reverse-active-text)"
  },
  "colors.button-color-ghost-reverse-active-icon": {
    "value": "var(--colors-button-color-ghost-reverse-active-icon)",
    "variable": "var(--colors-button-color-ghost-reverse-active-icon)"
  },
  "colors.button-color-ghost-reverse-focus-text": {
    "value": "var(--colors-button-color-ghost-reverse-focus-text)",
    "variable": "var(--colors-button-color-ghost-reverse-focus-text)"
  },
  "colors.button-color-ghost-reverse-focus-border": {
    "value": "var(--colors-button-color-ghost-reverse-focus-border)",
    "variable": "var(--colors-button-color-ghost-reverse-focus-border)"
  },
  "colors.button-color-ghost-reverse-focus-icon": {
    "value": "var(--colors-button-color-ghost-reverse-focus-icon)",
    "variable": "var(--colors-button-color-ghost-reverse-focus-icon)"
  },
  "colors.button-color-ghost-reverse-disabled-background": {
    "value": "var(--colors-button-color-ghost-reverse-disabled-background)",
    "variable": "var(--colors-button-color-ghost-reverse-disabled-background)"
  },
  "colors.button-color-ghost-reverse-disabled-text": {
    "value": "var(--colors-button-color-ghost-reverse-disabled-text)",
    "variable": "var(--colors-button-color-ghost-reverse-disabled-text)"
  },
  "colors.button-color-ghost-reverse-disabled-icon": {
    "value": "var(--colors-button-color-ghost-reverse-disabled-icon)",
    "variable": "var(--colors-button-color-ghost-reverse-disabled-icon)"
  },
  "colors.button-color-overlay-default-background": {
    "value": "var(--colors-button-color-overlay-default-background)",
    "variable": "var(--colors-button-color-overlay-default-background)"
  },
  "colors.button-color-overlay-default-text": {
    "value": "var(--colors-button-color-overlay-default-text)",
    "variable": "var(--colors-button-color-overlay-default-text)"
  },
  "colors.button-color-overlay-default-icon": {
    "value": "var(--colors-button-color-overlay-default-icon)",
    "variable": "var(--colors-button-color-overlay-default-icon)"
  },
  "colors.button-color-overlay-hover-background": {
    "value": "var(--colors-button-color-overlay-hover-background)",
    "variable": "var(--colors-button-color-overlay-hover-background)"
  },
  "colors.button-color-overlay-hover-text": {
    "value": "var(--colors-button-color-overlay-hover-text)",
    "variable": "var(--colors-button-color-overlay-hover-text)"
  },
  "colors.button-color-overlay-hover-icon": {
    "value": "var(--colors-button-color-overlay-hover-icon)",
    "variable": "var(--colors-button-color-overlay-hover-icon)"
  },
  "colors.button-color-overlay-active-background": {
    "value": "var(--colors-button-color-overlay-active-background)",
    "variable": "var(--colors-button-color-overlay-active-background)"
  },
  "colors.button-color-overlay-active-text": {
    "value": "var(--colors-button-color-overlay-active-text)",
    "variable": "var(--colors-button-color-overlay-active-text)"
  },
  "colors.button-color-overlay-active-icon": {
    "value": "var(--colors-button-color-overlay-active-icon)",
    "variable": "var(--colors-button-color-overlay-active-icon)"
  },
  "colors.button-color-overlay-focus-background": {
    "value": "var(--colors-button-color-overlay-focus-background)",
    "variable": "var(--colors-button-color-overlay-focus-background)"
  },
  "colors.button-color-overlay-focus-text": {
    "value": "var(--colors-button-color-overlay-focus-text)",
    "variable": "var(--colors-button-color-overlay-focus-text)"
  },
  "colors.button-color-overlay-focus-icon": {
    "value": "var(--colors-button-color-overlay-focus-icon)",
    "variable": "var(--colors-button-color-overlay-focus-icon)"
  },
  "colors.button-color-overlay-disabled-background": {
    "value": "var(--colors-button-color-overlay-disabled-background)",
    "variable": "var(--colors-button-color-overlay-disabled-background)"
  },
  "colors.button-color-overlay-disabled-text": {
    "value": "var(--colors-button-color-overlay-disabled-text)",
    "variable": "var(--colors-button-color-overlay-disabled-text)"
  },
  "colors.button-color-overlay-disabled-icon": {
    "value": "var(--colors-button-color-overlay-disabled-icon)",
    "variable": "var(--colors-button-color-overlay-disabled-icon)"
  },
  "colors.button-color-overlay-reverse-default-background": {
    "value": "var(--colors-button-color-overlay-reverse-default-background)",
    "variable": "var(--colors-button-color-overlay-reverse-default-background)"
  },
  "colors.button-color-overlay-reverse-default-text": {
    "value": "var(--colors-button-color-overlay-reverse-default-text)",
    "variable": "var(--colors-button-color-overlay-reverse-default-text)"
  },
  "colors.button-color-overlay-reverse-default-icon": {
    "value": "var(--colors-button-color-overlay-reverse-default-icon)",
    "variable": "var(--colors-button-color-overlay-reverse-default-icon)"
  },
  "colors.button-color-overlay-reverse-hover-background": {
    "value": "var(--colors-button-color-overlay-reverse-hover-background)",
    "variable": "var(--colors-button-color-overlay-reverse-hover-background)"
  },
  "colors.button-color-overlay-reverse-hover-text": {
    "value": "var(--colors-button-color-overlay-reverse-hover-text)",
    "variable": "var(--colors-button-color-overlay-reverse-hover-text)"
  },
  "colors.button-color-overlay-reverse-hover-icon": {
    "value": "var(--colors-button-color-overlay-reverse-hover-icon)",
    "variable": "var(--colors-button-color-overlay-reverse-hover-icon)"
  },
  "colors.button-color-overlay-reverse-active-background": {
    "value": "var(--colors-button-color-overlay-reverse-active-background)",
    "variable": "var(--colors-button-color-overlay-reverse-active-background)"
  },
  "colors.button-color-overlay-reverse-active-text": {
    "value": "var(--colors-button-color-overlay-reverse-active-text)",
    "variable": "var(--colors-button-color-overlay-reverse-active-text)"
  },
  "colors.button-color-overlay-reverse-active-icon": {
    "value": "var(--colors-button-color-overlay-reverse-active-icon)",
    "variable": "var(--colors-button-color-overlay-reverse-active-icon)"
  },
  "colors.button-color-overlay-reverse-focus-background": {
    "value": "var(--colors-button-color-overlay-reverse-focus-background)",
    "variable": "var(--colors-button-color-overlay-reverse-focus-background)"
  },
  "colors.button-color-overlay-reverse-focus-text": {
    "value": "var(--colors-button-color-overlay-reverse-focus-text)",
    "variable": "var(--colors-button-color-overlay-reverse-focus-text)"
  },
  "colors.button-color-overlay-reverse-focus-icon": {
    "value": "var(--colors-button-color-overlay-reverse-focus-icon)",
    "variable": "var(--colors-button-color-overlay-reverse-focus-icon)"
  },
  "colors.button-color-overlay-reverse-disabled-background": {
    "value": "var(--colors-button-color-overlay-reverse-disabled-background)",
    "variable": "var(--colors-button-color-overlay-reverse-disabled-background)"
  },
  "colors.button-color-overlay-reverse-disabled-text": {
    "value": "var(--colors-button-color-overlay-reverse-disabled-text)",
    "variable": "var(--colors-button-color-overlay-reverse-disabled-text)"
  },
  "colors.button-color-overlay-reverse-disabled-icon": {
    "value": "var(--colors-button-color-overlay-reverse-disabled-icon)",
    "variable": "var(--colors-button-color-overlay-reverse-disabled-icon)"
  },
  "colors.inclusions-color-heading": {
    "value": "var(--colors-inclusions-color-heading)",
    "variable": "var(--colors-inclusions-color-heading)"
  },
  "colors.inclusions-color-body": {
    "value": "var(--colors-inclusions-color-body)",
    "variable": "var(--colors-inclusions-color-body)"
  },
  "colors.inclusions-color-icon": {
    "value": "var(--colors-inclusions-color-icon)",
    "variable": "var(--colors-inclusions-color-icon)"
  },
  "colors.inclusions-color-background": {
    "value": "var(--colors-inclusions-color-background)",
    "variable": "var(--colors-inclusions-color-background)"
  },
  "colors.pricepoint-color-default-label": {
    "value": "var(--colors-pricepoint-color-default-label)",
    "variable": "var(--colors-pricepoint-color-default-label)"
  },
  "colors.pricepoint-color-default-strikethrough": {
    "value": "var(--colors-pricepoint-color-default-strikethrough)",
    "variable": "var(--colors-pricepoint-color-default-strikethrough)"
  },
  "colors.pricepoint-color-default-price": {
    "value": "var(--colors-pricepoint-color-default-price)",
    "variable": "var(--colors-pricepoint-color-default-price)"
  },
  "colors.pricepoint-color-reverse-label": {
    "value": "var(--colors-pricepoint-color-reverse-label)",
    "variable": "var(--colors-pricepoint-color-reverse-label)"
  },
  "colors.pricepoint-color-reverse-strikethrough": {
    "value": "var(--colors-pricepoint-color-reverse-strikethrough)",
    "variable": "var(--colors-pricepoint-color-reverse-strikethrough)"
  },
  "colors.pricepoint-color-reverse-price": {
    "value": "var(--colors-pricepoint-color-reverse-price)",
    "variable": "var(--colors-pricepoint-color-reverse-price)"
  },
  "colors.pricepoint-color-negative-label": {
    "value": "var(--colors-pricepoint-color-negative-label)",
    "variable": "var(--colors-pricepoint-color-negative-label)"
  },
  "colors.pricepoint-color-negative-strikethrough": {
    "value": "var(--colors-pricepoint-color-negative-strikethrough)",
    "variable": "var(--colors-pricepoint-color-negative-strikethrough)"
  },
  "colors.pricepoint-color-negative-price": {
    "value": "var(--colors-pricepoint-color-negative-price)",
    "variable": "var(--colors-pricepoint-color-negative-price)"
  },
  "colors.tripcard-color-default-label": {
    "value": "var(--colors-tripcard-color-default-label)",
    "variable": "var(--colors-tripcard-color-default-label)"
  },
  "colors.tripcard-color-default-route": {
    "value": "var(--colors-tripcard-color-default-route)",
    "variable": "var(--colors-tripcard-color-default-route)"
  },
  "colors.tripcard-color-default-title": {
    "value": "var(--colors-tripcard-color-default-title)",
    "variable": "var(--colors-tripcard-color-default-title)"
  },
  "colors.tripcard-color-reverse-label": {
    "value": "var(--colors-tripcard-color-reverse-label)",
    "variable": "var(--colors-tripcard-color-reverse-label)"
  },
  "colors.tripcard-color-reverse-route": {
    "value": "var(--colors-tripcard-color-reverse-route)",
    "variable": "var(--colors-tripcard-color-reverse-route)"
  },
  "colors.tripcard-color-reverse-title": {
    "value": "var(--colors-tripcard-color-reverse-title)",
    "variable": "var(--colors-tripcard-color-reverse-title)"
  },
  "colors.primary-navigation-color-default-bg": {
    "value": "var(--colors-primary-navigation-color-default-bg)",
    "variable": "var(--colors-primary-navigation-color-default-bg)"
  },
  "colors.primary-navigation-color-default-border": {
    "value": "var(--colors-primary-navigation-color-default-border)",
    "variable": "var(--colors-primary-navigation-color-default-border)"
  },
  "colors.primary-navigation-color-default-nav-item-default": {
    "value": "var(--colors-primary-navigation-color-default-nav-item-default)",
    "variable": "var(--colors-primary-navigation-color-default-nav-item-default)"
  },
  "colors.primary-navigation-color-default-nav-item-hover": {
    "value": "var(--colors-primary-navigation-color-default-nav-item-hover)",
    "variable": "var(--colors-primary-navigation-color-default-nav-item-hover)"
  },
  "colors.primary-navigation-color-default-nav-item-active": {
    "value": "var(--colors-primary-navigation-color-default-nav-item-active)",
    "variable": "var(--colors-primary-navigation-color-default-nav-item-active)"
  },
  "colors.primary-navigation-color-default-category-label-default": {
    "value": "var(--colors-primary-navigation-color-default-category-label-default)",
    "variable": "var(--colors-primary-navigation-color-default-category-label-default)"
  },
  "colors.primary-navigation-color-default-category-label-hover": {
    "value": "var(--colors-primary-navigation-color-default-category-label-hover)",
    "variable": "var(--colors-primary-navigation-color-default-category-label-hover)"
  },
  "colors.primary-navigation-color-default-category-label-active": {
    "value": "var(--colors-primary-navigation-color-default-category-label-active)",
    "variable": "var(--colors-primary-navigation-color-default-category-label-active)"
  },
  "colors.primary-navigation-color-default-category-item-default": {
    "value": "var(--colors-primary-navigation-color-default-category-item-default)",
    "variable": "var(--colors-primary-navigation-color-default-category-item-default)"
  },
  "colors.primary-navigation-color-default-category-item-default-bg": {
    "value": "var(--colors-primary-navigation-color-default-category-item-default-bg)",
    "variable": "var(--colors-primary-navigation-color-default-category-item-default-bg)"
  },
  "colors.primary-navigation-color-default-category-item-hover": {
    "value": "var(--colors-primary-navigation-color-default-category-item-hover)",
    "variable": "var(--colors-primary-navigation-color-default-category-item-hover)"
  },
  "colors.primary-navigation-color-default-category-item-hover-bg": {
    "value": "var(--colors-primary-navigation-color-default-category-item-hover-bg)",
    "variable": "var(--colors-primary-navigation-color-default-category-item-hover-bg)"
  },
  "colors.primary-navigation-color-default-category-item-active": {
    "value": "var(--colors-primary-navigation-color-default-category-item-active)",
    "variable": "var(--colors-primary-navigation-color-default-category-item-active)"
  },
  "colors.primary-navigation-color-default-category-item-active-bg": {
    "value": "var(--colors-primary-navigation-color-default-category-item-active-bg)",
    "variable": "var(--colors-primary-navigation-color-default-category-item-active-bg)"
  },
  "colors.primary-navigation-color-reverse-bg": {
    "value": "var(--colors-primary-navigation-color-reverse-bg)",
    "variable": "var(--colors-primary-navigation-color-reverse-bg)"
  },
  "colors.primary-navigation-color-reverse-border": {
    "value": "var(--colors-primary-navigation-color-reverse-border)",
    "variable": "var(--colors-primary-navigation-color-reverse-border)"
  },
  "colors.primary-navigation-color-reverse-nav-item-default": {
    "value": "var(--colors-primary-navigation-color-reverse-nav-item-default)",
    "variable": "var(--colors-primary-navigation-color-reverse-nav-item-default)"
  },
  "colors.primary-navigation-color-reverse-nav-item-hover": {
    "value": "var(--colors-primary-navigation-color-reverse-nav-item-hover)",
    "variable": "var(--colors-primary-navigation-color-reverse-nav-item-hover)"
  },
  "colors.primary-navigation-color-reverse-nav-item-active": {
    "value": "var(--colors-primary-navigation-color-reverse-nav-item-active)",
    "variable": "var(--colors-primary-navigation-color-reverse-nav-item-active)"
  },
  "textStyles.semantic-heading-standard-4xl": {
    "value": "var(--text-styles-semantic-heading-standard-4xl)",
    "variable": "var(--text-styles-semantic-heading-standard-4xl)"
  },
  "textStyles.semantic-heading-standard-3xl": {
    "value": "var(--text-styles-semantic-heading-standard-3xl)",
    "variable": "var(--text-styles-semantic-heading-standard-3xl)"
  },
  "textStyles.semantic-heading-standard-2xl": {
    "value": "var(--text-styles-semantic-heading-standard-2xl)",
    "variable": "var(--text-styles-semantic-heading-standard-2xl)"
  },
  "textStyles.semantic-heading-standard-xl": {
    "value": "var(--text-styles-semantic-heading-standard-xl)",
    "variable": "var(--text-styles-semantic-heading-standard-xl)"
  },
  "textStyles.semantic-heading-standard-lg": {
    "value": "var(--text-styles-semantic-heading-standard-lg)",
    "variable": "var(--text-styles-semantic-heading-standard-lg)"
  },
  "textStyles.semantic-heading-standard-md": {
    "value": "var(--text-styles-semantic-heading-standard-md)",
    "variable": "var(--text-styles-semantic-heading-standard-md)"
  },
  "textStyles.semantic-heading-standard-sm": {
    "value": "var(--text-styles-semantic-heading-standard-sm)",
    "variable": "var(--text-styles-semantic-heading-standard-sm)"
  },
  "textStyles.semantic-heading-standard-xs": {
    "value": "var(--text-styles-semantic-heading-standard-xs)",
    "variable": "var(--text-styles-semantic-heading-standard-xs)"
  },
  "textStyles.semantic-heading-pullout-4xl": {
    "value": "var(--text-styles-semantic-heading-pullout-4xl)",
    "variable": "var(--text-styles-semantic-heading-pullout-4xl)"
  },
  "textStyles.semantic-heading-pullout-3xl": {
    "value": "var(--text-styles-semantic-heading-pullout-3xl)",
    "variable": "var(--text-styles-semantic-heading-pullout-3xl)"
  },
  "textStyles.semantic-heading-pullout-2xl": {
    "value": "var(--text-styles-semantic-heading-pullout-2xl)",
    "variable": "var(--text-styles-semantic-heading-pullout-2xl)"
  },
  "textStyles.semantic-heading-pullout-xl": {
    "value": "var(--text-styles-semantic-heading-pullout-xl)",
    "variable": "var(--text-styles-semantic-heading-pullout-xl)"
  },
  "textStyles.semantic-heading-pullout-lg": {
    "value": "var(--text-styles-semantic-heading-pullout-lg)",
    "variable": "var(--text-styles-semantic-heading-pullout-lg)"
  },
  "textStyles.semantic-heading-pullout-md": {
    "value": "var(--text-styles-semantic-heading-pullout-md)",
    "variable": "var(--text-styles-semantic-heading-pullout-md)"
  },
  "textStyles.semantic-heading-pullout-sm": {
    "value": "var(--text-styles-semantic-heading-pullout-sm)",
    "variable": "var(--text-styles-semantic-heading-pullout-sm)"
  },
  "textStyles.semantic-heading-pullout-xs": {
    "value": "var(--text-styles-semantic-heading-pullout-xs)",
    "variable": "var(--text-styles-semantic-heading-pullout-xs)"
  },
  "textStyles.semantic-heading-logotype-4xl": {
    "value": "var(--text-styles-semantic-heading-logotype-4xl)",
    "variable": "var(--text-styles-semantic-heading-logotype-4xl)"
  },
  "textStyles.semantic-heading-logotype-3xl": {
    "value": "var(--text-styles-semantic-heading-logotype-3xl)",
    "variable": "var(--text-styles-semantic-heading-logotype-3xl)"
  },
  "textStyles.semantic-heading-logotype-2xl": {
    "value": "var(--text-styles-semantic-heading-logotype-2xl)",
    "variable": "var(--text-styles-semantic-heading-logotype-2xl)"
  },
  "textStyles.semantic-heading-logotype-xl": {
    "value": "var(--text-styles-semantic-heading-logotype-xl)",
    "variable": "var(--text-styles-semantic-heading-logotype-xl)"
  },
  "textStyles.semantic-heading-logotype-lg": {
    "value": "var(--text-styles-semantic-heading-logotype-lg)",
    "variable": "var(--text-styles-semantic-heading-logotype-lg)"
  },
  "textStyles.semantic-heading-logotype-md": {
    "value": "var(--text-styles-semantic-heading-logotype-md)",
    "variable": "var(--text-styles-semantic-heading-logotype-md)"
  },
  "textStyles.semantic-heading-logotype-sm": {
    "value": "var(--text-styles-semantic-heading-logotype-sm)",
    "variable": "var(--text-styles-semantic-heading-logotype-sm)"
  },
  "textStyles.semantic-heading-logotype-xs": {
    "value": "var(--text-styles-semantic-heading-logotype-xs)",
    "variable": "var(--text-styles-semantic-heading-logotype-xs)"
  },
  "textStyles.semantic-heading-logotype-2xs": {
    "value": "var(--text-styles-semantic-heading-logotype-2xs)",
    "variable": "var(--text-styles-semantic-heading-logotype-2xs)"
  },
  "textStyles.semantic-heading-logotype-3xs": {
    "value": "var(--text-styles-semantic-heading-logotype-3xs)",
    "variable": "var(--text-styles-semantic-heading-logotype-3xs)"
  },
  "textStyles.semantic-heading-logotype-4xs": {
    "value": "var(--text-styles-semantic-heading-logotype-4xs)",
    "variable": "var(--text-styles-semantic-heading-logotype-4xs)"
  },
  "textStyles.semantic-text-3xs-font-regular": {
    "value": "var(--text-styles-semantic-text-3xs-font-regular)",
    "variable": "var(--text-styles-semantic-text-3xs-font-regular)"
  },
  "textStyles.semantic-text-3xs-font-regular-underline": {
    "value": "var(--text-styles-semantic-text-3xs-font-regular-underline)",
    "variable": "var(--text-styles-semantic-text-3xs-font-regular-underline)"
  },
  "textStyles.semantic-text-2xs-font-regular": {
    "value": "var(--text-styles-semantic-text-2xs-font-regular)",
    "variable": "var(--text-styles-semantic-text-2xs-font-regular)"
  },
  "textStyles.semantic-text-2xs-font-regular-strikethrough": {
    "value": "var(--text-styles-semantic-text-2xs-font-regular-strikethrough)",
    "variable": "var(--text-styles-semantic-text-2xs-font-regular-strikethrough)"
  },
  "textStyles.semantic-text-2xs-font-light": {
    "value": "var(--text-styles-semantic-text-2xs-font-light)",
    "variable": "var(--text-styles-semantic-text-2xs-font-light)"
  },
  "textStyles.semantic-text-2xs-font-bold": {
    "value": "var(--text-styles-semantic-text-2xs-font-bold)",
    "variable": "var(--text-styles-semantic-text-2xs-font-bold)"
  },
  "textStyles.semantic-text-xs-font-regular": {
    "value": "var(--text-styles-semantic-text-xs-font-regular)",
    "variable": "var(--text-styles-semantic-text-xs-font-regular)"
  },
  "textStyles.semantic-text-xs-font-regular-underline": {
    "value": "var(--text-styles-semantic-text-xs-font-regular-underline)",
    "variable": "var(--text-styles-semantic-text-xs-font-regular-underline)"
  },
  "textStyles.semantic-text-xs-font-regular-strikethrough": {
    "value": "var(--text-styles-semantic-text-xs-font-regular-strikethrough)",
    "variable": "var(--text-styles-semantic-text-xs-font-regular-strikethrough)"
  },
  "textStyles.semantic-text-xs-font-light": {
    "value": "var(--text-styles-semantic-text-xs-font-light)",
    "variable": "var(--text-styles-semantic-text-xs-font-light)"
  },
  "textStyles.semantic-text-xs-font-bold": {
    "value": "var(--text-styles-semantic-text-xs-font-bold)",
    "variable": "var(--text-styles-semantic-text-xs-font-bold)"
  },
  "textStyles.semantic-text-xs-font-bold-underline": {
    "value": "var(--text-styles-semantic-text-xs-font-bold-underline)",
    "variable": "var(--text-styles-semantic-text-xs-font-bold-underline)"
  },
  "textStyles.semantic-text-sm-font-regular": {
    "value": "var(--text-styles-semantic-text-sm-font-regular)",
    "variable": "var(--text-styles-semantic-text-sm-font-regular)"
  },
  "textStyles.semantic-text-sm-font-regular-strikethrough": {
    "value": "var(--text-styles-semantic-text-sm-font-regular-strikethrough)",
    "variable": "var(--text-styles-semantic-text-sm-font-regular-strikethrough)"
  },
  "textStyles.semantic-text-sm-font-regular-underline": {
    "value": "var(--text-styles-semantic-text-sm-font-regular-underline)",
    "variable": "var(--text-styles-semantic-text-sm-font-regular-underline)"
  },
  "textStyles.semantic-text-sm-font-light": {
    "value": "var(--text-styles-semantic-text-sm-font-light)",
    "variable": "var(--text-styles-semantic-text-sm-font-light)"
  },
  "textStyles.semantic-text-sm-font-bold": {
    "value": "var(--text-styles-semantic-text-sm-font-bold)",
    "variable": "var(--text-styles-semantic-text-sm-font-bold)"
  },
  "textStyles.semantic-text-sm-font-bold-underline": {
    "value": "var(--text-styles-semantic-text-sm-font-bold-underline)",
    "variable": "var(--text-styles-semantic-text-sm-font-bold-underline)"
  },
  "textStyles.semantic-text-md-font-regular": {
    "value": "var(--text-styles-semantic-text-md-font-regular)",
    "variable": "var(--text-styles-semantic-text-md-font-regular)"
  },
  "textStyles.semantic-text-md-font-regular-strikethrough": {
    "value": "var(--text-styles-semantic-text-md-font-regular-strikethrough)",
    "variable": "var(--text-styles-semantic-text-md-font-regular-strikethrough)"
  },
  "textStyles.semantic-text-md-font-regular-underline": {
    "value": "var(--text-styles-semantic-text-md-font-regular-underline)",
    "variable": "var(--text-styles-semantic-text-md-font-regular-underline)"
  },
  "textStyles.semantic-text-md-font-light": {
    "value": "var(--text-styles-semantic-text-md-font-light)",
    "variable": "var(--text-styles-semantic-text-md-font-light)"
  },
  "textStyles.semantic-text-md-font-bold": {
    "value": "var(--text-styles-semantic-text-md-font-bold)",
    "variable": "var(--text-styles-semantic-text-md-font-bold)"
  },
  "textStyles.semantic-text-md-font-bold-underline": {
    "value": "var(--text-styles-semantic-text-md-font-bold-underline)",
    "variable": "var(--text-styles-semantic-text-md-font-bold-underline)"
  },
  "textStyles.semantic-text-lg-font-regular": {
    "value": "var(--text-styles-semantic-text-lg-font-regular)",
    "variable": "var(--text-styles-semantic-text-lg-font-regular)"
  },
  "textStyles.semantic-text-lg-font-regular-underline": {
    "value": "var(--text-styles-semantic-text-lg-font-regular-underline)",
    "variable": "var(--text-styles-semantic-text-lg-font-regular-underline)"
  },
  "textStyles.semantic-text-lg-font-regular-spaced": {
    "value": "var(--text-styles-semantic-text-lg-font-regular-spaced)",
    "variable": "var(--text-styles-semantic-text-lg-font-regular-spaced)"
  },
  "textStyles.semantic-text-lg-font-light": {
    "value": "var(--text-styles-semantic-text-lg-font-light)",
    "variable": "var(--text-styles-semantic-text-lg-font-light)"
  },
  "textStyles.semantic-text-lg-font-bold": {
    "value": "var(--text-styles-semantic-text-lg-font-bold)",
    "variable": "var(--text-styles-semantic-text-lg-font-bold)"
  },
  "textStyles.semantic-text-lg-font-bold-underline": {
    "value": "var(--text-styles-semantic-text-lg-font-bold-underline)",
    "variable": "var(--text-styles-semantic-text-lg-font-bold-underline)"
  },
  "textStyles.semantic-text-xl-font-regular": {
    "value": "var(--text-styles-semantic-text-xl-font-regular)",
    "variable": "var(--text-styles-semantic-text-xl-font-regular)"
  },
  "textStyles.semantic-text-xl-font-regular-underline": {
    "value": "var(--text-styles-semantic-text-xl-font-regular-underline)",
    "variable": "var(--text-styles-semantic-text-xl-font-regular-underline)"
  },
  "textStyles.semantic-text-xl-font-regular-spaced": {
    "value": "var(--text-styles-semantic-text-xl-font-regular-spaced)",
    "variable": "var(--text-styles-semantic-text-xl-font-regular-spaced)"
  },
  "textStyles.semantic-text-xl-font-light": {
    "value": "var(--text-styles-semantic-text-xl-font-light)",
    "variable": "var(--text-styles-semantic-text-xl-font-light)"
  },
  "textStyles.semantic-text-xl-font-bold": {
    "value": "var(--text-styles-semantic-text-xl-font-bold)",
    "variable": "var(--text-styles-semantic-text-xl-font-bold)"
  },
  "textStyles.semantic-text-xl-font-bold-underline": {
    "value": "var(--text-styles-semantic-text-xl-font-bold-underline)",
    "variable": "var(--text-styles-semantic-text-xl-font-bold-underline)"
  },
  "textStyles.semantic-text-2xl-font-regular": {
    "value": "var(--text-styles-semantic-text-2xl-font-regular)",
    "variable": "var(--text-styles-semantic-text-2xl-font-regular)"
  },
  "textStyles.semantic-text-2xl-font-regular-underline": {
    "value": "var(--text-styles-semantic-text-2xl-font-regular-underline)",
    "variable": "var(--text-styles-semantic-text-2xl-font-regular-underline)"
  },
  "textStyles.semantic-text-2xl-font-light": {
    "value": "var(--text-styles-semantic-text-2xl-font-light)",
    "variable": "var(--text-styles-semantic-text-2xl-font-light)"
  },
  "textStyles.semantic-text-2xl-font-bold": {
    "value": "var(--text-styles-semantic-text-2xl-font-bold)",
    "variable": "var(--text-styles-semantic-text-2xl-font-bold)"
  },
  "textStyles.semantic-text-2xl-font-bold-underline": {
    "value": "var(--text-styles-semantic-text-2xl-font-bold-underline)",
    "variable": "var(--text-styles-semantic-text-2xl-font-bold-underline)"
  },
  "textStyles.semantic-text-3xl-font-regular": {
    "value": "var(--text-styles-semantic-text-3xl-font-regular)",
    "variable": "var(--text-styles-semantic-text-3xl-font-regular)"
  },
  "textStyles.semantic-text-3xl-font-light": {
    "value": "var(--text-styles-semantic-text-3xl-font-light)",
    "variable": "var(--text-styles-semantic-text-3xl-font-light)"
  },
  "textStyles.semantic-text-3xl-font-bold": {
    "value": "var(--text-styles-semantic-text-3xl-font-bold)",
    "variable": "var(--text-styles-semantic-text-3xl-font-bold)"
  },
  "textStyles.semantic-text-4xl-font-regular": {
    "value": "var(--text-styles-semantic-text-4xl-font-regular)",
    "variable": "var(--text-styles-semantic-text-4xl-font-regular)"
  },
  "textStyles.semantic-text-4xl-font-light": {
    "value": "var(--text-styles-semantic-text-4xl-font-light)",
    "variable": "var(--text-styles-semantic-text-4xl-font-light)"
  },
  "textStyles.semantic-text-4xl-font-bold": {
    "value": "var(--text-styles-semantic-text-4xl-font-bold)",
    "variable": "var(--text-styles-semantic-text-4xl-font-bold)"
  },
  "textStyles.semantic-text-5xl-font-regular": {
    "value": "var(--text-styles-semantic-text-5xl-font-regular)",
    "variable": "var(--text-styles-semantic-text-5xl-font-regular)"
  },
  "textStyles.semantic-text-5xl-font-light": {
    "value": "var(--text-styles-semantic-text-5xl-font-light)",
    "variable": "var(--text-styles-semantic-text-5xl-font-light)"
  },
  "textStyles.semantic-text-5xl-font-bold": {
    "value": "var(--text-styles-semantic-text-5xl-font-bold)",
    "variable": "var(--text-styles-semantic-text-5xl-font-bold)"
  },
  "textStyles.semantic-text-6xl-font-regular": {
    "value": "var(--text-styles-semantic-text-6xl-font-regular)",
    "variable": "var(--text-styles-semantic-text-6xl-font-regular)"
  },
  "textStyles.semantic-text-6xl-font-light": {
    "value": "var(--text-styles-semantic-text-6xl-font-light)",
    "variable": "var(--text-styles-semantic-text-6xl-font-light)"
  },
  "textStyles.semantic-text-6xl-font-bold": {
    "value": "var(--text-styles-semantic-text-6xl-font-bold)",
    "variable": "var(--text-styles-semantic-text-6xl-font-bold)"
  },
  "textStyles.button-label-lg": {
    "value": "var(--text-styles-button-label-lg)",
    "variable": "var(--text-styles-button-label-lg)"
  },
  "textStyles.button-label-md": {
    "value": "var(--text-styles-button-label-md)",
    "variable": "var(--text-styles-button-label-md)"
  },
  "textStyles.button-label-sm": {
    "value": "var(--text-styles-button-label-sm)",
    "variable": "var(--text-styles-button-label-sm)"
  },
  "textStyles.button-label-xs": {
    "value": "var(--text-styles-button-label-xs)",
    "variable": "var(--text-styles-button-label-xs)"
  },
  "textStyles.pricepoint-text-price-lg": {
    "value": "var(--text-styles-pricepoint-text-price-lg)",
    "variable": "var(--text-styles-pricepoint-text-price-lg)"
  },
  "textStyles.pricepoint-text-price-sm": {
    "value": "var(--text-styles-pricepoint-text-price-sm)",
    "variable": "var(--text-styles-pricepoint-text-price-sm)"
  },
  "textStyles.pricepoint-text-price-cents-lg": {
    "value": "var(--text-styles-pricepoint-text-price-cents-lg)",
    "variable": "var(--text-styles-pricepoint-text-price-cents-lg)"
  },
  "textStyles.pricepoint-text-price-cents-sm": {
    "value": "var(--text-styles-pricepoint-text-price-cents-sm)",
    "variable": "var(--text-styles-pricepoint-text-price-cents-sm)"
  },
  "textStyles.pricepoint-text-label-lg": {
    "value": "var(--text-styles-pricepoint-text-label-lg)",
    "variable": "var(--text-styles-pricepoint-text-label-lg)"
  },
  "textStyles.pricepoint-text-label-sm": {
    "value": "var(--text-styles-pricepoint-text-label-sm)",
    "variable": "var(--text-styles-pricepoint-text-label-sm)"
  },
  "textStyles.pricepoint-text-strikethrough-lg": {
    "value": "var(--text-styles-pricepoint-text-strikethrough-lg)",
    "variable": "var(--text-styles-pricepoint-text-strikethrough-lg)"
  },
  "textStyles.pricepoint-text-strikethrough-sm": {
    "value": "var(--text-styles-pricepoint-text-strikethrough-sm)",
    "variable": "var(--text-styles-pricepoint-text-strikethrough-sm)"
  },
  "textStyles.tripcard-text-basic-label": {
    "value": "var(--text-styles-tripcard-text-basic-label)",
    "variable": "var(--text-styles-tripcard-text-basic-label)"
  },
  "textStyles.tripcard-text-basic-title": {
    "value": "var(--text-styles-tripcard-text-basic-title)",
    "variable": "var(--text-styles-tripcard-text-basic-title)"
  },
  "textStyles.tripcard-text-basic-route": {
    "value": "var(--text-styles-tripcard-text-basic-route)",
    "variable": "var(--text-styles-tripcard-text-basic-route)"
  },
  "textStyles.tripcard-text-inspirational-large-label": {
    "value": "var(--text-styles-tripcard-text-inspirational-large-label)",
    "variable": "var(--text-styles-tripcard-text-inspirational-large-label)"
  },
  "textStyles.tripcard-text-inspirational-large-title": {
    "value": "var(--text-styles-tripcard-text-inspirational-large-title)",
    "variable": "var(--text-styles-tripcard-text-inspirational-large-title)"
  },
  "textStyles.tripcard-text-inspirational-large-route": {
    "value": "var(--text-styles-tripcard-text-inspirational-large-route)",
    "variable": "var(--text-styles-tripcard-text-inspirational-large-route)"
  },
  "textStyles.tripcard-text-inspirational-small-label": {
    "value": "var(--text-styles-tripcard-text-inspirational-small-label)",
    "variable": "var(--text-styles-tripcard-text-inspirational-small-label)"
  },
  "textStyles.tripcard-text-inspirational-small-title": {
    "value": "var(--text-styles-tripcard-text-inspirational-small-title)",
    "variable": "var(--text-styles-tripcard-text-inspirational-small-title)"
  },
  "textStyles.tripcard-text-inspirational-small-route": {
    "value": "var(--text-styles-tripcard-text-inspirational-small-route)",
    "variable": "var(--text-styles-tripcard-text-inspirational-small-route)"
  },
  "textStyles.primary-navigtion-text-navitem-lg": {
    "value": "var(--text-styles-primary-navigtion-text-navitem-lg)",
    "variable": "var(--text-styles-primary-navigtion-text-navitem-lg)"
  },
  "textStyles.primary-navigtion-text-navitem-sm": {
    "value": "var(--text-styles-primary-navigtion-text-navitem-sm)",
    "variable": "var(--text-styles-primary-navigtion-text-navitem-sm)"
  },
  "textStyles.primary-navigtion-text-category-label-lg": {
    "value": "var(--text-styles-primary-navigtion-text-category-label-lg)",
    "variable": "var(--text-styles-primary-navigtion-text-category-label-lg)"
  },
  "textStyles.primary-navigtion-text-category-label-sm": {
    "value": "var(--text-styles-primary-navigtion-text-category-label-sm)",
    "variable": "var(--text-styles-primary-navigtion-text-category-label-sm)"
  },
  "textStyles.primary-navigtion-text-category-item-lg": {
    "value": "var(--text-styles-primary-navigtion-text-category-item-lg)",
    "variable": "var(--text-styles-primary-navigtion-text-category-item-lg)"
  },
  "textStyles.primary-navigtion-text-category-item-sm": {
    "value": "var(--text-styles-primary-navigtion-text-category-item-sm)",
    "variable": "var(--text-styles-primary-navigtion-text-category-item-sm)"
  },
  "shadows.semantic-shadow-focus-ring": {
    "value": "var(--shadows-semantic-shadow-focus-ring)",
    "variable": "var(--shadows-semantic-shadow-focus-ring)"
  },
  "shadows.semantic-shadow-xs": {
    "value": "var(--shadows-semantic-shadow-xs)",
    "variable": "var(--shadows-semantic-shadow-xs)"
  },
  "shadows.semantic-shadow-sm": {
    "value": "var(--shadows-semantic-shadow-sm)",
    "variable": "var(--shadows-semantic-shadow-sm)"
  },
  "shadows.semantic-shadow-base": {
    "value": "var(--shadows-semantic-shadow-base)",
    "variable": "var(--shadows-semantic-shadow-base)"
  },
  "shadows.semantic-shadow-md": {
    "value": "var(--shadows-semantic-shadow-md)",
    "variable": "var(--shadows-semantic-shadow-md)"
  },
  "shadows.semantic-shadow-lg": {
    "value": "var(--shadows-semantic-shadow-lg)",
    "variable": "var(--shadows-semantic-shadow-lg)"
  },
  "shadows.semantic-shadow-lg-reverse": {
    "value": "var(--shadows-semantic-shadow-lg-reverse)",
    "variable": "var(--shadows-semantic-shadow-lg-reverse)"
  },
  "shadows.semantic-shadow-xl": {
    "value": "var(--shadows-semantic-shadow-xl)",
    "variable": "var(--shadows-semantic-shadow-xl)"
  },
  "shadows.semantic-shadow-xl-reverse": {
    "value": "var(--shadows-semantic-shadow-xl-reverse)",
    "variable": "var(--shadows-semantic-shadow-xl-reverse)"
  },
  "shadows.semantic-shadow-2xl": {
    "value": "var(--shadows-semantic-shadow-2xl)",
    "variable": "var(--shadows-semantic-shadow-2xl)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar