import { css } from '@/styled-system/css'

import { RequestQuote, SpeakToAnExpert } from './ctas'

export function RequestQuoteOption({}) {
  return (
    <div
      className={css({
        mt: 'semantic-spacing-24',
        md: {
          mt: 'semantic-spacing-48',
        },
      })}
    >
      <RequestQuote />
      <SpeakToAnExpert />
    </div>
  )
}
