import {
  commonContentResolver,
  genericDataResolver,
  resolveArticleCard,
  resolveCta,
} from '@/utility/content-resolvers'
import type { PageSlug } from '@/types/resolved-types'

import type {
  ArticleCardCarouselProps,
  ArticleCard as ResolvedArticleCard,
} from '@/types/components/article-card-carousel'

export const dataResolver = (
  brand: string,
  data: Contentful.Models.ArticleCardGridCarousel,
  isLead?: boolean,
  allSlugs?: PageSlug[],
) =>
  genericDataResolver<
    Contentful.Models.ArticleCardGridCarousel,
    ResolvedArticleCard | null,
    ArticleCardCarouselProps
  >(
    data,
    brand,
    {
      id: '',
      showInNav: false,
      name: '',
      title: '',
      titleSize: 'heading3',
      text: '',
      items: [],
      cta: null,
      backgroundVariant: 'default',
    },
    {
      items: {
        collectionKey: 'items',
        resolver: async (item, context) => {
          const resolvedItem = await resolveArticleCard(item as never, context)
          if (resolvedItem) {
            return resolvedItem
          }
          return null
        },
      },
    },
    async (resolvedData, context): Promise<ArticleCardCarouselProps> => ({
      ...commonContentResolver(data as never, isLead),
      items: resolvedData.items || [],
      cta: data.cta
        ? (
            await resolveCta(data.cta, context.locale, context.brand, allSlugs)
          )[0] || null
        : null,
      backgroundVariant: (data.background ||
        'default') as ArticleCardCarouselProps['backgroundVariant'],
    }),
  )
