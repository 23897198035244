import React from 'react'
import { css, cx } from '@/styled-system/css'
import { stack } from '@/styled-system/patterns'
import Image from 'next/image'
import { findChildren, MenuItemSummary } from '../util/find-children'
import { MenuItem } from './mobileMenuItem'
import { ScrollArea } from '@/components/scrollArea'
import type { MenuItem as MenuItemType } from '../desktopNav/dropdownItem'
import type { MenuStackItem } from './mobileNav'
export const SubNav = ({
  index,
  currentItem,
  onOpenSubmenu,
}: {
  index: number
  currentItem: MenuStackItem
  onOpenSubmenu: (item: MenuStackItem) => void
}) => {
  const itemSummary: MenuItemSummary | undefined = findChildren(
    currentItem as MenuItemType,
  )
  return (
    <div
      data-id={`menu-${index}`}
      data-open={currentItem.index === index}
      className={css({
        position: 'absolute',
        zIndex: '50',
        height: '100%',
        insetY: '0',
        right: 0,
        h: 'full',
        w: 'full',
        mt: 84,
        borderTop: '1px solid',
        borderColor: 'semantic-border-default',
        bg: '#ffffff',
      })}
    >
      <ScrollArea
        className={css({
          h: 'calc(100dvh - 84px)',
          w: 'full',
        })}
      >
        <div
          className={css({
            mb: 'semantic-spacing-16',
          })}
        >
          <div
            className={cx(
              css({
                mt: 'semantic-spacing-32',
                px: 'semantic-spacing-32',
              }),
            )}
          >
            {currentItem.index !== 0 && (
              <span
                id={`details-heading-${index}`}
                className={cx(
                  css({
                    display: 'block',
                    fontSize: 'font-size-6',
                    fontFamily: 'font-family-logotype',
                    lineHeight: 'line-height-3',
                    mb: 'semantic-spacing-20',
                  }),
                )}
              >
                {currentItem.title}
              </span>
            )}
            <ul
              className={
                itemSummary?.hasMultipleLevels
                  ? stack({
                      gap: 'semantic-spacing-16',
                    })
                  : css({
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                    })
              }
            >
              {currentItem.items &&
                currentItem.items.map((item, index) => (
                  <MenuItem
                    key={index}
                    item={item as MenuItemType}
                    onOpenSubmenu={onOpenSubmenu}
                    hasChildren={itemSummary?.hasMultipleLevels}
                  />
                ))}
            </ul>
          </div>
          {currentItem.image && (
            <div
              className={css({
                px: 'semantic-spacing-16',
                mt: 'semantic-spacing-32',
                overflow: 'hidden',
              })}
            >
              <Image
                src={currentItem.image}
                alt={currentItem.title}
                width={300}
                height={200}
                className={css({
                  aspectRatio: '16/9',
                  width: '100%',
                  borderRadius: 'semantic-border-radius-large',
                  height: 'auto',
                  objectFit: 'cover',
                })}
              />
            </div>
          )}
        </div>
      </ScrollArea>
    </div>
  )
}
