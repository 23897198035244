'use client'

import { useMediaQuery } from 'usehooks-ts'

import { AccordionCarousel } from '@/global/components/media-gallery-carousel-tabs/accordion-carousel'
import { TabsCarousel } from '@/global/components/media-gallery-carousel-tabs/tabs-carousel'

import { Box, Section } from '@/styled-system/jsx'

import type { MediaGalleryTabsProps } from '@/types/components/media-gallery-carousel-tabs'

export const TabbedGallerySection = ({
  props,
}: {
  props: MediaGalleryTabsProps
}) => {
  const { items, title, titleSize, backgroundVariant } = props || {}
  const isDesktop = useMediaQuery('(min-width: 1024px)', {
    initializeWithValue: false,
  })
  const isReversed = backgroundVariant === 'reverse'

  if (!props || !items?.length) {
    return null
  }

  return (
    <Box
      overflow="hidden"
      w="full"
      bg={!isReversed ? 'semantic-bg-accent' : 'semantic-bg-reverse'}
    >
      <Section
        py={{
          base: 'semantic-spacing-32',
          md: 'semantic-spacing-64',
        }}
      >
        {isDesktop ? (
          <TabsCarousel
            items={items}
            title={title}
            titleSize={titleSize}
            titleTag={props?.titleTag}
            backgroundVariant={backgroundVariant}
            livePreviewProps={props?.livePreviewProps}
          />
        ) : (
          <AccordionCarousel
            items={items}
            title={title}
            titleSize={titleSize}
            titleTag={props?.titleTag}
            backgroundVariant={backgroundVariant}
            livePreviewProps={props?.livePreviewProps}
          />
        )}
      </Section>
    </Box>
  )
}
