import { useId } from 'react'

export function Rail() {
  const id = useId()

  return (
    <svg
      width="32"
      height="8"
      viewBox="0 0 32 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#rail-${id})`}>
        <path
          d="M32 3.68V0H31.3143V3.68H28.5143V0H27.8286V3.68H25.0286V0H24.3714V3.68H21.5429V0H20.8857V3.68H18.0571V0H17.4V3.68H14.6V0H13.9143V3.68H11.1143V0H10.4286V3.68H7.62857V0H6.97143V3.68H4.14286V0H3.48571V3.68H0.657143V0H0V3.68V4.32V8H0.657143V4.32H3.48571V8H4.14286V4.32H6.97143V8H7.62857V4.32H10.4286V8H11.1143V4.32H13.9143V8H14.6V4.32H17.4V8H18.0571V4.32H20.8857V8H21.5429V4.32H24.3714V8H25.0286V4.32H27.8286V8H28.5143V4.32H31.3143V8H32V4.32V3.68Z"
          fill="#1E1C1C"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="32" height="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
