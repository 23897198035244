'use client'

import { useEffect, useState } from 'react'

import { useLiveUpdate } from '@/components/hooks/use-live-update'
import { useSite } from '@/providers/site'

import { TabbedGallerySection } from '@/global/components/media-gallery-carousel-tabs/carousel-section'
import { dataResolver } from '@/global/components/media-gallery-carousel-tabs/utils/data-resolver'

import { TabbedCarouselDocument } from '@/graphql/client'

import { error } from '@/utility/logging'

import type { MediaGalleryTabsProps } from '@/types/components/media-gallery-carousel-tabs'

export function MediaGalleryTabsLivePreview({
  brand,
  data: originalData,
  isLead,
}: Readonly<{
  brand: string
  data: Contentful.Models.TabbedCarousel
  isLead?: boolean
}>) {
  const [data, setData] = useState<MediaGalleryTabsProps>({} as never)
  const { slugs } = useSite()

  const { entry: section, getInspectorProps } = useLiveUpdate(
    originalData,
    TabbedCarouselDocument,
  )

  useEffect(() => {
    if (section) {
      const fetchData = async () => {
        try {
          const result = await dataResolver(brand, section, isLead, slugs)
          setData(result)
        } catch (err) {
          error('Error fetching media gallery carousel tabs data', {
            brand,
            err: (err as Error).message,
          })
        }
      }

      fetchData()
    }
  }, [section, isLead, slugs])

  return data ? (
    <TabbedGallerySection
      props={{
        ...data,
        livePreviewProps: {
          title: getInspectorProps({ fieldId: 'title' }),
          text: getInspectorProps({ fieldId: 'text' }),
          cta: getInspectorProps({ fieldId: 'cta' }),
        },
      }}
    />
  ) : null
}
