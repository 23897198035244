'use client'

import { useEffect, useState } from 'react'

import { Accordion as AccordionComponent } from '@/components/accordion'
import { useLiveUpdate } from '@/components/hooks/use-live-update'

import { dataResolver } from '@/global/components/accordion/util/data-resolver'

import { AccordionDocument } from '@/graphql/client'

import { css } from '@/styled-system/css'

import { error } from '@/utility/logging'
import { useSite } from '@/providers/site'
import type { AccordionSectionProps } from '@/types/components/accordion-section'

export const AccordionSectionPreview = ({
  brand,
  data: originalData,
  isLead,
}: Readonly<{
  brand: string
  data: Contentful.Models.Accordion
  isLead?: boolean
}>) => {
  const [data, setData] = useState<AccordionSectionProps>({} as never)
  const { slugs } = useSite()

  const { entry: section, getInspectorProps } = useLiveUpdate(
    originalData,
    AccordionDocument,
  )

  useEffect(() => {
    if (section) {
      const fetchData = async () => {
        try {
          const result = await dataResolver(brand, section, isLead, slugs)
          setData(result)
        } catch (err) {
          error('Error fetching accordion data', {
            brand,
            err: (err as Error).message,
          })
        }
      }

      fetchData()
    }
  }, [section, isLead, slugs])

  return (
    <div
      className={css({
        py: {
          base: 'semantic-spacing-40',
          md: 'semantic-spacing-64',
        },
      })}
    >
      <AccordionComponent
        {...data}
        livePreviewProps={{
          title: getInspectorProps({ fieldId: 'title' }),
          text: getInspectorProps({ fieldId: 'text' }),
          cta: getInspectorProps({ fieldId: 'cta' }),
        }}
      />
    </div>
  )
}
