import React from 'react'
import { css, cx, type Argument } from '@/styled-system/css'
import { Flex } from '@/styled-system/jsx'
import { Button } from '@/components/button'
import type { ButtonProps } from '@/components/button'
interface ButtonGroupProps {
  buttons: ButtonProps[]
  layout: 'inline' | 'stacked'
  className?: string | Argument
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  buttons,
  layout,
  className,
}) => {
  return (
    <Flex
      direction={layout === 'inline' ? 'row' : 'column'}
      className={cx(
        css({
          gap: 'semantic-spacing-12',
        }),
        className,
      )}
    >
      {buttons.map((buttonProps, index) => (
        <Button key={index} {...buttonProps} />
      ))}
    </Flex>
  )
}
