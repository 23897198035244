import { motion } from 'framer-motion'
import Image from 'next/image'

import { ContentfulLivePreview } from '@contentful/live-preview'

import { Control } from '@/components/carousel/nav-control'

import { SlideContent } from '@/global/components/two-column-gallery-carousel/gallery-carousel-slide-content'

import { css } from '@/styled-system/css'
import { Box, Grid } from '@/styled-system/jsx'

import type { EmblaCarouselType } from 'embla-carousel'

import type { SlideItem } from '@/types/components/two-column-gallery-carousel'

const EmblaSlide = ({ children }: React.PropsWithChildren) => {
  return (
    <Box className="embla__slide" h="full">
      {children}
    </Box>
  )
}

export const CarouselSlide = ({
  emblaApi,
  slide,
  slideInView,
  isMobile,
}: Readonly<{
  emblaApi: EmblaCarouselType | undefined
  slide: SlideItem
  slideInView: boolean
  isMobile: boolean
}>) => {
  if (!slide?.image?.src) return null

  return (
    <EmblaSlide>
      <Grid
        columns={{
          base: 1,
          md: 2,
        }}
        columnGap={{
          md: 'semantic-spacing-48',
          lg: 'semantic-spacing-64',
          xl: 'space-1000',
        }}
        rowGap="semantic-spacing-32"
        {...ContentfulLivePreview.getProps({
          entryId: slide?.id,
          fieldId: 'title',
        })}
      >
        <motion.div
          className={css({
            position: 'relative',
            overflow: 'hidden',
            borderRadius: 'semantic-border-radius-medium',
            /// h: { base: 246, sm: 350, md: 415, lg: 565, xl: 800 },
            aspectRatio: {
              base: '343/264',
              sm: '568/360',
              md: '550/800',
            },
            w: 'full',
          })}
        >
          <Image
            fill
            sizes="(max-width: 768px) 100vw, 50vw"
            alt={slide.image.alt}
            src={slide.image.src}
            className={css({
              objectFit: 'cover',
              minWidth: '100%',
              minHeight: '100%',
            })}
          />
        </motion.div>
        <Box>
          {slide?.altImage?.src ? (
            <motion.div
              className={css({
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 'semantic-border-radius-medium',
                ///              h: { base: 246, sm: 350, md: 189, lg: 'auto', xl: 360 },
                aspectRatio: {
                  base: '343/264',
                  sm: '568/360',
                  md: '550/360',
                },
                mb: {
                  base: 'semantic-spacing-32',
                  md: 'semantic-spacing-40',
                },
                w: 'full',
              })}
            >
              <Image
                className={css({
                  objectFit: 'cover',
                  minWidth: '100%',
                  minHeight: '100%',
                })}
                fill
                sizes="(max-width: 768px) 100vw, 50vw"
                alt={slide?.altImage?.alt}
                src={slide.altImage.src}
              />
            </motion.div>
          ) : null}
          <SlideContent
            cta={slide?.cta?.href ? slide?.cta : undefined}
            desc={slide?.text}
            slideInView={slideInView}
            title={slide.title}
          />
          {isMobile ? null : (
            <Control disabledNavControl={!slideInView} emblaApi={emblaApi} />
          )}
        </Box>
      </Grid>
    </EmblaSlide>
  )
}
