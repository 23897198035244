'use client'
import React, { useRef } from 'react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { css, cx, type Styles } from '@/styled-system/css'
import { gradient as gradientRecipe } from '@/styled-system/recipes'
import { getPlaceholderImage } from '@/utility/client/get-placeholder-image'
import type { AspectRatio } from '@/types/components/aspect-ratio'
const DynamicVideoPlayer = dynamic(
  () => import('../videoPlayer').then((mod) => mod.VideoPlayer),
  {
    ssr: false,
    loading: () => null,
  },
)

export interface BackgroundMediaProps {
  videoSource?: string
  autoplay?: boolean
  useIntersectionObserver?: boolean
  showPlayButton?: boolean
  aspectRatio?: AspectRatio
  posterImage?: string
  as?: 'video' | 'image'
  gradient?: 'light' | 'medium' | 'strong'
  imageSource?: string
  alt?: string
  quality?: number
  classes?: {
    root?: string
    content?: string
  }
  children?: React.ReactNode
  priority?: boolean
}

const playerStyle: Styles = {
  position: 'absolute',
  top: 0,
  left: 0,
}

export const BackgroundMedia: React.FC<BackgroundMediaProps> = ({
  alt = 'Background Image',
  as = 'image',
  aspectRatio = '16/9',
  autoplay = true,
  children,
  classes,
  gradient = 'light',
  imageSource,
  posterImage,
  priority = false,
  quality = 95,
  videoSource,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const image = imageSource ? (
    <Image
      src={imageSource}
      className={css({
        inset: 0,
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: 1,
      })}
      fill
      priority={priority}
      objectFit="cover"
      sizes="100vw"
      alt={alt}
      placeholder="blur"
      blurDataURL={getPlaceholderImage(imageSource) || undefined}
      quality={quality}
    />
  ) : null

  const video =
    videoSource && posterImage ? (
      <DynamicVideoPlayer
        autoplay={autoplay}
        aspectRatio={aspectRatio}
        playerStyle={playerStyle}
        posterImage={posterImage}
        videoSource={videoSource}
      />
    ) : null

  const mediaElement = as === 'video' ? video : image

  return (
    <figure
      className={cx(
        css({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          overflow: 'hidden',
        }),
        gradientRecipe({ theme: gradient }),
        classes?.root,
      )}
      ref={containerRef}
    >
      {mediaElement}
      {children && (
        <figcaption
          className={cx(
            css({
              zIndex: 2,
            }),
            classes?.content,
          )}
        >
          {children}
        </figcaption>
      )}
    </figure>
  )
}
