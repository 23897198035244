'use client'

import { useEffect, useState } from 'react'

import { useLiveUpdate } from '@/components/hooks/use-live-update'
import { useSite } from '@/providers/site'

import { TripCarousel as TripCarouselSection } from '@/global/components/trip-carousel/trip-carousel'
import { dataResolver } from '@/global/components/trip-carousel/util/data-resolver'

import { TripCarouselDocument } from '@/graphql/client'

import { error } from '@/utility/logging'

import type { TripCarouselProps } from '@/types/components/trip-carousel'

export function TripCarouselLivePreview({
  brand,
  data: originalData,
  isLead,
}: Readonly<{
  brand: string
  data: Contentful.Models.TripCarousel
  isLead?: boolean
}>) {
  const [data, setData] = useState<TripCarouselProps>({} as never)
  const { slugs } = useSite()

  const { entry: section, getInspectorProps } = useLiveUpdate(
    originalData,
    TripCarouselDocument,
  )

  useEffect(() => {
    if (section) {
      const fetchData = async () => {
        try {
          const result = await dataResolver(brand, section, isLead, slugs)
          setData(result)
        } catch (err) {
          error('Error fetching trip carousel data', {
            brand,
            err: (err as Error).message,
          })
        }
      }

      fetchData()
    }
  }, [section, isLead, slugs])

  return data ? (
    <TripCarouselSection
      {...data}
      livePreviewProps={{
        title: getInspectorProps({ fieldId: 'title' }),
        text: getInspectorProps({ fieldId: 'text' }),
        cta: getInspectorProps({ fieldId: 'cta' }),
      }}
    />
  ) : null
}
