import type { TitleProps } from '@/components/title'
import { css, cx } from '@/styled-system/css'
import type {
  MediaGalleryTabsProps,
  TabCarouselGroupProps,
} from '@/types/components/media-gallery-carousel-tabs'
import {
  genericDataResolver,
  resolveGalleryItem,
} from '@/utility/content-resolvers'
import type { PageSlug } from '@/types/resolved-types'

const _itemStyles = {
  root: cx(
    css({
      display: 'flex',
      md: {
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'start',
      },
    }),
  ),
  content: cx(
    css({
      textAlign: {
        base: 'left',
        md: 'left',
      },
      '& > p': {
        textStyle: 'semantic-text-md-font-regular',
        color: 'semantic-fg-accent-dark',
      },
    }),
  ),
  title: cx(
    css({
      textStyle: 'semantic-text-lg-font-bold',
      color: 'semantic-fg-accent-dark',
    }),
  ),
}

export const dataResolver = (
  brand: string,
  data: Contentful.Models.TabbedCarousel,
  isLead?: boolean,
  slugs?: PageSlug[],
) =>
  genericDataResolver<
    Contentful.Models.TabbedCarouselItem,
    TabCarouselGroupProps,
    MediaGalleryTabsProps
  >(
    data,
    brand,
    {
      id: '',
      showInNav: false,
      title: '',
      titleSize: 'heading3',
      items: [],
      backgroundVariant: 'default',
    },
    {
      items: {
        collectionKey: 'items',
        resolver: async (item, context): Promise<TabCarouselGroupProps> => {
          const resolvedItems = await Promise.all(
            item?.items?.items.map((item) =>
              item ? resolveGalleryItem(item, context, slugs) : null,
            ) ?? [],
          )

          // Filter out any null values
          const filteredItems = resolvedItems.filter(
            (item): item is NonNullable<typeof item> => item !== null,
          )

          return {
            id: item.sys.id,
            heading: item.title || '',
            items: filteredItems,
          }
        },
      },
    },
    async (resolvedData): Promise<MediaGalleryTabsProps> => ({
      id: data.sys.id,
      showInNav: data.showInNav || false,
      title: data.title || '',
      titleSize: (data.titleSize || 'heading3') as TitleProps['size'],
      titleTag: isLead ? 'h1' : 'h2',
      items: resolvedData.items || [],
      backgroundVariant:
        data.backgroundVariant as MediaGalleryTabsProps['backgroundVariant'],
    }),
  )
