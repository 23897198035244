import { styled, type HTMLStyledProps } from '@/styled-system/jsx'
import { badge } from '@/styled-system/recipes'

/**
 * @example
 * ```jsx
 * import { Badge } from '@/components/badge';
 *
 * export const ExampleBadge = () => {
 *   return <Badge variant="secondary">Badge</Badge>;
 * };
 * ```
 */
export const Badge = styled('div', badge)
export type BadgeProps = HTMLStyledProps<typeof Badge>
