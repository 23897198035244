import { InlineIconText } from '@/components/inline-icon-text'
import { SectionHeader } from '@/components/section-header'
import type { TitleProps } from '@/components/title'

import { css, cx } from '@/styled-system/css'
import { Box, Flex, Section } from '@/styled-system/jsx'

import type { SplitFeaturesSectionProps } from '@/types/components/split-features-section'

function getVariantCss(variant: SplitFeaturesSectionProps['variant']) {
  switch (variant) {
    case 'with-border':
      return css({
        borderTopWidth: 'semantic-border-width-x-small',
        borderTopColor: 'semantic-border-default',
        py: 'semantic-spacing-24',
        pl: 'semantic-spacing-16',
        md: {
          borderTop: 0,
          borderLeftWidth: 'semantic-border-width-x-small',
          borderLeftColor: 'semantic-border-default',
          pl: 'semantic-spacing-48',
        },
      })
    case 'with-background':
      return css({
        bg: 'semantic-bg-accent',
        borderRadius: 'border-radius-4',
        py: 'semantic-spacing-24',
        px: 'semantic-spacing-32',
      })
  }
}

/**
 * This is  general SplitFeatureSection component.
 * It should be more generic and reusable.
 */
export function SplitFeatureSection({
  items,
  title,
  text,
  titleSize = 'heading3',
  titleTag = 'h2',
  showInNav: _showInNav, // TODO page navigation
  classNames,
  variant,
  livePreviewProps,
}: Readonly<SplitFeaturesSectionProps>) {
  return (
    <Box overflow="hidden" w="full" className={cx(classNames?.root)}>
      <Section
        py={{
          base: 'semantic-spacing-32',
          md: 'semantic-spacing-64',
        }}
        className={cx(classNames?.section)}
      >
        <div
          className={cx(
            css({
              display: 'grid',
              gridTemplateColumns: '1fr',
              md: {
                gridTemplateColumns: 'repeat(12, 1fr)',
                gap: 0,
              },
            }),
            classNames?.content,
          )}
        >
          <SectionHeader
            description={text}
            heading={title || ''}
            titleProps={{
              size: (titleSize || 'heading3') as TitleProps['size'],
              tag: titleTag,
              format: 'standard',
              ...(livePreviewProps?.title ?? {}),
            }}
            descriptionProps={{
              ...(livePreviewProps?.text ?? {}),
            }}
            mb={{ base: 'semantic-spacing-24', md: 'semantic-spacing-48' }}
            pr={{
              base: 'semantic-spacing-16',
              md: 'semantic-spacing-32',
            }}
            className={cx(
              css({
                gridColumn: 'span 7',
                pr: {
                  md: 'semantic-spacing-32',
                },
              }),
              classNames?.header,
            )}
          />

          <Flex
            flexDirection={{ base: 'column' }}
            justifyContent={{
              base: 'start',
              md: 'center',
            }}
            alignItems={{
              base: 'start',
              md: 'center',
            }}
            rowGap={{ base: 'semantic-spacing-16', md: 'semantic-spacing-32' }}
            className={cx(
              css({
                gridColumn: 'span 5',
              }),
              getVariantCss(variant),
              classNames?.features,
            )}
          >
            {items?.map((item) => <InlineIconText key={item.id} {...item} />)}
          </Flex>
        </div>
      </Section>
    </Box>
  )
}
