'use client'

import { useEffect, useState } from 'react'

import { useLiveUpdate } from '@/components/hooks/use-live-update'

import {
  FeaturedItemListSection,
  type FeaturedItemListProps,
} from '@/global/components/featured-item-list/featured-item-list'
import { dataResolver } from '@/global/components/featured-item-list/util/data-resolver'
import { useSite } from '@/providers/site'

import { FeaturedItemListSectionDocument } from '@/graphql/client'

import { error } from '@/utility/logging'

export function FeaturesListPreview({
  data: originalData,
  brand,
  isLead,
}: Readonly<{
  data: Contentful.Models.FeaturedItemListSection
  brand: string
  isLead?: boolean
}>) {
  const [data, setData] = useState<FeaturedItemListProps>({} as never)
  const { slugs } = useSite()

  const { entry: section, getInspectorProps } = useLiveUpdate(
    originalData,
    FeaturedItemListSectionDocument,
  )

  useEffect(() => {
    if (section) {
      const fetchData = async () => {
        try {
          const result = await dataResolver(brand, section, isLead, slugs)
          setData(result)
        } catch (err) {
          error('Error fetching featured item list data', {
            brand,
            err: (err as Error).message,
          })
        }
      }

      fetchData()
    }
  }, [section, isLead, slugs])

  return data ? (
    <FeaturedItemListSection
      {...data}
      livePreviewProps={{
        title: getInspectorProps({ fieldId: 'title' }),
        text: getInspectorProps({ fieldId: 'text' }),
        cta: getInspectorProps({ fieldId: 'cta' }),
      }}
    />
  ) : null
}
