import { css, cx } from '@/styled-system/css'

export const NavTitle = ({
  title,
  ...rest
}: {
  title: string
  id?: string
}) => {
  return (
    <span
      className={cx(
        css({
          display: 'block',
          px: 'semantic-spacing-6',
          fontSize: 'font-size-6',
          fontFamily: 'font-family-logotype',
          lineHeight: 'line-height-3',
        }),
      )}
      {...rest}
    >
      {title}
    </span>
  )
}
