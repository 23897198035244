'use client'

import useEmblaCarousel from 'embla-carousel-react'

import { Carousel } from '@/components/carousel'
import { Control } from '@/components/carousel/nav-control'
import { SectionHeader } from '@/components/section-header'
import { TripCard } from '@/global/components/trip-card'

import { Box, Section } from '@/styled-system/jsx'
import { cx } from '@/styled-system/css'

import type { TitleProps } from '@/components/title'

import type { TripCarouselProps } from '@/types/components/trip-carousel'
import { useSite } from '@/providers/site'
import { useTripsPricingInfo } from './util/use-trips-pricing'

export const TripCarousel: React.FC<TripCarouselProps> = ({
  backgroundVariant = 'default',
  items,
  title,
  text,
  cta,
  cardVariant = 'overlay',
  livePreviewProps,
  titleSize,
  titleTag,
  preferredDateRange,
}) => {
  const { brand, locale } = useSite()

  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 'auto',
    align: 'start',
    breakpoints: {
      '(max-width: 1439px)': {
        loop: true,
      },
    },
  })

  const { tripsPricing } = useTripsPricingInfo({
    brand: brand.code!,
    locale: locale,
    tripsCode: items?.map((item) => item.tripCode!),
    dateRange: preferredDateRange,
  })

  if (!items || !items.length) return null

  return (
    <Box
      bg={
        backgroundVariant === 'default'
          ? 'semantic-bg-accent'
          : 'semantic-bg-reverse'
      }
      overflow="hidden"
      w="full"
    >
      <Section
        py={{
          base: 'semantic-spacing-32',
          md: 'semantic-spacing-64',
        }}
      >
        <SectionHeader
          isReversed={backgroundVariant === 'reverse'}
          cta={cta}
          description={text}
          heading={title || ''}
          mb={{ base: 'semantic-spacing-24', md: 'semantic-spacing-48' }}
          titleProps={{
            size: (titleSize || 'heading3') as TitleProps['size'],
            tag: titleTag || 'h2',
            format: 'standard',
            ...(livePreviewProps?.title ?? {}),
          }}
          descriptionProps={{
            ...(livePreviewProps?.text ?? {}),
          }}
        />

        <Carousel emblaRef={emblaRef} emblaWrapperStyle="embla--card-carousel">
          {items?.map((item, index: number) => {
            const tripItem = tripsPricing?.find(
              (pricing) => pricing.tripCode === item.tripCode,
            )
            const priceFrom = tripItem?.prices?.double?.basePrice
            const priceTo = tripItem?.prices?.double?.price

            if (!item) return null

            return (
              <div
                key={`article-card-${index}`}
                className={cx('embla__slide', 'card-carousel__slide')}
              >
                <TripCard
                  {...item}
                  url={tripItem?.pageUrl ?? item?.url}
                  variant={cardVariant}
                  priceFrom={priceFrom}
                  priceTo={priceTo}
                />
              </div>
            )
          })}
        </Carousel>

        <Control
          emblaApi={emblaApi}
          navSize={{ base: 'md', md: 'lg' }}
          variant={backgroundVariant}
          loopToEdge
        />
      </Section>
    </Box>
  )
}
