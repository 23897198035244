'use client'

import useEmblaCarousel from 'embla-carousel-react'
import omit from 'lodash/omit'

import { Carousel } from '@/components/carousel'
import { Control } from '@/components/carousel/nav-control'
import { Title, TitleProps } from '@/components/title'

import { TestimonialCard } from '@/global/components/testimonial-carousel/testimonial-card'

import { css } from '@/styled-system/css'
import { Box, Section } from '@/styled-system/jsx'

import type { TestimonialCardProps } from '@/types/components/testimonials-carousel'
import type { LivePreviewProps } from '@/types/components/live-preview'

export type TestimonialCarouselProps = {
  id?: string
  title: string | null
  titleSize: TitleProps['size']
  titleTag?: TitleProps['tag']
  navTitle?: string | null
  showInNav?: boolean | null
  items: TestimonialCardProps[] | []
  livePreviewProps?: LivePreviewProps
}

const sectionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'semantic-spacing-24',
  lg: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: 0,
  },
})

const titleBoxStyles = css({
  display: 'flex',
  xl: {
    gridColumn: 'span 5',
  },
  lg: {
    gridColumn: 'span 6',
    justifyContent: 'center',
  },
})

const carouselBoxStyles = css({
  xl: {
    gridColumn: 'span 7',
    paddingLeft: 'semantic-spacing-64',
  },
  lg: {
    gridColumn: 'span 6',
  },
})

const emblaWrapperStyles = css({
  width: '100%',
  marginBottom: 'semantic-spacing-32',
})

const emblaViewportStyles = css({
  overflow: 'hidden',
})

export const TestimonialCarousel: React.FC<TestimonialCarouselProps> = ({
  title,
  titleSize,
  items,
  titleTag,
  livePreviewProps,
  ...rest
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: 'start' })

  if (!items?.length) return null

  const renderCarousel = () => (
    <>
      <Carousel
        emblaWrapperStyle={emblaWrapperStyles}
        emblaRef={emblaRef}
        emblaViewportStyle={emblaViewportStyles}
      >
        {items.map((item) => (
          <TestimonialCard
            id={item.id}
            className="embla__slide"
            key={`embla-slide-${item.id}`}
            text={item?.text}
            author={item?.author}
            destination={item?.destination}
            link={item?.link}
            buttonProps={item?.buttonProps}
          />
        ))}
      </Carousel>
      <Control emblaApi={emblaApi} />
    </>
  )

  const renderSingleTestimonial = () => (
    <TestimonialCard
      id={items[0]?.id}
      text={items[0]?.text}
      author={items[0]?.author}
      destination={items[0]?.destination}
      link={items[0]?.link}
      buttonProps={items[0]?.buttonProps}
    />
  )

  return (
    <Section
      id={rest?.id}
      {...omit(rest, 'locale', 'navTitle', 'showInNav', 'text')}
      py={{
        base: 'semantic-spacing-32',
        md: 'semantic-spacing-64',
      }}
      className={sectionStyles}
    >
      <Box className={titleBoxStyles}>
        <Title
          size={titleSize}
          tag={titleTag}
          format="standard"
          theme="dark"
          {...(livePreviewProps?.title ?? {})}
        >
          {title}
        </Title>
      </Box>
      <Box className={carouselBoxStyles}>
        {items.length > 1 ? renderCarousel() : renderSingleTestimonial()}
      </Box>
    </Section>
  )
}
