import { useId } from 'react'

import { Location } from './location'

export function Departure({
  colour = 'success',
}: Readonly<{ colour: 'error' | 'success' }>) {
  return <Location colour={`${colour}-emphasised`} />
}

export function FlightPath() {
  const id = useId()

  return (
    <svg
      width="32"
      height="4"
      viewBox="0 0 32 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#flight-path-${id})`}>
        <path
          d="M32 4H17.1005V0H32V4ZM14.8995 4H0V0H14.8995V4Z"
          fill="#010101"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="32" height="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
