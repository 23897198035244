import { css, cx } from '@/styled-system/css'

import type { InlineIconTextProps } from '@/components/inline-icon-text'

import type { FeaturedItemListProps } from '@/types/components/featured-item-list'
import type { PageSlug } from '@/types/resolved-types'

import {
  genericDataResolver,
  resolveIconText,
  commonContentResolver,
} from '@/utility/content-resolvers'

const itemStyles = {
  root: cx(
    css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      md: {
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'start',
      },
    }),
  ),
  content: cx(
    css({
      '& > p': {
        textStyle: 'semantic-text-md-font-regular',
        color: 'inclusions-color-body',
        '&:last-of-type': {
          mb: 0,
        },
      },
    }),
  ),
  title: cx(
    css({
      textStyle: 'semantic-text-lg-font-bold',
      color: 'inclusions-color-heading',
    }),
  ),
}

export const dataResolver = (
  brand: string,
  data: Contentful.Models.FeaturedItemListSection,
  isLead?: boolean,
  allSlugs?: PageSlug[],
): Promise<FeaturedItemListProps> =>
  genericDataResolver<
    Contentful.Models.FeaturedItem,
    InlineIconTextProps | null,
    FeaturedItemListProps
  >(
    data,
    brand,
    {
      id: '',
      showInNav: false,
      navTitle: '',
      title: '',
      titleSize: 'heading3',
      text: '',
      items: [],
    },
    {
      items: {
        collectionKey: 'items',
        resolver: async (item, context) => {
          const resolvedItem = await resolveIconText(item, context, allSlugs)

          if (resolvedItem) {
            return resolvedItem
          }

          return null
        },
      },
    },
    async (resolvedData): Promise<FeaturedItemListProps> => ({
      ...commonContentResolver(data as never, isLead),
      items:
        resolvedData.items?.map((item) => ({
          ...item,
          classNames: itemStyles,
        })) || [],
    }),
  )
