'use client'

import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { createStyleContext } from '@/theme/support/style-context'
import { Flex, styled } from '@/styled-system/jsx'
import { collapsible } from '@/styled-system/recipes'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { css } from '@/styled-system/css'
const { withProvider, withContext } = createStyleContext(collapsible)

export const CollapsiblePrimitiveRoot = withProvider(
  styled(CollapsiblePrimitive.Root),
  'root',
)
export const CollapsibleTriggerPrimitive = withContext(
  styled(CollapsiblePrimitive.CollapsibleTrigger),
  'trigger',
)
export const CollapsibleContent = withContext(
  styled(CollapsiblePrimitive.CollapsibleContent),
  'content',
)

export function Collapsible({
  children,
  ...props
}: React.ComponentProps<typeof CollapsiblePrimitiveRoot>) {
  return (
    <CollapsiblePrimitiveRoot {...props} className={css({})}>
      {children}
    </CollapsiblePrimitiveRoot>
  )
}
/**
 * @example
 * ```jsx
 * import { Collapsible } from '@/components/collapsible';
 *
 * export const ExampleLabel = () => {
 *   return <Collapsible >Label</Label>;
 * };
 * ```
 */
export function CollapsibleTrigger({ children }: React.PropsWithChildren) {
  return (
    <CollapsibleTriggerPrimitive asChild>
      <button
        className={css({
          w: 'full',
        })}
        type="button"
      >
        <Flex
          css={{
            alignItems: 'center',
            justifyContent: 'space-between',
            direction: 'column',
          }}
        >
          <div className={css({})}>{children}</div>
          <MdKeyboardArrowDown className={'collapsible-arrow'} />
        </Flex>
      </button>
    </CollapsibleTriggerPrimitive>
  )
}
