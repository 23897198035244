'use client'

import { css, cx } from '@/styled-system/css'
import React, { useState, useCallback } from 'react'
import { Dropdown } from './dropdown'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/navigation-menu'
import { NavItem } from './item'
export interface MenuItem {
  title: string
  href?: string
  items?: MenuItem[]
  image?: string
}

export const Navigation = ({
  menu,
  onSubmenuOpen,
  theme,
}: {
  menu: MenuItem[]
  theme: 'default' | 'frosted'
  onSubmenuOpen?: (state: string) => void
}) => {
  const [activeItem, setActiveItem] = useState<string>('')

  const handleItemChange = useCallback(
    (item: string) => {
      if (item) {
        onSubmenuOpen?.('open')
      } else {
        onSubmenuOpen?.('close')
      }
      setActiveItem(item)
    },
    [onSubmenuOpen, setActiveItem],
  )

  return (
    <NavigationMenu
      value={activeItem}
      onValueChange={handleItemChange}
      className={cx(
        css({
          display: {
            base: 'none',
            lg: 'flex',
          },
        }),
      )}
    >
      <NavigationMenuList
        className={cx(
          css({
            display: 'flex',
            alignContent: 'center',
            flexDirection: 'row',
            gap: {
              base: 'semantic-spacing-12',
              xl: 'semantic-spacing-20',
            },
            color:
              theme === 'default'
                ? 'primary-navigation-color-default-nav-item-default'
                : 'primary-navigation-color-reverse-nav-item-default',
            flex: 1,
          }),
        )}
      >
        {menu.map((item, index) => (
          <NavigationMenuItem key={index} value={`nav-top-${index}`}>
            {!item.items ? (
              <NavigationMenuLink asChild>
                <NavItem item={item} />
              </NavigationMenuLink>
            ) : (
              <>
                <NavigationMenuTrigger
                  onPointerMove={(event) => event.preventDefault()}
                  onPointerLeave={(event) => event.preventDefault()}
                >
                  {item.title}
                </NavigationMenuTrigger>
                <NavigationMenuContent
                  onPointerEnter={(event) => event.preventDefault()}
                  onPointerLeave={(event) => event.preventDefault()}
                >
                  <Dropdown item={item} />
                </NavigationMenuContent>
              </>
            )}
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  )
}
