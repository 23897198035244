import { resolveMedia } from '@/utility/server/resolvers'

import type { TitleVariantProps } from '@/styled-system/recipes'

import type {
  TripItineraryConfig,
  TripItineraryDay,
  TripItineraryOption,
} from '@/types/components/itinerary'

export function mapFragmentToComponentConfig(
  config: Contentful.Models.ItineraryDetails,
  trip: Contentful.Models.Trip,
): TripItineraryConfig {
  return {
    id: config?.sys?.id,
    days:
      config.days?.items.filter((d) => d !== null).map(mapItineraryDay) ?? [],
    duration:
      config.days?.items.reduce(
        (sum, current) => sum + (current?.days ?? 0),
        0,
      ) ?? 0,
    journey: trip.journeyName,
    startDestination: trip.startDestination?.name,
    endDestination: trip.endDestination?.name,
    title: config.title || '',
    titleSize: config.titleSize as TitleVariantProps['size'],
    description: config.text!,
    map: config?.mapImage ? resolveMedia(config.mapImage) : null,
    pdfName: 'APT_EUMCR15_mar2024-dec2024_27/06/2024',
    travelStyle: trip.mainTravelStyle?.name,
    tripCode: trip.tripCode,
    tripName: trip.name,
    i18n: {
      // TODO: Move this to i18n
      'explore-itinerary': 'Explore itinerary',
      'save-pdf': 'Save PDF',
      'trip-duration': '{days} days / {nights} night {type}',
      'meal-label': 'Daily',
      'accommodation-label': 'Accommodation',
    },
  }
}

export function mapItineraryDay(
  day: ItineraryDayFragment,
  index: number,
): TripItineraryDay {
  return {
    id: day.sys.id,
    accommodation: day.accommodation,
    description: day.text!,
    hero: day?.media ? resolveMedia(day?.media) : null,
    heroCaption: day.mediaShortCaption,
    number: index + 1,
    inclusions: day?.meals && {
      label: 'Daily',
      items: day.meals.filter((m) => m != null),
    },
    shortDescription: day.options?.items?.[0]?.title ?? null,
    title: day.title,
    options:
      day.options?.items.filter((o) => o !== null).map(mapItineraryDayOption) ??
      [],
  }
}

export function mapItineraryDayOption(
  option: ItineraryOptionFragment,
): TripItineraryOption {
  const iconImage = option.icon ? resolveMedia(option.icon) : null

  return {
    title: option.title,
    description: option.text!,
    iconImage: iconImage,
    iconSvg: option.iconSvg,
  }
}
