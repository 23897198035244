import {
  AccordionPrimitiveRoot,
  AccordionPrimitiveContent,
  AccordionPrimitiveItem,
  AccordionPrimitiveTrigger,
} from '@/components/accordion/accordion-primitive'
import { Title } from '@/components/title'

import { GalleryCarousel } from '@/global/components/media-gallery-carousel-tabs/gallery-carousel'

import { css } from '@/styled-system/css'

import type { TitleProps } from '@/components/title'

import type { MediaGalleryTabsProps } from '@/types/components/media-gallery-carousel-tabs'

export function AccordionCarousel({
  items,
  title,
  titleSize,
  titleTag,
  backgroundVariant,
  livePreviewProps,
}: Readonly<MediaGalleryTabsProps>) {
  return (
    <>
      {title && (
        <Title
          format="standard"
          size={(titleSize || 'heading3') as TitleProps['size']}
          tag={titleTag || 'h2'}
          theme={backgroundVariant === 'default' ? 'dark' : 'light'}
          additionalStyles={css({ mb: 'semantic-spacing-40' })}
          {...(livePreviewProps?.title ?? {})}
        >
          {title}
        </Title>
      )}
      <AccordionPrimitiveRoot
        defaultValue="accordion-item-0"
        collapsible
        type="single"
        variant={backgroundVariant}
      >
        {items?.map((carousel, index) => {
          return (
            <AccordionPrimitiveItem
              key={`accordion-content-${index}`}
              value={`accordion-item-${index}`}
              px={0}
            >
              <AccordionPrimitiveTrigger
                _closed={{
                  color: 'semantic-primary-highlight',
                }}
              >
                {carousel.heading}
              </AccordionPrimitiveTrigger>
              <AccordionPrimitiveContent>
                <GalleryCarousel
                  key={`carousel-${index}`}
                  carouselItem={carousel.items}
                  colorVariantType={backgroundVariant || 'default'}
                />
              </AccordionPrimitiveContent>
            </AccordionPrimitiveItem>
          )
        })}
      </AccordionPrimitiveRoot>
    </>
  )
}
