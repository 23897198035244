'use client'
import { useEffect, useState } from 'react'
import { css } from '@/styled-system/css'
import { Box, Flex } from '@/styled-system/jsx'
import { MdMonetizationOn, MdAirplanemodeActive } from 'react-icons/md'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/collapsible'
import { useMediaQuery } from 'usehooks-ts'
import { useSite } from '@/providers/site'
import { Button } from '@/components/button'
import { TripTnCModal } from './trip-promo-modal'

type TripPromoProps = {
  icon: 'dollar' | 'airplane'
  title: string
  description: string
}
export function TripPromo() {
  const siteContext = useSite()
  const borderLeftColor =
    siteContext.brand.code?.toUpperCase() === 'APT'
      ? 'semantic-fg-accent-alt'
      : 'semantic-color-tags-promo-A-background'

  return (
    <Box
      className={css({
        my: 'semantic-spacing-24',
        md: { my: 'semantic-spacing-32' },
        lg: {
          '& .collapsible-arrow': {
            display: 'none',
          },
        },
      })}
    >
      <CollapsibleSwitch>
        <div
          className={css({
            borderLeftColor: borderLeftColor,
            borderRadius: 'semantic-border-radius-medium',
            borderLeftStyle: 'solid',
            borderLeft: '16px',
          })}
        >
          <CollapsibleTrigger>
            <Header icon={'airplane'} title="Flights included" />
          </CollapsibleTrigger>
          <CollapsibleContent>
            Savings (per person) include: Economy flights up to $2000 Early
            payment discount of $500 off Special deal of $500 off Early payment
            discount applies for payments made in full 10 months prior to
            departure attribution required.
            <TnC />
          </CollapsibleContent>
        </div>
      </CollapsibleSwitch>
    </Box>
  )
}

function CollapsibleSwitch({
  children,
}: React.ComponentProps<typeof Collapsible>) {
  const isMinWidthTablet = useMediaQuery('(min-width: 768px)')
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(isMinWidthTablet)
  }, [isMinWidthTablet])

  if (isMinWidthTablet)
    return <Collapsible open={isOpen}>{children}</Collapsible>
  else return <Collapsible>{children}</Collapsible>
}

function Header({ icon, title }: Omit<TripPromoProps, 'description'>) {
  return (
    <Flex>
      <div
        className={css({
          '& svg': {
            h: 'semantic-sizing-32',
            w: 'semantic-sizing-32',
          },
        })}
      >
        {icon === 'dollar' && <MdMonetizationOn />}
        {icon === 'airplane' && (
          <MdAirplanemodeActive
            className={css({ transform: 'rotate(45deg)' })}
          />
        )}
      </div>
      <span
        className={css({
          ml: 'semantic-spacing-8',
          color: 'semantic-color-tags-promo-A-text',
          textStyle: 'semantic-text-xl-font-bold',
        })}
      >
        {title}
      </span>
    </Flex>
  )
}

function TnC() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        css={{ pt: 'semantic-spacing-8' }}
        variant="ghost"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={css({
            textStyle: 'semantic-text-sm-font-regular-underline',
            color: 'semantic-color-tags-promo-a-text',
          })}
        >
          Terms & conditions
        </span>
      </Button>
      {isOpen ? <TripTnCModal modalControls={{ isOpen, setIsOpen }} /> : null}
    </>
  )
}
