import { css } from '@/styled-system/css'
import { Box, Divider, Flex } from '@/styled-system/jsx'

import Image from 'next/image'

import nextId from 'react-id-generator'

import { DialogBody } from '@/components/dialog'
import { RichtextRenderer } from '@/components/richtextEditor'
import { SVGRenderer } from '@/components/svgRenderer'

import type { TripItineraryConfig } from '@/types/components/itinerary'

export function Body({
  activeDay,
  config,
}: {
  activeDay: number
  config: TripItineraryConfig
}) {
  const day = config.days[activeDay - 1]

  return (
    <DialogBody>
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        css={{ '--day-spacing-x': 'spacing.semantic-spacing-56' }}
      >
        <Box pr={{ lg: 'calc(var(--day-spacing-x) / 2)' }} w={{ lg: '50%' }}>
          <p
            className={css({
              color: 'semantic-fg-muted',
              textStyle: 'semantic-text-xl-font-regular',
              mb: 'semantic-spacing-8',
              textTransform: 'uppercase',
            })}
          >
            Day {day.number}
          </p>
          <h2
            className={css({
              color: 'semantic-color-typography-headings-solid',
              textStyle: {
                base: 'semantic-heading-standard-md',
                lg: 'semantic-heading-standard-xl',
              },
            })}
          >
            {day.title}
          </h2>
          <Divider
            borderColor="semantic-fg-disabled"
            my="semantic-spacing-24"
            w="full"
          />

          <Box textStyle="semantic-text-xl-font-regular-spaced">
            <RichtextRenderer {...day.description} />
          </Box>

          {day.accommodation ? (
            <p
              className={css({
                alignItems: 'center',
                color: 'semantic-color-typography-body-solid',
                display: 'flex',
                my: 'semantic-spacing-32',
                textStyle: 'semantic-text-xl-font-regular',
              })}
            >
              <b
                className={css({
                  color: 'semantic-color-typography-body-solid',
                })}
              >
                {config.i18n?.['accommodation-label'] ?? 'Stay'}:&nbsp;
              </b>

              {day.accommodation}
            </p>
          ) : null}

          <p
            className={css({
              alignItems: 'center',
              color: 'semantic-color-typography-body-solid',
              display: 'flex',
              my: 'semantic-spacing-32',
              textStyle: 'semantic-text-xl-font-bold',
            })}
          >
            {day.inclusions?.items.length && day.inclusions?.label ? (
              <strong
                className={css({
                  color: 'semantic-color-typography-body-solid',
                })}
              >
                {day.inclusions?.label}:
              </strong>
            ) : null}

            {day.inclusions?.items.map((item) => (
              <span
                className={css({
                  ml: 'semantic-spacing-12',
                  textStyle: 'semantic-text-xl-font-regular',
                })}
                key={nextId('trip-itinerary-day-inclusion-')}
              >
                &middot;&nbsp;&nbsp;{item}
              </span>
            ))}
          </p>

          {day.options.map((option, key) => {
            return (
              <Flex
                alignItems="start"
                key={key}
                bg="semantic-bg-accent"
                borderRadius="semantic-border-radius-x-large"
                p="semantic-spacing-24"
                mb="semantic-spacing-32"
              >
                {option.iconSvg ? (
                  <SVGRenderer
                    className={css({
                      mr: 'semantic-spacing-16',
                      '& > svg': {
                        w: {
                          base: 'semantic-sizing-24',
                          md: 'semantic-sizing-32',
                        },
                        h: {
                          base: 'semantic-sizing-24',
                          md: 'semantic-sizing-32',
                        },
                      },
                    })}
                    svgString={option.iconSvg}
                  />
                ) : null}

                <Box>
                  <h3
                    className={css({
                      color: 'semantic-color-typography-headings-solid',
                      mb: 'semantic-spacing-8',
                      textStyle: 'semantic-text-lg-font-bold',
                    })}
                  >
                    {option.title}
                  </h3>

                  {option.description ? (
                    <Box
                      mb="semantic-spacing-0"
                      textStyle="semantic-text-lg-font-regular"
                    >
                      <RichtextRenderer {...option.description} />
                    </Box>
                  ) : null}
                </Box>
              </Flex>
            )
          })}
        </Box>

        {day.hero?.imageSource ? (
          <Box pl={{ lg: 'calc(var(--day-spacing-x) / 2)' }} w={{ lg: '50%' }}>
            <Box
              borderRadius="semantic-border-radius-x-large"
              overflow="hidden"
              pos="relative"
            >
              <Box
                bgGradient="linear-gradient(228.8deg, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.8) 85.91%)"
                backgroundBlendMode="multiply"
                pos="absolute"
                inset={0}
                pointerEvents="none"
                zIndex={1}
              />

              <Image
                className={css({
                  w: 'full',
                  aspectRatio: '16 / 9',
                  objectFit: 'cover',
                })}
                src={day.hero.imageSource}
                height="900"
                width="1600"
                alt={day.hero.alt}
              />

              {day.heroCaption ? (
                <p
                  className={css({
                    bottom: 0,
                    color: 'semantic-color-typography-body-reversed',
                    mb: 'semantic-spacing-24',
                    mx: 'semantic-spacing-32',
                    pos: 'absolute',
                    textStyle: 'semantic-text-xs-font-bold',
                    zIndex: 1,
                  })}
                >
                  {day.heroCaption}
                </p>
              ) : null}
            </Box>
          </Box>
        ) : null}
      </Flex>
    </DialogBody>
  )
}
