'use client'

import useEmblaCarousel from 'embla-carousel-react'
import { useState, useEffect, useCallback } from 'react'

import { Button } from '@/components/button'
import { Card, CardContent } from '@/components/card'
import { Carousel } from '@/components/carousel'
import { Image } from '@/components/image'
import { Control } from '@/components/carousel/nav-control'
import { SectionHeader } from '@/components/section-header'

import { ContentfulLivePreview } from '@contentful/live-preview'

import { css, cx } from '@/styled-system/css'
import { Box } from '@/styled-system/jsx'

import type { FullBleedSectionProps } from '@/types/components/bleed-carousel'

export const FullBleedCarousel = ({
  id,
  backgroundVariant,
  items,
  classNames,
  titleTag,
}: FullBleedSectionProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })
  const [currentSlide, setCurrentSlide] = useState(0)
  const [textFade, setTextFade] = useState(false)
  const filteredItems = items?.filter((item) => item?.image?.src)

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setTextFade(true)
    setTimeout(() => {
      setCurrentSlide(emblaApi.selectedScrollSnap())

      setTextFade(false)
    }, 300)
  }, [emblaApi, setCurrentSlide])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on('select', onSelect)
    return () => {
      emblaApi.off('select', onSelect)
    }
  }, [emblaApi, onSelect])

  if (!filteredItems) return null

  return (
    <Box
      id={id}
      bg={
        backgroundVariant === 'default'
          ? 'semantic-bg-default'
          : 'semantic-bg-reverse'
      }
      className={cx(
        css({
          position: 'relative',
          overflow: 'hidden',
          minW: '100dvw',
          h: 'auto',
          minHeight: '700px',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
          gridTemplateRows: '1fr',
          alignItems: 'start',
          justifyContent: 'start',
          transition: 'all 0.3s ease-out',
        }),
        classNames?.root,
      )}
    >
      <div
        className={cx(
          css({
            gridRow: '1 / span 1',
            zIndex: 1,
            height: 'min-content',
            width: {
              base: '100%',
              md: 'min-content',
            },
            display: 'grid',
            justifyContent: 'stretch',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr',
            flexDirection: 'column',
            alignItems: 'start',
            py: {
              base: 'semantic-spacing-32',
              md: 'semantic-spacing-64',
            },
            px: {
              base: 'semantic-spacing-16',
              md: 'semantic-spacing-64',
            },
          }),
          classNames?.content,
        )}
      >
        {filteredItems.map((slide, index) =>
          slide ? (
            <Card
              key={`${slide.id}_${index}`}
              className={cx(
                css({
                  gridRow: '1 / -1',
                  gridColumn: '1 /  -1',
                  visibility: currentSlide === index ? 'visible' : 'hidden',
                  opacity: currentSlide === index ? 1 : 0,
                  p: 'semantic-spacing-48',
                  minHeight: '480px',
                  height: '100%',
                  width: {
                    base: '100%',
                    md: 480,
                  },
                  background: 'semantic-bg-overlay',
                  backdropFilter: 'blur(5px)',
                  borderRadius: 'semantic-border-radius-medium',
                  justifyContent: 'space-between',
                }),
                classNames?.card,
              )}
            >
              <CardContent>
                <SectionHeader
                  {...ContentfulLivePreview.getProps({
                    entryId: slide.id,
                    fieldId: 'title',
                  })}
                  isReversed={backgroundVariant !== 'reverse'}
                  heading={slide.title || ''}
                  titleProps={{
                    size: 'heading3',
                    tag: titleTag || 'h3',
                    format: 'standard',
                    className: '',
                  }}
                  className={css({
                    transition: 'opacity 0.3s',
                    opacity: textFade ? 0 : 1,
                    mb: {
                      base: 'semantic-spacing-24',
                      md: 'semantic-spacing-24',
                    },
                  })}
                  description={slide.text}
                >
                  {slide.cta?.href && (
                    <Button size={{ base: 'sm', md: 'lg' }} {...slide.cta}>
                      {slide.cta?.title}
                    </Button>
                  )}
                </SectionHeader>
              </CardContent>
              <Control
                emblaApi={emblaApi}
                navSize={{ base: 'md', md: 'lg' }}
                loopToEdge
                variant="reverse"
              />
            </Card>
          ) : null,
        )}
      </div>
      <Carousel
        emblaRef={emblaRef}
        emblaWrapperStyle={cx(
          css({
            position: 'absolute',
            width: '100%',
            height: '100%',
            inset: 0,
            maxH: '100%',
            gridColumn: '1 / -1',
            gridRow: '1 / span 1',
          }),
          classNames?.carousel,
        )}
        emblaViewportStyle={css({
          height: '100%',
          width: '100dvw',
        })}
        emblaContainerStyle={css({
          height: '100%',
        })}
      >
        {filteredItems.map((slide, index) => {
          return slide?.image?.src ? (
            <div
              key={`${slide.id}_${index}`}
              className={cx(
                'embla__slide',
                css({
                  pl: 0,
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                }),
              )}
            >
              <Image
                src={slide.image.src}
                alt={slide.image?.alt ?? slide.title}
                fill
                sizes="100vw"
                quality={95}
              />
            </div>
          ) : null
        })}
      </Carousel>
    </Box>
  )
}
