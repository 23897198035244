import type { MenuItem } from '../desktopNav/dropdownItem'

export interface MenuItemSummary {
  hasChildren: boolean
  hasMultipleLevels: boolean
}

export const findChildren = (item: MenuItem): MenuItemSummary => ({
  hasChildren: Boolean(item?.items?.length && item.items.length > 0),
  hasMultipleLevels:
    Boolean(
      item?.items?.length &&
        item.items?.some(
          (subitem) => subitem.items?.length && subitem?.items?.length > 0,
        ),
    ) ?? false,
})
