import { ReactElement } from 'react'

export const createIcon = ({
  displayName,
  viewBox,
  path,
}: {
  displayName: string
  viewBox?: string
  path: ReactElement
}) => {
  const IconComponent = (props: React.ComponentProps<'svg'>) => (
    <svg
      className={props.className}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      {path}
    </svg>
  )

  IconComponent.displayName = displayName
  return IconComponent
}

export default createIcon
