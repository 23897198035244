'use client'
import React, { useState, useCallback } from 'react'
import { Sheet, SheetContent, SheetTrigger } from '@/components/sheet'
import { Button } from '@/components/button'
import { MdArrowBackIos, MdMenu } from 'react-icons/md'
import { SubNav } from './subNav'
import { css } from '@/styled-system/css'

// Define a more comprehensive MenuItem interface
export interface MenuItem {
  title: string
  items?: MenuItem[]
  image?: string | null
  href?: string
}

// Define an interface for the menu stack items
export interface MenuStackItem extends MenuItem {
  index: number
}

interface MobileNavigationProps {
  menuItems: MenuItem[]
}

export const MobileNavigation: React.FC<MobileNavigationProps> = ({
  menuItems,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [menuStack, setMenuStack] = useState<MenuStackItem[]>([
    { title: '', items: menuItems, image: null, index: 0 },
  ])

  const openSubmenu = useCallback((item: MenuItem) => {
    if (item.items) {
      setMenuStack((prev) => [...prev, { ...item, index: prev.length }])
    }
  }, [])

  const goBack = useCallback(() => {
    setMenuStack((prev) => prev.slice(0, -1))
  }, [])

  const handleSheetOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        setMenuStack([{ title: '', items: menuItems, image: null, index: 0 }])
      }
      setIsOpen(open)
    },
    [menuItems],
  )

  const currentItem = menuStack[menuStack.length - 1]

  return (
    <Sheet open={isOpen} onOpenChange={handleSheetOpenChange} side="right">
      <SheetTrigger
        className={css({
          width: '48px',
          justifyContent: 'center',
          alignItems: 'center',
          display: {
            base: 'inline-flex',
            lg: 'none',
          },
        })}
      >
        <MdMenu size={24} color="#ffffff" />
      </SheetTrigger>

      <SheetContent
        className={css({
          p: 0,
          maxW: 512,
          w: '100%',
          '& > button.sheet__contentClose': {
            position: 'absolute',
            top: 26,
            right: 24,
            width: 32,
            height: 32,
            '& svg': {
              width: 32,
              height: 32,
            },
          },
        })}
      >
        <div
          className={css({
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
          })}
        >
          {currentItem.title && (
            <Button
              variant="ghost"
              onClick={menuStack.length > 1 ? goBack : undefined}
              className={css({
                position: 'absolute',
                top: 'semantic-spacing-24',
                left: 'semantic-spacing-32',
                gap: 'semantic-spacing-8',
                fontFamily: 'font-family-logotype',
              })}
            >
              <MdArrowBackIos className="h-4 w-4" />
              {currentItem.index > 1 ? menuStack[1]?.title : 'Back'}
            </Button>
          )}
          {menuStack.map((item, index) => (
            <SubNav
              key={`${item.title}-${index}`}
              index={index}
              currentItem={currentItem}
              onOpenSubmenu={openSubmenu}
            />
          ))}
        </div>
      </SheetContent>
    </Sheet>
  )
}
