import { Box, type BoxProps } from '@/styled-system/jsx'

export function DottedBorder({
  direction,
  ...props
}: Readonly<
  {
    direction: 'horizontal' | 'vertical'
  } & Omit<BoxProps, 'bgImage' | 'bgPosition' | 'bgRepeat' | 'bgSize'>
>) {
  const bgPosition =
    direction === 'horizontal'
      ? 'calc(var(--spacing-semantic-spacing-4) * -1) 0'
      : '0 calc(var(--spacing-semantic-spacing-4) * -1)'

  const bgSize =
    direction === 'horizontal'
      ? 'var(--spacing-semantic-spacing-10) var(--spacing-semantic-spacing-2)'
      : 'var(--spacing-semantic-spacing-2) var(--spacing-semantic-spacing-10)'

  return (
    <Box
      bgImage={`/**/url('data:image/svg+xml,<svg width="2" height="2" viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="1" cy="1" r="1" fill="%23808080"/></svg>')`}
      bgPosition={bgPosition}
      bgRepeat={direction === 'horizontal' ? 'repeat-x' : 'repeat-y'}
      bgSize={bgSize}
      {...props}
    />
  )
}
