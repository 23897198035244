import { encryptRequest } from '@/utility/client/security'
import { error } from '@/utility/logging'
import { env } from '@/utility/environment'

import type { Algolia } from '@/types/algolia/base'

/**
 * Execute a request to Algolia thru route handler.
 *
 * @returns the response from Algolia.
 */

export async function fetchAlgoliaTripData<TReturn>({
  searchRequest,
  responseFormatMethod,
}: Readonly<Algolia.SearchAPIRequest>) {
  const filters = JSON.stringify({
    tripCode: searchRequest.tripCode,
    brand: searchRequest.brand,
    locale: searchRequest.locale,
    ...(searchRequest?.dateRange
      ? { dateRange: searchRequest?.dateRange }
      : {}),
  })

  const params = new URLSearchParams({
    distinct: searchRequest.distinct.toString(),
    filters,
    ...(responseFormatMethod ? { responseFormatMethod } : {}),
    ...(searchRequest.searchIndex
      ? { searchIndex: searchRequest.searchIndex }
      : {}),
  })

  try {
    const request = await encryptRequest(params.toString())

    const response = await fetch(
      `/api/algolia/?r=${encodeURIComponent(request)}`,
      {
        cache: 'no-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (response.ok && response.status === 200) {
      return (await response.json()) as TReturn
    }

    error('Algolia request failed', {
      params: env.name !== 'prod' ? params : undefined,
      response,
      request: env.name !== 'prod' ? searchRequest : undefined,
    })
  } catch (err) {
    error('Unexpected error while fetching Algolia data', {
      error: (err as Error).message,
      searchRequest,
    })
  }

  return null
}

/**
 * Get trip season data from Algolia.
 *
 * @param tripCode - The trip code.
 * @param brand - The brand.
 * @param locale - The locale.
 * @param dateRange - The date range.
 *
 * @returns the trip season data from Algolia.
 */

export async function getAvailablePricingForTrip({
  tripCode,
  brand,
  locale,
}: Readonly<Algolia.SearchRequestOption>) {
  const distinct = false

  const data =
    fetchAlgoliaTripData<AlgoliaSearchResult.AvailablePricingForTrip>({
      searchRequest: {
        brand,
        distinct,
        locale,
        tripCode,
        searchIndex: 'featured',
      },
      responseFormatMethod: 'availablePricingForTrip',
    })

  return data
}

/**
 * Get trip pricing data from Algolia.
 *
 * @param tripCode - The list of trip code.
 * @param brand - The brand.
 * @param locale - The locale.
 * @param dateRange - The date range.
 *
 * @returns lowest prices for trips within a date range.
 */
export async function getTripPricesByDateRange({
  tripCode,
  brand,
  locale,
  dateRange,
}: Readonly<Algolia.SearchRequestOption>) {
  const distinct = true

  const data = fetchAlgoliaTripData<AlgoliaSearchResult.TripPricesByDateRange>({
    searchRequest: {
      brand,
      dateRange,
      distinct,
      locale,
      tripCode,
    },
    responseFormatMethod: 'tripPricesByDateRange',
  })

  return data
}
