import { useId } from 'react'

export function Line({ colour }: Readonly<{ colour: `#${string}` }>) {
  const id = useId()

  return (
    <svg
      width="32"
      height="4"
      viewBox="0 0 32 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#line-${id})`}>
        <path
          d="M29.8836 4H2.1164C0.959436 4 0 3.09333 0 2C0 0.906667 0.959436 0 2.1164 0H29.8836C31.0406 0 32 0.906667 32 2C32 3.09333 31.0406 4 29.8836 4Z"
          fill={colour}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="32" height="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
