'use client'

import { useLiveUpdate } from '@/components/hooks/use-live-update'

import { Itinerary } from '@/global/components/trip-itinerary/trip-itinerary'
import { mapFragmentToComponentConfig } from '@/global/components/trip-itinerary/utils'

import { ItineraryDetailsDocument } from '@/graphql/client'

export function TripItineraryLivePreview({
  data: originalData,
  trip,
}: Readonly<{
  data: Contentful.Models.ItineraryDetails
  trip: Contentful.Models.Trip
}>) {
  const { entry: itinerary } = useLiveUpdate(
    originalData,
    ItineraryDetailsDocument,
  )

  const componentConfig = mapFragmentToComponentConfig(itinerary, trip)

  return <Itinerary config={componentConfig} trip={trip} />
}
