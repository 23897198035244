'use client'

import { styled } from '@/styled-system/jsx'
import { tabs, type TabsVariant } from '@/styled-system/recipes'

import * as TabsPrimitive from '@radix-ui/react-tabs'

import { createStyleContext } from '@/theme/support/style-context'

const { withProvider, withContext } = createStyleContext(tabs)

const Root = ({
  children,
  ...props
}: React.ComponentProps<TabsVariant & typeof TabsPrimitive.Root>) => (
  <TabsPrimitive.Root {...props}>{children}</TabsPrimitive.Root>
)

/**
 * @example
 * import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/tabs'
 *
 * export const ExampleTabs = () => {
 *   return (
 *     <Tabs defaultValue="account">
 *       <TabsList>
 *         <TabsTrigger value="account">Account</TabsTrigger>
 *         <TabsTrigger value="password">Password</TabsTrigger>
 *       </TabsList>
 *       <TabsContent value="account">Make changes to your account here.</TabsContent>
 *       <TabsContent value="password">Change your password here.</TabsContent>
 *     </Tabs>
 *   )
 * }
 */

export const Tabs = withProvider(styled(Root), 'root')

/**
 * @example
 * import { Tabs, TabsList, TabsTrigger } from '@/components/tabs'
 *
 * export const ExampleTabs = () => {
 *   return (
 *     <Tabs defaultValue="account">
 *       <TabsList>
 *         <TabsTrigger value="account">Account</TabsTrigger>
 *         <TabsTrigger value="password">Password</TabsTrigger>
 *       </TabsList>
 *     </Tabs>
 *   )
 * }
 */

export const TabsList = withContext(styled(TabsPrimitive.List), 'list')

/**
 * @example
 * import { TabsTrigger } from '@/components/tabs'
 *
 * export const ExampleTabs = () => {
 *   return (
 *     <TabsTrigger value="account">Account</TabsTrigger>
 *   )
 * }
 */

export const TabsTrigger = withContext(styled(TabsPrimitive.Trigger), 'trigger')

/**
 * @example
 * import { TabsContent } from '@/components/tabs'
 *
 * export const ExampleTabs = () => {
 *   return (
 *     <TabsContent value="account">Make changes to your account here.</TabsContent>
 *   )
 * }
 */

export const TabsContent = withContext(styled(TabsPrimitive.Content), 'content')
