import { css } from '@/styled-system/css'
import { VisuallyHidden } from '@/styled-system/jsx'

import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/dialog'

export const LightboxHeader = ({
  counter,
  desc,
  title,
}: Readonly<{
  counter: string
  desc?: string | React.ReactNode
  title?: string
}>) => {
  return (
    <DialogHeader>
      <VisuallyHidden>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>{desc}</DialogDescription>
      </VisuallyHidden>

      <span
        className={css({
          textStyle: 'semantic-text-md-font-regular',
          color: 'semantic-color-feedback-typography-reversed',
        })}
      >
        {counter}
      </span>
    </DialogHeader>
  )
}
