'use client'
import { useEffect } from 'react'

import { Section } from '@/styled-system/jsx'
import { css } from '@/styled-system/css'
import { AccordionSectionProps } from '@/types/components/accordion-section'
import type { TitleProps } from '@/components/title'
import { Button } from '@/components/button'
import { AccordionPrimitiveRoot } from './accordion-primitive'
import { SectionHeader } from '../section-header'
import { useCallback, useState } from 'react'
import omit from 'lodash/omit'
import { AccordionItem } from './accordion-item'

/**
 * Use useSearchParams hooks to pass down the opened accordion items
 * URL Example.
 *  ?accordion=3qQuiIiPPsLCtl3qG2uyhp,6BERc5SHY7MqgGEMpED8kb#faq
 * @returns
 *
 */
export function Accordion({
  id,
  title = '',
  text,
  titleSize,
  titleTag,
  cta,
  items = [],
  activeItems = [],
  livePreviewProps,
}: Readonly<AccordionSectionProps>) {
  const _titleCss = css({
    color: 'semantic-color-typography-headings-solid',
    textStyle: 'semantic-heading-standard-md',
    mb: 'semantic-spacing-24',
    md: { textStyle: 'semantic-heading-standard-xl' },
    xl: { textStyle: 'semantic-heading-standard-2xl' },
  })

  const [values, setValues] = useState<string[]>([])

  useEffect(() => {
    // Create a map of unique IDs
    const idMap = new Map(activeItems.map((item) => [item.id, true]))
    // Convert the map keys to an array for the Accordion's defaultValue
    setValues(Array.from(idMap.keys()))
  }, [activeItems])

  const onContentClick = useCallback((value: string) => {
    setValues((currentValues) =>
      currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value],
    )
  }, [])

  return (
    <Section id={id}>
      <SectionHeader
        heading={title || ''}
        description={text}
        titleProps={{
          size: (titleSize || 'heading3') as TitleProps['size'],
          tag: titleTag || 'h2',
          format: 'standard',

          ...(livePreviewProps?.title ?? {}),
        }}
        descriptionProps={{
          ...(livePreviewProps?.text ?? {}),
        }}
        overwriteHeadingStyles={css({
          mb: {
            base: 'semantic-spacing-24',
            md: 'semantic-spacing-24',
          },
        })}
      >
        {cta?.href && (
          <Button
            size={{ base: 'sm', md: 'lg' }}
            {...omit(cta, 'label')}
            {...(livePreviewProps?.cta ?? {})}
          >
            {cta.label}
          </Button>
        )}
      </SectionHeader>
      <AccordionPrimitiveRoot value={values} type="multiple">
        {items.map((item, index) => {
          if (!item?.id) return null
          return (
            <AccordionItem
              key={`${item.id}-${index}`}
              item={item}
              onContentClick={onContentClick}
            />
          )
        })}
      </AccordionPrimitiveRoot>
    </Section>
  )
}
