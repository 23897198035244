import {
  commonContentResolver,
  genericDataResolver,
  resolveAccordionItem,
} from '@/utility/content-resolvers'

import type {
  AccordionSectionProps,
  AccordionItem,
} from '@/types/components/accordion-section'
import type { PageSlug } from '@/types/resolved-types'

export const dataResolver = (
  brand: string,
  data: Contentful.Models.Accordion,
  isLead?: boolean,
  allSlugs?: PageSlug[],
): Promise<AccordionSectionProps> =>
  genericDataResolver<
    Contentful.Models.Accordion,
    AccordionItem | null,
    AccordionSectionProps
  >(
    data,
    brand,
    {
      showInNav: false,
      title: '',
      titleSize: 'heading3',
      text: '',
      items: [],
      cta: null,
    },
    {
      activeItems: {
        collectionKey: 'activeItems',
        resolver: async (item, context) => ({
          ...((await resolveAccordionItem(item as never, context, allSlugs)) ||
            null),
        }),
      },

      items: {
        collectionKey: 'items',
        resolver: async (item, context) => ({
          ...((await resolveAccordionItem(item as never, context, allSlugs)) ||
            null),
        }),
      },
    },
    async (resolvedData): Promise<AccordionSectionProps> => ({
      ...commonContentResolver(data as never, isLead),
      items: resolvedData.items || [],
      activeItems: resolvedData.activeItems || [],
    }),
  )
