'use client'

import { useState } from 'react'

import type { TitleProps } from '@/components/title'

import { TripWidgetHeader } from '@/global/components/trip-widget/heading'
import { TripWidget } from '@/global/components/trip-widget/trip-widget'

import { css, cx } from '@/styled-system/css'
import { Box, Section } from '@/styled-system/jsx'

import { TripPromo } from './components/trip-promo'

export default function TripWidgetSection({
  seasons,
  trips,
  isPreview,
}: Readonly<{
  locale?: string
  text?: string | React.ReactNode
  title?: string
  titleSize?: TitleProps['size']
  titleTag?: TitleProps['tag']
  seasons: Contentful.Models.TripSeason[]
  trips: Contentful.Models.Trip[]
  isPreview?: boolean
}>) {
  const defaultTrip = trips[0]
  const [trip, _setTrip] = useState(defaultTrip)

  return (
    <Section
      className={cx(
        css({
          mt: {
            base: 'semantic-spacing-40',
            md: 'semantic-spacing-64',
            lg: 'semantic-spacing-80',
          },
          display: 'grid',
          gridTemplateColumns: '1fr',
          pb: 'semantic-spacing-40',
          lg: {
            gridTemplateColumns: 'repeat(12, 1fr)',
            gap: 'semantic-spacing-32',
            pb: 'semantic-spacing-64',
          },
        }),
      )}
    >
      <Box
        className={cx(
          css({
            gridColumn: '1 / -1',
            md: {
              gridColumn: 'span 7',
            },
          }),
        )}
      >
        <TripWidgetHeader isPreview={isPreview} trip={trip} />
        <TripPromo />
      </Box>
      <Box
        className={cx(
          css({
            gridColumn: '1 / -1',
            md: {
              justifySelf: 'end',
              gridColumn: 'span 5',
            },
          }),
        )}
      >
        <TripWidget seasons={seasons} trips={trips} />
      </Box>
    </Section>
  )
}
