import {
  commonContentResolver,
  genericDataResolver,
  resolveTestimonial,
} from '@/utility/content-resolvers'
import type { PageSlug } from '@/types/resolved-types'

import type {
  TestimonialCarouselProps,
  TestimonialCardProps,
} from '@/types/components/testimonials-carousel'

export const dataResolver = (
  brand: string,
  data: Contentful.Models.SplitTestimonials,
  isLead?: boolean,
  slugs?: PageSlug[],
): Promise<TestimonialCarouselProps> =>
  genericDataResolver<
    Contentful.Models.SplitTestimonials,
    TestimonialCardProps | null,
    TestimonialCarouselProps
  >(
    data,
    brand,
    {
      showInNav: false,
      navTitle: '',
      title: '',
      titleSize: 'heading3',
      items: [],
    },
    {
      items: {
        collectionKey: 'items',
        resolver: async (item, context) => {
          const resolvedTestimonial = await resolveTestimonial(
            item as never,
            context,
            slugs,
          )

          if (resolvedTestimonial) {
            return resolvedTestimonial
          }

          return null
        },
      },
    },
    async (resolvedData): Promise<TestimonialCarouselProps> => ({
      ...commonContentResolver(data as never, isLead),
      items: resolvedData.items || [],
    }),
  )
