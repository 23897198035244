'use client'

import * as DialogPrimitive from '@radix-ui/react-dialog'

import * as React from 'react'
import { MdClose } from 'react-icons/md'

import { styled } from '@/styled-system/jsx'
import { css } from '@/styled-system/css'
import { dialog, DialogVariant } from '@/styled-system/recipes'

import { createStyleContext } from '@/theme/support/style-context'

const { withProvider, withContext } = createStyleContext(dialog)

const DialogPortal = withContext(styled(DialogPrimitive.Portal), 'portal')
const DialogOverlay = withContext(styled(DialogPrimitive.Overlay), 'overlay')
const DialogClose = withContext(styled(DialogPrimitive.Close), 'closeButton')

const Content = withContext(styled('section'), 'content')

const ContentContainer = ({
  ref,
  children,
  ...props
}: React.ComponentProps<typeof DialogPrimitive.Content>) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content ref={ref} {...props}>
      <Content>{children}</Content>
    </DialogPrimitive.Content>
  </DialogPortal>
)
ContentContainer.displayName = DialogPrimitive.Content.displayName

const Root = ({
  children,
  ...props
}: React.ComponentProps<DialogVariant & typeof DialogPrimitive.Root>) => (
  <DialogPrimitive.Root {...props}>{children}</DialogPrimitive.Root>
)

const Header = ({ children, ...props }: React.ComponentProps<'header'>) => (
  <>
    <header {...props}>{children}</header>
    <DialogClose>
      <MdClose />
      <span className={css({ srOnly: true })}>Close</span>
    </DialogClose>
  </>
)

/**
 * @example
 * ```jsx
 * import {
 *   Dialog,
 *   DialogContent,
 *   DialogDescription,
 *   DialogFooter,
 *   DialogHeader,
 *   DialogTitle,
 *   DialogTrigger,
 * } from '@/components/dialog';
 *
 * export const ExampleDialog = () => {
 *   return (
 *     <Dialog>
 *       <DialogTrigger asChild>
 *         <Button>Edit Profile</Button>
 *       </DialogTrigger>
 *       <DialogContent sm={{ maxW: '425px' }}>
 *         <DialogHeader>
 *           <DialogTitle>Edit profile</DialogTitle>
 *           <DialogDescription>
 *             Make changes to your profile here. Click save when you're done.
 *           </DialogDescription>
 *         </DialogHeader>
 *         <DialogFooter>
 *           <DialogTrigger asChild>
 *             <Button variant="secondary">Close</Button>
 *           </DialogTrigger>
 *           <Button type="submit">Save changes</Button>
 *         </DialogFooter>
 *       </DialogContent>
 *     </Dialog>
 *   );
 * };
 * ```
 */

export const Dialog = withProvider(styled(Root), 'root')

/**
 * @example
 * ```jsx
 * import { DialogTrigger, Button } from '@/components/dialog';
 *
 * export const ExampleDialogTrigger = () => {
 *   return (
 *     <DialogTrigger asChild>
 *       <Button>Edit Profile</Button>
 *     </DialogTrigger>
 *   );
 * };
 * ```
 */

export const DialogTrigger = withContext(
  styled(DialogPrimitive.Trigger),
  'trigger',
)

/**
 * @example
 * ```jsx
 * import { DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/dialog';
 *
 * export const ExampleDialogContent = () => {
 *   return (
 *     <DialogContent>
 *       <DialogHeader>
 *         <DialogTitle>Edit profile</DialogTitle>
 *         <DialogDescription>
 *           Make changes to your profile here. Click save when you're done.
 *         </DialogDescription>
 *       </DialogHeader>
 *     </DialogContent>
 *   );
 * };
 * ```
 */

export const DialogContent = withContext(styled(ContentContainer), 'container')

export const DialogBody = withContext(styled('div'), 'body')

/**
 * @example
 * ```jsx
 * import { DialogHeader, DialogTitle, DialogDescription } from '@/components/dialog';
 *
 * export const ExampleDialogHeader = () => {
 *   return (
 *     <DialogHeader>
 *       <DialogTitle>Edit profile</DialogTitle>
 *       <DialogDescription>
 *         Make changes to your profile here. Click save when you're done.
 *       </DialogDescription>
 *     </DialogHeader>
 *   );
 * };
 * ```
 */
export const DialogHeader = withContext(styled(Header), 'header')

/**
 * @example
 *
 * ```jsx
 * import { DialogFooter, DialogTrigger, Button } from '@/components/dialog';
 *
 * export const ExampleDialogFooter = () => {
 *   return (
 *     <DialogFooter>
 *       <DialogTrigger asChild>
 *         <Button variant="secondary">Close</Button>
 *       </DialogTrigger>
 *       <Button type="submit">Save changes</Button>
 *     </DialogFooter>
 *   );
 * };
 * ```
 */

export const DialogFooter = withContext(styled('div'), 'footer')

/**
 * @example
 * ```jsx
 * import { DialogTitle } from '@/components/dialog';
 *
 * export const ExampleDialogTitle = () => {
 *   return (
 *     <DialogTitle>Edit profile</DialogTitle>
 *   );
 * };
 * ```
 */
export const DialogTitle = withContext(styled(DialogPrimitive.Title), 'title')

/**
 * @example
 * ```jsx
 * import { DialogDescription } from '@/components/dialog';
 *
 * export const ExampleDialogDescription = () => {
 *   return (
 *     <DialogDescription>
 *       Make changes to your profile here. Click save when you're done.
 *     </DialogDescription>
 *   );
 * };
 * ```
 */
export const DialogDescription = withContext(
  styled(DialogPrimitive.Description),
  'description',
)
