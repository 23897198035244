import { css } from '@/styled-system/css'
import { Box, Flex } from '@/styled-system/jsx'

import { MdOutlineFitScreen } from 'react-icons/md'

const TRANSITION_DURATION = 'all 0.4s ease-in-out'

export const backgroundOverlay = css({
  overflow: 'hidden',
  _before: {
    content: '""',
    bg: 'semantic-bg-canvas',
    bottom: 0,
    left: 0,
    opacity: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: TRANSITION_DURATION,
    zIndex: 1,
    h: 'full',
    w: 'full',
  },
  _hover: {
    '&:before': {
      opacity: 0.5,
    },

    '&:hover .expand-control, .expand': {
      opacity: 1,
    },
  },
})

export const LightboxExpandControl = () => {
  return (
    <Flex
      className="expand-control"
      alignItems="center"
      color="semantic-color-primary-reverse-default-text"
      columnGap="semantic-spacing-8"
      flexDirection={{ base: 'row-reverse', lg: 'row' }}
      m="semantic-spacing-16"
      opacity={{ base: 1, lg: 0 }}
      pos="absolute"
      right={0}
      textStyle="semantic-text-md-font-bold"
      top={0}
      transition={TRANSITION_DURATION}
      zIndex={2}
    >
      <MdOutlineFitScreen
        className={css({
          color: 'semantic-color-primary-reverse-default-icon',
          h: 'semantic-sizing-32',
          w: 'semantic-sizing-32',
        })}
      />
      <Box className="expand" opacity={0} transition={TRANSITION_DURATION}>
        Expand
      </Box>
    </Flex>
  )
}
