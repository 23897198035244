import { css, cx } from '@/styled-system/css'
import { Button } from '@/components/button'
import { MdArrowForward } from 'react-icons/md'
import { Image } from '@/components/image'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardTitle,
} from '@/components/card'
import { ContentfulLivePreview } from '@contentful/live-preview'

import type { BrochureCard } from '@/types/components/brochure-card-carousel'

export function BrochureCard({ id, cta, image, title, text }: BrochureCard) {
  if (!image?.src || !id) return null

  return (
    <Card
      className={cx('embla__slide', 'card-carousel__slide', 'group')}
      pos="relative"
      {...ContentfulLivePreview.getProps({ entryId: id, fieldId: 'title' })}
    >
      <div
        className={css({
          position: 'relative',
          overflow: 'hidden',
          aspectRatio: '1/1.414',
          border: '1px solid var(--colors-semantic-fg-disabled)',
          objectFit: 'cover',
          maxH: { base: 480, md: 533 },
          maxW: { base: 340, md: 377 },
        })}
      >
        {cta?.href && (
          <a
            className={css({
              pos: 'absolute',
              w: 'full',
              h: 'full',
              zIndex: 1,
            })}
            href={cta.href}
            target="_blank"
          >
            <span
              className={css({
                visibility: 'hidden',
                position: 'absolute',
                width: 0,
                height: 0,
                fontSize: 0,
                overflow: 'hidden',
                opacity: 0,
              })}
            >
              {cta.label}
            </span>
          </a>
        )}
        <Image src={image.src} alt={image.alt} />
      </div>
      <CardContent mt="semantic-spacing-32">
        {title && <CardTitle variant="default">{title}</CardTitle>}
        {text && <CardDescription variant="default">{text}</CardDescription>}
      </CardContent>
      <CardFooter mt="semantic-spacing-16">
        {cta?.href && (
          <Button
            size={{ base: 'sm', md: 'lg' }}
            {...cta}
            rightIcon={<MdArrowForward />}
          >
            {cta.title}
          </Button>
        )}
      </CardFooter>
    </Card>
  )
}
