'use client'

import { css } from '@/styled-system/css'
import { Box, Divider, VisuallyHidden } from '@/styled-system/jsx'

import { Image } from '@/components/image'

import { useEffect, useRef, useState } from 'react'
import {
  ReactZoomPanPinchContextState,
  TransformComponent,
  TransformWrapper,
  useControls,
  useTransformEffect,
  type ReactZoomPanPinchContentRef,
} from 'react-zoom-pan-pinch'
import { MdAdd, MdRemove } from 'react-icons/md'
import { useDebounceCallback } from 'usehooks-ts'

import { Button } from '@/components/button'

import type { TripItineraryConfig } from '@/types/components/itinerary'

import './index.css'

const ZoomControl = ({ currentMinScale }: { currentMinScale: number }) => {
  const { zoomIn, zoomOut } = useControls()
  const [currentScale, setCurrentScale] = useState<number>(0)

  const transformEffect = ({ state }: ReactZoomPanPinchContextState) => {
    setCurrentScale(state.scale)
  }

  const debouncedTransformEffect = useDebounceCallback(transformEffect, 100)

  useTransformEffect(debouncedTransformEffect)

  return (
    <Box
      className="group"
      display="inline-flex"
      flexDirection="column"
      bottom="semantic-spacing-16"
      pos="absolute"
      right="semantic-spacing-16"
      zIndex={500}
    >
      <Button
        css={{
          px: 'semantic-spacing-16',
          py: 'semantic-spacing-12',
          w: 'semantic-sizing-40',
          borderTopRadius: 'semantic-border-radius-x-large',
          borderBottomRadius: '0',
          zIndex: 2,
        }}
        size="md"
        variant="overlay-reverse"
        disabled={currentScale > currentMinScale + 4}
        onClick={() => zoomIn()}
        icon={<MdAdd />}
      >
        <VisuallyHidden>Zoom map in</VisuallyHidden>
      </Button>
      <Divider
        borderColor="semantic-border-default"
        borderWidth="semantic-border-width-1"
      />
      <Button
        css={{
          px: 'semantic-spacing-16',
          py: 'semantic-spacing-12',
          w: 'semantic-sizing-40',
          borderTopRadius: '0',
          borderBottomRadius: 'semantic-border-radius-x-large',
        }}
        size="md"
        variant="overlay-reverse"
        disabled={currentScale === currentMinScale}
        onClick={() => zoomOut()}
        icon={<MdRemove />}
      >
        <VisuallyHidden>Zoom map out</VisuallyHidden>
      </Button>
    </Box>
  )
}

export default function ReactPinchPanZoom({
  config,
}: Readonly<{
  config: TripItineraryConfig
}>) {
  const pinchPanZoomRef = useRef<ReactZoomPanPinchContentRef>(null)

  useEffect(() => {
    if (pinchPanZoomRef.current) {
      pinchPanZoomRef.current.resetTransform()
      pinchPanZoomRef.current.centerView()
    }
  }, [])

  return (
    <TransformWrapper
      centerOnInit
      initialPositionX={100}
      initialPositionY={100}
      initialScale={1}
      minScale={1}
      pinch={{ step: 5 }}
      ref={pinchPanZoomRef}
      wheel={{ step: 1, smoothStep: 0.002 }}
    >
      <ZoomControl currentMinScale={1} />
      <TransformComponent
        wrapperClass="pinch-pan-zoom-wrapper"
        contentClass="pinch-pan-zoom-content"
        wrapperStyle={{ height: '100%', width: '100%' }}
        contentStyle={{ height: '100%', width: '100%' }}
      >
        {config.map?.imageSource ? (
          <Image
            className={css({ objectFit: 'cover', w: 'full', h: 'full' })}
            alt={config.map?.imageAlt ?? ''}
            src={config.map?.imageSource}
            fill
            sizes="100vw"
          />
        ) : null}
      </TransformComponent>
    </TransformWrapper>
  )
}
