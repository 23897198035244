'use client'

import { cx } from '@/styled-system/css'

import { Button, type ButtonProps } from '@/components/button'

import { MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md'

import type { EmblaCarouselType } from 'embla-carousel'

type UsePrevNextButtonsType = {
  currentActiveSlide: number
  isActive: boolean
  nextBtnDisabled: boolean
  onNextButtonClick: () => void
  onPrevButtonClick: () => void
  prevBtnDisabled: boolean
}

export type NavigationControlProps = Omit<
  ButtonProps,
  'css' | 'aria-label' | 'disabled'
> & {
  disabled: boolean
  isPrevBtn?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined,
  loopToEdge?: boolean,
): Readonly<UsePrevNextButtonsType> => {
  const [currentActiveSlide, setCurrentActiveSlide] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return

    if (loopToEdge && !emblaApi.canScrollPrev()) {
      emblaApi.scrollTo(emblaApi.scrollSnapList().length - 1)
      return
    }
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return

    if (loopToEdge && !emblaApi.canScrollNext()) {
      emblaApi.scrollTo(0)
      return
    }
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setCurrentActiveSlide(emblaApi.selectedScrollSnap())
    setPrevBtnDisabled(!loopToEdge && !emblaApi.canScrollPrev())
    setNextBtnDisabled(!loopToEdge && !emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    const updateState = () => {
      const isActive =
        emblaApi.internalEngine().options.active &&
        (emblaApi.canScrollNext() || emblaApi.canScrollPrev())

      setIsActive(isActive)
      setCurrentActiveSlide(emblaApi.selectedScrollSnap())
      onSelect(emblaApi)
    }

    updateState()

    emblaApi.on('reInit', updateState).on('select', updateState)

    return () => {
      emblaApi.off('reInit', updateState).off('select', updateState)
    }
  }, [emblaApi, onSelect])

  return {
    currentActiveSlide,
    nextBtnDisabled,
    onNextButtonClick,
    onPrevButtonClick,
    prevBtnDisabled,
    isActive,
  }
}

export function NavigationControl({
  disabled,
  isPrevBtn = false,
  onClick,
  ...props
}: Readonly<NavigationControlProps>) {
  const { className, size, ...rest } = props

  return (
    <Button
      className={cx(
        'embla__button',
        `embla__${isPrevBtn ? 'prev' : 'next'}`,
        className,
      )}
      {...rest}
      aria-label={isPrevBtn ? 'Previous slide' : 'Next slide'}
      disabled={disabled}
      onClick={onClick}
      icon={
        isPrevBtn ? <MdOutlineArrowBackIos /> : <MdOutlineArrowForwardIos />
      }
      size={size ?? 'lg'}
    />
  )
}
