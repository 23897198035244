'use client'
import { TripDocument } from '@/graphql/client'
import { useLiveUpdate } from '@/components/hooks/use-live-update'
import { OverviewHeader } from './trip-overview-header'

export function OverviewHeaderPreview({
  data: originalData,
}: Readonly<{
  data: Contentful.Models.Trip
}>) {
  const { entry: tripPage } = useLiveUpdate(originalData, TripDocument)
  return <OverviewHeader trip={tripPage} />
}
