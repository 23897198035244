// import { resolvePageSlug } from '@/utility/server/contentful'
import { MediaProps, ButtonProps } from '@/types/interface'
import type { ButtonProps as ButtonComponentProps } from '@/components/button'
import Link from 'next/link'

export type ResolvedCtaProps = ButtonComponentProps & {
  href: string | null
  title?: string
  target?: string
}

/* 
    This utility function sanitizes the query string
    and returns a sanitized string
*/
export const sanitizeQueryString = (queryString: string): string => {
  if (typeof queryString !== 'string') return ''

  return queryString
    .replace(/^\?/, '')
    .split('&')
    .map((pair) => {
      const [key, value] = pair.split('=').map((part) => part.trim())
      if (!key) return ''
      return `${encodeURIComponent(decodeURIComponent(key))}=${encodeURIComponent(decodeURIComponent(value || ''))}`
    })
    .filter(Boolean)
    .join('&')
}

/* 
    This utility function resolves the media from the contentful data
    and returns an object with the resolved media props
*/
export const resolveMedia = (media: MediaProps): Record<string, string> => {
  const { type, bynder, youtube } = media || {}
  const { original, alternativeText, description, internalId } =
    bynder?.[0] || {}
  return {
    ...(type === 'youtube' && {
      videoSource: youtube,
      posterImage: original?.url,
      videoType: type,
    }),
    ...(type === 'bynder' && { imageSource: original?.url }),
    alt: alternativeText || description || '',
    description: description || '',
    id: internalId || '',
  }
}

/* 
    This utility function resolves the CTA buttons from the contentful data
    and returns an array of objects with the resolved button props
*/
export const resolveCta = async (
  cta: { buttons?: ButtonProps[] },
  _locale: string,
  _brand: string,
): Promise<ResolvedCtaProps[]> => {
  if (!cta?.buttons) return []

  return Promise.all(
    cta.buttons.map(async (button) => {
      const {
        label,
        variant,
        type,
        email,
        phone,
        query,
        target,
        pageProps,
        href,
      } = button
      const sanitizedQuery = query ? sanitizeQueryString(query) : ''
      const parsedPageProps = pageProps ? JSON.parse(pageProps) : null

      let buttonProps: Record<string, string> = {}

      if (type === 'internal_link' && parsedPageProps) {
        // assemble the parent page object
        const _parentPage = parsedPageProps.parentPageId
          ? { sys: { __typename: 'Sys', id: parsedPageProps.parentPageId } }
          : null

        // TODO resolve the page slug
        /* const pageData = resolvePageSlug(
          brand,
          locale,
          parentPage,
          parsedPageProps.slug,
          parsedPageProps.pageId,
        ) */
        const pageData = null

        buttonProps = {
          as: Link as never,
          href: pageData
            ? sanitizedQuery
              ? `/${pageData}?${sanitizedQuery}`
              : `/${pageData}`
            : '/#',
        }
      } else if (type === 'external_link') {
        buttonProps = {
          as: 'a',
          href: href
            ? sanitizedQuery
              ? `${href}?${sanitizedQuery}`
              : href
            : '#',
          target: target || '_blank',
        }
      } else if (type === 'email') {
        buttonProps.href = `mailto:${email}`
      } else if (type === 'phone') {
        buttonProps.href = `tel:${phone}`
      }

      return {
        ...buttonProps,
        children: label,
        variant: variant as ResolvedCtaProps['variant'],
        href: buttonProps.href,
        title: label,
      }
    }),
  )
}
