import {
  genericDataResolver,
  resolveIconText,
  commonContentResolver,
} from '@/utility/content-resolvers'

import { css, cx } from '@/styled-system/css'

import type { InlineIconTextProps } from '@/components/inline-icon-text'

import type { SplitFeaturesSectionProps } from '@/types/components/split-features-section'
import type { PageSlug } from '@/types/resolved-types'

const itemStyles = {
  root: cx(
    css({
      display: 'flex',
      alignItems: 'start',
      md: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'start',
      },
    }),
  ),
  content: cx(
    css({
      textAlign: {
        base: 'left',
        md: 'left',
      },
      '& > p': {
        textStyle: 'semantic-text-md-font-regular',
        color: 'semantic-fg-accent-dark',
      },
    }),
  ),
  title: cx(
    css({
      textStyle: 'semantic-text-lg-font-bold',
      color: 'semantic-fg-accent-dark',
    }),
  ),
}
export const dataResolver = (
  brand: string,
  data: Contentful.Models.SplitFeatures,
  isLead?: boolean,
  slugs?: PageSlug[],
): Promise<SplitFeaturesSectionProps> =>
  genericDataResolver<
    Contentful.Models.SplitFeatures,
    InlineIconTextProps,
    SplitFeaturesSectionProps
  >(
    data,
    brand,
    {
      showInNav: false,
      navTitle: '',
      title: '',
      titleSize: 'heading3',
      text: '',
      items: [],
    },
    {
      items: {
        collectionKey: 'items',
        resolver: async (item, context) => ({
          ...(await resolveIconText(item as never, context, slugs)),
        }),
      },
    },
    async (resolvedData): Promise<SplitFeaturesSectionProps> => ({
      ...commonContentResolver(data as never, isLead),
      items:
        resolvedData.items?.map((item) => ({
          ...item,
          classNames: itemStyles,
        })) || [],
      variant: (data.variant ||
        'with-border') as SplitFeaturesSectionProps['variant'],
    }),
  )
