'use client'

import * as React from 'react'
import { format } from 'date-fns'
import DatePickerPrimitive from 'react-datepicker'
import { css } from '@/styled-system/css'
import { useState } from 'react'
import { YearsCustomHeader } from './utils'
import './styles.css'
import { MdExpandMore } from 'react-icons/md'
export type { DatePickerPrimitive }
/**
 * Provides a raw DatePicker component
 * This is an intentional abstraction to allow for loading styles.
 * @param props
 * @returns
 */
export function DatePickerRaw(
  props: React.ComponentProps<typeof DatePickerPrimitive>,
) {
  return <DatePickerPrimitive className={css({})} {...props} />
}

export type DatePickerProps = {
  startDate?: Date
  selectedDate: Date
  setSelectedDate: (date: Date) => void
} & React.ComponentProps<typeof DatePickerPrimitive>

const DatePickerStylesOverwrite = {
  position: 'relative',

  '& .react-datepicker': {
    bg: 'semantic-bg-default',
    boxShadow: 'semantic-shadow-xl',
    borderRadius: 'semantic-border-radius-medium',
    position: 'absolute !important',
    top: 0,
    left: 0,
    w: '100%',
    maxW: '344px',
  },
  '& .react-datepicker__month-text': {
    h: 'semantic-sizing-34',
    px: '23px',
    _hover: {
      bg: 'semantic-bg-default !important',
    },
  },
  '& .react-datepicker__month-wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    textStyle: 'semantic-text-sm-font-regular',
    color: 'semantic-color-primary-default-text-accent',
    textTransform: 'uppercase',
    '& .react-datepicker__month-text--selected': {
      textStyle: 'semantic-text-sm-font-regular !important',
      color: 'semantic-color-primary-default-text-accent !important',
      bg: 'semantic-bg-default !important',
    },
    '& .react-datepicker__month-text--today': {
      textStyle: 'semantic-text-sm-font-regular !important',
    },
  },
}
/**
 * Styled DatePicker component.
 * Should be as per design specs
 * @returns
 */
export function DatePicker({
  dateFormat = 'MMM yyyy',
  selectedDate,
  startDate = new Date(),
  includeDates,
  setSelectedDate,
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (date: Date | null) => {
    setIsOpen(!isOpen)

    if (date instanceof Date) {
      setSelectedDate(date)
    }
  }

  // TODO: Fix this typing
  const handleClick = (e: any) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }
  return (
    <div>
      <button
        className={css({
          w: 'full',
          display: 'flex',
          pt: 'semantic-spacing-10',
          pb: 'semantic-spacing-6',
          borderBottom: '1px solid',
          '& span': {
            textStyle: 'semantic-text-xl-font-regular',
            color: 'semantic-fg-default',
          },
          justifyContent: 'space-between',
          '& svg': {
            h: 'semantic-sizing-32',
            w: 'semantic-sizing-32',
          },
        })}
        type="button"
        onClick={handleClick}
      >
        <span>{format(selectedDate, dateFormat as string)}</span>{' '}
        <MdExpandMore />
      </button>
      <div className={css(DatePickerStylesOverwrite)}>
        {isOpen && (
          <DatePickerRaw
            inline
            onChange={handleChange}
            showMonthYearPicker
            showFourColumnMonthYearPicker
            renderCustomHeader={(p) => (
              <YearsCustomHeader {...p} startDate={startDate} />
            )}
            dateFormat={dateFormat}
            selected={selectedDate}
            includeDates={includeDates}
            onClickOutside={() => setIsOpen(!isOpen)}
            startDate={startDate}
          />
        )}
      </div>
    </div>
  )
}
