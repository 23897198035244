import omit from 'lodash/omit'

import { Button } from '@/components/button'

import { css } from '@/styled-system/css'
import { Box } from '@/styled-system/jsx'

import type { ResolvedCtaProps } from '@/types/resolved-types'

export function SlideContent({
  title,
  desc,
  cta,
}: Readonly<{
  title: string | null | undefined
  desc?: string | React.ReactNode
  cta?: ResolvedCtaProps
  slideInView: boolean
}>) {
  return (
    <Box mb="semantic-spacing-32">
      {title && (
        <h3
          className={css({
            color: 'semantic-color-typography-headings-solid',
            mb: 'semantic-spacing-24',
            textStyle: {
              base: 'semantic-heading-standard-md',
              md: 'semantic-heading-standard-xl',
            },
          })}
        >
          {title}
        </h3>
      )}
      {desc ? (
        <div
          className={css({
            color: 'semantic-color-typography-body-solid',
            mb: { base: 'semantic-spacing-24', lg: 'semantic-spacing-40' },
            textStyle: {
              base: 'semantic-text-md-font-regular',
              md: 'semantic-text-lg-font-regular-spaced',
            },
          })}
        >
          {desc}
        </div>
      ) : null}

      {cta?.href ? (
        <Button size={{ base: 'sm', md: 'lg' }} {...omit(cta, 'label')}>
          {cta.label}
        </Button>
      ) : null}
    </Box>
  )
}
