import { error } from '@/utility/logging'

export const getPlaceholderImage = (imageSource: string): string | null => {
  if (!imageSource) {
    error('Image source is required.')
    return ''
  }

  try {
    // Attempt to create a URL object to validate the URL
    const url = new URL(imageSource)

    // Set the placeholder parameter
    url.searchParams.set('w', '10')

    return url.toString()
  } catch (err) {
    // Check if the error is due to invalid URL
    if (err instanceof TypeError && err.message.includes('Invalid URL')) {
      error('Provided image source is not a valid URL:', imageSource)
    } else {
      error('Error processing the URL:', error)
    }

    return '' // If available replace with default placeholder image
  }
}
