export type ColorVariantType = 'default' | 'reverse'

export type ColorVariant = {
  background: string
  headingText: string
  bodyText: string
  carouselDots: string
}

type ColorVariants = {
  default: ColorVariant
  reverse: ColorVariant
}

export const colorVariants: ColorVariants = {
  default: {
    background: 'semantic-bg-accent',
    bodyText: 'semantic-color-typography-body-solid',
    carouselDots: 'semantic-primary-default',
    headingText: 'semantic-color-typography-headings-solid',
  },
  reverse: {
    background: 'semantic-bg-reverse',
    bodyText: 'semantic-color-typography-body-reversed',
    carouselDots: 'semantic-primary-subtle',
    headingText: 'semantic-color-typography-headings-reversed',
  },
}
