import { MdOutlinePhone, MdSearch } from 'react-icons/md'

import { Button } from '@/components/button'

import Link from '@/global/components/link'

import { css, cx } from '@/styled-system/css'

export const InfoLine = ({
  phone,
  newsLine,
  theme,
}: {
  phone?: string | null
  newsLine?: string | React.ReactNode | null
  theme?: 'default' | 'frosted'
}) => {
  const styles = {
    root: {
      borderBottom: '1px solid',
      borderColor: 'primary-navigation-color-default-border',
    },
    inner: {
      color:
        theme === 'default'
          ? 'primary-navigation-color-default-nav-item-default'
          : 'primary-navigation-color-reverse-nav-item-default',
      maxW: 1680,
      width: '100%',
      mx: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      minH: {
        base: '40px',
        lg: '44px',
      },
      px: {
        base: 'semantic-spacing-16',
        lg: 42,
      },
    },
    newsLine: {
      textStyle: {
        base: 'semantic-text-xs-font-bold',
        md: 'semantic-text-sm-font-bold',
      },
      letterSpacing: '0.1px',
    },
    button: {
      display: 'inline-flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontFamily: 'font-family-logotype',
      gap: 'semantic-spacing-10',
      color: 'inherit',
      _hover: {
        color: 'inherit',
      },
    },
  }

  return (
    <div className={cx(css(styles.root))}>
      <div className={cx(css(styles.inner))}>
        {newsLine && (
          <span
            className={cx(
              css({
                textStyle: {
                  base: 'semantic-text-xs-font-bold',
                  md: 'semantic-text-sm-font-bold',
                },
                letterSpacing: '0.1px',
              }),
            )}
          >
            {newsLine}
          </span>
        )}
        <div
          className={cx(
            css({
              display: {
                base: 'none',
                lg: 'inline-flex',
              },
              alignContent: 'center',
              gap: 'semantic-spacing-16',
            }),
          )}
        >
          {phone && (
            <Link
              href={`tel:${phone}`}
              className={css({
                ...styles.button,
                fontWeight: 'font-weight-bold',
                fontSize: 'font-size-4',
              })}
            >
              <MdOutlinePhone size={24} /> {phone}
            </Link>
          )}
          <Button
            variant="ghost"
            className={cx(
              css({
                ...styles.button,
                fontWeight: 'font-weight-regular',
                fontSize: 'font-size-2',
              }),
            )}
          >
            <MdSearch size={24} /> Search
          </Button>
        </div>
      </div>
    </div>
  )
}
