'use client'

import useEmblaCarousel from 'embla-carousel-react'

import { Carousel } from '@/components/carousel'
import { Control } from '@/components/carousel/nav-control'
import { SectionHeader } from '@/components/section-header'

import { Box, Section } from '@/styled-system/jsx'
import { cx } from '@/styled-system/css'

import type { TitleProps } from '@/components/title'

import type { ContentCardCarouselProps } from '@/types/components/content-card-carousel'

export function ContentCarousel({
  title,
  titleSize,
  text,
  cta,
  children,
  classNames,
  livePreviewProps,
  titleTag,
}: ContentCardCarouselProps) {
  const hasChildren =
    (Array.isArray(children) && children.length > 1) || children ? true : false

  const [emblaRef, emblaApi] = useEmblaCarousel({
    active: hasChildren,
    slidesToScroll: 'auto',
    align: 'start',
    breakpoints: {
      '(max-width: 1439px)': {
        loop: true,
      },
    },
  })

  if (!hasChildren) return null

  return (
    <Box overflow="hidden" w="full" className={cx(classNames?.root)}>
      <Section
        py={{
          base: 'semantic-spacing-32',
          md: 'semantic-spacing-64',
        }}
        className={cx(classNames?.section)}
      >
        <SectionHeader
          cta={cta}
          description={text}
          heading={title || ''}
          titleProps={{
            size: (titleSize || 'heading3') as TitleProps['size'],
            tag: titleTag || 'h2',
            format: 'standard',
            ...(livePreviewProps?.title ?? {}),
          }}
          descriptionProps={{
            ...(livePreviewProps?.text ?? {}),
          }}
          mb={{ base: 'semantic-spacing-24', md: 'semantic-spacing-48' }}
          className={cx(classNames?.header)}
        />

        <Carousel emblaRef={emblaRef} emblaWrapperStyle="embla--card-carousel">
          {children}
        </Carousel>

        <Control
          emblaApi={emblaApi}
          navSize={{ base: 'md', md: 'lg' }}
          loopToEdge
        />
      </Section>
    </Box>
  )
}
