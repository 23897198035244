import React from 'react'
import { MdArrowForwardIos } from 'react-icons/md'

import { Button } from '@/components/button'

export interface MenuItem {
  title: string
  href?: string
  items?: MenuItem[]
  image?: string
}

export const DropdownItem = ({
  item,
  isActive,
  onActivate,
}: {
  item: MenuItem
  isActive: boolean
  onActivate: () => void
}) => (
  <li role="none">
    <Button
      as={item.items ? 'button' : 'a'}
      css={{
        width: '100%',
        minHeight: 'semantic-sizing-40',
        textAlign: 'left',
        justifyContent: 'space-between',
        px: 'semantic-spacing-6',
        _hover: { backgroundColor: 'semantic-bg-accent' },
        '&[aria-expanded="true"]': { backgroundColor: 'semantic-bg-accent' },
        _focus: { outline: '2px solid blue', outlineOffset: '2px' },
      }}
      variant="ghost"
      href={item.href}
      onMouseEnter={onActivate}
      onFocus={onActivate}
      role="menuitem"
      aria-haspopup={item.items ? 'true' : 'false'}
      aria-expanded={isActive ? 'true' : 'false'}
    >
      {item.title}
      {item.items && <MdArrowForwardIos aria-hidden="true" />}
    </Button>
  </li>
)
