import { getDaysString } from '@/global/components/trip-widget/utils'
import { LabelStyled } from './label-styled'
import { css } from '@/styled-system/css'

export function Info({ duration }: Readonly<{ duration: number }>) {
  return (
    <div
      className={css({
        '& div': {
          mt: '18px',
          textStyle: 'semantic-text-xl-font-regular',
          color: 'semantic-fg-default',
        },
      })}
    >
      <LabelStyled>Travel Information</LabelStyled>
      <div>{getDaysString(duration)} Tour</div>
    </div>
  )
}
