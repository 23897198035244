export function FreedomOfChoiceStop() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3648 13.7073V18.5558H18.3187V13.7073H13.3648ZM15.9998 2.6665C17.8444 2.6665 19.5747 3.01784 21.1909 3.72052C22.807 4.4232 24.2124 5.3806 25.407 6.59272C26.6191 7.78728 27.5765 9.19264 28.2792 10.8088C28.9818 12.425 29.3332 14.1553 29.3332 15.9998C29.3332 17.8444 28.9818 19.5747 28.2792 21.1909C27.5765 22.807 26.6191 24.2212 25.407 25.4333C24.2124 26.6279 22.807 27.5765 21.1909 28.2792C19.5747 28.9818 17.8444 29.3332 15.9998 29.3332C14.1553 29.3332 12.425 28.9818 10.8088 28.2792C9.19264 27.5765 7.77849 26.6279 6.56637 25.4333C5.37182 24.2212 4.4232 22.807 3.72052 21.1909C3.01784 19.5747 2.6665 17.8444 2.6665 15.9998C2.6665 14.1553 3.01784 12.425 3.72052 10.8088C4.4232 9.19264 5.37182 7.78728 6.56637 6.59272C7.77849 5.3806 9.19264 4.4232 10.8088 3.72052C12.425 3.01784 14.1553 2.6665 15.9998 2.6665ZM22.1395 15.6836L26.7245 12.7324L22.1395 9.78113V11.9946H13.3648V10.1237H15.4728L12.5216 5.5387L9.59668 10.1237H11.6784V18.5558H9.88653V16.5005L5.30155 19.4517L9.86018 22.403V20.295H18.3714V22.2976H16.2633L19.2146 26.8826L22.1395 22.2976H20.0578V13.7337H22.1395V15.6836Z"
        fill="var(--colors-semantic-primary-default)"
      ></path>
    </svg>
  )
}
