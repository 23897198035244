'use client'

import { differenceInMonths, addMonths, isSameMonth } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { MdEast } from 'react-icons/md'

import { css } from '@/styled-system/css'

import { error } from '@/utility/logging'
import { getAvailablePricingForTrip } from '@/utility/client/algolia'

export function useTripsData(
  seasons: Contentful.Models.TripSeason[],
  trips: Contentful.Models.Trip[],
  brand: string,
  locale: string,
) {
  const routeList = getRouteList(trips)

  const { selectedRoute, setSelectedRoute } = useRoutSelection(trips)

  const { dateList, selectedDate, setSelectedDate, selectedSeason } =
    useSeasonsDateList(seasons, selectedRoute.preferredSeason?.sys.id || '')

  const { selectedDatePrice, availableTrips, isLoading } = useTripPricingInfo({
    brand,
    locale,
    tripCode: selectedRoute.tripCode!,
    selectedDate,
    setSelectedDate,
  })

  return {
    availableTrips,

    dateList,
    routeList,
    selectedSeason,

    selectedDate,
    setSelectedDate,
    selectedRoute,
    setSelectedRoute,

    selectedDatePrice,
    isLoading,
  }
}

function useRoutSelection(trips: Contentful.Models.Trip[]) {
  const [selectedRoute, setSelected] = useState(trips[0])

  return {
    selectedRoute,
    setSelected,

    setSelectedRoute(id: string) {
      const found = trips.find((trip) => trip.sys.id === id) || trips[0]

      setSelected(found)
    },
  }
}

/**
 * Creates a list of routes for the dropdown component.
 *
 * @param trips
 * @returns
 */
function getRouteList(trips: Contentful.Models.Trip[]) {
  return trips.map((trip) => ({
    text: trip.journeyName ? (
      trip.journeyName
    ) : (
      <>
        <span>
          {trip.startDestination?.name}
          <span
            className={css({
              '& svg': {
                display: 'inline',
                h: 'semantic-sizing-24',
                w: 'semantic-sizing-24',
                mx: 'semantic-spacing-8',
              },
            })}
          >
            <MdEast />
          </span>
          <span>{trip.endDestination?.name}</span>
        </span>
      </>
    ),
    value: trip.sys?.id as string,
  }))
}

/**
 * //TODO this needs to be refactored to use API data
 * @param trips Creates a List of available list
 * @returns
 */
function useSeasonsDateList(
  seasons: Contentful.Models.TripSeason[],
  preferredSeasonId: string,
) {
  const [selectedSeason, setSelectedSeason] =
    useState<Contentful.Models.TripSeason>(
      seasons.find((season) => season.sys.id === preferredSeasonId) ??
        seasons[0],
    )

  const dateList = seasons.flatMap((season) => {
    const startDate = new Date(season.validFrom)
    const endDate = new Date(season.validTo)

    const difference = Math.abs(differenceInMonths(endDate, startDate)) + 1

    const dates = Array.from({ length: difference }, (_, i) =>
      addMonths(startDate, i),
    )
    // const dates= [startDate, endDate]
    return dates
  })

  const [selectedDate, setSelectedDateInternal] = useState(
    selectedSeason.preferredDate ?? new Date(),
  )
  const setSelectedDate = (date: Date) => {
    setSelectedDateInternal(date)
    setSelectedSeason(getSelectedSeasonFromDate(seasons, date) ?? seasons[0])
  }

  return {
    dateList,
    selectedDate,
    setSelectedDate,
    selectedSeason,
  }
}

function getSelectedSeasonFromDate(
  seasons: Contentful.Models.TripSeason[],
  date: Date,
) {
  return seasons.find(
    (season) =>
      date >= new Date(season.validFrom) && date <= new Date(season.validTo),
  )
}

// TODO Buidl a more robust currency formatter and make it a utility function
export function currencyFormatter(amount: number, locale: string) {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'AUD', // Get this from the BRAND Config
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount)
}

/**
 * 
 String "day" or "days" based on the number of days
 */
export function getDaysString(days: number) {
  return `${days} ${days === 1 ? 'day' : 'days'}`
}

function useTripPricingInfo({
  brand,
  locale,
  tripCode,
  selectedDate,
  setSelectedDate,
}: {
  brand: string
  locale: string
  tripCode: string
  selectedDate: Date
  setSelectedDate: (date: Date) => void
}) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [tripPricing, setTripPricing] =
    useState<AlgoliaSearchResult.AvailablePricingForTrip | null>(null)

  useEffect(() => {
    if (!tripCode) {
      return
    }

    const fetchData = async () => {
      try {
        const pricingData = await getAvailablePricingForTrip({
          brand,
          locale,
          tripCode: [tripCode],
        })

        if (pricingData) {
          setTripPricing(pricingData)
          setIsLoading(false)

          const initialDate = pricingData?.[0]?.date || null

          if (initialDate) {
            setSelectedDate(initialDate)
          }
        }
      } catch (err) {
        error('Error fetching trip season pricing:', err)
      }
    }

    fetchData()
  }, [brand, locale, tripCode])

  const selectedDatePrice = useMemo(
    () =>
      tripPricing?.find(
        (pricing) =>
          pricing?.date &&
          selectedDate &&
          isSameMonth(selectedDate, pricing?.date),
      )?.prices,
    [selectedDate, tripPricing],
  )

  const hasNoAvailableTrips =
    tripPricing && !tripPricing?.find((pricing) => pricing.prices)

  return {
    isLoading,
    selectedDatePrice,
    availableTrips: hasNoAvailableTrips
      ? []
      : (tripPricing ?? []).map((pricing) => pricing.date as Date),
  }
}
