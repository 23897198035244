'use client'

import { css } from '@/styled-system/css'

import { AnimatePresence, motion } from 'framer-motion'
import { MdOutlineChevronRight } from 'react-icons/md'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/tabs'
import { Title } from '@/components/title'
import { GalleryCarousel } from '@/global/components/media-gallery-carousel-tabs/gallery-carousel'
import type { MediaGalleryTabsProps } from '@/types/components/media-gallery-carousel-tabs'
import type { TitleProps } from '@/components/title'
import { ContentfulLivePreview } from '@contentful/live-preview'

export function TabsCarousel({
  items,
  title,
  titleSize,
  titleTag,
  backgroundVariant,
  livePreviewProps,
}: Readonly<MediaGalleryTabsProps>) {
  return (
    <Tabs
      orientation="vertical"
      display="flex"
      defaultValue="tab-0"
      columnGap="semantic-spacing-56"
      variant={backgroundVariant}
    >
      <TabsList
        alignItems="flex-start"
        flex={{ lg: '0 0 240px', xl: '0 0 296px' }}
        flexDir="column"
        h="full"
      >
        {title && (
          <Title
            format="standard"
            tag={titleTag || 'h2'}
            size={(titleSize || 'heading3') as TitleProps['size']}
            theme={backgroundVariant === 'default' ? 'dark' : 'light'}
            additionalStyles={css({ mb: 'semantic-spacing-40' })}
            {...(livePreviewProps?.title ?? {})}
          >
            {title}
          </Title>
        )}
        {items.map((item, index) => (
          <TabsTrigger
            py="semantic-spacing-16"
            key={`tab-trigger-${index}`}
            value={`tab-${index}`}
            textStyle="semantic-text-2xl-font-regular"
            px={0}
            w="full"
            {...ContentfulLivePreview.getProps({
              entryId: item?.id,
              fieldId: 'title',
            })}
          >
            {item?.heading}

            <span className={css({ ml: 'auto', pl: 'semantic-spacing-8' })}>
              <MdOutlineChevronRight size={32} />
            </span>
          </TabsTrigger>
        ))}
      </TabsList>
      {items.map((carousel, index) => {
        return (
          <TabsContent key={`tab-content-${index}`} value={`tab-${index}`}>
            <AnimatePresence mode="wait">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8 }}
              >
                <GalleryCarousel
                  key={`carousel-${index}`}
                  carouselItem={carousel.items}
                  colorVariantType={backgroundVariant || 'default'}
                />
              </motion.div>
            </AnimatePresence>
          </TabsContent>
        )
      })}
    </Tabs>
  )
}
