import omit from 'lodash/omit'

import { Button } from '@/components/button'
import { SectionHeader } from '@/components/section-header'

import { GalleryCarousel } from '@/global/components/two-column-gallery-carousel/gallery-carousel'

import { css } from '@/styled-system/css'
import { Section } from '@/styled-system/jsx'

import type { TwoColumnGalleryCarouselConfig } from '@/types/components/two-column-gallery-carousel'
import type { TitleProps } from '@/components/title'

const overwriteHeadingStyles = css({
  textStyle: {
    base: 'semantic-heading-standard-lg',
    md: 'semantic-heading-standard-2xl',
  },
})

export const TwoColumnGalleryCarouselSection = ({
  props,
}: {
  props: TwoColumnGalleryCarouselConfig
}) => {
  if (!props?.items?.length) return null

  return (
    <Section
      py={{
        base: 'semantic-spacing-32',
        md: 'semantic-spacing-64',
      }}
    >
      <SectionHeader
        heading={props.title || ''}
        description={props.text}
        overwriteHeadingStyles={overwriteHeadingStyles}
        titleProps={{
          size: (props?.titleSize || 'heading3') as TitleProps['size'],
          tag: props.titleTag || 'h2',
          format: 'standard',
          ...(props?.livePreviewProps?.title ?? {}),
        }}
        descriptionProps={{
          ...(props?.livePreviewProps?.text ?? {}),
        }}
      >
        {props.cta?.href && (
          <Button
            size={{ base: 'sm', md: 'lg' }}
            {...omit(props.cta, 'label')}
            {...(props?.livePreviewProps?.cta ?? {})}
          >
            {props.cta.label}
          </Button>
        )}
      </SectionHeader>
      <GalleryCarousel sliderItems={props?.items} />
    </Section>
  )
}
