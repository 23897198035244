'use client'

import { Dialog, DialogContent } from '@/components/dialog'
import { UseDisclosureReturn } from '@/components/hooks/use-disclosure'

export function Lightbox({
  disclosure: { onClose, isOpen },
  children,
}: Readonly<{
  disclosure: UseDisclosureReturn
  children: React.ReactNode
}>) {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={onClose}
      size="fullscreen"
      variant="lightbox"
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}
