import { css, cx } from '@/styled-system/css'
import { Box } from '@/styled-system/jsx'
import { Button } from '@/components/button'
import type { ResolvedCtaProps } from '@/types/resolved-types'
import { DialogFooter } from '@/components/dialog'
import omit from 'lodash/omit'
import { MdArrowForward } from 'react-icons/md'

export const LightboxFooter = ({
  caption,
  shortCaption,
  cta,
}: Readonly<{
  caption?: string | React.ReactNode
  shortCaption?: string
  cta?: ResolvedCtaProps
}>) => {
  return (
    <DialogFooter
      bottom={0}
      columnGap="semantic-spacing-16"
      justifyContent="space-between"
      maxWidth={1344}
      minH={{ base: 112, lg: 136 }}
      mx="auto"
      pos="sticky"
      w="full"
    >
      <Box>
        {shortCaption ? (
          <p
            className={css({
              textStyle: 'semantic-text-md-font-bold',
              color: 'semantic-color-feedback-typography-reversed',
            })}
          >
            {shortCaption}
          </p>
        ) : null}
        {caption ? (
          <div
            className={css({
              display: { base: 'none', lg: 'block' },
              '& > p, & > span': {
                textStyle: 'semantic-text-sm-font-regular',
                color: 'semantic-color-feedback-typography-reversed',
              },
            })}
          >
            {caption}
          </div>
        ) : null}
      </Box>
      {cta ? (
        <Button
          size={{ base: 'sm', md: 'lg' }}
          className={cx(
            css({
              width: 'fit-content',
              minWidth: 'fit-content',
            }),
          )}
          rightIcon={
            <MdArrowForward className={css({ ml: 'semantic-spacing-8' })} />
          }
          {...omit(cta, ['label'])}
        >
          {cta.label}
        </Button>
      ) : null}
    </DialogFooter>
  )
}
