'use client'

import Fade from 'embla-carousel-fade'
import useEmblaCarousel from 'embla-carousel-react'

import { useMediaQuery } from 'usehooks-ts'

import { Carousel } from '@/components/carousel'
import { usePrevNextButtons } from '@/components/carousel/btn-prev-next'
import { Control } from '@/components/carousel/nav-control'

import { CarouselSlide } from '@/global/components/two-column-gallery-carousel/gallery-carousel-slide'

import { css, cx } from '@/styled-system/css'

import type { TwoColumnGalleryCarouselConfig } from '@/types/components/two-column-gallery-carousel'

const wrapperStyles = css({
  overflow: 'hidden',
  pos: 'relative',
})

export function GalleryCarousel({
  sliderItems = [],
}: Readonly<{ sliderItems: TwoColumnGalleryCarouselConfig['items'] }>) {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { active: sliderItems?.length > 1, duration: 40 },
    [Fade()],
  )

  const { currentActiveSlide } = usePrevNextButtons(emblaApi)

  const isMobile = useMediaQuery('(max-width: 767px)', {
    initializeWithValue: false,
  })

  if (!sliderItems.length) return null

  return (
    <>
      <Carousel
        emblaRef={emblaRef}
        emblaWrapperStyle={cx(wrapperStyles, 'no-slide-spacing')}
      >
        {sliderItems?.map((slide, index) => (
          <CarouselSlide
            slideInView={currentActiveSlide === index}
            key={`slide-${index}_${slide.id}`}
            slide={slide}
            emblaApi={emblaApi}
            isMobile={isMobile}
          />
        ))}
      </Carousel>
      {isMobile ? <Control emblaApi={emblaApi} navSize="md" /> : null}
    </>
  )
}
