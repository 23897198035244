import { Dialog, DialogContent } from '@/components/dialog'
import { UseDisclosureReturn } from '@/components/hooks/use-disclosure'

import { Header } from '@/global/components/trip-itinerary/dialog/dialog-header'
import { Body } from '@/global/components/trip-itinerary/dialog/dialog-body'
import { Footer } from '@/global/components/trip-itinerary/dialog/dialog-footer'

import type { TripItineraryConfig } from '@/types/components/itinerary'

export function TripItineraryDetailModal({
  activeDay,
  config,
  disclosure: { onClose, isOpen },
  onDaySelect,
}: Readonly<{
  activeDay: number
  config: TripItineraryConfig
  disclosure: UseDisclosureReturn
  onDaySelect: (day: number) => void
}>) {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={onClose}
      size="fullscreen"
      scrollBehavior="inside"
    >
      <DialogContent minH="full">
        <Header
          activeDay={activeDay}
          config={config}
          onDaySelect={onDaySelect}
        />

        <Body activeDay={activeDay} config={config} />

        <Footer
          activeDay={activeDay}
          config={config}
          onDaySelect={onDaySelect}
        />
      </DialogContent>
    </Dialog>
  )
}
