import { css } from '@/styled-system/css'

import { PriceTripCode } from '@/global/components/trip-widget/components/trip-price'
import { CheckAvailability, SpeakToAnExpert } from './ctas'

export function BookingOptions({
  price,
  basePrice,
  tripCode,
}: Readonly<{
  price: number
  basePrice: number
  tripCode: string
}>) {
  return (
    <>
      <PriceTripCode price={price} base={basePrice} tripCode={tripCode} />
      <div
        className={css({
          mt: 'semantic-spacing-24',
          md: {
            mt: 'semantic-spacing-48',
          },
        })}
      >
        <CheckAvailability />
        <SpeakToAnExpert />
      </div>
    </>
  )
}
