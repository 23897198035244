'use client'

import React, { useMemo, useState, useCallback } from 'react'
import { MdOutlinePhone, MdSearch } from 'react-icons/md'

import { Image } from '@/components/image'
import { Button } from '@/components/button'

import Link from '@/global/components/link'

import { css, cx } from '@/styled-system/css'

import { Navigation } from './desktopNav/nav'
import { InfoLine } from './infoLine'
import { MobileNavigation } from './mobileNav/mobileNav'
import logo from './tempAssets/Logo.svg'

interface MenuItem {
  title: string
  href?: string
  items?: MenuItem[]
  image?: string
}

interface HeaderComponentProps {
  menu: MenuItem[]
  phone?: string | null
  newsLine?: string | React.ReactNode | null
  theme: 'default' | 'frosted'
}

type State = 'open' | 'closed'

export const HeaderComponent: React.FC<HeaderComponentProps> = ({
  theme = 'frosted',
  menu,
  phone,
  newsLine,
}) => {
  const [themeState, setThemeState] = useState(theme)

  const handleOnSubmenuOpen = useCallback((state: State) => {
    const body = document.body

    if (state === 'open') {
      body.setAttribute('data-nav-state', 'open')
    } else {
      body.removeAttribute('data-nav-state')
    }

    setThemeState(state === 'open' ? 'default' : 'frosted')
  }, [])
  const infoLine = useMemo(
    () => <InfoLine phone={phone} newsLine={newsLine} theme={themeState} />,
    [themeState],
  )

  const navBar = useMemo(() => {
    return (
      <div
        className={cx(
          'navWrapper',
          css({
            width: '100%',
            position: 'relative',
          }),
        )}
      >
        <div
          className={cx(
            'navContainer',
            css({
              width: '100%',
              display: 'flex',
              gap: 'semantic-spacing-16',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: {
                base: 'space-between',
                lg: 'flex-start',
              },
              px: {
                base: 'semantic-spacing-16',
                lg: 42,
              },
              mx: 'auto',
              maxW: 1680,
            }),
          )}
        >
          <Link
            href={'/'}
            className={cx(
              css({
                display: 'block',
                aspectRatio: 1,
                maxH: {
                  base: '64px',
                  lg: '96px',
                },
                maxW: {
                  base: '64px',
                  lg: '96px',
                },
                flexBasis: {
                  base: '64px',
                  lg: '96px',
                },
                position: 'relative',
              }),
            )}
          >
            <Image src={logo.src} alt="Brand logo" />
          </Link>
          <Navigation
            menu={menu}
            theme={themeState}
            onSubmenuOpen={(state) => handleOnSubmenuOpen(state as State)}
          />
          <div
            className={cx(
              css({
                display: {
                  base: 'inline-flex',
                  lg: 'none',
                },
              }),
            )}
          >
            <Button
              variant="ghost"
              className={css({
                width: '48px',
              })}
            >
              <MdOutlinePhone color="#ffffff" size={24} />
            </Button>
            <Button
              variant="ghost"
              className={css({
                width: '48px',
              })}
            >
              <MdSearch color="#ffffff" size={24} />
            </Button>
            <MobileNavigation menuItems={menu} />
          </div>
        </div>
      </div>
    )
  }, [handleOnSubmenuOpen, themeState, setThemeState])

  return (
    <header
      className={cx(
        css({
          position: themeState === 'default' ? 'relative' : 'absolute',
          width: '100%',
          zIndex: 10,
          display: 'block',
          transition: 'background-color 0.1s',
          ...(themeState === 'default' && {
            backgroundColor: 'primary-navigation-color-default-bg',
          }),
          ...(themeState === 'frosted' && {
            top: 0,
            left: 0,
            zIndex: 10,
            background: 'rgba(0, 0, 0, 0.2)',
            backdropFilter: 'blur(5px)',
          }),
        }),
      )}
    >
      {infoLine}
      {navBar}
    </header>
  )
}
