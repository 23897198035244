'use client'

import { css } from '@/styled-system/css'
import { Box } from '@/styled-system/jsx'

import React, { useRef, useState } from 'react'
import { MdArrowForward } from 'react-icons/md'
import { useRovingTabIndex, useFocusEffect } from 'react-roving-tabindex'

import { DottedBorder } from '@/global/components/dotted-border'

import { Departure, Location } from '@/components/icons'
import { Image } from '@/components/image'
import { SVGRenderer } from '@/components/svgRenderer'
import { ContentfulLivePreview } from '@contentful/live-preview'

import type {
  TripItineraryDay,
  TripItineraryOption,
} from '@/types/components/itinerary'

const renderBynderImageIcon = (src?: string, alt?: string) => {
  if (!src) {
    return null
  }

  return <Image src={src} alt={alt ?? 'icon'} />
}

const renderSvg = (svg?: string) => {
  if (!svg) {
    return null
  }

  return (
    <SVGRenderer
      className={css({
        '& > svg': {
          w: 'semantic-sizing-24',
          h: 'semantic-sizing-24',
        },
      })}
      svgString={svg}
    />
  )
}

function TripItineraryDayIcon({
  isFirst,
  isLast,
  dayOption,
}: Readonly<{
  isFirst: boolean
  isLast: boolean
  dayOption: TripItineraryOption
}>) {
  if (isFirst || isLast) {
    return <Departure colour={isFirst ? 'success' : 'error'} />
  }

  const bynderImage = dayOption?.iconImage
  const iconSvg = dayOption?.iconSvg

  if (!bynderImage?.imageSource && !iconSvg) {
    return <Location />
  }

  if (bynderImage?.imageSource) {
    return renderBynderImageIcon(bynderImage.imageSource, bynderImage.alt)
  }

  if (iconSvg) {
    return renderSvg(iconSvg)
  }

  // Fallback to default icon
  return <Location />
}

export function TripItineraryTimelineItem({
  day,
  direction,
  isActive,
  isFirst,
  isLast,
  onDaySelect,
}: Readonly<{
  day: TripItineraryDay
  direction: 'horizontal' | 'vertical'
  isActive: boolean
  isFirst: boolean
  isLast: boolean
  onDaySelect: (day: number) => void
}>) {
  const ref = useRef<HTMLDivElement>(null)
  const [isInteracting, setIsInteracting] = useState(false)

  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(
    // eslint-disable-next-line react-compiler/react-compiler
    ref,
    false,
  )

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, day: number) => {
    handleKeyDown(e)

    if (e.key === 'Enter' || e.code === 'Space') {
      onDaySelect(day)
    }
  }

  // eslint-disable-next-line react-compiler/react-compiler
  useFocusEffect(focused, ref)

  return (
    <Box
      css={
        isInteracting &&
        (direction === 'vertical' || (direction === 'horizontal' && !isActive))
          ? {
              bg: 'semantic-bg-accent',
              boxShadow: 'semantic-shadow-lg',
            }
          : undefined
      }
      borderColor={
        isActive && direction === 'horizontal'
          ? 'semantic-border-selected'
          : 'transparent'
      }
      borderStyle="solid"
      borderWidth={2}
      borderRadius="semantic-border-radius-x-large"
      cursor="pointer"
      display="flex"
      flexDirection={direction === 'horizontal' ? 'column' : 'row'}
      ml={
        direction === 'horizontal' && isFirst
          ? 'semantic-spacing-24'
          : undefined
      }
      pb={
        direction === 'horizontal'
          ? 'semantic-spacing-12'
          : 'semantic-spacing-16'
      }
      pl={
        direction === 'horizontal'
          ? 'semantic-spacing-12'
          : 'semantic-spacing-8'
      }
      pos="relative"
      pr={
        direction === 'horizontal'
          ? isLast
            ? 'semantic-spacing-24'
            : 'semantic-spacing-12'
          : 'semantic-spacing-16'
      }
      pt="semantic-spacing-8"
      transition="all 250ms ease-in-out"
      tabIndex={tabIndex}
      minW={110}
      zIndex={1}
      data-timeline-day={day.number}
      onClick={() => {
        handleClick()
        onDaySelect(day.number)
      }}
      onKeyDown={(e) => onKeyDown(e, day.number)}
      onMouseEnter={() => setIsInteracting(true)}
      onMouseLeave={() => setIsInteracting(false)}
      onTouchStart={() => setIsInteracting(true)}
      onTouchMove={() => setIsInteracting(true)}
      onTouchEnd={() => setIsInteracting(false)}
      ref={ref}
      {...ContentfulLivePreview.getProps({
        entryId: day.id,
        fieldId: 'title',
      })}
    >
      <Box
        mr={direction === 'vertical' ? 'semantic-spacing-16' : undefined}
        pos="relative"
      >
        {!isLast ? (
          <DottedBorder
            className="dotted-border"
            zIndex={1000}
            direction={direction}
            height={direction === 'horizontal' ? 'semantic-sizing-4' : '100%'}
            left={direction === 'horizontal' ? 'semantic-spacing-32' : '50%'}
            pos="absolute"
            top={
              direction === 'horizontal'
                ? 'semantic-spacing-16'
                : 'semantic-spacing-32'
            }
            transform={
              direction === 'horizontal'
                ? 'translateY(-50%)'
                : 'translateX(-50%)'
            }
            w={
              direction === 'horizontal'
                ? `calc(100% + var(--spacing-semantic-spacing-16))`
                : 'semantic-sizing-4'
            }
          />
        ) : null}

        <TripItineraryDayIcon
          dayOption={day.options[0]}
          isFirst={isFirst}
          isLast={isLast}
        />
      </Box>

      <Box ml="-semantic-spacing-2">
        <p
          className={css({
            color: 'semantic-fg-muted',
            textStyle: 'semantic-text-sm-font-bold',
            mb: 0,
            textTransform: 'uppercase',
          })}
        >
          Day {day.number}
        </p>
        <h4
          className={css({
            color: 'semantic-fg-accent',
            textStyle:
              direction === 'horizontal'
                ? 'semantic-text-sm-font-regular'
                : 'button-label-md',
          })}
        >
          {day.title}
        </h4>
        {direction === 'vertical' ? (
          <p
            className={css({
              color: 'semantic-color-typography-body-solid',
              mb: 'semantic-spacing-0',
              textStyle: 'semantic-text-sm-font-regular',
            })}
          >
            {day.shortDescription}
          </p>
        ) : null}
      </Box>

      {direction === 'vertical' ? (
        <Box
          alignItems="center"
          display="flex"
          flexShrink={0}
          mb="-semantic-spacing-16"
          ml="auto"
          mt="-semantic-spacing-8"
          pl="semantic-spacing-4"
        >
          <MdArrowForward
            className={css({
              boxSizing: 'semantic-sizing-20',
              color: 'semantic-fg-accent',
              opacity: isInteracting ? 1 : 0,
              transition: 'opacity 250ms ease-in-out',
            })}
          />
        </Box>
      ) : null}
    </Box>
  )
}
