import chunk from 'lodash/chunk'
import React from 'react'
import { useMediaQuery } from 'usehooks-ts'

import { LightboxThumbnail } from '@/components/lightbox/lightbox-thumbnail'

import { ContentfulLivePreview } from '@contentful/live-preview'

import { Box, Flex } from '@/styled-system/jsx'

import type { GalleryItem } from '@/types/components/gallery-item'

const EmblaSlide = ({ children }: React.PropsWithChildren) => {
  return (
    <Box className="embla__slide">
      <Flex
        borderRadius="semantic-border-radius-x-large"
        columnGap={{ md: 'semantic-spacing-8' }}
        flexDir="row"
        h={{ base: 343, xs: 428, md: 327, lg: 426, xl: 535 }}
        overflow="hidden"
      >
        {children}
      </Flex>
    </Box>
  )
}

const GROUP_SIZE = 3

export const CarouselSlide = ({
  slides,
  openLightbox,
}: Readonly<{
  slides: GalleryItem[]
  openLightbox: (index: number) => void
}>) => {
  const isMobile = useMediaQuery('(max-width: 768px)', {
    initializeWithValue: false,
  })

  // Group every 3 images into a single array
  const slideGroups = chunk(slides, GROUP_SIZE)

  const onSlideClick = (galleryItem: GalleryItem) => {
    openLightbox(slides.indexOf(galleryItem))
  }

  return (
    <>
      {slideGroups.map((galleryItem, key) => {
        if (isMobile || galleryItem.length === 1) {
          return galleryItem.map((image) => (
            <EmblaSlide key={`embla-slide-${key}`}>
              <LightboxThumbnail
                slide={image}
                onImageClick={() => onSlideClick(image)}
                {...ContentfulLivePreview.getProps({
                  entryId: image?.id,
                  fieldId: 'title',
                })}
              />
            </EmblaSlide>
          ))
        }

        if (galleryItem.length === GROUP_SIZE) {
          return (
            <EmblaSlide key={`embla-slide-${key}`}>
              <LightboxThumbnail
                slide={galleryItem[0]}
                w={{ md: '60%' }}
                onImageClick={() => onSlideClick(galleryItem[0])}
                {...ContentfulLivePreview.getProps({
                  entryId: galleryItem?.[0]?.id,
                  fieldId: 'title',
                })}
              />
              <Flex
                flexDir={{ md: 'column' }}
                height={{ md: 'full' }}
                rowGap={{ md: 'semantic-spacing-8' }}
                w={{ md: '40%' }}
              >
                <LightboxThumbnail
                  h={{ md: '50%' }}
                  slide={galleryItem[1]}
                  smSize
                  onImageClick={() => onSlideClick(galleryItem[1])}
                  {...ContentfulLivePreview.getProps({
                    entryId: galleryItem?.[1]?.id,
                    fieldId: 'title',
                  })}
                />
                <LightboxThumbnail
                  h={{ md: '50%' }}
                  slide={galleryItem[2]}
                  onImageClick={() => onSlideClick(galleryItem[2])}
                  smSize
                  {...ContentfulLivePreview.getProps({
                    entryId: galleryItem?.[2]?.id,
                    fieldId: 'title',
                  })}
                />
              </Flex>
            </EmblaSlide>
          )
        }

        return (
          <EmblaSlide key={`embla-slide-${key}`}>
            {galleryItem.map((galleryItem, key) => (
              <LightboxThumbnail
                key={`interactive-image-${key}`}
                slide={galleryItem}
                w="50%"
                onImageClick={() => onSlideClick(galleryItem)}
                {...ContentfulLivePreview.getProps({
                  entryId: galleryItem?.id,
                  fieldId: 'title',
                })}
              />
            ))}
          </EmblaSlide>
        )
      })}
    </>
  )
}
