import { OverviewHeaderPreview } from '@/global/components/trip-widget/heading/header-live-preview'
import { OverviewHeader } from '@/global/components/trip-widget/heading/trip-overview-header'

export function TripWidgetHeader({
  isPreview,
  trip,
}: {
  isPreview?: boolean
  trip: Contentful.Models.Trip
}) {
  if (!trip) {
    return null
  }

  return isPreview ? (
    <OverviewHeaderPreview data={trip} />
  ) : (
    <OverviewHeader trip={trip} />
  )
}
