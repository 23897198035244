'use client'

import { useEffect, useState } from 'react'

import { useLiveUpdate } from '@/components/hooks/use-live-update'

import { FullBleedCarousel } from '@/global/components/bleed-section/bleed-slider'
import { dataResolver } from '@/global/components/bleed-section/util/data-resolver'

import { FullBleedSectionDocument } from '@/graphql/client'
import { useSite } from '@/providers/site'

import { error } from '@/utility/logging'

import type { FullBleedSectionProps } from '@/types/components/bleed-carousel'

export function FullBleedCarouselPreview({
  data: originalData,
  brand,
  isLead,
}: Readonly<{
  data: Contentful.Models.FullBleedSection
  brand: string
  isLead?: boolean
}>) {
  const [data, setData] = useState<FullBleedSectionProps>({} as never)
  const { slugs } = useSite()

  const { entry: section, getInspectorProps } = useLiveUpdate(
    originalData,
    FullBleedSectionDocument,
  )

  useEffect(() => {
    if (section) {
      const fetchData = async () => {
        try {
          const result = await dataResolver(brand, section, isLead, slugs)
          setData(result)
        } catch (err) {
          error('Error fetching bleed section data', {
            brand,
            err: (err as Error).message,
          })
        }
      }

      fetchData()
    }
  }, [section, isLead, slugs])

  return data ? (
    <FullBleedCarousel
      {...data}
      livePreviewProps={{
        title: getInspectorProps({ fieldId: 'title' }),
        text: getInspectorProps({ fieldId: 'text' }),
        cta: getInspectorProps({ fieldId: 'cta' }),
      }}
    />
  ) : null
}
