'use client'

import { useLiveUpdate } from '@/components/hooks/use-live-update'

import { AnchorNav } from '@/global/components/anchor-nav/anchor-nav'

import { debug } from '@/utility/logging'

import {
  AnchorNavConfig,
  NavContent,
  PageContent,
} from '@/types/components/anchor-nav'

export function AnchorNavPreview({
  brand,
  config,
  pageContent: originalPageContent,
  query,
  navContent,
}: Readonly<{
  brand: string
  config: AnchorNavConfig
  pageContent: PageContent
  query?: Contentful.QueryParams['query']
  navContent: NavContent
}>) {
  const { entry: pageContent } = useLiveUpdate(originalPageContent, query)

  debug('Currently active brand', {
    brand,
    context: '<AnchorNavPreview />',
    pageContent,
  })

  return <AnchorNav config={config} navContent={navContent} />
}
