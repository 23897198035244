'use client'

import throttle from 'lodash/throttle'
import React, { MouseEvent, useEffect } from 'react'
import { MdArrowUpward } from 'react-icons/md'

import { Button } from '@/components/button'

import Link from '@/global/components/link'

import { Box } from '@/styled-system/jsx'
import { css, cx } from '@/styled-system/css'

import { debug } from '@/utility/logging'

import type {
  AnchorNavConfig,
  NavLinks,
  NavContent,
} from '@/types/components/anchor-nav'

function backToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

function handleNavClick(index: number, id: string, _event: MouseEvent) {
  _event.preventDefault()
  const links = document.querySelectorAll('.anchor-nav-link')
  const element = document.getElementById(id)

  for (const link of links) {
    link.setAttribute('active', 'false')
  }

  links[index].setAttribute('active', 'true')

  element?.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

function ctaButtonHandler(cta: NavLinks, isTripPage: boolean) {
  if (isTripPage && cta.label === 'Request a Quote') {
    // console.log('TODO: add function when price is ready')
  } else {
    location.href = cta.href
  }
}

export const AnchorNav = ({
  config,
  navContent,
}: Readonly<{
  config: AnchorNavConfig
  navContent?: NavContent
}>) => {
  useEffect(function mount() {
    const sections = navContent?.links?.map((link) => {
      return document.getElementById(link.href)
    })
    const navItems = document.querySelectorAll('.anchor-nav-link')
    if (!sections || !navItems) return

    const onScroll = () => {
      const viewportTop = window.scrollY
      const viewportBottom = viewportTop + window.innerHeight

      for (const section of sections) {
        if (!section) return
        const id = section.id
        const elementTop = section.offsetTop
        const elementBottom = elementTop + section.offsetHeight
        if (
          elementBottom + section.offsetHeight * 0.25 > viewportTop &&
          elementTop + section.offsetHeight * 0.25 < viewportBottom
        ) {
          for (const navItem of navItems) {
            return navItem.setAttribute(
              'active',
              navItem.classList.contains(id) ? 'true' : 'false',
            )
          }
        }
      }
    }

    window.addEventListener('scroll', throttle(onScroll, 250))

    return function unMount() {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  debug('Anchor navigation content', {
    config,
    content: navContent,
  })

  return (
    <Box
      w="100%"
      m="0"
      mb={'semantic-spacing-16'}
      shadow={'semantic-shadow-lg'}
      px="semantic-spacing-16"
      position="sticky"
      top="0"
      zIndex={100}
      bg="semantic-bg-default"
      data-component-name="anchor-nav"
      data-component-index-name="anchor-nav-0"
    >
      <Box
        display="flex"
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        justifyContent="space-between"
        maxW="1440px"
        margin="0 auto"
        h={{ base: '108px', md: '64px', xl: '72px' }}
        alignItems="center"
      >
        <Box
          display="flex"
          alignItems="center"
          order={1}
          mr={{
            base: 'semantic-spacing-20',
            lg: 'semantic-spacing-32',
            xl: 'semantic-spacing-40',
          }}
        >
          <Button
            icon={<MdArrowUpward />}
            css={{
              width: 'semantic-sizing-16',
              height: 'semantic-sizing-16',
              borderRadius: 'border-radius-9',
            }}
            variant={'secondary'}
            onClick={() => {
              backToTop()
            }}
          ></Button>

          <Box
            ml="semantic-spacing-8"
            color="semantic-color-typography-headings-solid"
            maxW={{ base: '147px', md: '188px', lg: 'auto' }}
          >
            {config.travelStyle ? (
              <p
                className={css({
                  textStyle: {
                    base: 'semantic-text-2xs-font-regular',
                    md: 'semantic-text-xs-font-regular',
                  },
                })}
              >
                {config.travelStyle}
              </p>
            ) : null}
            <p
              className={css({
                textStyle: {
                  base: 'semantic-text-sm-font-regular',
                  md: 'semantic-text-md-font-regular',
                  lg: 'semantic-text-xl-font-regular',
                },
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
              })}
            >
              {config.pageTitle}
            </p>
          </Box>
        </Box>
        <Box
          order={{ base: 3, md: 2 }}
          overflowX={{ base: 'scroll', lg: 'hidden' }}
          display="flex"
          flexGrow={1}
          h={{ base: '48px', md: '64px', xl: '72px' }}
          w={{ base: '100vw', md: 'auto' }}
          className={css({
            _after: {
              content: '" "',
              position: 'absolute',
              right: { base: 0, md: '157px' },
              zIndex: 1,
              width: '64px',
              height: '48px',
              bg: 'linear-gradient(to right, rgba(255, 255, 255, 0.00) 0%, #FFF 100%); ',
              pointerEvents: 'none',
              display: { base: 'block', lg: 'none' },
            },
          })}
        >
          {navContent?.links.map((link: NavLinks, index: number) => (
            <Link
              href="#"
              key={index}
              aria-label={link.label}
              onClick={(event) => {
                handleNavClick(index, link.href, event)
              }}
              className={cx(
                css({
                  mr: {
                    base: 'semantic-spacing-24',
                    lg: 'semantic-spacing-32',
                    xl: 'semantic-spacing-40',
                  },
                  borderBottom: '2px solid transparent',
                  color: 'semantic-color-typography-body-solid',
                  whiteSpace: 'nowrap',
                  textStyle: {
                    base: 'semantic-text-sm-font-regular',
                    lg: 'semantic-text-md-font-regular',
                  },
                  display: 'flex',
                  alignItems: 'center',
                  _hover: {
                    borderBottomColor: 'semantic-fg-accent-alt',
                    cursor: 'pointer',
                  },
                  '&[active=true]': {
                    fontWeight: 'bold',
                    borderBottomColor: 'semantic-fg-accent-alt',
                  },
                }),
                'anchor-nav-link',
                `${link.href}`,
              )}
            >
              {link.label}
            </Link>
          ))}
        </Box>
        {navContent?.cta.label && (
          <Button
            css={{
              ml: { lg: 'auto' },
              width: { base: 'fit-content', lg: 'auto' },
              whiteSpace: 'nowrap',
              order: { base: 2, md: 3 },
              maxW: '157px',
            }}
            variant={config.isTripPage ? 'enhanced-primary' : 'primary'}
            size={'sm'}
            onClick={() => {
              ctaButtonHandler(navContent.cta, config.isTripPage)
            }}
            aria-label="Back to top"
          >
            {navContent.cta.label}
          </Button>
        )}
      </Box>
    </Box>
  )
}
