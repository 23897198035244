import SVG from 'react-inlinesvg'

import { cx } from '@/styled-system/css'

interface SVGRendererProps {
  className?: string
  svgString: string
}

export function SVGRenderer({ className, svgString }: SVGRendererProps) {
  return (
    <span className={cx(className)}>
      <SVG src={svgString} />
    </span>
  )
}
