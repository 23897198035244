'use client'

import { useEffect, useState } from 'react'

import { useLiveUpdate } from '@/components/hooks/use-live-update'

import { ArticleCardCarouselSection } from '@/global/components/article-card-carousel/article-carousel-section'
import { dataResolver } from '@/global/components/article-card-carousel/util/data-resolver'

import { ArticleCardGridCarouselDocument } from '@/graphql/client'
import { useSite } from '@/providers/site'

import { error } from '@/utility/logging'

import type { ArticleCardCarouselProps } from '@/types/components/article-card-carousel'

export function ArticleCardCarouselPreview({
  data: originalData,
  brand,
  isLead,
}: Readonly<{
  data: Contentful.Models.ArticleCardGridCarousel
  brand: string
  isLead?: boolean
}>) {
  const [data, setData] = useState<ArticleCardCarouselProps>({} as never)
  const { slugs } = useSite()

  const { entry: section, getInspectorProps } = useLiveUpdate(
    originalData,
    ArticleCardGridCarouselDocument,
  )

  useEffect(() => {
    if (section) {
      const fetchData = async () => {
        try {
          const result = await dataResolver(brand, section, isLead, slugs)
          setData(result)
        } catch (err) {
          error('Error fetching article card carousel data', {
            brand,
            err: (err as Error).message,
          })
        }
      }

      fetchData()
    }
  }, [section, isLead, slugs])

  return data ? (
    <ArticleCardCarouselSection
      props={{
        ...data,
        livePreviewProps: {
          title: getInspectorProps({ fieldId: 'title' }),
          text: getInspectorProps({ fieldId: 'text' }),
          cta: getInspectorProps({ fieldId: 'cta' }),
        },
      }}
    />
  ) : null
}
