import { styled } from '@/styled-system/jsx'
import {
  tableBody,
  tableContainer,
  tableHead,
  tableHeader,
  tableCell,
  table,
} from '@/styled-system/recipes'

const TableContainer = styled('div', tableContainer)

const BaseTable = ({ ...props }: React.ComponentProps<'table'>) => (
  <TableContainer width="auto">
    <table {...props} />
  </TableContainer>
)

BaseTable.displayName = 'Table'

export const Table = styled(BaseTable, table)

export const TableHeader = styled('thead', tableHeader)

export const TableBody = styled('tbody', tableBody)

export const TableCell = styled('td', tableCell)

export const TableHead = styled('th', tableHead)
