'use client'

import { Box } from '@/styled-system/jsx'

import { throttle } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import nextId from 'react-id-generator'
import { RovingTabIndexProvider } from 'react-roving-tabindex'

import { TripItineraryTimelineGradient } from '@/global/components/trip-itinerary/trip-itinerary-timeline-gradient'
import { TripItineraryTimelineItem } from '@/global/components/trip-itinerary/trip-itinerary-timeline-item'

import type { TripItineraryConfig } from '@/types/components/itinerary'

export function TripItineraryTimelineScroller({
  activeDay,
  children,
  config,
  direction = 'vertical',
  onDaySelect,
}: Readonly<
  React.PropsWithChildren<{
    activeDay: number
    config: TripItineraryConfig
    direction?: 'horizontal' | 'vertical'
    onDaySelect: (day: number) => void
  }>
>) {
  const dayLength = config.days.length
  const scrollRef = useRef<HTMLDivElement>(null)

  const [needsBottomGradient, setNeedsBottomGradient] = useState(true)
  const [needsTopGradient, setNeedsTopGradient] = useState(false)

  const onScroll = throttle((event: Event) => {
    const target = event.target as HTMLDivElement | null

    if (target) {
      setNeedsBottomGradient(
        target.scrollHeight - target.scrollTop - 50 >= target.clientHeight,
      )
      setNeedsTopGradient(target.scrollTop >= 10)
    }
  }, 100)

  useEffect(() => {
    scrollRef.current?.addEventListener('scroll', onScroll)

    return () => {
      scrollRef.current?.removeEventListener('scroll', onScroll)
    }
  }, [scrollRef])

  useEffect(() => {
    if (direction === 'horizontal') {
      const dayElement = scrollRef.current?.querySelector(
        `[data-timeline-day="${activeDay.toString()}"]`,
      )

      if (dayElement) {
        dayElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [activeDay])

  return (
    <>
      {direction === 'vertical' ? (
        <TripItineraryTimelineGradient
          needsBottomGradient={needsBottomGradient}
          needsTopGradient={needsTopGradient}
        />
      ) : null}

      <Box
        css={{
          '--scroll-width-offset': 'spacing.semantic-spacing-0',
          '--scroll-x-offset': 'spacing.semantic-spacing-4',
          '--timeline-x-offset': 'spacing.semantic-spacing-12',
          '--timeline-x-spacing': {
            base: 'spacing.semantic-spacing-16',
            md: 'spacing.semantic-spacing-42',
          },

          '@supports selector(::-webkit-scrollbar)': {
            '--scroll-width-offset': 'spacing.semantic-spacing-8',

            _scrollbar: {
              appearance: 'none',
              maxH: 'semantic-sizing-8',
              maxW: 'semantic-sizing-8',
            },
            _scrollbarCorner: {
              display: 'none',
            },
            _scrollbarButton: {
              display: 'none',
            },
            _scrollbarTrack: {
              background: 'transparent',
            },
            _scrollbarThumb: {
              background: 'semantic-neutral-subtle',
              borderColor: 'semantic-neutral-muted',
              borderStyle: 'solid',
              borderWidth: 'semantic-border-width-x-small',
              borderRadius: 'semantic-border-radius-x-large',
            },
          },
        }}
        h={direction === 'vertical' ? '100%' : undefined}
        ml={
          direction === 'vertical'
            ? `calc(var(--timeline-x-spacing) * -1)`
            : undefined
        }
        overflowX={direction === 'horizontal' ? 'scroll' : 'hidden'}
        overflowY={direction === 'horizontal' ? 'hidden' : 'scroll'}
        pb={
          direction === 'horizontal'
            ? 'semantic-spacing-12'
            : 'semantic-spacing-20'
        }
        pl={direction === 'vertical' ? 'var(--timeline-x-spacing)' : undefined}
        pr={direction === 'vertical' ? 'var(--timeline-x-offset)' : undefined}
        w={
          direction === 'horizontal'
            ? 'auto'
            : `calc(100% + var(--timeline-x-spacing) + var(--scroll-width-offset) + var(--scroll-x-offset))`
        }
        ref={scrollRef}
      >
        <Box
          display={direction === 'horizontal' ? 'grid' : undefined}
          gap={direction === 'horizontal' ? 'semantic-spacing-16' : undefined}
          w={
            direction === 'vertical'
              ? 'calc(100% + var(--timeline-x-offset) - var(--scroll-x-offset))'
              : ''
          }
          style={{
            gridTemplateColumns:
              direction === 'horizontal'
                ? `repeat(${config.days.length}, minmax(max-content, min-content))`
                : undefined,
          }}
        >
          {children}

          <RovingTabIndexProvider options={{ direction }}>
            {config.days.map((day) => (
              <TripItineraryTimelineItem
                key={nextId('trip-itinerary-timeline-item-')}
                day={day}
                direction={direction}
                isActive={activeDay === day.number}
                isFirst={day.number === 1}
                isLast={day.number === dayLength}
                onDaySelect={onDaySelect}
              />
            ))}
          </RovingTabIndexProvider>
        </Box>
      </Box>
    </>
  )
}
