'use client'

import { useState } from 'react'

import { Image } from '@/components/image'

import Link from '@/global/components/link'

import { css, cx } from '@/styled-system/css'

import { DropdownItem } from './dropdownItem'
import { NavTitle } from './navTitle'
import { findChildren } from '../util/find-children'

interface MenuItem {
  title: string
  href?: string
  items?: MenuItem[]
  image?: string
}

const DropdownImage = ({ item }: { item: MenuItem }) =>
  item.image && (
    <figure
      className={css({
        flex: 1,
        height: '100%',
        width: '100%',
        minH: '310px',
        position: 'relative',
        borderRadius: 'semantic-border-radius-medium',
        overflow: 'hidden',
      })}
    >
      <Image src={item.image} alt={`Illustration for ${item.title}`} />
    </figure>
  )

export const Dropdown = ({ item }: { item: MenuItem }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  if (!item) return null

  const itemSummary = findChildren(item)

  return (
    <div
      className={cx(
        css({
          display: 'grid',
          maxW: 1680,
          columnGap: !itemSummary.hasMultipleLevels ? 'semantic-spacing-40' : 0,
          mx: 'auto',
          gridTemplateColumns: itemSummary.hasMultipleLevels
            ? 'minmax(290px, 450px) 1fr'
            : '1fr 1fr',
          py: 'semantic-spacing-48',
          px: 42,
        }),
      )}
    >
      <div
        className={cx(
          css(
            itemSummary.hasMultipleLevels && {
              pr: 'semantic-spacing-48',
              borderRight: '1px solid',
              borderColor: 'semantic-border-default',
            },
          ),
        )}
      >
        {item.title && <NavTitle title={item.title} />}
        {item.items && (
          <ul
            className={cx(
              css({
                marginTop: 'semantic-spacing-40',
                listStyle: 'none',
                padding: 0,
              }),
            )}
            role="menu"
          >
            {item.items.map((subItem, index) => (
              <DropdownItem
                key={index}
                item={subItem}
                isActive={activeIndex === index}
                onActivate={() => setActiveIndex(index)}
              />
            ))}
          </ul>
        )}
      </div>
      {itemSummary.hasMultipleLevels && (
        <div
          className={cx('children', css({ pl: 'semantic-spacing-56' }))}
          aria-live="polite"
        >
          {item.items?.map(
            (subItem, index) =>
              subItem.items && (
                <div
                  key={index}
                  className={cx(
                    css({
                      display: activeIndex === index ? 'grid' : 'none',
                      gridTemplateColumns:
                        'minmax(320px, 1fr) minmax(266px, 405px)',
                      ...(activeIndex === index
                        ? { animateIn: true, fadeIn: 0 }
                        : { animateOut: true, fadeOut: 0 }),
                    }),
                  )}
                  role="region"
                  aria-label={`Details for ${subItem.title}`}
                >
                  <div>
                    {subItem.title && (
                      <NavTitle
                        title={subItem.title}
                        id={`details-heading-${index}`}
                      />
                    )}
                    <ul
                      aria-labelledby={`details-heading-${index}`}
                      className={cx(
                        css({
                          marginTop: 'semantic-spacing-40',
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr',
                          gap: 'semantic-spacing-8',
                          listStyle: 'none',
                          padding: 0,
                        }),
                      )}
                    >
                      {subItem.items.map(
                        (nestedItem, nestedIndex) =>
                          nestedItem.href && (
                            <li key={nestedIndex}>
                              <Link
                                href={nestedItem.href}
                                className={cx(
                                  css({
                                    py: 'semantic-spacing-8',
                                    px: 'semantic-spacing-6',
                                    display: 'block',
                                  }),
                                )}
                              >
                                {nestedItem.title}
                              </Link>
                            </li>
                          ),
                      )}
                    </ul>
                  </div>
                  {subItem.image && <DropdownImage item={subItem} />}
                </div>
              ),
          )}
        </div>
      )}

      {!itemSummary.hasMultipleLevels && item.image && (
        <DropdownImage item={item} />
      )}
    </div>
  )
}
