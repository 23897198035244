import { SectionHeader } from '@/components/section-header'
import { InlineIconText } from '@/components/inline-icon-text'

import { css, cx } from '@/styled-system/css'
import { Section, Box, Divider, Grid } from '@/styled-system/jsx'

import type { InlineIconTextProps } from '@/components/inline-icon-text'
import type { LivePreviewProps } from '@/types/components/live-preview'
import type { TitleProps } from '@/components/title'

export interface FeatureItemProps {
  id: string
  icon?: string
  text: string
}

export interface FeaturedItemListProps {
  id?: string
  titleSize?: TitleProps['size']
  titleTag?: TitleProps['tag']
  showInNav?: boolean
  navTitle?: string
  title: string
  text: string | React.ReactNode
  items: InlineIconTextProps[]
  className?: string
  livePreviewProps?: LivePreviewProps
}

const boxStyles = css({
  boxSizing: 'border-box',
  px: {
    base: 'semantic-spacing-20',
    md: 'semantic-spacing-56',
  },
  py: {
    base: 'semantic-spacing-32',
    md: 'semantic-spacing-64',
  },
  borderRadius: 'semantic-border-radius-medium',
  backgroundColor: 'inclusions-color-background',
})

const gridStyles = css({
  pt: 'semantic-spacing-32',
  md: {
    pt: 'semantic-spacing-40',
  },
})

export const FeaturedItemListSection: React.FC<FeaturedItemListProps> = ({
  items,
  className,
  title,
  text,
  titleSize = 'heading4',
  titleTag = 'h2',
  showInNav: _showInNav, // TODO page navigation
  livePreviewProps,
}) => {
  return (
    <Section
      py={{
        base: 'semantic-spacing-32',
        md: 'semantic-spacing-64',
      }}
    >
      <Box className={cx(boxStyles, className)}>
        <SectionHeader
          heading={title}
          description={text}
          titleProps={{
            size: titleSize,
            tag: titleTag || 'h2',
            format: 'standard',
            ...(livePreviewProps?.title ?? {}),
          }}
          descriptionProps={{
            ...(livePreviewProps?.text ?? {}),
          }}
          pr={{ md: 'semantic-spacing-40' }}
          mb={{ base: 'semantic-spacing-16', md: 'semantic-spacing-20' }}
        />
        <Divider borderColor="semantic-border-default" borderWidth={1} />
        <Grid
          columns={2}
          className={gridStyles}
          alignItems="start"
          columnGap={{ base: 'semantic-spacing-24', md: 'semantic-spacing-32' }}
          rowGap="semantic-spacing-24"
        >
          {items?.map((item) => <InlineIconText key={item.id} {...item} />)}
        </Grid>
      </Box>
    </Section>
  )
}
