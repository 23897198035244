import { cx } from '@/styled-system/css'
import { Box } from '@/styled-system/jsx'

import './embla-base.css'
import './embla-card-carousel.css'

export function Carousel({
  children,
  emblaContainerStyle,
  emblaViewportStyle,
  emblaWrapperStyle,
  emblaRef,
}: Readonly<
  React.PropsWithChildren<{
    emblaContainerStyle?: string
    emblaViewportStyle?: string
    emblaWrapperStyle?: string
    emblaRef: <ViewportElement extends HTMLElement>(
      instance: ViewportElement | null,
    ) => void
  }>
>) {
  return (
    <Box className={cx('embla', emblaWrapperStyle)}>
      <Box className={cx('embla__viewport', emblaViewportStyle)} ref={emblaRef}>
        <Box className={cx('embla__container', emblaContainerStyle)}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}
