import { css, cx } from '@/styled-system/css'
import { VisuallyHidden } from '@/styled-system/jsx'
import { MdArrowForward } from 'react-icons/md'
import Image from 'next/image'
import { Badge } from '@/components/badge'
import { Button } from '@/components/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardTitle,
} from '@/components/card'
import { ContentfulLivePreview } from '@contentful/live-preview'

import type { ArticleCard } from '@/types/components/article-card-carousel'

export function ArticleCard({
  id,
  cta,
  image,
  title,
  text,
  tags,
}: ArticleCard) {
  if (!image?.src || !id) return null
  return (
    <Card
      className={cx('embla__slide', 'card-carousel__slide', 'group')}
      minH={{ base: 524, lg: 550 }}
      pos="relative"
      {...ContentfulLivePreview.getProps({ entryId: id, fieldId: 'title' })}
    >
      {cta?.href && (
        <a
          className={css({ pos: 'absolute', w: 'full', h: 'full' })}
          href={cta?.href}
        >
          <VisuallyHidden>{cta?.label}</VisuallyHidden>
        </a>
      )}
      {image?.src && (
        <Image
          className={css({
            borderRadius: 'semantic-border-radius-large',
            flex: '1 1 auto',
            h: 'full',
            objectFit: 'cover',
            w: 'full',
            aspectRatio: '377/282',
          })}
          alt={image.alt}
          src={image.src}
          width={600}
          height={500}
        />
      )}
      <CardContent flex="0 0 188px" variant="default">
        {tags && (
          <div
            className={css({
              display: 'inline-flex',
              gap: 'semantic-spacing-6',
            })}
          >
            {tags?.map((tag) => <Badge key={tag.id}>{tag?.name}</Badge>)}
          </div>
        )}
        {title && <CardTitle variant="default">{title}</CardTitle>}
        {text && <CardDescription variant="default">{text}</CardDescription>}
      </CardContent>
      <CardFooter mt="semantic-spacing-8">
        {cta?.href && (
          <Button
            css={{
              _groupHover: {
                color: 'button-color-ghost-hover-icon',
                buttonIconColor: 'button-color-ghost-hover-icon',
              },
            }}
            as="a"
            href={cta.href}
            rightIcon={<MdArrowForward />}
            size="lg"
            variant="ghost"
          >
            {cta.label}
          </Button>
        )}
      </CardFooter>
    </Card>
  )
}
