import { RichtextRenderer } from '@/components/richtextEditor'
import { SectionHeader } from '@/components/section-header'

import { ContentfulLivePreview } from '@contentful/live-preview'

import { css, cx } from '@/styled-system/css'

import type { TitleProps } from '@/components/title'

export const OverviewHeader = ({
  trip,
  titleTag,
}: {
  trip: Contentful.Models.Trip
  titleTag?: TitleProps['tag']
}) => {
  return (
    <SectionHeader
      heading={trip.tripPageTitle || ''}
      description={
        trip.tripPageText ? (
          <RichtextRenderer
            json={trip.tripPageText.json}
            links={trip.tripPageText.links as never}
          />
        ) : null
      }
      titleProps={{
        size: 'heading2',
        tag: titleTag || 'h2',
        format: 'standard',
        ...ContentfulLivePreview.getProps({
          entryId: trip.sys.id,
          fieldId: 'tripPageTitle',
        }),
      }}
      descriptionProps={{
        ...ContentfulLivePreview.getProps({
          entryId: trip.sys.id,
          fieldId: 'tripPageText',
        }),
      }}
      className={cx(
        css({
          gridColumn: '1 / -1',
          md: {
            gridColumn: 'span 7',
            pr: 'semantic-spacing-32',
          },
        }),
      )}
    />
  )
}
