/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Converts a base64 encoded string to an array buffer.
 *
 * @param input the base64 encoded string to convert
 * @returns an array buffer
 */
export function base64ToBuffer(input: any) {
  const binaryString = atob(input)
  const bytes = new Uint8Array(binaryString.length)

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  return bytes.buffer
}

/**
 * Converts an array buffer to a base64 encoded string.
 *
 * @param buffer the array buffer to convert
 * @returns a base64 encoded string
 */
export function bufferToBase64(buffer: ArrayBuffer) {
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength

  let binary = ''
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }

  return btoa(binary)
}
