import { security } from '@/utility/environment'
import { bufferToBase64 } from '@/utility/helpers'

/**
 * Encrypt the `request` using the public key.
 *
 * @param request the `string` to encrypt
 * @returns the encrypted and base64 encoded `string`
 */
export async function encryptRequest(request: string) {
  if (
    !security.publicKey ||
    !security.publicKey?.key_ops?.includes('encrypt')
  ) {
    return btoa(request)
  }

  const publickKey = await crypto.subtle.importKey(
    'jwk',
    security.publicKey,
    { name: 'RSA-OAEP', hash: 'SHA-256' },
    false,
    ['encrypt'],
  )

  const requestArray = new TextEncoder().encode(request)

  const requestBuffer = await crypto.subtle.encrypt(
    { name: 'RSA-OAEP' },
    publickKey,
    requestArray,
  )

  return bufferToBase64(requestBuffer)
}
