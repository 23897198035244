'use client'

import { ContentfulLivePreview } from '@contentful/live-preview'

import { useEffect, useState } from 'react'
import { MdOutlineSaveAlt } from 'react-icons/md'

import { Button } from '@/components/button'
import { RichtextRenderer } from '@/components/richtextEditor'
import { Title } from '@/components/title'

import { Box, Section } from '@/styled-system/jsx'
import { css } from '@/styled-system/css'

import { encryptRequest } from '@/utility/client/security'

import type { TripItineraryConfig } from '@/types/components/itinerary'

async function getPdfUrl(
  trip: Contentful.Models.Trip,
  config: TripItineraryConfig,
) {
  const params = new URLSearchParams()

  params.set('itineraryId', config.id)
  params.set('tripId', trip.sys.id)
  params.set('tripSeasonId', trip?.seasons?.items[0]?.sys.id ?? '')

  return `/api/pdf/?r=${encodeURIComponent(await encryptRequest(params.toString()))}`
}

export function TripSaveButton({
  label,
  trip,
  config,
}: Readonly<{
  label: string
  trip: Contentful.Models.Trip
  config: TripItineraryConfig
}>) {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)

  useEffect(() => {
    getPdfUrl(trip, config).then(setPdfUrl)
  }, [])

  if (!pdfUrl) {
    return null
  }

  return (
    <Button
      as="a"
      href={pdfUrl}
      css={{
        ml: { lg: 'auto' },
        mt: { base: 'semantic-spacing-24', lg: 0 },
        width: { base: 'fit-content', lg: 'auto' },
      }}
      leftIcon={<MdOutlineSaveAlt />}
      variant={'secondary'}
    >
      {label}
    </Button>
  )
}

export const TripItineraryHeader = ({
  config,
  trip,
}: Readonly<{
  config: TripItineraryConfig
  trip: Contentful.Models.Trip
}>) => {
  return (
    <Section my={{ base: 'semantic-spacing-32', md: 'semantic-spacing-64' }}>
      <Box
        mb="semantic-spacing-24"
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Title
          size={config.titleSize || 'heading4'}
          theme="light"
          format="standard"
          additionalStyles={css({
            color: 'semantic-color-typography-headings-solid',
          })}
          {...ContentfulLivePreview.getProps({
            entryId: config.id,
            fieldId: 'title',
          })}
        >
          {config.title}
        </Title>

        <TripSaveButton
          label={config.i18n['save-pdf']}
          trip={trip}
          config={config}
        />
      </Box>

      {config.description ? (
        <Box
          textStyle="semantic-text-xl-font-regular-spaced"
          {...ContentfulLivePreview.getProps({
            entryId: config.id,
            fieldId: 'text',
          })}
        >
          <RichtextRenderer {...config.description} />
        </Box>
      ) : null}
    </Section>
  )
}
