import { Box } from '@/styled-system/jsx'

export function TripItineraryTimelineGradient({
  needsBottomGradient,
  needsTopGradient,
}: {
  needsBottomGradient: boolean
  needsTopGradient: boolean
}) {
  return (
    <>
      <Box
        background="linear-gradient(0deg, rgba(252, 252, 252, 0.00) 0%, #FCFCFC 100%)"
        h={100}
        left={0}
        opacity={needsTopGradient ? 1 : 0}
        pos="absolute"
        pointerEvents="none"
        top={0}
        transition="opacity 250ms ease-in-out"
        userSelect="none"
        w="100%"
        zIndex={2}
      />
      <Box
        background="linear-gradient(180deg, rgba(252, 252, 252, 0.00) 0%, #FCFCFC 100%)"
        bottom={0}
        h={100}
        left={0}
        opacity={needsBottomGradient ? 1 : 0}
        pos="absolute"
        pointerEvents="none"
        transition="opacity 250ms ease-in-out"
        userSelect="none"
        w="100%"
        zIndex={2}
      />
    </>
  )
}
