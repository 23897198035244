import { css, cx } from '@/styled-system/css'

import {
  genericDataResolver,
  resolveCta,
  resolveRichText,
  resolveGalleryItem,
  resolveIconText,
} from '@/utility/content-resolvers'

import type { InlineIconTextProps } from '@/components/inline-icon-text'
import type { TitleProps } from '@/components/title'
import type { GalleryItem } from '@/types/components'
import type { MediaGalleryCarouselConfig } from '@/types/components/media-gallery-carousel'
import type { PageSlug } from '@/types/resolved-types'

const itemStyles = {
  root: cx(
    css({
      display: 'flex',
      alignItems: 'start',
      md: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'start',
      },
    }),
  ),
  content: cx(
    css({
      textAlign: {
        base: 'left',
        md: 'left',
      },
      '& > p': {
        textStyle: 'semantic-text-md-font-regular',
        color: 'semantic-fg-accent-dark',
      },
    }),
  ),
  title: cx(
    css({
      textStyle: 'semantic-text-lg-font-bold',
      color: 'semantic-fg-accent-dark',
    }),
  ),
}

export const dataResolver = (
  brand: string,
  data: Contentful.Models.MosaicCarousel,
  isLead?: boolean,
  allSlugs?: PageSlug[],
): Promise<MediaGalleryCarouselConfig> =>
  genericDataResolver<
    Contentful.Models.MosaicCarousel,
    GalleryItem | InlineIconTextProps | null,
    MediaGalleryCarouselConfig
  >(
    data,
    brand,
    {
      showInNav: false,
      navTitle: '',
      title: '',
      titleSize: 'heading3',
      text: '',
      mediaItems: [],
      featureItems: [],
      cta: null,
      backgroundVariant: 'default',
    },
    {
      mediaItems: {
        collectionKey: 'items',
        resolver: async (item, context) => ({
          ...(await resolveGalleryItem(item as never, context, allSlugs)),
        }),
      },
      featureItems: {
        collectionKey: 'featureItems',
        resolver: async (item, context) => ({
          ...((await resolveIconText(item as never, context, allSlugs)) ||
            null),
        }),
      },
    },

    async (resolvedData, context): Promise<MediaGalleryCarouselConfig> => ({
      id: data.sys.id,
      showInNav: data.showInNav || false,
      navTitle: data.navTitle || '',
      title: data.title || '',
      titleTag: isLead ? 'h1' : 'h2',
      titleSize: (data.titleSize || 'heading3') as TitleProps['size'],
      text: data.text?.json ? resolveRichText(data.text.json) : '',
      mediaItems:
        resolvedData.mediaItems?.filter(
          (item): item is NonNullable<typeof item> => item !== null,
        ) || [],
      featureItems:
        resolvedData.featureItems
          ?.map((item) => ({
            ...item,
            classNames: itemStyles,
          }))
          ?.filter((item): item is NonNullable<typeof item> => item !== null) ||
        [],
      cta: data.cta
        ? (
            await resolveCta(data.cta, context.locale, context.brand, allSlugs)
          )[0] || null
        : null,
      backgroundVariant:
        data.background as MediaGalleryCarouselConfig['backgroundVariant'],
    }),
  )
