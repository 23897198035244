import { forwardRef } from 'react'

import Link from '@/global/components/link'

import { css, cx } from '@/styled-system/css'

import type { MenuItem } from './nav'

interface NavItemProps {
  item: MenuItem
}

export const NavItem = forwardRef<HTMLAnchorElement, NavItemProps>(
  ({ item }, ref) => {
    if (!item.href) return null

    return (
      <Link
        ref={ref}
        href={item.href}
        className={cx(
          css({
            display: 'inline-flex',
            alignItems: 'center',
            gap: 'semantic-spacing-8',
            fontSize: 'font-size-2',
            fontFamily: 'font-family-logotype',
            fontWeight: 'font-weight-semibold',
            textTransform: 'uppercase',
            minHeight: '96px',
            px: 'semantic-spacing-12',
            backgroundColor: 'transparent',
          }),
        )}
      >
        {item.title}
      </Link>
    )
  },
)

// Add a display name for the component (useful for debugging)
NavItem.displayName = 'NavItem'
