import type { EmblaCarouselType } from 'embla-carousel'
import omit from 'lodash/omit'

import { Button } from '@/components/button'
import { LightboxThumbnail } from '@/components/lightbox/lightbox-thumbnail'

import { ContentfulLivePreview } from '@contentful/live-preview'

import { css, cx } from '@/styled-system/css'
import { Box, Flex, Grid } from '@/styled-system/jsx'

import type { CarouselItem } from '@/types/components/media-gallery-carousel-tabs'

const EmblaSlide = ({ children }: React.PropsWithChildren) => {
  return <Box className="embla__slide">{children}</Box>
}

export function CarouselSlide({
  slide,
  openLightbox,
}: Readonly<{
  slide: CarouselItem
  slideInView: boolean
  emblaApi: EmblaCarouselType | undefined
  openLightbox: () => void
}>) {
  if (!slide?.image?.src) return null

  return (
    <EmblaSlide>
      <Flex
        {...ContentfulLivePreview.getProps({
          entryId: slide?.id,
          fieldId: 'title',
        })}
        borderRadius="semantic-border-radius-x-large"
        columnGap={{ md: 'semantic-spacing-8' }}
        flexDir="column"
        className={css({
          aspectRatio: '820/615',
        })}
      >
        <LightboxThumbnail
          slide={slide}
          onImageClick={openLightbox}
          mb={{ base: 'semantic-spacing-24', md: 'semantic-spacing-40' }}
        />
      </Flex>
      <Grid
        rowGap="semantic-spacing-24"
        mb={{ base: 'semantic-spacing-24', md: 'semantic-spacing-40' }}
      >
        <span
          className={css({
            textStyle: {
              base: 'semantic-text-md-font-bold',
              md: 'semantic-text-xl-font-bold',
            },
          })}
        >
          {slide.title}
        </span>
        <div
          className={css({
            '& > p': {
              '&:last-of-type': {
                mb: 'semantic-spacing-4',
              },
              textStyle: {
                base: 'semantic-text-md-font-regular',
                md: 'semantic-text-xl-font-regular-spaced',
              },
            },
          })}
        >
          {slide.text}
        </div>
        {slide?.cta?.href ? (
          <Button
            size={{ base: 'sm', md: 'lg' }}
            className={cx(
              css({
                width: 'fit-content',
              }),
            )}
            {...omit(slide.cta, 'label')}
          >
            {slide.cta.label}
          </Button>
        ) : null}
      </Grid>
    </EmblaSlide>
  )
}
